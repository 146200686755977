// actions.js
import { getDashboardAPI } from '../../api/Services';
import {

  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  
} from './actionTypes';
import { message } from 'antd';


// Get Payment Out Actions
export const getDashboardRequest = () => ({
  type: GET_DASHBOARD_REQUEST,
});

export const getDashboardSuccess = (response) => ({
  type: GET_DASHBOARD_SUCCESS,
  payload: response,
});

export const getDashboardFailure = (error) => ({
  type: GET_DASHBOARD_FAILURE,
  payload: error,
});


// Get dashboard app
export const getDashboardData = (data) => async (dispatch) => {
  dispatch(getDashboardRequest());
  try {
    const response = await getDashboardAPI(data); 
    if (response) {
      dispatch(getDashboardSuccess(response));
    }
    if (response?.error) {
      dispatch(getDashboardFailure(response));
      message.error(response?.error);
    }
    return response;
  } catch (error) {
    dispatch(getDashboardFailure(error.message));
    throw error;
  }
};
