import {
    SAVE_UNIT_REQUEST,
    SAVE_UNIT_SUCCESS,
    SAVE_UNIT_FAILURE,
    GET_UNITS_REQUEST,
    GET_UNITS_SUCCESS,
    GET_UNITS_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,
    
    saveUnit: {
        loading: false,
        error: null,
        response: null,
    },

    getUnits: {
        loading: false,
        error: null,
        response: null
    }
};

const unitReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_UNIT_REQUEST:
            return {
                ...state,
                saveUnit: {
                    ...state.saveUnit,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_UNIT_SUCCESS:
            return {
                ...state,
                saveUnit: {
                    ...state.saveUnit,
                    loading: false,
                    error: null,
                    response: action.payload
                },
                getUnits: {
                    ...state.getUnits,
                    loading: false,
                    error: null,
                    response: [...state.getUnits.response, action?.payload?.data]
                }
            };
        case SAVE_UNIT_FAILURE:
            return {
                ...state,
                saveUnit: {
                    ...state.saveUnit,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        case GET_UNITS_REQUEST:
            return {
                ...state,
                getUnits: {
                    ...state.getUnits,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_UNITS_SUCCESS:
            return {
                ...state,
                getUnits: {
                    ...state.getUnits,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_UNITS_FAILURE:
            return {
                ...state,
                getUnits: {
                    ...state.getUnits,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default unitReducer;
