import React from 'react';
import { useLocation } from 'react-router-dom';

function ExpensesView() {
    const location = useLocation();
    const data = location.state?.record;
    console.log(data);

    if (!data) {
        return <div>No data available.</div>;
    }

    // Assuming 'data.expense_items' is an array
    const tableData = data.expense_items || []; // Fallback to an empty array if undefined

    return (
        <div className="w-full p-5 bg-gray-200">
            <h2 className="text-2xl text-white text-center bg-purple-600 p-2 rounded-lg">
                Expenses Details
            </h2>
            <div className="mt-5 bg-white rounded-lg shadow-md p-5">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <span className="font-bold">Expense Number:</span>
                        <span className="ml-8">{data.exp_no}</span>
                    </div>
                    <div>
                        <span className="font-bold">Date:</span>
                        <span className="ml-10">{data.date}</span>
                    </div>
                    
                    <div>
                        <span className="font-bold">Payment Mode:</span>
                        <span className="ml-10">{data.payment_mode}</span>
                    </div>
                    <div>
                        <span className="font-bold">Total Amount:</span>
                        <span className="text-red-500 ml-2">₹ {data.expense_items?.reduce((acc, item) => acc + item.amount, 0)}</span>
                    </div>
                </div>
                <hr className="my-4" />
                <h4 className="text-lg font-semibold">Additional Information</h4>
                <div className="mt-2 bg-gray-100 rounded-lg p-4">
                    <table className="min-w-full">
                        <thead>
                            <tr className="border-b">
                                <th className="text-left p-2">Category</th>
                                <th className="text-left p-2">Description</th>
                                <th className="text-left p-2">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((item, index) => (
                                <tr key={index} className="border-b">
                                    <td className="p-2">{item.category_name}</td>
                                    <td className="p-2">{item.description}</td>
                                    <td className="p-2 text-red-500">₹ {item.amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <hr className="my-4" />
                <h4 className="text-lg font-semibold">Description</h4>
                <div className="mt-2 bg-gray-100 rounded-lg p-4">
                    <p>{data.description}</p>
                </div>
            </div>
        </div>
    );
}

export default ExpensesView;
