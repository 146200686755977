import React, { useEffect, useState } from 'react';
import { Button, Card, Select, DatePicker } from 'antd';
import DynamicTable from '../../tables/DynamicTable';
import { useDispatch, useSelector } from 'react-redux';
import { getLedgers } from './actions';
import AppListHeader from '../../header/AppListHeader';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';
import { getParties } from '../actions';
import secureLocalStorage from 'react-secure-storage';


const { RangePicker } = DatePicker;

function Ledger({ companyDetail }) {
  const dispatch = useDispatch();
  const ledgersResponse = useSelector(state => state.ledgers.getLedgers.response);
  const partyResponse = useSelector(state => state.parties.getParties.response);
  
  const [filterQuery, setFilterQuery] = useState({ party__id: companyDetail?.id });
  
  // State variables for company details
  const [user, setUser] = useState(null);
  const [companyLogo, setCompanyLogo] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyMobile, setCompanyMobile] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');

  useEffect(() => {
    const user = JSON.parse(secureLocalStorage.getItem("loggedInUser"));
    setUser(user);
    setCompanyLogo((user?.company_logo)?.split('?X-Amz-Algorithm=')[0]);
    setCompanyName(user?.company_name);
    setCompanyMobile(user?.mobile_number);
    setCompanyEmail(user?.email);
    if (user?.city && user?.state) {
      setCompanyAddress(`${user?.address}, ${user?.city}, ${user?.state}, ${user?.country}`);
    }
  }, []);

  useEffect(() => {
    getLedgerData();
    if (partyResponse?.party_count !== partyResponse?.parties?.results?.length) {
      dispatch(getParties());
    }
  }, [dispatch, filterQuery]);

  const getLedgerData = async () => {
    await dispatch(getLedgers(filterQuery));
  };

  const columns = [
    // { Header: 'Company Name', accessor: 'company_name' },
    { Header: 'Voucher.', accessor: 'inv_no' },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Voucher', accessor: 'voucher_type' },
    { Header: 'Party Type', accessor: 'party_type' },
    { Header: 'Amount', accessor: 'amount' },
  ];

  const tableColumns = columns.map(ledger => ({
    title: ledger.Header,
    dataIndex: ledger.accessor,
    key: ledger.accessor,
  }));

  const preparedLedgers = Array.isArray(ledgersResponse?.ledgers) ? 
    ledgersResponse.ledgers.map(ledger => ({
      party: ledger.party,
      date: ledger.date,
      inv_no: ledger.purchase_inv_no || ledger.sale_inv_no || ledger.id,
      company_name: ledger.company_name,
      amount: ledger.amount,
      party_type: ledger.party_type || '-',
      voucher_type: ledger.sale ? 'Sale' :
                    ledger.purchase ? 'Purchase' :
                    ledger.expense ? 'Expense' :
                    ledger.payment_in ? 'Payment In' :
                    ledger.payment_out ? 'Payment Out' :
                    '-',
    })) 
    : []; 
    const handlePrint = () => {
      const printContents = document.getElementById('printable-area').innerHTML;
      const newWindow = window.open('', '', 'height=600,width=800');
      
      newWindow.document.write('<style>');
      newWindow.document.write('body { font-family: Arial, sans-serif; margin: 0; padding: 0; }');
      newWindow.document.write('table { width: 100%; border-collapse: collapse; }');
      newWindow.document.write('th, td { border: 1px solid #ddd; padding: 8px; }');
      newWindow.document.write('th { background-color: #f2f2f2; }');
      newWindow.document.write('.flex { display: flex; justify-content: space-between; align-items: center; margin: 0; padding: 0; }');
      newWindow.document.write('.logo { width: 80px; height: auto; margin-right: 10px; }');
      newWindow.document.write('.details { text-align: right; margin: 0; padding: 0; }');
      newWindow.document.write('.details h2 { margin: 0; padding: 0; }');
      newWindow.document.write('.details p { margin: 0; padding: 0; }');
      newWindow.document.write('.divider { border: none; border-top: 1px solid #ddd; margin: 10px 0; }'); // Divider styles
      newWindow.document.write('</style>');
      newWindow.document.write('</head><body>');
      
      // Add company logo and details with flexbox
      newWindow.document.write(`
        <div class="flex">
          <img src="${companyLogo}" alt="Company Logo" class="logo" />
          <div class="details">
            <h2>${companyName}</h2>
            <p>${companyAddress}</p>
            <p>Phone: ${companyMobile}</p>
            <p>Email: ${companyEmail}</p>
          </div>
        </div>
        <hr class="divider" />
      `);
      
      // Remove the Ledger Report heading from the print contents
      const printContentWithoutHeading = printContents.replace(/<h2>Ledger Report<\/h2>/, '');
      newWindow.document.write(printContentWithoutHeading);
      
      newWindow.document.write('</body></html>');
      newWindow.document.close();
      newWindow.print();
    };
    
    
    

  return (
    <div>
      {!companyDetail && (
        <AppListHeader
          headerContent='Ledger Report'
          excel={true}
          handleExcelExport={() => {/* Export logic */}}
          print={true}
          download={false}
          cancel={true}
          printNavigation='/ListPrint'
          printData={{ tableColunm: tableColumns, tableData: preparedLedgers, name: 'Party Ledger List' }}
        />
      )}

      <div className="flex flex-wrap mb-4 shadow-md p-1">
        {!companyDetail && (
          <Select
            placeholder="Select Party"
            className='w-[250px] m-1'
            onChange={party => setFilterQuery(prev => ({ ...prev, party__id: party }))}
            allowClear
            showSearch
            optionFilterProp='children'
          >
            {partyResponse?.parties?.results?.map(data => (
              <Select.Option key={data.id} value={data.id}>{data.company_name}</Select.Option>
            ))}
          </Select>
        )}

        <Select
          placeholder="Select Voucher Type"
          className='w-[250px] m-1'
          onChange={type => setFilterQuery(prev => ({ ...prev, voucher: type }))} 
          allowClear
        >
          <Select.Option value='sale'>Sale</Select.Option>
          <Select.Option value='purchase'>Purchase</Select.Option>
          <Select.Option value='payment_in'>Payment In</Select.Option>
          <Select.Option value='payment_out'>Payment Out</Select.Option>
          <Select.Option value='expense'>Expense</Select.Option>
        </Select>
        
        <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={(dates, dateStrings) => {
          if (dates) {
            setFilterQuery(prev => ({ ...prev, date: dateStrings[0], date_lte: dateStrings[1] }));
          } else {
            setFilterQuery(prev => ({ ...prev, date: null, date_lte: null }));
          }
        }} />

        <Button className='bg-blue-600 text-white mt-1' onClick={handlePrint}>Print</Button>
      </div>

      <div id="printable-area" className="ledger-printable">
        <h2>Ledger Report</h2>
        {companyDetail ? (
          <Card title={companyDetail.company_name} size="small" className="border-2 border-gray-300 mb-4">
            <div className="flex justify-between">
              <div className="p-2 text-sm">
                To,<br />
                {companyDetail.company_name}<br />
                {companyDetail.address}, {companyDetail.city}, {companyDetail.state}, {companyDetail.zip_code}<br />
                {companyDetail.mobile_no}<br />
                {companyDetail.gst_no}
              </div>
              <Card className="balance border-2 border-gray-300 p-2 text-sm w-1/4">
                Current Balance<br />
                <hr className="my-1 border-gray-400" />
                <p className="font-bold">{companyDetail.current_balance}</p>
              </Card>
            </div>
          </Card>
        ) : null}

        <DynamicTable data={preparedLedgers} tableColumns={tableColumns} />
      </div>
    </div>
  );
}

export default Ledger;
