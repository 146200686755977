import { combineReducers } from 'redux';
import authReducer from '../component/globalFiles/authentication/authReducer';
import clientReducer from '../component/client/clientReducer';
import partyReducer from '../component/party/partyReducer';
import categoryReducer from '../component/product/category/categoryReducer';
import itemReducer from '../component/product/item/itemReducer';
import purchaseReducer from '../component/purchase/purchaseInvoice/purchaseReducer';
import quotationReducer from '../component/sale/quotation/quotationReducer';
import deliveryChallanReducer from '../component/sale/deliveryChallan/deliveryChallanReducer';
import paymentInReducer from '../component/sale/paymentIn/paymentInReducer'
import payementOutReducer from '../component/purchase/paymentOut/paymentOutReducer'
import purchaseOrderReducer from '../component/purchase/purchaseOrder/purchaseOrderReducer'
import saleReducer from '../component/sale/saleInvoice/saleReducer'
import ledgerReducer from '../component/party/ledger/ledgerReducer';
import dashboardReducer from '../component/dashboard/dashboardReducer';
import unitReducer from '../component/product/unit/unitReducer'
import dayBookReducer from '../component/report/dayBook/dayBookReducer';
import itemWiseReportReducer from '../component/report/itemWiseReport/ltemWiseReportReducer';
import paymentReportReducer from '../component/report/payment/paymentReportReducer';
import taxReducer from '../component/taxModule/TaxReducer'
import expenseNameReducer from '../component/expenses/categary/expenseNameReducer';
import expensesReducer from '../component/expenses/expenses/ExpensesReducer';

const rootReducer = combineReducers({
    emailVarifies: authReducer,
    clients: clientReducer,
    parties: partyReducer,
    categories: categoryReducer,
    items: itemReducer,
    purchase: purchaseReducer,
    quotations: quotationReducer,
    deliveryChallans: deliveryChallanReducer,
    paymentIn:paymentInReducer,
    paymentOut:payementOutReducer,
    // quotations: quotationReducer,
    // devileryChallans: deliveryChallanReducer,
    purchaseOrders: purchaseOrderReducer,
    sale:saleReducer,
    ledgers: ledgerReducer,
    dashboard: dashboardReducer,
    units: unitReducer,
    dayBooks: dayBookReducer,
    itemsWiseReport: itemWiseReportReducer,
    paymentReports: paymentReportReducer,
    taxDetails: taxReducer,
    expenseNames: expenseNameReducer,
    expenses: expensesReducer,
  });
  
  export default rootReducer;