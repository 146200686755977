import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getItemWiseReport } from './actions'
import InvoiceList from './InvoiceList'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Select, Tooltip, DatePicker, message,Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import AppListHeader from '../../header/AppListHeader'
import * as XLSX from 'xlsx';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods'
import { getParties } from '../../party/actions'
import { deleteSale } from '../../sale/saleInvoice/actions'
import { deletePurchase } from '../../purchase/purchaseInvoice/actions'

const { RangePicker } = DatePicker;

export default function ItemInvoiceReport() {

    const dispatch = useDispatch()
    const iteminvoiceResponse = useSelector(state => state.itemsWiseReport.getItemWiseReport.response)
    const partyResponse = useSelector(state => state.parties.getParties.response)
    const saleDeleteResponse = useSelector(state => state.sale.deleteSale.response)
    const purchaseDeleteResponse = useSelector(state => state.purchase.deletePurchase.response)

    const location = useLocation()

    const [filterQuery, setFilterQuery] = useState({ item: location?.state?.item?.id })

    const navigate = useNavigate();

    useEffect(() => {
        getItemsWiseReportData()
        /**
         * handle party state
         */
        if (
            partyResponse?.party_count == partyResponse?.parties?.results.length &&
            partyResponse?.parties?.results.length > 0
        ) {

        }
        else {
            dispatch(getParties())
        }
        /**
         * end of handling party state
         */
    }, [dispatch, filterQuery, saleDeleteResponse, purchaseDeleteResponse])

    const getItemsWiseReportData = async () => {
        const response = await dispatch(getItemWiseReport(filterQuery))
    }

    /**
     * To create for header and access key
     */
    const columns = [
        { Header: 'Date', accessor: 'date' },
        { Header: 'Party Name', accessor: 'billed_to_company_name' },
        // { Header: 'Invoice Number', accessor: 'sale_inv_no' },
        // { Header: 'original Invoice Number', accessor: 'original_inv_no' },
        { Header: 'Amount', accessor: 'total_amount' },
        { Header: 'Balance Amount', accessor: 'balance_amount' },
        { Header: 'Status', accessor: 'payment_status' },
    ];

    // Function to handle navigation
    // const handleView = (record) => {
    //     navigate("/sale-invoice-bill", { state: { record: record } });
    // };

    const handleView = (record) => {
        navigate("/invoice2/print-dashboard", { state: { record } });
    };


    // const handleDelete = async (record) => {
    //     const confirmed = window.confirm("Are you sure you want to delete this invoice?");
    //     if (confirmed) {
    //         await dispatch(deleteItemWiseReportRequest(record.id)); 
    //         getItemsWiseReportData(); 
    //     }
    // };

    const handleDelete = async (data) => {
        if (data?.id) {
            if (data?.sale_inv_no) {
                const response = await dispatch(deleteSale(data?.id))
            } else if (data?.purchase_inv_no) {
                const response = await dispatch(deletePurchase(data?.id))
            } else {
                message.error("Something went wrong")
            }
        } else {
            message.error("Please Select Data")
        }
    }


    // Convert columns to the format expected by Ant Design Table
    var tableColumns = columns.map((column) => ({
        title: column.Header,
        dataIndex: column.accessor,
        key: column.accessor,
        sorter: ["total_amount", 'balance_amount', 'date'].includes(column.accessor),
        ellipsis: true,
    }));

    const actionColumn = {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <span className="flex space-x-2">
                <Tooltip title="View" color="black">
                    <button className="p-1 mx-0.5 bg-black rounded" onClick={() => handleView(record)}>
                        <EyeOutlined className="text-white p-0 m-0" />
                    </button>
                </Tooltip>
                {/* <Tooltip title="Delete" color="red">
                    <button className="p-1 mx-0.5 bg-red-500 rounded" >
                        <DeleteOutlined className="text-white p-0 m-0" onClick={() => handleDelete(record)} />
                    </button>
                </Tooltip> */}
                <Popconfirm
                    placement="topRight"
                    title='object delete'
                    description='Are you sure want to delete'
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleDelete(record)}
                >
                    <Button disabled={record?.payment_status === 'paid' ? true : false} className="p-1 mx-0.5 bg-red-500 rounded">
                        <DeleteOutlined className="text-black p-0 m-0" />
                    </Button>
                </Popconfirm>
                {/* <Tooltip title="print" color="blue">
                    <button className="p-1 mx-0.5 bg-blue-500 rounded" onClick={() => handlePrint(record)}>
                        <p className='bg-blue-500 text-white'>Print</p>
                    </button>
                </Tooltip> */}
            </span>
        ),
    };

    const voucherTypeColumn = {
        title: 'Voucher Type',
        key: 'voucher_type',
        render: (_, record) => (
            <span className="flex space-x-2">
                <p className='p-[2px] px-2 rounded-md border'>{record.sale_inv_no ? 'Sale' : 'Purchase'}</p>
            </span>
        ),
    };

    tableColumns = [...tableColumns, voucherTypeColumn, actionColumn];

    const handlePartyChange = (party) => {
        setFilterQuery({
            ...filterQuery,
            party: party
        })
    }

    const handleVoucherTypeChange = (type) => {
        setFilterQuery({
            ...filterQuery,
            voucher_type: type
        })
    }

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            setFilterQuery({
                ...filterQuery,
                start_date: dateStrings[0],
                end_date: dateStrings[1]
            })
        } else {
            console.log('Clear');
        }
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order == 'ascend' ?
                `${sorter?.field}`
                :
                sorter?.order == 'descend' ?
                    `-${sorter?.field}`
                    :
                    null,
        })
    }

    /**
     * EXPORT TO EXCEL
     */
    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Assuming quotationDatas is the list you want to export
        const wsData = (iteminvoiceResponse?.combined_invoices || iteminvoiceResponse?.purchases || iteminvoiceResponse?.sales).map((e) => [
            e.date,
            e.sale_inv_no || e.purchase_inv_no,
            e.billed_to_company_name,
            e.total_amount,
            e.balance_amount,
            e.payment_status,
        ]) || [];

        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'Date',
                'Invoice No',
                'Party Name',
                'Total Amount',
                'Balance Amount',
                'Payment Status'
            ],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'ItemWiseReport');
        XLSX.writeFile(workbook, 'ItemWiseReport.xlsx');
    };


    return (
        <div>

            <AppListHeader
                headerContent={'Item Wise Invoices - ' + location?.state?.item?.item_name}
                excel={true}
                handleExcelExport={handleExportToExcel}
                print={true}
                download={false}
                cancel={true}
                printNavigation='/ListPrint'
                printData={{ tableColunm: tableColumns, tableData: iteminvoiceResponse?.combined_invoices || iteminvoiceResponse?.purchases || iteminvoiceResponse?.sales, name: ' Payment List' }}
            />

            <div className="flex flex-wrap mb-4 shadow-md p-1">
                <Select
                    placeholder="Select Party"
                    className='w-[250px] m-1'
                    onChange={handlePartyChange}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                >
                    {partyResponse?.parties?.results?.map((data, id) => (
                        <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
                    ))}
                </Select>

                <Select
                    placeholder="Select Voucer Type"
                    className='w-[250px] m-1'
                    onChange={handleVoucherTypeChange}
                    allowClear
                >
                    <Select.Option value='sale'>Sale</Select.Option>
                    <Select.Option value='purchase'>Purchase</Select.Option>
                </Select>
                <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={onRangeChange} />
            </div>


            {/* <div className="container p-4 m-auto flex flex-col w-fit justify-center items-center">
                <h1 className="text-2xl font-bold mb-4">Demo Org</h1>
                <h2 className="text-xl font-bold mb-4">Item Wise Invoices</h2>
                <h2 className="text-xl font-bold mb-4">{location?.state?.item?.item_name}</h2>
                <p className="text-sm mb-4">From 21/01/2020 To 21/01/2020</p>
            </div> */}


            <InvoiceList
                tableColumns={tableColumns}
                data={iteminvoiceResponse?.combined_invoices || iteminvoiceResponse?.purchases || iteminvoiceResponse?.sales}
                handleTableChange={handleTableChange}
            />
        </div>
    )
}
