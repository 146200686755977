import React from 'react';
import { Avatar, Button, Typography, Space } from 'antd';
import { MailOutlined, SettingOutlined, UserOutlined, LogoutOutlined, VideoCameraOutlined, EditOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
const { Title, Text, Link } = Typography;

const UserProfile = ({ onClose }) => {
  const user = JSON.parse(secureLocalStorage.getItem('loggedInUser'));
  return (
    <div style={{ padding: '10px', flex: '1 1 auto', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

      <Space direction="vertical" style={{ width: '100%' }} size="small">
        <Space direction="horizontal" style={{ width: '100%' }} align="center">
          <Space direction="horizontal" align="center">
            {user.company_logo ? (
              <Avatar size={50} src={user.company_logo.split('?X-Amz-Algorithm=')[0]} />
            ) : (
              <Avatar size={50} icon={<UserOutlined />} />
            )}
            <div>
              <Title level={4} style={{ margin: 0, fontSize: '18px' }}>{user.company_name}</Title>
              <Text>{user.email}</Text>
              <NavLink to="/update-client">
                <Button onClick={ onClose } type="link" icon={<EditOutlined />} className=''>Edit Profile</Button>
              </NavLink>
            </div>
          </Space>
        </Space>
        <Text>User ID: {user.id}</Text>
        <Text>Name : {user.owner_name}</Text>
        <Text>Phone: {user.mobile_number}</Text>
        <Text>City: {user.city}</Text>


        <Text>Address: {user.address}</Text>
        {/* <Link href="#">My Account</Link> */}
        {/* <Button type="link" icon={<LogoutOutlined />} style={{ fontSize: '14px' }}>Sign Out</Button>

        <Title level={5}>Need Assistance?</Title>
        <Button type="link" icon={<MailOutlined />} style={{ fontSize: '14px' }}>Send an email</Button>
        <Button type="link" icon={<VideoCameraOutlined />} style={{ fontSize: '14px' }}>Register for webinars</Button>
        <Button type="link" icon={<SettingOutlined />} style={{ fontSize: '14px' }}>Early Access Features</Button> */}
      </Space>
    </div>
  );
};

export default UserProfile;
