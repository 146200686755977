// reducer.js
import {
    SAVE_PAYMENT_OUT_REQUEST,
    SAVE_PAYMENT_OUT_SUCCESS,
    SAVE_PAYMENT_OUT_FAILURE,

    GET_PAYMENT_OUTS_REQUEST,
    GET_PAYMENT_OUTS_SUCCESS,
    GET_PAYMENT_OUTS_FAILURE,

    DELETE_PAYMENT_OUTS_REQUEST,
    DELETE_PAYMENT_OUTS_SUCCESS,
    DELETE_PAYMENT_OUTS_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    savePaymentOut: {
        loading: false,
        error: null,
        response: null,
    },

    getPaymentOuts: {
        loading: false,
        error: null,
        response: null,
    },

    deletePaymentOut: {
        loading: false,
        error: null,
        response: null,
    }
};

const paymentOutReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PAYMENT_OUT_REQUEST:
            return {
                ...state,
                savePaymentOut: {
                    ...state.savePaymentOut,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_PAYMENT_OUT_SUCCESS:
            return {
                ...state,
                savePaymentOut: {
                    ...state.savePaymentOut,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case SAVE_PAYMENT_OUT_FAILURE:
            return {
                ...state,
                savePaymentOut: {
                    ...state.savePaymentOut,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        case GET_PAYMENT_OUTS_REQUEST:
            return {
                ...state,
                getPaymentOuts: {
                    ...state.getPaymentOuts,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_PAYMENT_OUTS_SUCCESS:
            return {
                ...state,
                getPaymentOuts: {
                    ...state.getPaymentOuts,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_PAYMENT_OUTS_FAILURE:
            return {
                ...state,
                getPaymentOuts: {
                    ...state.getPaymentOuts,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        case DELETE_PAYMENT_OUTS_REQUEST:
            return {
                ...state,
                deletePaymentOut: {
                    ...state.deletePaymentOut,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case DELETE_PAYMENT_OUTS_SUCCESS:
            return {
                ...state,
                deletePaymentOut: {
                    ...state.deletePaymentOut,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case DELETE_PAYMENT_OUTS_FAILURE:
            return {
                ...state,
                deletePaymentOut: {
                    ...state.deletePaymentOut,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default paymentOutReducer;
