import React, { useEffect, useState } from 'react';
import ExpenseNameForm from './ExpenseNameForm';
import ExpenseNameList from './ExpenseCategoryList';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenseNames } from './actions';

function ExpenseCategoryContained() {

    const [newExpenseName, setNewExpenseName] = useState(false);

    const handleExpenseNameOpen = () => {
        setNewExpenseName(true);
    };

    return (
        <div>
            <div className='m-auto'>
                {newExpenseName ?
                    <ExpenseNameForm setNewExpenseName={setNewExpenseName} />
                    :
                    <Button
                        className='float-right mr-5 my-2 bg-[#a854f9] text-white' 
                        onClick={handleExpenseNameOpen}>
                        Create Expense Category Name
                    </Button>
                }
            </div>
            <br/>
            <ExpenseNameList />
        </div>
    );
}

export default ExpenseCategoryContained;
