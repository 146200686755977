import React, { useEffect, useState } from 'react'
import DymanicTable from '../../tables/DynamicTable'
import { useDispatch, useSelector } from 'react-redux';
import { getUnits } from './actions';
// import { getCategories } from './actions';


export default function UnitList() {

  const dispatch = useDispatch()

  const response = useSelector(state => state.units.getUnits.response)
  const loading = useSelector(state => state.units.getUnits.loading)

  useEffect(() => {
    dispatch(getUnits())
  }, [dispatch])

  const columns = [
    { Header: 'Unit Name', accessor: 'name' },
    // { Header: 'Created Date', accessor: 'created_at' },
    // { Header: 'Modified Date', accessor: 'updated_at' },
  ];
  const tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
  }));

  return (
    <div>
      <DymanicTable columns={columns} data={response} tableColumns={tableColumns} />
    </div>
  )
}