// reducer.js
import {
    SAVE_QUOTATION_REQUEST,
    SAVE_QUOTATION_SUCCESS,
    SAVE_QUOTATION_FAILURE,

    GET_QUOTATIONS_REQUEST,
    GET_QUOTATIONS_SUCCESS,
    GET_QUOTATIONS_FAILURE,

    DELETE_QUOTATIONS_REQUEST,
    DELETE_QUOTATIONS_SUCCESS,
    DELETE_QUOTATIONS_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    saveQuotation: {
        loading: false,
        error: null,
        response: null,
    },

    getQuotations: {
        loading: false,
        error: null,
        response: null,
    },

    deleteQuotation: {
        loading: false,
        error: null,
        response: null,
    }
};

const quotationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_QUOTATION_REQUEST:
            return {
                ...state,
                saveQuotation: {
                    ...state.saveQuotation,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_QUOTATION_SUCCESS:
            return {
                ...state,
                saveQuotation: {
                    ...state.saveQuotation,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case SAVE_QUOTATION_FAILURE:
            return {
                ...state,
                saveQuotation: {
                    ...state.saveQuotation,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        case GET_QUOTATIONS_REQUEST:
            return {
                ...state,
                getQuotations: {
                    ...state.getQuotations,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_QUOTATIONS_SUCCESS:
            return {
                ...state,
                getQuotations: {
                    ...state.getQuotations,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_QUOTATIONS_FAILURE:
            return {
                ...state,
                getQuotations: {
                    ...state.getQuotations,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        // Handle delete actions
        case DELETE_QUOTATIONS_REQUEST:
            return {
                ...state,
                deleteQuotation: {
                    ...state.deleteQuotation,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case DELETE_QUOTATIONS_SUCCESS:
            return {
                ...state,
                deleteQuotation: {
                    ...state.deleteQuotation,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case DELETE_QUOTATIONS_FAILURE:
            return {
                ...state,
                deleteQuotation: {
                    ...state.deleteQuotation,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default quotationReducer;
