import React, { useEffect, useState } from 'react';
import { DownOutlined, DownloadOutlined } from '@ant-design/icons';
import { Input, Dropdown, Button, Select, DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import QuotationList from './QuotationList'; // Ensure this import matches the file name
import { useDispatch, useSelector } from 'react-redux';
import { getQuotations } from './actions'; // Assuming this is the correct action
import * as XLSX from 'xlsx';
import AppListHeader from '../../header/AppListHeader';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';
import { getParties } from '../../party/actions';

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function QuotationDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const quotationDatas = useSelector(state => state.quotations.getQuotations.response);
    const partyResponse = useSelector(state => state.parties.getParties.response)
    const quotationDeleteResponse = useSelector(state => state.quotations.deleteQuotation.response);


    const [filterQuery, setFilterQuery] = useState(null)

    useEffect(() => {
        dispatch(getQuotations());
        /**
         * handle party state
         */
        if (
            partyResponse?.party_count == partyResponse?.parties?.results?.length &&
            partyResponse?.parties?.results?.length > 0
        ) {
        }
        else {
            dispatch(getParties())
        }
        /**
         * end of handling party state
         */
    }, [dispatch, quotationDeleteResponse]);

    const handlePrint = () => {
        console.log(quotationDatas);
        navigate('/quotation-bill/print', { state: { quotationDatas } });
    };

    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Assuming quotationDatas is the list you want to export
        const wsData = quotationDatas?.quotations?.results?.map((e) => [
            e.date,
            e.quotation_no,
            e.billed_to_company_name,
            e.total_amount,
        ]) || [];

        const worksheet = XLSX.utils.aoa_to_sheet([
            ['Date', 'Quotation Number', 'Party Name', 'Total Amount'],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
        XLSX.writeFile(workbook, 'Quotations.xlsx');
    };

    const navigateToQuotationForm = () => {
        navigate('/sales/quotation-form');
    };


    const handlePartyChange = (party) => {
        setFilterQuery({
            ...filterQuery,
            party: party
        })
    }

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            setFilterQuery({
                ...filterQuery,
                date: dateStrings[0],
                date_lte: dateStrings[1]
            })
        } else {
            console.log('Clear');
            setFilterQuery({
                ...filterQuery,
                date: null,
                date_lte: null
            })
        }
    };

    const columns = [
        { title: 'Date', dataIndex: 'date' },
        { title: 'Quotation Number', dataIndex: 'quotation_no' },
        { title: 'Party Name', dataIndex: 'billed_to_company_name' },
        { title: 'Total Amount', dataIndex: 'total_amount' },
    ];

    return (
        <div className=''>
            <AppListHeader
                headerContent='Quotations'
                creationLable="Create Quotation"
                handleCreateNew={navigateToQuotationForm}
                excel={true}
                handleExcelExport={handleExportToExcel}
                print={true}
                download={true}
                cancel={true}
                printNavigation='/ListPrint'
                printData={{ tableColunm: columns, tableData: quotationDatas?.quotations?.results, name: 'Quotation List' }}
            />

            <div className="flex flex-wrap mb-4 shadow-md p-1">
                <Select
                    placeholder="Select Party"
                    className='w-[250px] m-1'
                    onChange={handlePartyChange}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                >
                    {partyResponse?.parties?.results?.map((data, id) => (
                        <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
                    ))}
                </Select>
                <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={onRangeChange} />
            </div>
            <QuotationList quotationDatas={quotationDatas?.quotations?.results} />
        </div>
    );
}