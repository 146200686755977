import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import { EditOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const GstR3List = ({ gstR3Report, handleTableChange }) => {
  const navigate = useNavigate();

  const columns = [
    // { title: 'Invoice Number', dataIndex: 'invoiceNumber', key: 'invoiceNumber', sorter: true, ellipsis: true },
    { title: 'Date', dataIndex: 'date', key: 'date', sorter: true},
    { title: 'Party Name', dataIndex: 'company_name', key: 'company_name'},
    { title: 'Taxable Value', dataIndex: 'sub_total', key: 'sub_total', sorter: true, ellipsis: true },
    { title: 'CGST', dataIndex: 'cgst', key: 'cgst', ellipsis: true },
    { title: 'SGST', dataIndex: 'sgst', key: 'sgst', ellipsis: true },
    { title: 'IGST', dataIndex: 'igst', key: 'igst', ellipsis: true },
    { title: 'GST Tax', dataIndex: 'gst_amount', key: 'gst_amount', sorter: true, ellipsis: true },
    { title: 'Amount', dataIndex: 'amount', key: 'amount'},
  ];

  const handleView = (record) => {
    // Implement view functionality
    console.log('Viewing record:', record);
  };

  // Add an action column
  columns.push({
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <span className="flex space-x-2">
        <Tooltip title="View" color="black">
          <button className="p-1 mx-0.5 bg-black rounded" onClick={() => handleView(record)}>
            <EyeOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip>
        <Tooltip title="Edit" color="blue">
          <button className="p-1 mx-0.5 bg-blue-500 rounded">
            <EditOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip>
      </span>
    ),
  });

  return (
    <div className="overflow-scroll scrollbar-thin scrollbar-thumb-red-500 scrollbar-track-orange-200 mt-4">
      <Table
        size='small'
        columns={columns}
        dataSource={gstR3Report}
        style={{ fontSize: 'xx-large' }}
        bordered
        pagination={false}
        onChange={handleTableChange}
        rowKey={(record, index) => index}
        // loading={loading}
      />
    </div>
  );
};

export default GstR3List;