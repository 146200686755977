import React from 'react'
import abountImg from '../../images/about.PNG'

import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css/animate.min.css'; 

export default function About() {
    // useEffect(() => {
    //     AOS.init({
    //       duration: 1000, 
    //       easing: 'ease-in-out',
    //     //   once: true,
    //     });
    //   }, []);
    return (
        <div 
        id="about"
            className="
            flex flex-col md:flex-row items-center md:p-8 p-4
            md:h-[75vh] 
            "
        >
            <div
                className="
                md:w-1/3 w-[100%] shadow-xl h-[100%]
                "
            >
                <img
                    src={abountImg}
                    alt="Billing System"
                    className="
                    rounded shadow-md w-[100%] h-[100%]
                    "
                    data-aos="fade-up"
                    // data-aos='fade-in-bottom-left'
                  
                />
            </div>
            <div
                className="
                w-full md:w-2/3 p-4 m-5 shadow-xl text-center h-[100%]
                flex items-center sm:p-20 p-2 bg-[rgba(255,255,255,0.7)]
                
                "
            >
                <div
                >
                    <h2
                        className="
                        text-3xl font-bold my-3 font-semibold drop-shadow  "
                           data-aos="fade-up"
                    >
                        About Us
                    </h2>

                    <div
                        className='
                        border-solid border-2 border-[red] w-[20%] m-auto my-5
                        '
                    >

                    </div>

                    <p
                        className="text-lg leading-8 drop-shadow "
                           data-aos="fade-up"
                    >
                        Welcome to our online billing system, where managing your business has never been easier.
                        Our platform offers comprehensive features including parties, products, sales, purchases,
                        stock management, and detailed reports, all accessible from a user-friendly dashboard.
                    </p>
                </div>
            </div>
        </div>
    )
}