// import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
// import Auth from './component/globalFiles/authentication/Auth';
// import Head from './component/globalFiles/Head';
// import LandingPage from './component/globalFiles/LandingPage';
import { useEffect } from 'react';
import Header from '../component/globalFiles/Header';
import LandingPage from '../component/globalFiles/LandingPage';
import Auth from '../component/globalFiles/authentication/Auth';
import Login from '../component/globalFiles/authentication/Login';
import './UnAuthenticatedRoutes.css'
import UnAuthHeader from '../component/globalFiles/UnAuthHeader';
import About from '../component/globalFiles/About';
import Feature from '../component/globalFiles/Feature';
import MainContent from '../component/globalFiles/MainContant';
import Pricing from '../component/globalFiles/Pricing';


function UnAuthenticatedRotes() {

    return (
        <div className='un-route-container'>
            {/* <Header /> */}
            <UnAuthHeader />
            <div className='unAuthBg'>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/about" element={<About />} />
                <Route path="/feature" element={<Feature />} />
                <Route path="/Contact" element={<MainContent />} />
                <Route path="/Princing" element={<Pricing />} />

                <Route path="/register" element={<Auth name="register" />} />
                <Route path="/login" element={<Auth name="login" />} />

                <Route path="/email-verify" element={<Auth name="varify email" />} />
                <Route path="/reset-password-link" element={<Auth name="reset password link" />} />
                <Route path="/reset-password/:token" element={<Auth name="reset password" />} />
                <Route path="*" element={<div className='mt-4 text-[25px]'> not found</div>} />
            </Routes>
            </div>
        </div>
    );
}

export default UnAuthenticatedRotes;