import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Card, Form, Input, Button, Select, Row, Col, message, Divider, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { saveExpenses } from './actions'
import { getExpenseCategories } from '../categary/actions';
import { Link, useNavigate } from 'react-router-dom'
import ExpenseCategoryContained from '../categary/ExpenseCategoryContained';


const { TextArea } = Input;


const CreateExpense = () => {
    const [form] = Form.useForm();
    const [totalExpense, setTotalExpense] = useState(0);
    const [items, setItems] = useState([]);
    const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);

    const dispatch = useDispatch()


    // Ensure that responseExpenseCategory is always an array
    const responseExpensesCategory = useSelector(state => state.expenseNames.getExpenseNames.response);
    const navigate = useNavigate()


    useEffect(() => {
        if (responseExpensesCategory?.expense_categories_count == responseExpensesCategory?.expense_categories?.length &&
            responseExpensesCategory?.expense_categories?.length > 0
        ) {
        }
        else (
            dispatch(getExpenseCategories())
        )
    }, [dispatch])


    const onFinish = async (values) => {
        const formattedValues = {
            date: values.date,
            payment_type: values.payment_mode || '',
            description: values.description || '',
            exp_no: values.expense_number || '',
            total_amount: totalExpense || 0,
            expense_items: items.map(item => ({
                category: item.item,
                description: item.description || '',
                amount: item.amount || 0
            }))
        };
             
        try {
            const response = await dispatch(saveExpenses(formattedValues));
            if (response?.success) {
                navigate('/expenses'); // redirect as needed
            } else {
                message.error(response.message || 'Failed to save expense. Please try again.');
            }
        } catch (error) {
            message.error(error?.message || 'An unexpected error occurred. Please try again.');
        }
    };

    const handleItemChange = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        setItems(newItems);
        form.setFieldsValue({ items: newItems });
        updateTotalExpense(newItems);
    };

    const updateTotalExpense = (updatedItems) => {
        const total = updatedItems.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
        setTotalExpense(total.toFixed(2));
    };

    const handleAddItem = () => {
        const newItem = {
            id: items.length + 1,
            item: '',
            description: '',
            amount: 0,
        };

        const updatedItems = [...items, newItem];
        setItems(updatedItems);
        form.setFieldsValue({ items: updatedItems });
        updateTotalExpense(updatedItems);
    };

    return (
        <div className="">
            <Modal
                title="Create Expense Category"
                visible={isCategoryModalVisible}
                onCancel={() => setIsCategoryModalVisible(false)}
                footer={null}
            >
                <ExpenseCategoryContained />
            </Modal>
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
            >
                <div className="flex justify-between mb-4">
                    <h2 className="text-2xl font-semibold">Expense Invoice</h2>
                    <div className="flex space-x-2">
                        <Button type="primary" htmlType="submit" className="bg-purple-500 border-none shadow-inner">
                            <b>Save</b>
                        </Button>
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                            onClick={() => navigate(-1)}
                        >
                            <b>Cancel</b>
                        </Button>
                    </div>
                </div>

                <Row gutter={16} className='mb-4'>
                    <Col span={10}>
                        <Card size='small' className='h-[100%]'>
                            <Form.Item
                                name="expense_number"
                                label="Expense Number"
                                rules={[{ required: true, message: 'Please enter an expense number' }]}
                                className='mb-2 p-0'
                            >
                                <Input placeholder="Enter Expense Number" />
                            </Form.Item>

                            {/* <div className="flex flex-wrap gap-4"> */}
                            <Form.Item
                                name="date"
                                label="Date"
                                rules={[{ required: true, message: 'Please select a date' }]}
                                className="flex-1 min-w-auto mb-2 p-0"
                            >
                                <Input type='date' style={{ width: '100%' }} />
                            </Form.Item>
                            {/* </div> */}
                        </Card>
                    </Col>

                    <Col span={14} >
                        <Card size='small'>

                            <Form.Item
                                name="payment_mode"
                                label="Payment Mode"
                                rules={[{ required: true, message: 'Please select a payment mode' }]}
                                className='mb-2 p-0'
                            >
                                <Select placeholder="Select Payment Mode">
                                    <Select.Option value="CASH">CASH</Select.Option>
                                    <Select.Option value="UPI">UPI</Select.Option>
                                    <Select.Option value="CARD">CARD</Select.Option>
                                    <Select.Option value="BANK">BANK</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label="Description"
                            >
                                <TextArea rows={3} placeholder="Enter Notes" />
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>

                <Form.List name="items">
                    {(fields, { add, remove }) => (
                        <div className='shadow-md w-auto'>
                            <table className="w-full mb-6 border-collapse">
                                <thead>
                                    <tr>
                                        <th className="p-2 border border-gray-300">NO</th>
                                        <th className="p-2 border border-gray-300">Expense Category</th>
                                        <th className="p-2 border border-gray-300">Description</th>
                                        <th className="p-2 border border-gray-300">AMOUNT (₹)</th>
                                        <th className="p-2 border border-gray-300">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                        <tr key={key}>
                                            <td className="p-2 border border-gray-300">{index + 1}</td>
                                            <td className="p-2 border border-gray-300">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'item']}
                                                    fieldKey={[fieldKey, 'item']}
                                                    rules={[{ required: true, message: 'Please select an expense category' }]}
                                                    className="mb-0"
                                                >
                                                    <Select
                                                        onChange={(value) => handleItemChange(index, 'item', value)}
                                                        placeholder="Select Category"
                                                        showSearch
                                                        optionFilterProp='children'
                                                        dropdownRender={menu => (
                                                            <>
                                                                <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
                                                                    <Link
                                                                        onClick={() => setIsCategoryModalVisible(!isCategoryModalVisible)}
                                                                        className='flex ml-auto text-blue-500'
                                                                    >
                                                                        New category
                                                                    </Link>
                                                                </div>
                                                                <Divider style={{ margin: '0px 0' }} />
                                                                {menu}
                                                            </>
                                                        )}
                                                    >
                                                        {Array.isArray(responseExpensesCategory?.expense_categories) && responseExpensesCategory?.expense_categories.length > 0 ? (
                                                            responseExpensesCategory.expense_categories
                                                                .filter(dt => dt.id && dt.category_name)  // Filter out invalid data
                                                                .map(dt => (
                                                                    <Select.Option key={dt.id} value={dt.id}>
                                                                        {dt.category_name}
                                                                    </Select.Option>
                                                                ))
                                                        ) : (
                                                            <Select.Option disabled>No categories available</Select.Option>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'description']}
                                                    fieldKey={[fieldKey, 'description']}
                                                    className="mb-0"
                                                >
                                                    <Input
                                                        onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'amount']}
                                                    fieldKey={[fieldKey, 'amount']}
                                                    className="mb-0"
                                                >
                                                    <Input
                                                        onChange={(e) => handleItemChange(index, 'amount', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                <Button
                                                    type="danger"
                                                    onClick={() => {
                                                        const newItems = [...items];
                                                        newItems.splice(index, 1);
                                                        setItems(newItems);
                                                        form.setFieldsValue({ items: newItems });
                                                        updateTotalExpense(newItems);
                                                    }}
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </td>

                                        </tr>


                                    ))}
                                    <tr>
                                        <td colSpan="9" className="p-2 border border-gray-300 text-xs">
                                            <Button type="dashed" onClick={handleAddItem} block>
                                                + Add Item
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </Form.List>
                <Card>
                    <div className='flex justify-between'>
                        <h2><b>Total Expense Amount:</b></h2>
                        <h2 className='text-green-400'>₹{totalExpense}</h2>

                    </div>
                </Card>
            </Form>
        </div>
    );
};

export default CreateExpense;