// Actions Types for clents

export const SAVE_PaymentIn_REQUEST = 'SAVE_PaymentIn_REQUEST';
export const SAVE_PaymentIn_SUCCESS = 'SAVE_PaymentIn_SUCCESS';
export const SAVE_PaymentIn_FAILURE = 'SAVE_PaymentIn_FAILURE';

export const GET_PaymentIn_REQUEST = 'GET_PaymentIn_REQUEST';
export const GET_PaymentIn_SUCCESS = 'GET_PaymentIn_SUCCESS';
export const GET_PaymentIn_FAILURE = 'GET_PaymentIn_FAILURE';

export const GET_PENDING_SALES_REQUEST = 'GET_PENDING_SALES_REQUEST';
export const GET_PENDING_SALES_SUCCESS = 'GET_PENDING_SALES_SUCCESS';
export const GET_PENDING_SALES_FAILURE = 'GET_PENDING_SALES_FAILURE';



export const DELETE_PAYMENTIN_FAILURE = 'DELETE_PAYMENTIN_FAILURE';
export const DELETE_PAYMENTIN_SUCCESS = 'DELETE_PAYMENTIN_SUCCESS';
export const DELETE_PAYMENTIN_REQUEST = 'DELETE_PAYMENTIN_REQUEST';
