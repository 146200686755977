// reducer.js
import {
    SEND_OTP_REQUEST,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAILURE,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    sendOtp: {
        loading: false,
        error: null,
        response: null
    },
    verifyOtp: {
        loading: false,
        error: null,
        response: null
    },
    login: {
        loading: false,
        error: null,
        response: null
    }
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_OTP_REQUEST:
            return {
                ...state,
                sendOtp: {
                    ...state.sendOtp,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SEND_OTP_SUCCESS:
            return {
                ...state,
                sendOtp: {
                    ...state.sendOtp,
                    loading: false,
                    response: action.payload
                }
            };
        case SEND_OTP_FAILURE:
            return {
                ...state,
                sendOtp: {
                    ...state.sendOtp,
                    loading: false,
                    error: action.payload
                }
            };

        case VERIFY_OTP_REQUEST:
            return {
                ...state,
                verifyOtp: {
                    ...state.verifyOtp,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                verifyOtp: {
                    ...state.verifyOtp,
                    loading: false,
                    response: action.payload
                }
            };
        case VERIFY_OTP_FAILURE:
            return {
                ...state,
                verifyOtp: {
                    ...state.verifyOtp,
                    loading: false,
                    error: action.payload
                }
            };

        case LOGIN_REQUEST:
            return {
                ...state,
                login: {
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: {
                    loading: false,
                    response: action.payload
                }
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                login: {
                    loading: false,
                    error: action.payload
                }
            };
        default:
            return state;
    }
};

export default authReducer;
