import axios from 'axios';
import {
  SAVE_UNIT_REQUEST,
  SAVE_UNIT_SUCCESS,
  SAVE_UNIT_FAILURE,
  GET_UNITS_REQUEST,
  GET_UNITS_SUCCESS,
  GET_UNITS_FAILURE
} from './actionTypes';
import { message } from 'antd';
import { getUnitsAPI, saveUnitAPI } from '../../../api/Services';

// Save Unit Actions
export const saveUnitRequest = () => ({
  type: SAVE_UNIT_REQUEST
});

export const saveUnitSuccess = (response) => ({
  type: SAVE_UNIT_SUCCESS,
  payload: response
});

export const saveUnitFailure = error => ({
  type: SAVE_UNIT_FAILURE,
  payload: error
});

// Get Units Actions
export const getUnitsRequest = () => ({
  type: GET_UNITS_REQUEST
});

export const getUnitsSuccess = (response) => ({
  type: GET_UNITS_SUCCESS,
  payload: response
});

export const getUnitsFailure = error => ({
  type: GET_UNITS_FAILURE,
  payload: error
});

// Action Method for API call and send data to the backend side

// To save the unit detail
export const saveUnit = (data) => async (dispatch) => {
  dispatch(saveUnitRequest());
  try {
    const response = await saveUnitAPI(data);

    if (response?.success) {
      dispatch(saveUnitSuccess(response));
      message.success(response?.success);
    }
    if (response?.error) {
      dispatch(saveUnitFailure(response));
      message.error(response?.error);
    }
    return response;

  } catch (error) {
    dispatch(saveUnitFailure(error?.message));
    throw error;
  }
};

// To get all units data
export const getUnits = () => async (dispatch) => {
  dispatch(getUnitsRequest());
  try {
    const response = await getUnitsAPI();

    if (response) {
      dispatch(getUnitsSuccess(response));
    }
    return response;

  } catch (error) {
    dispatch(getUnitsFailure(error?.message));
    throw error;
  }
};
