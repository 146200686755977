import React from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { useDispatch, useSelector } from 'react-redux';
import { saveExpenseCategory } from './actions';

export default function ExpenseNameForm({ setNewExpenseName }) {

  const dispatch = useDispatch();

  const loading = useSelector(state => state?.expenseNames?.getExpenses?.loading);
  const error = useSelector(state => state?.expenseNames?.getExpenses?.error);
 
  const onFinish = async (values) => {
    try {
      const response = await dispatch(saveExpenseCategory(values));
      
      if (response?.success) {
        setNewExpenseName(false);
      } else {
        // Display error message from the backend
        message.error(response.message || 'Failed to save expense category. Please try again.');
      }
    } catch (error) {
      // Handle any unexpected errors
      message.error(error?.response?.data?.message || error.message || 'An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div>
      <div className="p-1 max-w-lg m-auto">
        <Form
          layout="vertical"
          name="expenseNameForm"
          onFinish={onFinish}
        >
          <Card
          // title="Add Expense Category Name"
          size="small">
            <div className="flex items-end gap-3">
              <Form.Item
                name="category_name"
                className="flex-grow m-0"
                label="Expense Category Name"
                rules={[{ required: true, message: 'Please enter expense name' }]}
              >
                <Input placeholder="Expense name" />
              </Form.Item>
              <Form.Item className="m-0">
                <Button disabled={loading} type="primary" className='bg-[#a854f9] text-white' htmlType="submit">
                  {loading ? "....." : ""}Save
                </Button>
              </Form.Item>
            </div>
          </Card>
          <div className="p-4"></div>
        </Form>
      </div>
    </div>
  );
}
