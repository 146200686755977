// reducer.js
import {
    SAVE_CLIENT_REQUEST,
    SAVE_CLIENT_SUCCESS,
    SAVE_CLIENT_FAILURE,
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null
};

const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_CLIENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                response: null
            };
        case SAVE_CLIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload
            };
        case SAVE_CLIENT_FAILURE:
            return {
                ...state.sendOtp,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default clientReducer;
