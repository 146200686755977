// reducer.js
import {
    SAVE_DELIVERY_CHALLAN_REQUEST,
    SAVE_DELIVERY_CHALLAN_SUCCESS,
    SAVE_DELIVERY_CHALLAN_FAILURE,

    GET_DELIVERY_CHALLANS_REQUEST,
    GET_DELIVERY_CHALLANS_SUCCESS,
    GET_DELIVERY_CHALLANS_FAILURE,

    DELETE_DELIVERY_CHALLAN_REQUEST,
    DELETE_DELIVERY_CHALLAN_SUCCESS,
    DELETE_DELIVERY_CHALLAN_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    saveDeliveryChallans: {
        loading: false,
        error: null,
        response: null,
    },

    getDeliveryChallans: {
        loading: false,
        error: null,
        response: null,
    },

    deleteDeliveryChallan: {
        loading: false,
        error: null,
        response: null,
    }
};

const deliveryChallanReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_DELIVERY_CHALLAN_REQUEST:
            return {
                ...state,
                saveDeliveryChallans: {
                    ...state.saveDeliveryChallans,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_DELIVERY_CHALLAN_SUCCESS:
            return {
                ...state,
                saveDeliveryChallans: {
                    ...state.saveDeliveryChallans,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case SAVE_DELIVERY_CHALLAN_FAILURE:
            return {
                ...state,
                saveDeliveryChallans: {
                    ...state.saveDeliveryChallans,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        case GET_DELIVERY_CHALLANS_REQUEST:
            return {
                ...state,
                getDeliveryChallans: {
                    ...state.getDeliveryChallans,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_DELIVERY_CHALLANS_SUCCESS:
            return {
                ...state,
                getDeliveryChallans: {
                    ...state.getDeliveryChallans,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_DELIVERY_CHALLANS_FAILURE:
            return {
                ...state,
                getDeliveryChallans: {
                    ...state.getDeliveryChallans,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        // Handle delete actions
        case DELETE_DELIVERY_CHALLAN_REQUEST:
            return {
                ...state,
                deleteDeliveryChallan: {
                    ...state.deleteDeliveryChallan,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case DELETE_DELIVERY_CHALLAN_SUCCESS:
            return {
                ...state,
                deleteDeliveryChallan: {
                    ...state.deleteDeliveryChallan,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case DELETE_DELIVERY_CHALLAN_FAILURE:
            return {
                ...state,
                deleteDeliveryChallan: {
                    ...state.deleteDeliveryChallan,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;

            
    }
};

export default deliveryChallanReducer;
