import React from 'react'
import bgImg from '../../images/cover-bg.jpg'
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css/animate.min.css'; 
export default function Hero() {
    // useEffect(() => {
    //     AOS.init({
    //       duration: 1000, 
    //       easing: 'ease-in-out',
    //     //   once: true,
    //     });
    //   }, []);
    return (
        <div id="home">
            <div
                className='
                    w-[99.7vw] h-[70vh] relative
                '
            >
                <img
                    src={bgImg}
                    alt="hero"
                    className='
                    absolute w-[100%] h-[50vh] shadow-2xl
                    '
                />

                <div
                    className='
                    sm:w-[50%] w-[90%] bg-[white] rounded-2xl shadow-2xl
                    border-solid border-3 border-[red] bottom-1 absolute
                    '
                    style={{ top: '50%', left: '50%', transform: 'translate(-50%, -10%)' }}
                >
                    <div
                        className="
                        flex flex-col justify-center text-[40px] my-2
                        text-center font-semibold drop-shadow 
                        
                        "
                        data-aos="fade-up"
                    >
                        Effortless Online Billing
                    </div>
                    <div
                        className="
                        border-2 border-solid border-[red] w-[20%] m-auto my-5
                        "
                    >
                    </div>
                    <div
                        className="
                        flex flex-col justify-center text-center mt-10 text-[18px]
                        drop-shadow 
                        "
                        data-aos="fade-up"
                       
                    >
                        Streamline Your Business Operations
                    </div>
                </div>

            </div>
        </div>
    )
}