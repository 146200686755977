// actions.js
import {
  SAVE_TCS_REQUEST,
  SAVE_TCS_SUCCESS,
  SAVE_TCS_FAILURE,
  
  GET_TCS_REQUEST,
  GET_TCS_SUCCESS,
  GET_TCS_FAILURE,
  
  SAVE_TDS_REQUEST,
  SAVE_TDS_SUCCESS,
  SAVE_TDS_FAILURE,
  
  GET_TDS_REQUEST,
  GET_TDS_SUCCESS,
  GET_TDS_FAILURE,
  
  GET_TDS_RECEIVABLE_CHARGED_REQUEST,
  GET_TDS_RECEIVABLE_CHARGED_SUCCESS,
  GET_TDS_RECEIVABLE_CHARGED_FAILURE,
  
  GET_TCS_RECEIVABLE_CHARGED_REQUEST,
  GET_TCS_RECEIVABLE_CHARGED_SUCCESS,
  GET_TCS_RECEIVABLE_CHARGED_FAILURE,
  
  GET_TCS_PAYABLE_CHARGED_REQUEST,
  GET_TCS_PAYABLE_CHARGED_SUCCESS,
  GET_TCS_PAYABLE_CHARGED_FAILURE,
  
  GET_TDS_PAYABLE_CHARGED_REQUEST,
  GET_TDS_PAYABLE_CHARGED_SUCCESS,
  GET_TDS_PAYABLE_CHARGED_FAILURE,
  
} from './actionTypes';
import { saveTcsDetailAPI, getTcsDetailAPI, saveTdsDetailAPI, getTdsDetailAPI, getTcsPyableAPI, getTdsPyableAPI, getTdsReceivableAPI, getTcsReceivableAPI } from '../../api/Services';
import { message } from 'antd';

// TCS Actions
export const saveTcsDetailsRequest = () => ({
  type: SAVE_TCS_REQUEST
});

export const saveTcsDetailsSuccess = (response) => ({
  type: SAVE_TCS_SUCCESS,
  payload: response
});

export const saveTcsDetailsFailure = (error) => ({
  type: SAVE_TCS_FAILURE,
  payload: error
});

export const getTcsDetailsRequest = () => ({
  type: GET_TCS_REQUEST
});

export const getTcsDetailsSuccess = (response) => ({
  type: GET_TCS_SUCCESS,
  payload: response
});

export const getTcsDetailsFailure = (error) => ({
  type: GET_TCS_FAILURE,
  payload: error
});

export const getTcsReceivableRequest = () => ({
  type: GET_TCS_RECEIVABLE_CHARGED_REQUEST
});

export const getTcsReceivableSuccess = (response) => ({
  type: GET_TCS_RECEIVABLE_CHARGED_SUCCESS,
  payload: response
});

export const getTcsReceivableFailure = (error) => ({
  type: GET_TCS_RECEIVABLE_CHARGED_FAILURE,
  payload: error
});

export const getTcsPayableRequest = () => ({
  type: GET_TCS_PAYABLE_CHARGED_REQUEST
});

export const getTcsPayableSuccess = (response) => ({
  type: GET_TCS_PAYABLE_CHARGED_SUCCESS,
  payload: response
});

export const getTcsPayableFailure = (error) => ({
  type: GET_TCS_PAYABLE_CHARGED_FAILURE,
  payload: error
});

// TDS Actions
export const saveTdsDetailsRequest = () => ({
  type: SAVE_TDS_REQUEST
});

export const saveTdsDetailsSuccess = (response) => ({
  type: SAVE_TDS_SUCCESS,
  payload: response
});

export const saveTdsDetailsFailure = (error) => ({
  type: SAVE_TDS_FAILURE,
  payload: error
});

export const getTdsDetailsRequest = () => ({
  type: GET_TDS_REQUEST
});

export const getTdsDetailsSuccess = (response) => ({
  type: GET_TDS_SUCCESS,
  payload: response
});

export const getTdsDetailsFailure = (error) => ({
  type: GET_TDS_FAILURE,
  payload: error
});

export const getTdsReceivableRequest = () => ({
  type: GET_TDS_RECEIVABLE_CHARGED_REQUEST
});

export const getTdsReceivableSuccess = (response) => ({
  type: GET_TDS_RECEIVABLE_CHARGED_SUCCESS,
  payload: response
});

export const getTdsReceivableFailure = (error) => ({
  type: GET_TDS_RECEIVABLE_CHARGED_FAILURE,
  payload: error
});

export const getTdsPayableRequest = () => ({
  type: GET_TDS_PAYABLE_CHARGED_REQUEST
});

export const getTdsPayableSuccess = (response) => ({
  type: GET_TDS_PAYABLE_CHARGED_SUCCESS,
  payload: response
});

export const getTdsPayableFailure = (error) => ({
  type: GET_TDS_PAYABLE_CHARGED_FAILURE,
  payload: error
});

/**
 * Action Methods for API calls and sending data to the backend
 */

/**
 * To save TCS details
 */
export const saveTcsDetails = (data) => async (dispatch) => {
  dispatch(saveTcsDetailsRequest());
  try {
    const response = await saveTcsDetailAPI(data);

    if (response?.success) {
      dispatch(saveTcsDetailsSuccess(response));
      message.success(response?.success);
    } 
    if (response?.error) {
      dispatch(saveTcsDetailsFailure(response));
    }
    return response;

  } catch (error) {
    dispatch(saveTcsDetailsFailure(error.message));
    throw error;
  }
};

export const getTcsDetails = () => async (dispatch) => {
  dispatch(getTcsDetailsRequest());
  try {
    const response = await getTcsDetailAPI();

    if (response) {
      dispatch(getTcsDetailsSuccess(response));
    } 
    if (response?.error) {
      dispatch(getTcsDetailsFailure(response));
    }
    return response;

  } catch (error) {
    dispatch(getTcsDetailsFailure(error.message));
    throw error;
  }
};

/**
 * To save TDS details
 */
export const saveTdsDetails = (data) => async (dispatch) => {
  dispatch(saveTdsDetailsRequest());
  try {
    const response = await saveTdsDetailAPI(data);
    if (response?.success) {
      dispatch(saveTdsDetailsSuccess(response));
      message.success(response?.success);
    } 
    if (response?.error) {
      dispatch(saveTdsDetailsFailure(response));
    }
    return response;

  } catch (error) {
    dispatch(saveTdsDetailsFailure(error.message));
    throw error;
  }
};

export const getTdsDetails = () => async (dispatch) => {
  dispatch(getTdsDetailsRequest());
  try {
    const response = await getTdsDetailAPI();

    if (response) {
      dispatch(getTdsDetailsSuccess(response));
    } 
    if (response?.error) {
      dispatch(getTdsDetailsFailure(response));
    }
    return response;

  } catch (error) {
    dispatch(getTdsDetailsFailure(error.message));
    throw error;
  }
};

/**
 * To get TCS receivable
 */
export const getTcsReceivable = (data) => async (dispatch) => {
  dispatch(getTcsReceivableRequest());
  try {
    const response = await getTcsReceivableAPI(data);
    if (response) {
      dispatch(getTcsReceivableSuccess(response));
    } 
    if (response?.error) {
      dispatch(getTcsReceivableFailure(response));
    }
    return response;

  } catch (error) {
    dispatch(getTcsReceivableFailure(error.message));
    throw error;
  }
};

/**
 * To get TCS payable
 */
export const getTcsPayable = () => async (dispatch) => {
  dispatch(getTcsPayableRequest());
  try {
    const response = await getTcsPyableAPI();

    if (response) {
      dispatch(getTcsPayableSuccess(response));
    } 
    if (response?.error) {
      dispatch(getTcsPayableFailure(response));
    }
    return response;

  } catch (error) {
    dispatch(getTcsPayableFailure(error.message));
    throw error;
  }
};

/**
 * To get TDS receivable
 */
export const getTdsReceivable = (data) => async (dispatch) => {
  dispatch(getTdsReceivableRequest());
  try {
    const response = await getTdsReceivableAPI(data);
    if (response) {
      dispatch(getTdsReceivableSuccess(response));
    } 
    if (response?.error) {
      dispatch(getTdsReceivableFailure(response));
    }
    return response;

  } catch (error) {
    dispatch(getTdsReceivableFailure(error.message));
    throw error;
  }
};

/**
 * To get TDS payable
 */
export const getTdsPayable = () => async (dispatch) => {
  dispatch(getTdsPayableRequest());
  try {
    const response = await getTdsPyableAPI();

    if (response) {
      dispatch(getTdsPayableSuccess(response));
    } 
    if (response?.error) {
      dispatch(getTdsPayableFailure(response));
    }
    return response;

  } catch (error) {
    dispatch(getTdsPayableFailure(error.message));
    throw error;
  }
};