import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { customTable } from '../../../settings/codeSettings/additionalMethods';

const PaymentInDashboardTable = ({ columns, data, tableColumns, handleTableChange }) => {
  const childTableColumns = [
    { title: 'Date', dataIndex: 'date' },
    { title: 'Invoice No', dataIndex: 'sale_inv_no' },
    { title: 'Original No', dataIndex: 'original_inv_no' },
    { title: 'Total Amount', dataIndex: 'invoice_amount' },
    { title: 'Amount Settled', dataIndex: 'settled_amount' },
    { title: 'Remaining Amount', dataIndex: 'balance_amount' },
  ];

  return (
    <div className="overflow-scroll scrollbar-thin scrollbar-thumb-red-500 scrollbar-track-orange-200 mt-4">
      <Table
        size='small'
        columns={tableColumns}
        dataSource={data}
        style={{ fontSize: 'xx-large' }} 
        bordered
        pagination={false}
        rowKey={(record, index) => index} // Assuming data array is not uniquely keyed
        onRow={(record) => ({
          style: { backgroundColor: record?.payment_status === 'paid' ? '#e6ffe6' : '' }
        })}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {customTable(childTableColumns, record?.payment_ins)}
            </>
          ),
          rowExpandable: (record) => record?.payment_ins && record.payment_ins.length > 0,
        }}
      />
    </div>
  );
};

PaymentInDashboardTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PaymentInDashboardTable;
