// reducer.js
import {
    GET_LEDGERS_REQUEST,
    GET_LEDGERS_SUCCESS,
    GET_LEDGERS_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    getLedgers: {
        loading: false,
        error: null,
        response: null,
    }
};

const ledgerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LEDGERS_REQUEST:
        return {
            ...state,
            getLedgers: {
                ...state.getLedgers,
                loading: true,
                error: null,
                response: null
            }
        };
        case GET_LEDGERS_SUCCESS:
            return {
                ...state,
                getLedgers: {
                    ...state.getLedgers,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_LEDGERS_FAILURE:
            return {
                ...state,
                getLedgers: {
                    ...state.getLedgers,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default ledgerReducer;
