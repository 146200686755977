import React, { useEffect, useState } from 'react';
import { Card, Select, DatePicker } from 'antd';
import DynamicTable from '../tables/DynamicTable';
import { useDispatch, useSelector } from 'react-redux';
import { getTcsReceivable } from './action';
import AppListHeader from '../header/AppListHeader';
import * as XLSX from 'xlsx';
import { rangePresets } from '../../settings/codeSettings/additionalMethods';

const { RangePicker } = DatePicker;

function TcsReceivable({ companyDetail }) {

  const dispatch = useDispatch()
  const TcsReceivableResponse = useSelector(state => state.taxDetails.getTcsReceivableCharged.response)

  const [filterQuery, setFilterQuery] = useState({ party__id: companyDetail?.id })

  useEffect(() => {
    getTcsReceivableData()
  }, [dispatch, filterQuery])


  /**
   * To create for header and access key
   */
  const columns = [
    { Header: 'Date', accessor: 'date' },
    { Header: 'Tcs Amount', accessor: 'tcs_amount' },
    { Header: 'Tcs Rate (%)', accessor: 'tcs_rate_percentage' },
    { Header: 'Perticuler', accessor: 'perticuler' },
  ];


  const getTcsReceivableData = async () => {
    const partyID = companyDetail?.id
    const response = await dispatch(getTcsReceivable(filterQuery))
  }

  const tableColumns = columns.map((TcsReceivable) => ({
    title: TcsReceivable.Header,
    dataIndex: TcsReceivable.accessor,
    key: TcsReceivable.accessor,
  }));

  const preparedTcsReceivable = TcsReceivableResponse?.tcs_receivable?.map((TcsReceivable) => ({
    date: TcsReceivable.date,
    tcs_rate_percentage: TcsReceivable.tcs_rate_percentage,
    tcs_amount: TcsReceivable.tcs_amount,
    perticuler: TcsReceivable.sale && 'Sale' || TcsReceivable.purchase && 'Purchase' || TcsReceivable.expense && 'Expense' || TcsReceivable.payment_in && 'Payment In' || TcsReceivable.payment_out && 'Payment Out'
  }));


  /**
   * current data export to excel sheet
   */
  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const wsData = preparedTcsReceivable?.map((e, index) => [
      index + 1,
      e.date,
      e.tcs_rate_percentage,
      e.tcs_amount,
      e.perticuler,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      [
        'S.No.',
        'Date',
        'TCS Percentage (%)',
        'Amount',
        'Perticular'
      ],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, `TcsReceivable_${companyDetail?.company_name || ''}`);
    XLSX.writeFile(workbook, `TcsRecievale_${companyDetail?.company_name || ''}.xlsx`);
  };
  /**
   * end of xeporting data
   */


  /**
   * filter for changes
   */
  const handleVoucherTypeChange = (type) => {
    setFilterQuery({
      ...filterQuery,
      voucher_type: type
    })
  }

  const handlePartyChange = (party) => {
    setFilterQuery({
      ...filterQuery,
      party: party
    })
  }

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log('From: ', dates[0], ', to: ', dates[1]);
      // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      setFilterQuery({
        ...filterQuery,
        date: dateStrings[0],
        date_lte: dateStrings[1]
      })
    } else {
      setFilterQuery({
        ...filterQuery,
        date: null,
        date_lte: null
      })
    }
  };
  /**
   * end of changing filteration
   */


  return (
    <div className="">

      {!companyDetail &&
        <AppListHeader
          headerContent='TCS Receivable Report'
          excel={true}
          handleExcelExport={handleExportToExcel}
          print={true}
          download={false}
          cancel={true}
          printNavigation='/ListPrint'
          printData={{ tableColunm: tableColumns, tableData: preparedTcsReceivable, name: ' Party TCS Receivable  List' }}
        />
      }

      <div className="flex flex-wrap mb-4 shadow-md p-1">
        <Select
          placeholder="Select Party"
          className='w-[250px] m-1'
          onChange={handlePartyChange}
          allowClear
          showSearch
          optionFilterProp='children'
        >
          {TcsReceivableResponse?.parties?.map((data, id) => (
            <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
          ))}
        </Select>

        <Select
          placeholder="Select Voucer Type"
          className='w-[250px] m-1'
          onChange={handleVoucherTypeChange}
          allowClear
          showSearch
          optionFilterProp='children'
        >
          <Select.Option value='sale'>Sale</Select.Option>
          <Select.Option value='purchase'>Purchase</Select.Option>
        </Select>
        <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={onRangeChange} />
      </div>


      {companyDetail ?
        <Card title={`${companyDetail?.company_name}`} size="small" className="border-2 border-gray-300">
          <div className="flex justify-between">
            <div className="p-2 text-sm">
              To,<br />
              {companyDetail?.company_name}<br />
              {companyDetail?.address}, {companyDetail?.city}, {companyDetail?.state}, {companyDetail?.zip_code} <br />
              {companyDetail?.mobile_no} <br />
              {companyDetail?.gst_no}
            </div>
            <div className="border-2 border-gray-300 p-2 text-sm">
              <hr className="my-1 border-gray-400" />
              Total Receivable<br />
            </div>
          </div>
          <DynamicTable columns={tableColumns} data={preparedTcsReceivable} tableColumns={tableColumns} />
        </Card>
        :
        <DynamicTable columns={tableColumns} data={preparedTcsReceivable} tableColumns={tableColumns} />
      }
    </div>
  );
}

export default TcsReceivable;
