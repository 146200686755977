import {
  SAVE_EXPENSE_CATEGORY_REQUEST,
  SAVE_EXPENSE_CATEGORY_SUCCESS,
  SAVE_EXPENSE_CATEGORY_FAILURE,

  GET_EXPENSE_CATEGORIES_REQUEST,
  GET_EXPENSE_CATEGORIES_SUCCESS,
  GET_EXPENSE_CATEGORIES_FAILURE
} from './actionTypes';
import { message } from 'antd';
import { getExpenseCategoriesAPI, saveExpenseCategoryAPI } from '../../../api/Services';

// Save ExpenseCategory Actions
export const saveExpenseCategoryRequest = () => ({
  type: SAVE_EXPENSE_CATEGORY_REQUEST
});

export const saveExpenseCategorySuccess = (response) => ({
  type: SAVE_EXPENSE_CATEGORY_SUCCESS,
  payload: response
});

export const saveExpenseCategoryFailure = error => ({
  type: SAVE_EXPENSE_CATEGORY_FAILURE,
  payload: error
});

/**
 * Get all expense categories data
 */
export const getExpenseCategoriesRequest = () => ({
  type: GET_EXPENSE_CATEGORIES_REQUEST
});

export const getExpenseCategoriesSuccess = (response) => ({
  type: GET_EXPENSE_CATEGORIES_SUCCESS,
  payload: response
});

export const getExpenseCategoriesFailure = error => ({
  type: GET_EXPENSE_CATEGORIES_FAILURE,
  payload: error
});

/**
 * Action Method for API call and sending data to the backend side
 */

/**
 * To save the expense category detail
 */
export const saveExpenseCategory = (data) => async (dispatch) => {
  dispatch(saveExpenseCategoryRequest());
  try {
    const response = await saveExpenseCategoryAPI(data);

    if (response?.success) {
      dispatch(saveExpenseCategorySuccess(response));
      message.success(response?.success);
    } else {
      dispatch(saveExpenseCategoryFailure(response));
      message.error(response?.error);
    }
    return response;

  } catch (error) {
    dispatch(saveExpenseCategoryFailure(error?.message));
    throw error;
  }
};

/**
 * To get all expense categories data
 */
export const getExpenseCategories = (data) => async (dispatch) => {
  dispatch(getExpenseCategoriesRequest());
  try {
    const response = await getExpenseCategoriesAPI(data);
    if (response) {
      dispatch(getExpenseCategoriesSuccess(response));
    }
    return response;

  } catch (error) {
    dispatch(getExpenseCategoriesFailure(error?.message));
    throw error;
  }
};
