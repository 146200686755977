import React, { useEffect, useState } from 'react';
import { DownOutlined, DownloadOutlined } from '@ant-design/icons';
import { Input, Dropdown, Button, Select, DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import PaymentInList from './PaymentInList';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentIn } from './actions';
import * as XLSX from 'xlsx';
import AppListHeader from '../../header/AppListHeader';
import { getParties } from '../../party/actions';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function PaymentInDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = useSelector(state => state.paymentIn.getPaymentIn.loading);
    const error = useSelector(state => state.paymentIn.getPaymentIn.error);
    const paymentInDatas = useSelector(state => state.paymentIn.getPaymentIn.response);
    const partyResponse = useSelector(state => state.parties.getParties.response)
    const paymentInDeleteResponse = useSelector(state => state.paymentIn.deletePaymentIn.response);

    const [partySearchText, setPartySearchText] = useState('')   // state for set the searched value
    const [filterQuery, setFilterQuery] = useState(null)                                                           // state for filter query
    const [paymentInDetail, setPaymentInDetail] = useState('')

    useEffect(() => {
        getPaymentInData();

        /**
         * handle party state
         */
        if (
            partyResponse?.party_count == partyResponse?.parties?.results?.length &&
            partyResponse?.parties?.results?.length > 0
        ) {

        }
        else {
            dispatch(getParties())
        }
        /**
         * end of handling party state
         */

    }, [dispatch, partySearchText, filterQuery, paymentInDeleteResponse]);

    const getPaymentInData = async () => {

        if (partySearchText) {
            await dispatch(getPaymentIn({ search: partySearchText }));
        }

        else if (filterQuery) {
            await dispatch(getPaymentIn(filterQuery));
        }

        else {
            const response = await dispatch(getPaymentIn());

            if (response) {
                setPaymentInDetail({
                    ...paymentInDetail,
                    partiesCount: response.party_count,
                    toCollect: response.total_to_collect_balance,
                    toPay: response.total_to_pay_balance,
                });
            }
        }
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order == 'ascend' ?
                `${sorter?.field}`
                :
                sorter?.order == 'descend' ?
                    `-${sorter?.field}`
                    :
                    null,
        })
    }

    const handlePartySearch = (value) => {
        setPartySearchText(value)
    };

    const handleCreatePaymentIn = () => {
        navigate('/sales/CreatePaymentIn');
    };

    const handlePrint = () => {
        console.log(paymentInDatas);
        navigate('/payment-in-bill/print', { state: { paymentInDatas } });
    };

    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Assuming paymentInDatas is the list you want to export
        const wsData = paymentInDatas?.payment_ins.map((e, index) => [
            index + 1,
            e.date, // Replace with correct date format if necessary
            e.party_company_name,
            e.remark,
            e.payment_mode,
            e.amount,
        ]) || [];

        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'S.No.',
                'Date',
                'Party Name',
                'Description',
                'Payment Mode',
                'Amount',
            ],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Payments_in');
        XLSX.writeFile(workbook, 'Payments_in.xlsx');
    };


    /**
     * to change the filter
     */
    const handlePaymentModeChange = (mode) => {
        setFilterQuery({
            ...filterQuery,
            payment_mode: mode
        })
    }

    const handlePartyChange = (party) => {
        setFilterQuery({
            ...filterQuery,
            party__id: party
        })
    }

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            setFilterQuery({
                ...filterQuery,
                start_date: dateStrings[0],
                end_date: dateStrings[1]
            })
        } else {
            console.log('Clear');
            setFilterQuery({
                ...filterQuery,
                start_date: null,
                end_date: null
            })
        }
    };
    /**
     * end of changing the filter
     */

    const columns = [
        { title: 'Date', dataIndex: 'date' },
        { title: 'Party Name', dataIndex: 'party_company_name' },
        { title: 'Payment Mode', dataIndex: 'payment_mode' },
        { title: 'Total Amount', dataIndex: 'amount' },
    ];


    return (
        <div className=''>

            <AppListHeader
                headerContent='Payment Ins'
                creationLable="Create New Payment In"
                handleCreateNew={handleCreatePaymentIn}
                excel={true}
                handleExcelExport={handleExportToExcel}
                print={true}
                download={true}
                cancel={true}
                printNavigation='/ListPrint'
                printData={{ tableColunm: columns, tableData: paymentInDatas?.payment_ins, name: 'Payment In List' }}
            />

            <div className="flex flex-wrap mb-4 shadow-md p-1">
                <Select
                    placeholder="Select Party"
                    className='w-[250px] m-1'
                    onChange={handlePartyChange}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                >
                    {partyResponse?.parties?.results?.map((data, id) => (
                        <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
                    ))}
                </Select>

                <Select
                    placeholder="Payment Mode"
                    className='w-[250px] m-1'
                    onChange={handlePaymentModeChange}
                    allowClear
                >
                    <Select.Option value='CASH'>CASH</Select.Option>
                    <Select.Option value='BANK'>BANK</Select.Option>
                    <Select.Option value='UPI'>UPI</Select.Option>
                    <Select.Option value='CARD'>CARD</Select.Option>
                </Select>
                <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={onRangeChange} />
            </div>

            <PaymentInList
                paymentInDatas={paymentInDatas?.payment_ins}
                handleTableChange={handleTableChange}
            />
        </div>
    );
}
