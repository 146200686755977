// import axios from 'axios';
import {
  SAVE_ITEM_REQUEST,
  SAVE_ITEM_SUCCESS,
  SAVE_ITEM_FAILURE,

  GET_ITEMS_REQUEST,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAILURE,

  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE

} from './actionTypes';
import { getItemsAPI, saveItemAPI, updateItemAPI } from '../../../api/Services';
import { message } from 'antd';


export const saveItemRequest = () => ({
  type: SAVE_ITEM_REQUEST
});

export const saveItemSuccess = (response) => ({
  type: SAVE_ITEM_SUCCESS,
  payload: response
});

export const saveItemFailure = error => ({
  type: SAVE_ITEM_FAILURE,
  payload: error
});


/**
 * Get all item data
 */
export const getItemsRequest = () => ({
  type: GET_ITEMS_REQUEST,
  payload: []
});

export const getItemsSuccess = (response) => ({
  type: GET_ITEMS_SUCCESS,
  payload: response
});

export const getItemsFailure = error => ({
  type: GET_ITEMS_FAILURE,
  payload: error
});


/**
 * update the item data
 */
export const updateItemRequest = () => ({
  type: UPDATE_ITEM_REQUEST,
});

export const updateItemSuccess = (response) => ({
  type: UPDATE_ITEM_SUCCESS,
  payload: response
});

export const updateItemFailure = error => ({
  type: UPDATE_ITEM_FAILURE,
  payload: error
});


/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To save the item detail
 */
export const saveItem = (data) => async (dispatch) => {
  dispatch(saveItemRequest());
  try {
    const response = await saveItemAPI(data)
    if (response?.success) {
      dispatch(saveItemSuccess(response))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(saveItemFailure(response))
      message.error(response?.error)
    }
    return response;

  } catch (error) {
    dispatch(saveItemFailure(error?.message));
    throw error;
  }
};



/**
 * To get the all items data
 */
export const getItems = (data) => async (dispatch) => {
  dispatch(getItemsRequest());
  try {
    const response = await getItemsAPI(data)
    if (response) {
      dispatch(getItemsSuccess(response))
    }
    return response;
  } catch (error) {
    dispatch(getItemsFailure(error.message));
    throw error;
  }
};


/**
 * To UPDATE the item
 */
export const updateItems = (itemId, data) => async (dispatch) => {
  dispatch(updateItemRequest());
  try {
    const response = await updateItemAPI(itemId, data)

    if (response) {
      dispatch(updateItemSuccess(response))
    }
    return response;

  } catch (error) {
    dispatch(updateItemFailure(error.message));
    throw error;
  }
};