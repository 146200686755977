import React, {useState}from 'react';
import { Form, Input, Button, Card,message } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { useDispatch, useSelector } from 'react-redux'
import { saveCategory } from './actions';

export default function CategoryForm({ setNewCategory }) {

  const dispatch = useDispatch()

  const loading = useSelector(state => state?.categoies?.saveCategory?.loading)
  const error = useSelector(state => state?.categoies?.saveCategory?.error)
  // const response = useSelector(state => state?.categoies?.saveCategory?.response)
  // const list = useSelector(state => state?.categoies?.saveCategory?.list)
  const [apiError, setApiError] = useState('');


  const onFinish = async (values) => {
    setApiError('');
    const response = await dispatch(saveCategory(values))
    if (response?.success) {
      // message.success('Category saved successfully!'); // Show success message
      setNewCategory(false);
    } else {

      const errorMessage = response?.error || 'Failed to save category';
      setApiError(errorMessage);
      message.error(errorMessage); // Show error message
    }
  };

  return (
      <div className="p-1 max-w-lg m-auto">
        {/* <h2 className="text-2xl font-semibold mb-4">Create New Item</h2> */}
        <Form
          layout="vertical"
          name="categoryForm"
          onFinish={onFinish}
        >
          <Card title="Add Category Name" size="small">
            <div className="flex items-end gap-3">
              <Form.Item
                name="category_name"
                className="flex-grow m-0"
                label="Category Name"
                rules={[{ required: true, message: 'Please enter item name' }]}
              // validateTrigger="onSubmit"
              >
                <Input placeholder="Item name" maxLength={50}/>
              </Form.Item>
              <Form.Item className="m-0">
                <Button className='bg-[#a854f9]' disabled={loading} type="primary" htmlType="submit">
                  <b>{loading ? "....." : ""} Save</b>
                </Button>
              </Form.Item>
            </div>
          </Card>
          {/* <div className="p-4">

          </div> */}
        </Form>
      </div>
  );
}