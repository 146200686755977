import axios from 'axios';
import {
  SAVE_EXPENSE_REQUEST,
  SAVE_EXPENSE_SUCCESS,
  SAVE_EXPENSE_FAILURE,
  GET_EXPENSES_REQUEST,
  GET_EXPENSES_SUCCESS,
  GET_EXPENSES_FAILURE
} from './actionTypes';
import { message } from 'antd';
import { getExpenseAPI, saveExpenseAPI } from '../../../api/Services';

// Action Creators
export const saveExpenseRequest = () => ({
  type: SAVE_EXPENSE_REQUEST
});

export const saveExpenseSuccess = (response) => ({
  type: SAVE_EXPENSE_SUCCESS,
  payload: response
});

export const saveExpenseFailure = error => ({
  type: SAVE_EXPENSE_FAILURE,
  payload: error
});

export const getExpensesRequest = () => ({
  type: GET_EXPENSES_REQUEST
});

export const getExpensesSuccess = (response) => ({
  type: GET_EXPENSES_SUCCESS,
  payload: response
});

export const getExpensesFailure = error => ({
  type: GET_EXPENSES_FAILURE,
  payload: error
});

// Async Action Creators

/**
 * To save the expense detail
 */
export const saveExpenses = (data) => async (dispatch) => {
  dispatch(saveExpenseRequest());
  try {
    const response = await saveExpenseAPI(data);

    if (response?.success) {
      dispatch(saveExpenseSuccess(response));
      message.success(response?.success);
    } else if (response?.error) {
      dispatch(saveExpenseFailure(response));
      message.error(response?.error);
    }
    return response;
  } catch (error) {
    dispatch(saveExpenseFailure(error.message));
    throw error;
  }
};

/**
 * To get all expenses
 */
export const getExpenses = (data) => async (dispatch) => {
  dispatch(getExpensesRequest());
  try {
    const response = await getExpenseAPI(data);

    if (response) {
      dispatch(getExpensesSuccess(response));
    }
    return response;
  } catch (error) {
    dispatch(getExpensesFailure(error.message));
    throw error;
  }
};
