import { Button, Form, Input, message } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux';
import { saveTcsDetails } from './action'

export default function TcsRateForm({setTcsId, setIsAddTcs}) {

    const dispatch = useDispatch()

    const onFinish = async (values) => {
        const response = await dispatch(saveTcsDetails(values))
        if (response?.success) {
            setTcsId(response?.data?.id)
            setIsAddTcs(false)
        }
    };

    return (
        <div>
            <Form
                layout="vertical"
                name="tcsForm"
                onFinish={onFinish}
            >
                <Form.Item
                    name="tax_name"
                    className="my-1"
                    label="Tax Name"
                    rules={[{ required: true, message: 'Please enter tax name' }]}
                >
                    <Input placeholder="Tax name" />
                </Form.Item>
                <Form.Item
                    name="section_name"
                    className="my-1"
                    label="Section Name"
                    rules={[{ required: true, message: 'Please enter section name' }]}
                >
                    <Input placeholder="Section name" />
                </Form.Item>
                <Form.Item
                    name="tax_rate"
                    className="my-1"
                    label="Tax Rate"
                    rules={[{ required: true, message: 'Please enter tax rate' }]}
                >
                    <Input type="number" placeholder="Tax rate" />
                </Form.Item>
                <Form.Item className="m-0">
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        {/* {loading ? "....." : ""} Save */}
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
