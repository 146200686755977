import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Invoice.css"; // Make sure your CSS is correctly linked here
import { Row } from "antd";
import secureLocalStorage from 'react-secure-storage';

const Invoice = () => {
  const location = useLocation();
  const invoiceData = location.state?.record;
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyMobile, setCompanyMobile] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [user, setUser] = useState("");

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const invoiceContent = document.getElementById('invoice').innerHTML;
    const invoiceTitle = invoiceData.sale_inv_no ? "SALE_INVOICE" : "PURCHASE_INVOICE";
    const fileName = `${companyName}_${invoiceTitle}_${invoiceData.sale_inv_no || invoiceData.purchase_inv_no}_billzap.in.pdf`;


    printWindow.document.write(`
      <html>
        <head>
          <title>${fileName}</title>
        <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
          <style>
          
            table {
              width: 100%;
              border-collapse: collapse;
              border: 1px solid #000;
            }
            th {
              border: 1px solid #000;
              text-align: left;
              background-color: #f2f2f2;
              padding: 10px;

            }
            
            .text-right {
              text-align: right; /* Right alignment for printed text */
            }

            .bill_name{
              border: 1px solid #000;
              padding: 10px;
            }
            .table_data{
              border: 1px solid #000;
           }
            .heading{
              background: #eee;
            }
            
          # company_details{
          display: flex;
          justity: space-between;
          border: 1px solid red;
          }
          
          </style>
        </head>
        <body onload="window.print()">
          <div>${invoiceContent}</div>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  const numberToWords = (num) => {
    const a = [
      '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
      'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const b = [
      '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];

    const convert = (n) => {
      if (n < 20) return a[n];
      if (n < 100) return b[Math.floor(n / 10)] + (n % 10 ? '-' + a[n % 10] : '');
      if (n < 1000) return a[Math.floor(n / 100)] + ' Hundred' + (n % 100 ? ' ' + convert(n % 100) : '');
      if (n < 100000) return convert(Math.floor(n / 1000)) + ' Thousand' + (n % 1000 ? ' ' + convert(n % 1000) : '');
      if (n < 10000000) return convert(Math.floor(n / 100000)) + ' Lakh' + (n % 100000 ? ' ' + convert(n % 100000) : '');
      return convert(Math.floor(n / 10000000)) + ' Crore' + (n % 10000000 ? ' ' + convert(n % 10000000) : '');
    };

    // Split the number into whole and fractional parts
    const parts = num.toString().split('.');
    const wholePart = Math.floor(num);
    const fractionalPart = parts[1] ? parts[1] : '0';

    // Convert both parts to words
    const wholeInWords = convert(wholePart);
    let fractionalInWords = '';

    // Handle fractional part
    if (fractionalPart !== '0') {
      fractionalInWords = ' Point ';
      for (const digit of fractionalPart) {
        fractionalInWords += a[parseInt(digit)] + ' ';
      }
    }

    return wholeInWords + fractionalInWords.trim() + ' Only';
  };

  useEffect(() => {
    const user = JSON.parse(secureLocalStorage.getItem("loggedInUser"));
    setUser(user);
    setCompanyLogo((user?.company_logo)?.split('?X-Amz-Algorithm=')[0]);
    setCompanyName(user?.company_name);
    setCompanyMobile(user?.mobile_number);
    setCompanyEmail(user?.email);
    if (user?.city && user?.state) {
      setCompanyAddress(`${user?.address}, ${user?.city}, ${user?.state}, ${user?.country}`);
    }
  }, []);

  return (
    <div>
      <div className="invoice-box" id="invoice">
        <div style={{ display: "flex", columnGap: "520px" }}>
          <div className="w-28">
            <img src={companyLogo} style={{ width: "120px" }} alt="Company Logo" />
          </div>
          <div className=" justify-center font-bold ">
            <h2 id="Invoice_heading">
              {invoiceData.sale_inv_no ? "TAX" : "PURCHASE"} INVOICE
            </h2>
          </div>
          {/* <div>
            <h2>{companyName}</h2>
          </div> */}
        </div>
        <Row style={{ columnGap: "60%", width: '100%' }}>
          <div className="flex justify-between w-full" id="company_details">
            <div>
              <h1><b>{companyName}</b></h1>
              <p>GSTIN : {user.gst}
                <br />
                Mobile No. : {companyMobile}
                <br />
                Email Id : {companyEmail}
                <br />
                Address : {companyAddress}
              </p>
            </div>
            <div>
              <p>
                {invoiceData.sale_inv_no &&
                  <p>Sale Inv No : {invoiceData.sale_inv_no}</p>
                }
                {invoiceData.purchase_inv_no &&
                  <p>Purchae Inv No : {invoiceData.purchase_inv_no}</p>
                }
                {invoiceData.original_inv_no &&
                  <p>Original Inv No : {invoiceData.original_inv_no}</p>
                }
                {/* Original Inv No : {invoiceData.original_inv_no ? invoiceData.original_inv_no : invoiceData.purchase_inv_no}
                <br /> */}
                Order No :  {invoiceData.order_no ? invoiceData.order_no : invoiceData.order_no}
                <br />
                Date : {invoiceData.date}
                <br />
                {invoiceData.eway_bill_no &&
                  <span>E-Way Bill No : {invoiceData.eway_bill_no}</span>
                }
              </p>
            </div>
          </div>
        </Row>
        <br />
        <table cellPadding="2" cellSpacing="2">
          <tbody>
            <tr className="heading">
              <th>Billing Address</th>
              <th>Shipping Address</th>
            </tr>
            <tr className="details">
              <td className="bill_name">
                Party:
                {invoiceData.billed_to_party_full_name}
                <br />
                Company:
                {invoiceData.billed_to_company_name}
                <br />
                Address:
                {invoiceData.billed_to_address} {invoiceData.billed_to_city} {invoiceData.billed_to_state} {invoiceData.billed_to_country}<br />
                {invoiceData.billed_to_gst_no &&
                  <>
                    <span>GST: {invoiceData.billed_to_gst_no}</span>
                    <br />
                  </>
                }
                Phone: {invoiceData.billed_to_mobile_no}
              </td>
              <td className="bill_name">
                Party:
                {invoiceData.shipped_to_party_full_name}
                <br />
                Company:
                {invoiceData.shipped_to_company_name}
                <br />
                Address:
                {invoiceData.billed_to_address} {invoiceData.billed_to_city} {invoiceData.billed_to_state} {invoiceData.billed_to_country}<br />
                {invoiceData.billed_to_gst_no &&
                  <>
                    <span>GST: {invoiceData.billed_to_gst_no}</span>
                    <br />
                  </>
                }
                Phone: {invoiceData.shipped_to_mobile_no}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="invoice-table " >
          <thead>
            <tr>
              <th>No.</th>
              <th>Item</th>
              <th>Hsn</th>
              <th>Qty</th>
              <th>Unit</th>
              <th>Rate (₹)</th>
              <th>Taxable (₹)</th>
              <th>Amount (₹)</th>
            </tr>
          </thead>
          <tbody>
            {(invoiceData.sale_items || invoiceData.purchase_items)?.map((item, index) => (
              <tr key={index}>
                <td className="table_data" style={{ padding: '1%' }}>{index + 1}</td>
                <td className="table_data" style={{ padding: '1%' }}>{item.item_name}</td>
                <td className="table_data" style={{ padding: '1%' }}>{item.item_hsn_code}</td>
                <td className="table_data" style={{ padding: '1%' }}>{item.qty}</td>
                <td className="table_data" style={{ padding: '1%' }}>{item.item_unit}</td>
                <td className="table_data" style={{ padding: '1%' }}>{parseFloat(item.rate).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td className="table_data" style={{ padding: '1%' }}>{parseFloat(item.total).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td className="table_data" style={{ padding: '1%' }}>{parseFloat(item.amount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className="summary-table">
          <tbody>
            <tr>
              <td className="text-right">Total Amount before Tax </td>
              <td className="text-right">{invoiceData.sub_total}</td>
            </tr>
            <tr>
              <td className="text-right">Round Off</td>
              <td className="text-right">{invoiceData.round_off}</td>
            </tr>
            {/* <tr>
              <td className="text-right">Shipping Charges</td>
              <td className="text-right">{invoiceData.shipping_charge}</td>
            </tr> */}
            {(invoiceData.sale_additional_charges || invoiceData.purchase_additional_charges)?.map((additional, index) => (
              <tr key={index}>
                <td className="text-right">{additional?.charge_name}</td>
                <td className="text-right">{additional?.amount}</td>
              </tr>
            ))}
            <tr>
              <td className="text-right">GST Amount</td>
              <td className="text-right">{invoiceData.gst_amount}</td>
            </tr>
            <tr>
              <td className="text-right">CGST </td>
              <td className="text-right">{invoiceData.cgst}</td>
            </tr>
            <tr>
              <td className="text-right">SGST </td>
              <td className="text-right">{invoiceData.sgst}</td>
            </tr>
            {(invoiceData.sale_tcs?.[0]?.tcs_amount || invoiceData.purchase_tcs?.[0]?.tcs_amount) && (
              <tr>
                <td className="text-right">TCS</td>
                <td className="text-right">
                  {invoiceData.sale_tcs?.[0]?.tcs_amount && (
                    <>
                      {invoiceData.sale_tcs[0].tcs_amount} ({invoiceData.sale_tcs[0].tcs_rate_percentage}%)
                    </>
                  )}
                  {invoiceData.purchase_tcs?.[0]?.tcs_amount && (
                    <>
                      {invoiceData.purchase_tcs[0].tcs_amount} ({invoiceData.purchase_tcs[0].tcs_rate_percentage}%)
                    </>
                  )}
                </td>
              </tr>
            )}

            {(invoiceData.sale_tds?.[0]?.tds_amount || invoiceData.purchase_tds?.[0]?.tds_amount) && (
              <tr>
                <td className="text-right">TDS</td>
                <td className="text-right">
                  {invoiceData.sale_tds?.[0]?.tds_amount && (
                    <>
                      {invoiceData.sale_tds[0].tds_amount} ({invoiceData.sale_tds[0].tds_rate_percentage}%)
                    </>
                  )}
                  {invoiceData.purchase_tds?.[0]?.tds_amount && (
                    <>
                      {invoiceData.purchase_tds[0].tds_amount} ({invoiceData.purchase_tds[0].tds_rate_percentage}%)
                    </>
                  )}
                </td>
              </tr>
            )}

            <tr>
              <td className="text-right"><strong>Total Amount</strong></td>
              <td className="text-right"><strong>{invoiceData.total_amount}</strong></td>
            </tr>
            <tr>
              <td className="text-right">Paid Amount </td>
              <td className="text-right">{invoiceData.paid_amount}</td>
            </tr>
            <tr>
              <td className="text-right"><strong>Balance Total </strong></td>
              <td className="text-right"><strong>{invoiceData.balance_amount}</strong></td>
            </tr>
            {/* <tr>
              <td className="text-right table-column-70">Status</td>
              <td className="text-right table-column-30"><strong>{invoiceData.payment_status.toUpperCase()}</strong></td>
            </tr> */}

          </tbody>
        </table>

        <table className="bank-details" style={{ paddingLeft: '10px' }}>
          <tbody className="bank_details" >
            <tr style={{ marginLeft: "10px" }}>
              <td><strong>Bank Details:</strong></td>
            </tr>
            <tr>
              <td>Bank Name: {user.bank_name}</td>
            </tr>
            <tr>
              <td>Account Holder Name: {user.acc_holder_name}</td>
            </tr>
            <tr>
              <td>Account No.: {user.acc_no}</td>
            </tr>
            <tr>
              <td>IFSC: {user.ifsc_code}</td>
            </tr>
          </tbody>
        </table>

        <p>
          Total Invoice Amount in Words: <strong>{numberToWords(invoiceData.total_amount)}</strong>
        </p>
        <p>This is a computer-generated invoice.</p>
      </div>

      <button onClick={handlePrint} className="print-btn">Print Invoice</button>
    </div>
  );
};

export default Invoice;