import React, { useEffect, useState } from 'react';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Dropdown, Button, Menu, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import DynamicTable from '../../tables/DynamicTable';
import CategoryContained from '../category/CategoryContained';
import AppListHeader from '../../header/AppListHeader';


export default function ItemList({ columns, tableColumns, items, handleTableChange }) {

  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCategory, setNewCategory] = useState('');

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  

  return (
    <div className="">
      <div className="overflow-x-auto">
        <DynamicTable
          columns={columns}
          data={items}
          tableColumns={tableColumns}
          handleTableChange={handleTableChange}
        />
      </div>
      <Modal
        title="Add Category"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CategoryContained />
      </Modal>
    </div>
  );
}
