import React, { useState, useEffect } from 'react';
import { Form, Input, DatePicker, Select, message } from 'antd';
import 'tailwindcss/tailwind.css';

const { Option } = Select;

const PaymentInForm = () => {
  const [form] = Form.useForm();
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [invoices, setInvoices] = useState([
    { date: '27 Jun 2024', dueDate: '27 Jul 2024', number: 1, amount: 715.04, pending: 715.04, settled: 0 },
    { date: '27 Jun 2024', dueDate: '27 Jul 2024', number: 2, amount: 1000, pending: 1000, settled: 0 },
  ]);

  const handleCheckboxChange = (invoiceNumber) => {
    const updatedSelectedInvoices = selectedInvoices.includes(invoiceNumber)
      ? selectedInvoices.filter((num) => num !== invoiceNumber)
      : [...selectedInvoices, invoiceNumber];
    setSelectedInvoices(updatedSelectedInvoices);
  };

  useEffect(() => {
    const totalPending = selectedInvoices.reduce((total, num) => {
      const invoice = invoices.find((inv) => inv.number === num);
      return total + invoice.pending;
    }, 0);
    setPaymentAmount(totalPending);
  }, [selectedInvoices]);

  const handlePaymentAmountChange = (e) => {
    const amount = parseFloat(e.target.value) || 0;
    setPaymentAmount(amount);

    const updatedInvoices = invoices.map((invoice) => {
      if (amount >= invoice.pending) {
        amount -= invoice.pending;
        return { ...invoice, settled: invoice.pending, pending: 0 };
      } else if (amount > 0) {
        const newPending = invoice.pending - amount;
        return { ...invoice, settled: amount, pending: newPending };
      } else {
        return { ...invoice, settled: 0, pending: invoice.amount };
      }
    });

    setInvoices(updatedInvoices);
    const selectedInvoiceNumbers = updatedInvoices.filter(inv => inv.settled > 0).map(inv => inv.number);
    setSelectedInvoices(selectedInvoiceNumbers);
  };

  const handleErrors = (error) => {
    if (error.response && error.response.data) {
      // Assuming the backend returns an array of error messages
      const errorMessages = error.response.data.errors || [error.response.data.message];
      errorMessages.forEach(msg => message.error(msg));
    } else {
      message.error("An unexpected error occurred. Please try again.");
    }
  };

  const handleSubmit = async () => {
    try {
      // Simulating API call
      const response = await mockApiCall(); // Replace with actual API call
      if (!response.success) {
        handleErrors(response);
      } else {
        message.success("Payment processed successfully.");
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const mockApiCall = () => {
    return new Promise((resolve, reject) => {
      // Simulate a successful response
      resolve({ success: true });
      // Simulate an error response
      // reject({ response: { data: { errors: ["Payment amount exceeds available balance."] } } });
    });
  };

  return (
    <Form form={form} className="p-4" onFinish={handleSubmit}>
      <Form.Item label="Party Name" name="partyName">
        <Input defaultValue="rahul sahu" />
      </Form.Item>
      <Form.Item label="Current Balance">
        <span>₹6,715.04</span>
      </Form.Item>
      <Form.Item label="Enter Payment Amount">
        <Input value={paymentAmount} onChange={handlePaymentAmountChange} />
      </Form.Item>
      <Form.Item label="Payment Date">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Payment Mode">
        <Select defaultValue="CASH">
          <Option value="CASH">CASH</Option>
          <Option value="BANK">BANK</Option>
          <Option value="UPI">UPI</Option>
          <Option value="CARD">CARD</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Payment In Number" name="paymentInNumber">
        <Input defaultValue="1" />
      </Form.Item>
      <Form.Item label="Notes" name="notes">
        <Input.TextArea />
      </Form.Item>

   
  <table className="table-auto min-w-full mt-4 overflow-x-auto" style={{border:'1px solid'}}>
    <thead>
      <tr>
        <th className="px-4 py-2"></th>
        <th className="px-4 py-2">Date</th>
        <th className="px-4 py-2">Due Date</th>
        <th className="px-4 py-2">Invoice Number</th>
        <th className="px-4 py-2">Invoice Amount</th>
        <th className="px-4 py-2">Amount Settled</th>
      </tr>
    </thead>
    <tbody>
      {invoices.map((invoice) => (
        <tr key={invoice.number}>
          <td className="px-4 py-2">
            <input
              type="checkbox"
              checked={selectedInvoices.includes(invoice.number)}
              onChange={() => handleCheckboxChange(invoice.number)}
            />
          </td>
          <td className="px-4 py-2">{invoice.date}</td>
          <td className="px-4 py-2">{invoice.dueDate}</td>
          <td className="px-4 py-2">{invoice.number}</td>
          <td className="px-4 py-2">
            ₹{invoice.amount} (₹{invoice.pending} pending)
          </td>
          <td className="px-4 py-2">₹{invoice.settled}</td>
        </tr>
      ))}
    </tbody>
  </table>
{/* </div> */}


      <Form.Item>
        <button type="submit" className="mt-4 bg-blue-500 text-white p-2 rounded">
          Submit Payment
        </button>
      </Form.Item>
    </Form>
  );
};

export default PaymentInForm;
