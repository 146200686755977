import React, { useEffect, useState } from 'react';
import PartyList from './PartyList';
import { Button, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getParties } from './actions';

const { Search } = Input;
const { Option } = Select;

const columns = [
  { title: 'Party Name', dataIndex: 'company_name', key: 'company_name' },
  { title: 'Mobile No', dataIndex: 'mobile_no', key: 'mobile_no' },
  { title: 'Party Type', dataIndex: 'party_type', key: 'party_type' },
  { title: 'State', dataIndex: 'state', key: 'state' },
  { title: 'City', dataIndex: 'city', key: 'city' },
  { title: 'GST No', dataIndex: 'gst_no', key: 'gst_no' },
  { title: 'Opening Balance', dataIndex: 'opening_balance', key: 'opening_balance' },
  { title: 'Current Balance', dataIndex: 'current_balance', key: 'current_balance' },
];

export default function PartyDashboard() {
  const dispatch = useDispatch();
  const partyDatas = useSelector(state => state.parties.getParties.response);

  const [partySearchText, setPartySearchText] = useState('');
  const [filterQuery, setFilterQuery] = useState(null);
  const [partiesDetail, setPartyDetail] = useState({
    partiesCount: 0,
    toCollect: 0,
    toPay: 0,
  });
  const [paginationDetail, setPaginationDetail] = useState({
    currentPage: 1,
    partyCount: partyDatas?.parties?.results?.count,
    pageSize: filterQuery?.page_size,
  });
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    getPartiesData();
  }, [dispatch, partySearchText, filterQuery]);

  const getPartiesData = async () => {
    if (partySearchText) {
      await dispatch(getParties({ search: partySearchText }));
    } else if (filterQuery) {
      const response = await dispatch(getParties(filterQuery));
      if (response) {
        setPartyDetail({
          partiesCount: response.party_count,
          toCollect: response.total_to_collect_balance,
          toPay: response.total_to_pay_balance,
        });
      }
    } else {
      const response = await dispatch(getParties());
      if (response) {
        setPartyDetail({
          partiesCount: response.party_count,
          toCollect: response.total_to_collect_balance,
          toPay: response.total_to_pay_balance,
        });
      }
    }
  };

  const navigate = useNavigate();

  const handlePartySearch = (value) => {
    setPartySearchText(value);
  };

  const handlePrint = () => {
    navigate('/ListPrint', { state: { tableData: partyDatas?.parties?.results, tableColunm: columns, name: 'Party List' } });
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    setFilterQuery({
      ...filterQuery,
      ordering: sorter?.order === 'ascend' ? `${sorter?.field}` : sorter?.order === 'descend' ? `-${sorter?.field}` : null,
    });
  };

  const handlePageChange = (pageNumber) => {
    setFilterQuery({
      ...filterQuery,
      page: pageNumber,
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    setFilterQuery({
      ...filterQuery,
      page_size: pageSize,
    });
  };

  const handlePartyFilter = (value) => {
    setFilter(value);
    let updatedFilterQuery = { ...filterQuery };

    if (value === 'To Pay') {
      updatedFilterQuery = { party_type: 'To Pay' };
    } else if (value === 'To Collect') {
      updatedFilterQuery = { party_type: 'To Collect' };
    } else {
      updatedFilterQuery = null;
    }

    setFilterQuery(updatedFilterQuery);
  };

  return (
    <div className="p-4 bg-white min-h-screen">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold">Parties</h2>
      </div>

      <div className="flex flex-wrap gap-4 mb-4">
        <div className="p-4 border rounded-lg shadow-lg flex-1 min-w-[200px]">
          <p className="text-gray-500">All Parties</p>
          <h3 className="text-2xl font-bold">{partyDatas?.party_count}</h3>
        </div>
        <div className="p-4 border rounded-lg shadow-lg flex-1 min-w-[200px]">
          <p className="text-green-500">To Collect</p>
          <h3 className="text-2xl font-bold text-green-500">₹ {partyDatas?.total_to_collect_balance}</h3>
        </div>
        <div className="p-4 border rounded-lg shadow-lg flex-1 min-w-[200px]">
          <p className="text-red-500">To Pay</p>
          <h3 className="text-2xl font-bold text-red-500">₹ {partyDatas?.total_to_pay_balance}</h3>
        </div>
      </div>

      <div className="flex flex-col mb-4">
        <div className="flex items-center space-x-2 mb-2 flex-wrap">
          <div className="flex-1 min-w-[200px]">
            <Search
              placeholder="Search here"
              allowClear
              onSearch={handlePartySearch}
              className="w-full"
            />
          </div>
          <Button className="bg-[#a854f9] text-white" onClick={handlePrint}>
            <b>Print</b>
          </Button>
          <Select
            className="p-1 w-1/2 md:w-44"
            value={filter}
            onChange={handlePartyFilter}
          >
            <Option value="all">Select Categories</Option>
            <Option value="To Pay">To Pay</Option>
            <Option value="To Collect">To Collect</Option>
          </Select>
          <Button
            onClick={() => navigate('/party-form')}
            className="bg-violet-600 text-white"
          >
            <b>Create Party</b>
          </Button>
        </div>
      </div>

      <PartyList
        paginationDetail={paginationDetail}
        handlePageChange={handlePageChange}
        parties={partyDatas?.parties?.results}
        handleTableChange={handleTableChange}
        onShowSizeChange={onShowSizeChange}
      />
    </div>
  );
}
