// Actions Types for items

export const SAVE_ITEM_REQUEST = 'SAVE_ITEM_REQUEST';
export const SAVE_ITEM_SUCCESS = 'SAVE_ITEM_SUCCESS';
export const SAVE_ITEM_FAILURE = 'SAVE_ITEM_FAILURE';

export const GET_ITEMS_REQUEST = 'GET_ITEMS_REQUEST';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAILURE = 'GET_ITEMS_FAILURE';

export const UPDATE_ITEM_REQUEST = 'UPDATE_ITEM_REQUEST';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAILURE = 'UPDATE_ITEM_FAILURE';