import React, { useEffect, useState } from 'react';
import { DownOutlined, DownloadOutlined } from '@ant-design/icons';
import { Input, Dropdown, Button, Select, DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import PurchaseOrderList from './PurchaseOrderList';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchaseOrders } from './actions';
import * as XLSX from 'xlsx';
import AppListHeader from '../../header/AppListHeader';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';
import { getParties } from '../../party/actions';

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function PurchaseOrderDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const purchaseOrderDatas = useSelector(state => state.purchaseOrders.getPurchaseOrders.response);
    const partyResponse = useSelector(state => state.parties.getParties.response)
    const purchaseOrderDeleteResponse = useSelector(state => state.purchaseOrders.deletePurchaseOrder.response)

    const [filterQuery, setFilterQuery] = useState(null)

    useEffect(() => {
        dispatch(getPurchaseOrders());
        /**
         * handle party state
         */
        if (
            partyResponse?.party_count == partyResponse?.parties?.results?.length &&
            partyResponse?.parties?.results?.length > 0
        ) {
        }
        else {
            dispatch(getParties())
        }
        /**
         * end of handling party state
         */
    }, [dispatch, purchaseOrderDeleteResponse]);

    const handleCreatePurchaseOrder = () => {
        navigate('/purchase/purchase-order-form');
    };

    const handlePrint = () => {
        console.log(purchaseOrderDatas);
        navigate('/purchase-order-bill/print', { state: { purchaseOrderDatas } });
    };

    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Assuming purchaseOrderDatas is the list you want to export
        const wsData = purchaseOrderDatas?.purchase_orders?.results?.map((e, index) => [
            e.date, // Replace with correct date format if necessary
            e.purchase_order_no,
            e.billed_to_company_name,
            e.total_amount,
        ]) || [];

        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'Date',
                'PO Number',
                'Party Name',
                'Total Amount',
            ],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'PurchaseOrders');
        XLSX.writeFile(workbook, 'PurchasesOrders.xlsx');
    };

    const handlePartyChange = (party) => {
        setFilterQuery({
            ...filterQuery,
            party: party
        })
    }

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            setFilterQuery({
                ...filterQuery,
                date: dateStrings[0],
                date_lte: dateStrings[1]
            })
        } else {
            console.log('Clear');
            setFilterQuery({
                ...filterQuery,
                date: null,
                date_lte: null
            })
        }
    };
    const columns = [
        { title: 'Date', dataIndex: 'date' },
        { title: 'P O Number', dataIndex: 'purchase_order_no' },
        { title: 'Party Name', dataIndex: 'billed_to_company_name' },
        { title: 'Total Amount', dataIndex: 'total_amount' },
        // { title: 'Items', dataIndex: 'purchase_order_items' },
    ];

    return (
        <div className=''>
            <AppListHeader
                headerContent='Purchase Order'
                creationLable="Create Purchase Order"
                handleCreateNew={handleCreatePurchaseOrder}
                excel={true}
                handleExcelExport={handleExportToExcel}
                print={true}
                download={true}
                cancel={true}
                printNavigation='/ListPrint'
                printData={{ tableColunm: columns, tableData: purchaseOrderDatas?.purchase_orders?.results, name: 'Purchase Order List' }}
            />

            <div className="flex flex-wrap mb-4 shadow-md p-1">
                <Select
                    placeholder="Select Party"
                    className='w-[250px] m-1'
                    onChange={handlePartyChange}
                    allowClearurc
                    showSearch
                    optionFilterProp='children'
                >
                    {partyResponse?.parties?.results?.map((data, id) => (
                        <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
                    ))}
                </Select>

                <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={onRangeChange} />
            </div>

            <PurchaseOrderList purchaseOrderDatas={purchaseOrderDatas?.purchase_orders?.results} />
        </div>
    );
}
