import React from 'react'
import { Button, Form, Input, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { loginClient } from './actions';
import { Link, useNavigate } from 'react-router-dom';
import useSelection from 'antd/es/table/hooks/useSelection';
import  secureLocalStorage  from  "react-secure-storage";

export default function Login() {

    const [form] = Form.useForm()

    const dispatch = useDispatch();

    const navigate = useNavigate()

    const { error, loading, response } = useSelector(state => state.emailVarifies.login)

    const handleLogin = async (values) => {

        const response = await dispatch(loginClient(values))

        if (response?.success) {
            message.success(response?.success)
            secureLocalStorage.setItem("access", response?.tokens?.access)
            secureLocalStorage.setItem("tenant", response?.tenantInfo?.tenant_name)
            secureLocalStorage.setItem("refresh_token", response?.tokens?.refresh)
            secureLocalStorage.setItem("loggedInUser", JSON.stringify(response?.clientData))
            navigate("/dashboard")
        } else if (response?.error) {
            message.error(response?.error)
        } else {
            message.error("Something Went Wrong.")
        }
    }

    return (
        <div className='w-[100%]'>
            <div
                className='
                float-right text-[14px]
                '
            >New User? <Link className='text-[blue]' to="/email-verify">Register</Link>
            </div>
            <br />
            <br />
            <div
                className='
                text-[18px] font-bold text-[#000080]
                '
            >
                Welcome To Billzap
            </div>
            <div
                className='
                text-[14px]
                '
            >
                Login to Continue
            </div>
            <br />

            <Form
                form={form}
                onFinish={handleLogin}
            >
                <Form.Item
                    name="email"
                    rules={
                        [
                            {
                                required: true,
                                message: "Please Enter Email"
                            },
                            {
                                type: 'email',
                                message: "Please Enter Valid Email"
                            }
                        ]
                    }
                >
                    <Input
                        placeholder='Enter Email ID'
                        maxLength={50}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={
                        [
                            {
                                required: true,
                                message: "Please Enter Password"
                            }
                        ]
                    }
                >
                    <Input.Password
                        placeholder='Enter Password'
                        maxLength={50}
                    />
                </Form.Item>
                <div
                    className='
                    flex justify-between
                    '
                >
                    <Button
                        className='
                    rounded-2xl shadow-lg bg-[#000080] text-[white] px-10 h-[35px]
                    '
                        htmlType='submit'
                        disabled={loading}
                    >
                        <b>{loading ? "Loading..." : "Login"}</b>
                    </Button>
                    <Link to="/reset-password-link" className='text-[blue]'>Forget Password</Link>
                </div>
            </Form>
        </div>
    )
}

