// Actions Types for clents

export const SAVE_QUOTATION_REQUEST = 'SAVE_QUOTATION_REQUEST';
export const SAVE_QUOTATION_SUCCESS = 'SAVE_QUOTATION_SUCCESS';
export const SAVE_QUOTATION_FAILURE = 'SAVE_QUOTATION_FAILURE';

export const GET_QUOTATIONS_REQUEST = 'GET_QUOTATIONS_REQUEST';
export const GET_QUOTATIONS_SUCCESS = 'GET_QUOTATIONS_SUCCESS';
export const GET_QUOTATIONS_FAILURE = 'GET_QUOTATIONS_FAILURE';


export const DELETE_QUOTATIONS_REQUEST = 'DELETE_QUOTATIONS_REQUEST';
export const DELETE_QUOTATIONS_SUCCESS = 'DELETE_QUOTATIONS_SUCCESS';
export const DELETE_QUOTATIONS_FAILURE = 'DELETE_QUOTATIONS_FAILURE';
