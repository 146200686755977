import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, message, Upload } from 'antd';
import { MailOutlined, UploadOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import { useDispatch } from 'react-redux';
import { updateClient } from './actions';
import { useNavigate } from 'react-router-dom';
import { loggedInUser } from '../../settings/codeSettings/additionalMethods'
import secureLocalStorage from 'react-secure-storage';

const { Option } = Select;

export default function UpdateClientForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [clientData, setClientData] = useState({});

  useEffect(() => {
    // Fetch current client data and set initial form values
    // This is a placeholder. You should replace it with actual API call to get client data
    const fetchedClientData = JSON.parse(secureLocalStorage.getItem('loggedInUser')) || {};
    delete fetchedClientData['company_logo']
    setClientData(fetchedClientData);
    form.setFieldsValue(fetchedClientData);
  }, [form]);

  const onFinish = async (values) => {
    const clientId = clientData?.id;
    const response = await dispatch(updateClient(clientId, values));

    if (response?.success) {
      secureLocalStorage.setItem('loggedInUser', JSON.stringify(response.data));
      navigate('/customer-view');
    }
    else {
      if (typeof response=== 'object') {
        Object.entries(response).forEach(([key, value]) => {
          message.error(`${key}: ${value}`);
        });
      } else {
        message.error(response?.error);
      }
    }
  };

  const handleLogoChange = ({ fileList }) => setFileList(fileList);

  // const userData = {
  //   email: loggedInUser?.email,
  //   mobile_number: loggedInUser?.mobile_number,
  //   owner_name: loggedInUser?.owner_name,
  //   company_name: loggedInUser?.company_name
  // }

  return (
    <div className="">
      <Form
        form={form}
        layout="vertical"
        name="updateClientForm"
        onFinish={onFinish}
        initialValues={clientData}
      >
        <div className="flex justify-between mb-4">
          <h2 className="text-2xl font-semibold">Update Profile</h2>
          <div className="flex space-x-2">
            <Button type="primary" htmlType="submit" className="bg-purple-500 border-none shadow-inner">
              <b>Save</b>
            </Button>
            <Button
              htmlType="button"
              className="shadow-inner"
              onClick={() => navigate(-1)}
            >
              <b>Cancel</b>
            </Button>
          </div>
        </div>
        <div className="flex flex-wrap justify-between">
          <Card
            title="Company Information"
            size="small"
            className="w-full md:w-[49%] mb-4"
            style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}
          >
            <Form.Item name="company_name" label="Company Name" rules={[{ required: true }]}>
              <Input disabled/>
            </Form.Item>
            <Form.Item name="tag_line" label="Tag Line">
              <Input />
            </Form.Item>
            <Form.Item name="company_logo" label="Company Logo" getValueFromEvent={({ file }) => file.originFileObj}
            >
              <Upload
                listType="picture"
                maxCount={1}
                fileList={fileList}
                onChange={handleLogoChange}
                accept='image/png, image/jpeg, image/jpg'
              >
                <Button icon={<UploadOutlined />}>Upload Logo</Button>
              </Upload>
            </Form.Item>
          </Card>

          <Card
            title="Personal Information"
            size="small"
            className="w-full md:w-[49%] mb-4"
            style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}
          >
            <Form.Item name="owner_name" label="Owner Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
              <Input prefix={<MailOutlined />} disabled/>
            </Form.Item>
            <Form.Item name="mobile_number" label="Mobile Number" rules={[{ required: true }]}>
              <Input disabled/>
            </Form.Item>
          </Card>

          <Card
            title="Address"
            size="small"
            className="w-full md:w-[49%] mb-4"
            style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}
          >
            <Form.Item name="address" label="Address" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="city" label="City" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="state" label="State" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="country" label="Country" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Card>

          <Card
            title="Bank Information"
            size="small"
            className="w-full md:w-[49%] mb-4"
            style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}
          >
            <Form.Item name="bank_name" label="Bank Name">
              <Input />
            </Form.Item>
            <Form.Item name="acc_holder_name" label="Account Holder Name">
              <Input />
            </Form.Item>
            <Form.Item name="acc_no" label="Account Number">
              <Input />
            </Form.Item>
            <Form.Item name="ifsc_code" label="IFSC Code">
              <Input maxLength={30}/>
            </Form.Item>
          </Card>
          <Card
            title="Business Information"
            size="small"
            className="w-full md:w-[100%] mb-4"
            style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}
          >
            <Form.Item name="gst" label="GST Number">
              <Input />
            </Form.Item>
          </Card>

       
        </div>
      </Form>
    </div>
  );
}
