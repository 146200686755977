import axios from "axios";
import * as siteConfig from "../config/config";
import secureLocalStorage from "react-secure-storage";

const API = axios.create({
    baseURL: siteConfig.default.staticBaseURL,
    timeout: siteConfig.default.apiTimeout,
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName: "csrftoken",
    credentails: true,
})

API.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error)
    }
);

API.interceptors.response.use(
    (response) => {
        return response;
    },
    // (error) => {
    //     Promise.reject(error)
    // }
    async (error) => {
        // Check if the error is a 401 Unauthorized
        if (error.response && error.response.status === 401) {
            const refreshToken = secureLocalStorage.getItem('refresh_token');

            if (refreshToken) {
                try {
                    
                    // Assume there's an API endpoint to refresh the token
                    const response = await axios.post(`${siteConfig.default.staticBaseURL}adminApp/auth/refresh-token`, { refresh: refreshToken });

                    // Store the new token
                    secureLocalStorage.setItem('access', response.data.access);

                    // Update the Authorization header with the new token
                    error.config.headers['Authorization'] = `Bearer ${response.data.access}`;

                    // Retry the failed request with the new token
                    return API(error.config);

                } catch (refreshError) {
                    // If token refresh fails, log the user out
                    secureLocalStorage.removeItem('access');
                    secureLocalStorage.removeItem('loggedInUser');
                    secureLocalStorage.removeItem('tenant');
                    secureLocalStorage.removeItem('refresh_token');
                    window.location.href = '/login';
                    return Promise.reject(refreshError);
                }
            } else {
                // No refresh token available, log out
                secureLocalStorage.removeItem('access');
                secureLocalStorage.removeItem('loggedInUser');
                secureLocalStorage.removeItem('tenant');
                secureLocalStorage.removeItem('refresh_token');
                window.location.href = '/login';
            }
        }
        // secureLocalStorage.removeItem('access');
        // secureLocalStorage.removeItem('loggedInUser');
        // secureLocalStorage.removeItem('tenant');
        // secureLocalStorage.removeItem('refresh_token');
        // window.location.href = '/login';
        return Promise.reject(error);
    }
);

export default API;