import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DatePicker, Select, message } from 'antd';
import * as XLSX from 'xlsx';
import { getSales } from '../../../sale/saleInvoice/actions'; // Importing getSales action
import AppListHeader from '../../../header/AppListHeader';
import GstList from './GstList';
import { rangePresets } from '../../../../settings/codeSettings/additionalMethods';
import { getParties } from '../../../party/actions';

const { RangePicker } = DatePicker;

export default function GstR1Report() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gstR1ReportResponse = useSelector(state => state.sale.getSales.response);
  console.log(gstR1ReportResponse, 'aaaaa')
  const partyResponse = useSelector((state) => state.parties.getParties.response);

  const [filterQuery, setFilterQuery] = useState({});
  const [salesDetail, setSalesDetail] = useState({});
  const [saleSearchText, setSaleSearchText] = useState('');

  useEffect(() => {
    getSalesData();
    /**
     * handle party state
     */
    if (
      partyResponse?.party_count == partyResponse?.parties?.results?.length &&
      partyResponse?.parties?.results?.length > 0
    ) {

    }
    else {
      dispatch(getParties())
    }
    /**
     * end of handling party state
     */
  }, [dispatch, saleSearchText, filterQuery]);

  const getSalesData = async () => {
    let response;

    if (saleSearchText || filterQuery) {
      response = await dispatch(getSales(filterQuery));
    } else {
      response = await dispatch(getSales());
    }

    if (response) {
      setSalesDetail({
        totalSales: response.total_sales,
        totalPaidAmount: response.total_paid_amount,
        totalUnPaidAmount: response.total_unpaid_amount,
      });
    }
  };

  const handleExportToExcel = useCallback(() => {
    if (!gstR1ReportResponse?.sales?.results?.length) {
      message.warning('No data available to export');
      return;
    }

    const workbook = XLSX.utils.book_new();
    const wsData = gstR1ReportResponse?.sales?.results?.map((item) => [
      item.sale_inv_no,
      item.date,
      item.billed_to_company_name,
      item.total_amount,
      item.cgst,
      item.sgst,
      item.igst,
      item.gst_amount,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Invoice Number', 'Date', 'Customer GSTIN', 'Taxable Value', 'CGST', 'SGST', 'IGST', 'Total Tax'],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'GstR1Report');
    XLSX.writeFile(workbook, 'GstR1Report.xlsx');
  }, [gstR1ReportResponse]);

  const handlePartyChange = (partyId) => {
    setFilterQuery((prevQuery) => ({ ...prevQuery, billed_to__id: partyId || undefined }));
  };

  const handleGstTypeChange = (gstType) => {
    setFilterQuery((prevQuery) => ({ ...prevQuery, gst_type: gstType || undefined }));
  };

  const handleRangeChange = (dates, dateStrings) => {
    setFilterQuery((prevQuery) => ({
      ...prevQuery,
      start_date: dates ? dateStrings[0] : undefined,
      end_date: dates ? dateStrings[1] : undefined,
    }));
  };

  // const handleTableChange = (pagination, filters, sorter) => {
  //   // Logic to handle pagination, sorting, or filtering
  //   console.log('Table changed:', pagination, filters, sorter);
  // };
  const handleTableChange = async (pagination, filters, sorter) => {
    setFilterQuery({
      ...filterQuery,
      ordering: sorter?.order == 'ascend' ?
        `${sorter?.field}`
        :
        sorter?.order == 'descend' ?
          `-${sorter?.field}`
          :
          null,
    })
  }

  return (
    <div>
      <AppListHeader
        headerContent="GST R1 Report"
        excel
        handleExcelExport={handleExportToExcel}
        print
        cancel
      />

      <div className="flex flex-wrap mb-4 shadow-md p-1">
        {/* Party Selector */}
        <Select
          placeholder="Select Party"
          className="w-[250px] m-1"
          onChange={handlePartyChange}
          allowClear
          value={filterQuery.billed_to__id || undefined}
        >
          {partyResponse?.parties?.results?.map((party) => (
            <Select.Option key={party.id} value={party.id}>
              {party.company_name}
            </Select.Option>
          ))}
        </Select>

        {/* GST Type Selector */}
        <Select
          placeholder="Select GST Type"
          className="w-[250px] m-1"
          onChange={handleGstTypeChange}
          allowClear
          value={filterQuery.gst_type || undefined}
        >
          <Select.Option value="cgst">CGST</Select.Option>
          <Select.Option value="sgst">SGST</Select.Option>
          <Select.Option value="igst">IGST</Select.Option>
        </Select>

        {/* Date Range Picker */}
        <RangePicker
          className="w-[250px] m-1"
          presets={rangePresets}
          onChange={handleRangeChange}
        />
      </div>

      {/* GST List Table */}
      <GstList
        gstR1Data={gstR1ReportResponse?.sales?.results}
        handleTableChange={handleTableChange} // Passing handleTableChange function
      />
    </div>
  );
}
