import {
    SAVE_EXPENSE_CATEGORY_REQUEST,
    SAVE_EXPENSE_CATEGORY_SUCCESS,
    SAVE_EXPENSE_CATEGORY_FAILURE,

    GET_EXPENSE_CATEGORIES_REQUEST,
    GET_EXPENSE_CATEGORIES_SUCCESS,
    GET_EXPENSE_CATEGORIES_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,
    
    saveExpenseName: {
        loading: false,
        error: null,
        response: null,
    },

    getExpenseNames: {
        loading: false,
        error: null,
        response: null,
        list: []
    }
};

const expenseNameReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_EXPENSE_CATEGORY_REQUEST:
            return {
                ...state,
                saveExpenseName: {
                    ...state.saveExpenseName,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_EXPENSE_CATEGORY_SUCCESS:
            return {
                ...state,
                saveExpenseName: {
                    ...state.saveExpenseName,
                    loading: false,
                    error: null,
                    response: action.payload
                },
                getExpenseNames: {
                    ...state.getExpenseNames,
                    loading: false,
                    error: null,
                    response: {
                        ...state.getExpenseNames.response,
                        expense_categories: [
                            ...state.getExpenseNames.response.expense_categories,
                            action.payload?.data
                        ]
                    }
                    // response: [...state.getExpenseNames.response, action.payload?.data]
                }
            };
        case SAVE_EXPENSE_CATEGORY_FAILURE:
            return {
                ...state,
                saveExpenseName: {
                    ...state.saveExpenseName,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        case GET_EXPENSE_CATEGORIES_REQUEST:
            return {
                ...state,
                getExpenseNames: {
                    ...state.getExpenseNames,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_EXPENSE_CATEGORIES_SUCCESS:
            return {
                ...state,
                getExpenseNames: {
                    ...state.getExpenseNames,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_EXPENSE_CATEGORIES_FAILURE:
            return {
                ...state,
                getExpenseNames: {
                    ...state.getExpenseNames,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default expenseNameReducer;
