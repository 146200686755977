// actions.js
import axios from 'axios';
import {
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SEND_RESET_PASSWORD_LINK_REQUEST,
  SEND_RESET_PASSWORD_LINK_SUCCESS,
  SEND_RESET_PASSWORD_LINK_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
} from './actionTypes';
import { loginAPI, sendOtpAPI, varifyOtpAPI, sendResetPasswordLinkAPI, resetPasswordAPI } from '../../../api/Services';


/**
 * To send the otp
 */
export const sendOtpRequest = () => ({
  type: SEND_OTP_REQUEST
});

export const sendOtpSuccess = (response) => ({
  type: SEND_OTP_SUCCESS,
  payload: response
});

export const sendOtpFailure = error => ({
  type: SEND_OTP_FAILURE,
  payload: error
});


/**
 * To varify the otp
 */
export const verifyOtpRequest = () => ({
  type: VERIFY_OTP_REQUEST
});

export const verifyOtpSuccess = (response) => ({
  type: VERIFY_OTP_SUCCESS,
  payload: response
});

export const verifyOtpFailure = error => ({
  type: VERIFY_OTP_FAILURE,
  payload: error
});


/**
 * To login client
 */
export const loginRequest = () => ({
  type: LOGIN_REQUEST
});

export const loginSuccess = (response) => ({
  type: LOGIN_SUCCESS,
  payload: response
});

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: error
});


/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To send the otp for varfication
 */
export const sendOtp = (data) => async (dispatch) => {
  dispatch(sendOtpRequest());
  try {
    const response = await sendOtpAPI(data)

    if (response?.success) {
      dispatch(sendOtpSuccess(response))
    } else {
      dispatch(sendOtpFailure(response))
    }
    return response;

  } catch (error) {
    dispatch(sendOtpFailure(error.message));
    throw error; 
  }
};


/**
 * To vafify the otp and send it to the backend 
 */
export const verifyOtp = (data) => async (dispatch) => {
  dispatch(verifyOtpRequest());
  try {
    const response = await varifyOtpAPI(data)
    if (response.success){
      dispatch(verifyOtpSuccess(response));
    } else if (response.error){
      dispatch(verifyOtpFailure(response.error))
    }
    return response
  } catch (error) {
    dispatch(verifyOtpFailure(error.message));
  }
};



/**
 * To login the client
 */
export const loginClient = (data) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const response = await loginAPI(data)
    dispatch(loginSuccess(response));
    return response
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};


/**
 * To send reset password link
 */
export const sendResetPasswordLinkRequest = () => ({
  type: SEND_RESET_PASSWORD_LINK_REQUEST,
});

export const sendResetPasswordLinkSuccess = (response) => ({
  type: SEND_RESET_PASSWORD_LINK_SUCCESS,
  payload: response
});

export const sendResetPasswordLinkFailure = error => ({
  type: SEND_RESET_PASSWORD_LINK_FAILURE,
  payload: error
});


/**
 * To send the reset password link to the user's email
 */
export const sendResetPasswordLink = (data) => async (dispatch) => {
  dispatch(sendResetPasswordLinkRequest());
  try {
    const response = await sendResetPasswordLinkAPI(data);
    dispatch(sendResetPasswordLinkSuccess(response));
    return response;
  } catch (error) {
    dispatch(sendResetPasswordLinkFailure(error.message));
    throw error;
  }
};


/**
 * To reset the password
 */
export const resetPasswordRequest = () => ({
  type: RESET_PASSWORD_REQUEST
});

export const resetPasswordSuccess = (response) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: response
});

export const resetPasswordFailure = error => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error
});

export const resetPassword = (data, token) => async (dispatch) => {
  dispatch(resetPasswordRequest());
  try {
    const response = await resetPasswordAPI(data, token);
    dispatch(resetPasswordSuccess(response));
    return response;
  } catch (error) {
    dispatch(resetPasswordFailure(error.message));
    return error
  }
};
