import React from 'react';
import logo from '../../../images/logo.png';
import { Button, Col, Table, Row, Divider } from 'antd';
import { useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

export default function Invoice() {
    const location = useLocation();
    const record = location.state?.record || {};
    const { quotation = {}, billDetail = {}, customerDetail = {} } = record;
    const basicAmount = billDetail.basicAmount || 1000;
    const taxAmount = (basicAmount * 18) / 100;
    const billAmount = basicAmount + taxAmount;

   
    const printInvoice = () => {
        window.print();
    };


    const columns = [
        { title: 'ITEM', dataIndex: 'item_name', key: 'item_name' },
        { title: 'QTY', dataIndex: 'qty', key: 'qty' },
        { title: 'RATE', dataIndex: 'rate', key: 'rate' },
        { title: 'DISCOUNT', dataIndex: 'discount', key: 'discount' },
        { title: 'GST', dataIndex: 'gst_amount', key: 'gst_amount' },
        { title: 'AMOUNT', dataIndex: 'amount', key: 'amount' },
    ];

    const user = JSON.parse(secureLocalStorage.getItem("loggedInUser")) || {};

    return (
        <div className=" bg-white shadow-lg rounded-lg text-[9px] md:text-[15px]">
            <div className="invoice-content">
                <div className="overflow-auto w-full m-auto px-2 sm:px-4">
                    <div style={{ display: 'flex', justifyContent: 'space-between'  }} >
                    <div className="h-[22vh] sm:h-[15vh]">

                            <img src={user.company_logo || logo} alt="Logo" className="h-20" />
                            <p className="invoice-title">Quotation</p>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <h1 className="invoice-company-name">{user?.company_name}</h1>
                            <p className="invoice-detail">
                                {user?.address && user?.address + ','}
                                {user?.city && user?.city + ','}
                                {user?.state && user?.state + ','}
                                {user?.zip_code && user?.zip_code}
                            </p>
                            <p>Mobile Number: {user?.mobile_number}</p>
                            <p>Email: {user?.email}</p>
                            <p className="invoice-detail">GST Number: {user?.gst}</p>
                        </div>
                    </div>
                    <Divider className='m-0'/>


                    <div className='py-1 text-[9px] md:text-[15px]'>
                        <Row gutter={8} align="middle">
                            <Col span={13} className='text-start'>
                                <strong className='text-[9px] md:text-[15px]'>Quotation Number:</strong> <strong className='text-[9px] md:text-[15px]'>{record?.quotation_no}</strong>
                            </Col>
                            <Col span={11} className='text-start'>
                                <strong className='text-[9px] md:text-[15px]'>Date:</strong><strong className='text-[9px] md:text-[15px]'> {record?.date}</strong>
                            </Col>
                        </Row>
                    </div>
                    <Divider className='m-0'/>


                    <div className="flex">
                        <div className="w-full">
                            <div className="bg-gray-300 font-semibold px-2 py-1">Billed From</div>
                            <Divider className='m-0'/>
                            <div className='px-1'>Company Name - {user?.company_name}</div>
                            <div className='px-1'>Address - {user?.address}</div>
                            <div className='px-1'>City - {user?.city}</div>
                            <div className='px-1'>State - {user?.state}</div>
                            <div className='px-1'>Zip Code - {user?.zip_code}</div>
                            <div className='px-1'>Phone - {user?.mobile_number}</div>
                            <div className='px-1'>GST - {user?.gst}</div>
                        </div>
                        <div className="w-full sm:w-[45%]">
                            <div className="bg-gray-300 font-semibold px-2 py-1">Billed To</div>
                            <Divider className='m-0'/>

                            <div className='px-1'>Company Name - {record?.billed_to_company_name || ''}</div>
                            <div className='px-1'>Address - {record?.billed_to_address || ''}</div>
                            <div className='px-1'>City - {record?.city || ''}</div>
                            <div className='px-1'>State - {record?.state || ''}</div>
                            <div className='px-1'>Zip Code - {record?.zip_code || ''}</div>
                            <div className='px-1'>Phone - {record?.mobile_number || ''}</div>
                            <div className='px-1'>GST - {record?.gst || ''}</div>
                        </div>
                    </div>
                    <div className='overflow-auto'>
                        <Table
                            columns={columns}
                            dataSource={record?.quotation_items || []}
                            pagination={false}
                            className="rounded-lg border border-gray-300 mb-4 w-full text-[8px] sm:text-[10px] md:text-[12px]"
                            size="small"
                            rowClassName="hover:bg-gray-50"
                            cellFontSizeSM
                            style={{ fontSize: 'inherit' }}
                        />
                    </div>
                    <Row>
                        <Col span={12}></Col>
                        <Col span={12}>
                            <table className="table-auto w-full text-left text-[9px] md:text-[15px]">
                                <tbody>
                                    <tr>
                                        <td className='p-1 font-medium'>SUB TOTAL</td>
                                        <td className='p-1'>₹ {record?.sub_total}</td>
                                    </tr>
                                    <tr>
                                        <td className='p-1 font-medium'>GST Amount</td>
                                        <td className='p-1'>₹ {record?.gst_amount}</td>
                                    </tr>
                                    <tr>
                                        <td className='p-1 font-medium'>Shipping Charges</td>
                                        <td className='p-1'>₹ {record?.shipping_charge}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="border-t border-gray-300 my-2"></td>
                                    </tr>
                                    <tr>
                                        <th className='p-1 font-semibold'>TOTAL AMOUNT</th>
                                        <th className='p-1 font-semibold'>₹ {record?.total_amount}</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="border-t border-gray-300 my-2"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <div className="w-full flex my-4 justify-center">
                        <Button
                            type="primary"
                            onClick={printInvoice}
                            className="block mx-auto print-button"
                        >
                            Print Invoice
                        </Button>
                    </div>
                </div>

            <style>
                    {`
                    @media print {
                        body {
                            margin: 0;
                            padding: 0;
                            width: 210mm; /* A4 width */
                            height: 297mm; /* A4 height */
                        }
                        .invoice-content {
                            width: 210mm;
                            height: auto; /* Set height as needed */
                            margin: 0 auto;
                            // padding: 20mm; 
                        }
                        body * {
                            visibility: hidden;
                        }
                        .invoice-content, .invoice-content * {
                            visibility: visible;
                        }
                        .print-button {
                            display: none !important; /* Hide the print button during print */
                        }
                    }
                    `}
                </style>
                </div >
                </div >

         
    );
}
