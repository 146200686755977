// actions.js
import axios from 'axios';
import {
  SAVE_PURCHASE_REQUEST,
  SAVE_PURCHASE_SUCCESS,
  SAVE_PURCHASE_FAILURE,

  GET_PURCHASES_REQUEST,
  GET_PURCHASES_SUCCESS,
  GET_PURCHASES_FAILURE,

  DELETE_PURCHASE_FAILURE,
  DELETE_PURCHASE_SUCCESS,
  DELETE_PURCHASE_REQUEST

} from './actionTypes';
import { deletePurchasesAPI, getPurchasesAPI, savePurchaseAPI } from '../../../api/Services';
import { message } from 'antd';


export const savePurchaseRequest = () => ({
  type: SAVE_PURCHASE_REQUEST
});

export const savePurchaseSuccess = (response) => ({
  type: SAVE_PURCHASE_SUCCESS,
  payload: response
});

export const savePurchaseFailure = error => ({
  type: SAVE_PURCHASE_FAILURE,
  payload: error
});


/**
 * Get all purchased data
 */
export const getPurchasesRequest = () => ({
  type: GET_PURCHASES_REQUEST
});

export const getPurchasesSuccess = (response) => ({
  type: GET_PURCHASES_SUCCESS,
  payload: response
});

export const getPurchasesFailure = error => ({
  type: GET_PURCHASES_FAILURE,
  payload: error
});


/**
 * Delete the invoices
 */
export const deletePurchaseRequest = () => ({
  type: DELETE_PURCHASE_REQUEST
});

export const deletePurchaseSuccess = (response, id) => ({
  type: DELETE_PURCHASE_SUCCESS,
  payload: response,
  meta: { id: id }
});

export const deletePurchaseFailure = error => ({
  type: DELETE_PURCHASE_FAILURE,
  payload: error
});


/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To save the purchase detail
 */
export const savePurchase = (data) => async (dispatch) => {
  dispatch(savePurchaseRequest());
  try {
    const response = await savePurchaseAPI(data)

    if (response?.success) {
      dispatch(savePurchaseSuccess(response))
      message.success(response?.success)
    }
    else {
      dispatch(savePurchaseFailure(response?.error))
      message.error('Data not Saved! Please check your item fields')
    }
    return response;

  } catch (error) {
    dispatch(savePurchaseFailure(error.message));
    throw error;
  }
};



/**
 * To get the all purchased data
 */
export const getPurchases = (data) => async (dispatch) => {
  dispatch(getPurchasesRequest());
  try {
    const response = await getPurchasesAPI(data)

    if (response?.purchases) {
      dispatch(getPurchasesSuccess(response))
    }
    return response;
  } catch (error) {
    dispatch(getPurchasesFailure(error.message));
    throw error;
  }
};


/**
 * To delete the purchase detail
 */
export const deletePurchase = (id) => async (dispatch) => {
  dispatch(deletePurchaseRequest());
  try {
    const response = await deletePurchasesAPI(id)

    if (response?.success) {
      dispatch(deletePurchaseSuccess(response, id))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(deletePurchaseFailure(response))
      message.error(response?.error)
    }
    return response;
  } catch (error) {
    dispatch(deletePurchaseFailure(error.message));
    throw error;
  }
};