import React, { useEffect, useState } from 'react';
import { AudioOutlined, DownOutlined, DownloadOutlined } from '@ant-design/icons';
import { Input, Dropdown, Button, DatePicker, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DeliveryChalanList from './DeliveryChalanList'
import { getDeliveryChallans, getDeliveryChallansSuccess } from './actions';
import * as XLSX from 'xlsx';
import AppListHeader from '../../header/AppListHeader';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';
import { getParties } from '../../party/actions';
import { getItems } from '../../product/item/actions';

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function ItemList() {

  const dispatch = useDispatch();
  const DeliveryChalanDatas = useSelector(state => state.deliveryChallans.getDeliveryChallans.response)
  const partyResponse = useSelector(state => state.parties.getParties.response)
  const deliveryChallanDeleteResponse = useSelector(state => state.deliveryChallans.deleteDeliveryChallan.response);


  const [filterQuery, setFilterQuery] = useState(null)

  useEffect(() => {
    dispatch(getDeliveryChallans())
    /**
     * handle party state
     */
    if (
      partyResponse?.party_count == partyResponse?.parties?.results?.length &&
      partyResponse?.parties?.results?.length > 0
    ) {
    }
    else {
      dispatch(getParties())
    }
    /**
     * end of handling party state
     */

  }, [dispatch, deliveryChallanDeleteResponse])

  const navigate = useNavigate();

  const handleNavigateCreateDeliveryChallan = () => {
    navigate('/sales/delivery-challan-form')
  }

  const handlePrint = () => {
    console.log(DeliveryChalanDatas)
    navigate('/delivery-challan-bill/', { state: { DeliveryChalanDatas } });
  };
  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Assuming DeliveryChalanDatas is the list you want to export
    const wsData = DeliveryChalanDatas?.delivery_challans?.results.map((e) => [
      e.date,
      e.delivery_challan_no,
      e.billed_to_company_name,
      e.total_amount,
    ]) || [];

    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Date', 'Delivery Challan Number', 'Company Name', 'Total Amount'],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'DeliveryChallan');
    XLSX.writeFile(workbook, 'DeliveryChallan.xlsx');
  };


  const handlePartyChange = (party) => {
    setFilterQuery({
      ...filterQuery,
      party: party
    })
  }

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log('From: ', dates[0], ', to: ', dates[1]);
      console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      setFilterQuery({
        ...filterQuery,
        date: dateStrings[0],
        date_lte: dateStrings[1]
      })
    } else {
      console.log('Clear');
      setFilterQuery({
        ...filterQuery,
        date: null,
        date_lte: null
      })
    }
  };

  const columns = [
    { title: 'Date', dataIndex: 'date' },
    { title: 'Delivery Challan Number', dataIndex: 'delivery_challan_no' },

    { title: 'Party Name', dataIndex: 'billed_to_company_name' },
    { title: 'Total Amount', dataIndex: 'total_amount' },
    // { title: 'Items', dataIndex: 'purchase_order_items' },


  ];
  return (
    <div className=''>
      <AppListHeader
        headerContent='Delivery Challan'
        creationLable="Create Delivery Challan"
        handleCreateNew={handleNavigateCreateDeliveryChallan}
        excel={true}
        handleExcelExport={handleExportToExcel}
        print={true}
        download={true}
        cancel={true}
        printNavigation='/ListPrint'
        printData={{ tableColunm: columns, tableData: DeliveryChalanDatas?.delivery_challans?.results, name: 'Delivery Challan List' }}
      />

      <div className="flex flex-wrap mb-4 shadow-md p-1">
        <Select
          placeholder="Select Party"
          className='w-[250px] m-1'
          onChange={handlePartyChange}
          allowClear
          showSearch
          optionFilterProp='children'
        >
          {partyResponse?.parties?.results?.map((data, id) => (
            <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
          ))}
        </Select>

        <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={onRangeChange} />
      </div>

      <DeliveryChalanList DeliveryChalanDatas={DeliveryChalanDatas?.delivery_challans?.results} />

    </div>
  );
}
