import React from 'react'
import { Button, Form, Input, message } from 'antd'
import { useDispatch } from 'react-redux';
import { resetPassword } from './actions';
import { Link, useParams } from 'react-router-dom';

export default function ResetPassword() {
    const [form] = Form.useForm()
    const dispatch = useDispatch();
    const { token } = useParams();

    const handleResetPassword = async (values) => {
        if (values.newPassword !== values.confirmPassword) {
            message.error("Passwords do not match.");
            return;
        }

        const response = await dispatch(resetPassword(values, token))

        if (response?.success) {
            message.success(response.success)
            form.resetFields()
        } else if (response?.error) {
            message.error(response?.error)
        } else {
            message.error("Something Went Wrong.")
        }
    }

    return (
        <div className='w-[100%]'>
            <div
                className='
                text-[18px] font-bold text-[#000080]
                '
            >
                Reset Your Password
            </div>
            <div
                className='
                text-[14px]
                '
            >
                Enter your new password
            </div>
            <br />

            <Form
                form={form}
                onFinish={handleResetPassword}
            >
                <Form.Item
                    name="new_password"
                    rules={[
                        {
                            required: true,
                            message: "Please Enter New Password"
                        },
                        {
                            min: 6,
                            message: "Password must be at least 6 characters long"
                        }
                    ]}
                >
                    <Input.Password
                        placeholder='Enter New Password'
                        maxLength={50}
                    />
                </Form.Item>
                <Form.Item
                    name="confirm_password"
                    rules={[
                        {
                            required: true,
                            message: "Please Confirm New Password"
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('new_password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords do not match'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        placeholder='Confirm New Password'
                        maxLength={50}
                    />
                </Form.Item>
                <div
                    className='
                    flex justify-between
                    '
                >
                    <Button
                        className='
                    rounded-2xl shadow-lg bg-[#000080] text-[white] px-10 h-[35px]
                    '
                        htmlType='submit'
                    >
                        <b>Reset Password</b>
                    </Button>

                    <Link to="/login" className='text-[blue]'>Back to Login</Link>
                </div>
            </Form>
        </div>
    )
}
