import React, { useEffect, useState } from 'react';
import { Dropdown, Button, Card, Select, DatePicker } from 'antd';
import DynamicTable from '../../tables/DynamicTable';
import { DownOutlined, CalendarOutlined, ShareAltOutlined, ArrowDownOutlined, PrinterOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getLedgers } from './actions';
import { getDayBook } from './actions';
import { useNavigate } from 'react-router-dom';
import AppListHeader from '../../header/AppListHeader';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';
import { getParties } from '../../party/actions';
import * as XLSX from 'xlsx';

const { RangePicker } = DatePicker;

function DayBook({ companyDetail }) {

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const dayBookResponse = useSelector(state => state.dayBooks.getDayBooks.response)
  const partyResponse = useSelector(state => state.parties.getParties.response)

  const [filterQuery, setFilterQuery] = useState(null)

  useEffect(() => {
    getDayBookData()

    /**
     * handle party state
     */
    if (
      partyResponse?.party_count == partyResponse?.parties?.length &&
      partyResponse?.parties?.length > 0
    ) {

    }
    else {
      dispatch(getParties())
    }
    /**
     * end of handling party state
     */

  }, [dispatch])


  /**
   * To create for header and access key
   */
  const columns = [
    { Header: 'Company Name', accessor: 'company_name' },
    { Header: 'Voucher', accessor: 'voucher_type' },
    { Header: 'Party Type', accessor: 'party_type' },
    { Header: 'Amount', accessor: 'amount' }
  ];


  const getDayBookData = async () => {
    // const partyID = companyDetail?.id
    if (
      dayBookResponse?.day_book_count == dayBookResponse?.day_book?.length &&
      dayBookResponse?.day_book?.length > 0
    ) {

    }
    else {
      const response = await dispatch(getDayBook())
    }
  }

  const tableColumns = columns.map((dayBook) => ({
    title: dayBook.Header,
    dataIndex: dayBook.accessor,
    key: dayBook.accessor,
  }));

  const preparedDayBooks = dayBookResponse?.day_book?.map((dayBook) => ({
    party: dayBook.party,
    company_name: dayBook.company_name,
    amount: dayBook.amount,
    party_type: dayBook.party_type ? dayBook.party_type : '-',
    voucher_type: dayBook.sale ? 'Sale' : dayBook.purchase ? 'Purchase' : dayBook.expense ? 'Expense' : dayBook.payment_in ? 'Payment In' : 'Payment Out'
  }));

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Assuming paymentOutDatas is the list you want to export
    const wsData = preparedDayBooks.map((e, index) => [
      index + 1,
      e.company_name,
      e.party_type,
      e.voucher_type,
      e.amount,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      [
        'S.No.',
        'Party',
        'Party Type',
        'Voucher Type',
        'Amount'
      ],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Daybook');
    XLSX.writeFile(workbook, 'Daybook.xlsx');
  };

  /**
   * filter for changes
   */
  const handleVoucherTypeChange = (type) => {
    setFilterQuery({
      ...filterQuery,
      voucher_type: type
    })
  }

  const handlePartyChange = (party) => {
    setFilterQuery({
      ...filterQuery,
      party: party
    })
  }

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log('From: ', dates[0], ', to: ', dates[1]);
      console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      setFilterQuery({
        ...filterQuery,
        date: dateStrings[0],
        date_lte: dateStrings[1]
      })
    } else {
      setFilterQuery({
        ...filterQuery,
        date: null,
        date_lte: null
      })
    }
  };
  /**
   * end of changing filteration
   */


  return (
    <div className="">
      <AppListHeader
        headerContent='Daybook Report'
        excel={true}
        handleExcelExport={handleExportToExcel}
        print={true}
        download={false}
        cancel={true}
        printNavigation='/ListPrint'
        printData={{ tableColunm: tableColumns, tableData: preparedDayBooks, name: ' DayBook List' }}
      />

      <div className="flex flex-wrap mb-4 shadow-md p-1">
        <Select
          placeholder="Select Party"
          className='w-[250px] m-1'
          onChange={handlePartyChange}
          allowClear
          showSearch
          optionFilterProp='children'
        >
          {partyResponse?.parties?.results?.map((data, id) => (
            <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
          ))}
        </Select>

        <Select
          placeholder="Select Voucer Type"
          className='w-[250px] m-1'
          onChange={handleVoucherTypeChange}
          allowClear
        >
          <Select.Option value='sale'>Sale</Select.Option>
          <Select.Option value='purchase'>Purchase</Select.Option>
        </Select>
        <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={onRangeChange} />
      </div>

      <DynamicTable columns={tableColumns} data={preparedDayBooks} tableColumns={tableColumns} />
    </div>
  );
}

export default DayBook;