import dayjs from 'dayjs';
import secureLocalStorage from 'react-secure-storage';

/**
* created for contain methods for reusibility
*/

import { Table } from "antd";

/**
 * create custom table
 */
const customTable = (tableColumns, saleItems) => {
    return (
        <div>
            <Table
                size='small'
                columns={tableColumns}
                dataSource={saleItems}
                bordered
                pagination={false}
                rowClassName={() => 'border-red border'}
            />
        </div>
    )
}
/**
 * end of creating custome table
 */


/**
 * create search method
 */
function handleSearchText(value, stateFunc) {
    stateFunc(value)
    return value
}
/**
 * end of creating seach method
 */


/**
 * set the date range for filteration
 */
const rangePresets = [
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Last 14 Days',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'Last 90 Days',
        value: [dayjs().add(-90, 'd'), dayjs()],
    },
    {
        label: 'Last 60 Days',
        value: [dayjs().add(-60, 'd'), dayjs()],
    },
    {
        label: 'Last 365 Days',
        value: [dayjs().add(-365, 'd'), dayjs()],
    },
];
/**
 * end of setting date range
 */

/**
 * get the secureLocalStorage data
 */
const loggedInUser = JSON.parse(secureLocalStorage.getItem('loggedInUser'))
/**
 * End of getting secureLocalStorage data
 */

export {
    customTable,
    handleSearchText,
    rangePresets,
    loggedInUser
}