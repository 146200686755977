import React from 'react';
import { Layout, Row, Col, Divider, Typography, Table,Button } from 'antd'; // Import Ant Design components
import logo from '../../../images/logo.png'; // Import logo image
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { loggedInUser } from '../../../settings/codeSettings/additionalMethods';
import Config from '../../../config/config';
const { Header, Content } = Layout;
const { Title } = Typography;

// Define columns for the invoice table
const columns = [
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Party Name', dataIndex: 'billed_to', key: 'billed_to' },
  { title: 'Sale Invoice Number', dataIndex: 'sale_inv_no', key: 'sale_inv_no' },
  { title: 'Original Invoice Number', dataIndex: 'original_inv_no', key: 'original_inv_no' },
  { title: 'Shipped To', dataIndex: 'shipped_to', key: 'shipped_to' },
  { title: 'Amount', dataIndex: 'total_amount', key: 'total_amount' },
  { title: 'Balance Amount', dataIndex: 'balance_amount', key: 'balance_amount' },
  { title: 'Status', dataIndex: 'payment_status', key: 'payment_status' },
];
const printInvoice = () => {
    window.print();
  };
export default function SaleInvoiceListPrint() {
  const location = useLocation();
  const saleInvoiceList = location.state.salesData;
  console.log(saleInvoiceList)

  return (
    <Layout className="p-6 bg-gray-100 min-h-screen">
      <Header className="bg-blue-500 py-1 text-center h-10 shadow-md">
        <Title level={2} className="text-blue-100">Sale Invoice</Title>
      </Header>
      <Content className="p-8 bg-white shadow-lg rounded-lg">
        <div className="invoice-content">
          <Row>
            <Col span={4}>
              <img src={loggedInUser?.company_logo.split('?X-Amz-Algorithm=')[0]} style={{ height: '20vh' }} alt="Company Logo" />
            </Col>
            <Col span={18}>
              <div style={{ textAlign: 'center' }}>
                <h1 style={{ fontSize: 'xx-large', fontWeight: 'bold' }}>{loggedInUser.company_name.toUpperCase()}</h1>
                <p style={{ fontSize: 'large' }}>{loggedInUser.address}</p>
                <p>Mobile Number : {loggedInUser.mobile_number}</p>
                <p>Email : {loggedInUser.email}</p>
                <p style={{ fontSize: 'large', fontWeight: 'bold' }}>GST Number: {loggedInUser.gst}</p>
              </div>
            </Col>
          </Row>
          <Divider />
          <Table
            columns={columns}
            dataSource={saleInvoiceList|| []}
            pagination={false}
            className="rounded-lg border border-gray-300 mb-6"
            size="small"
            rowClassName="hover:bg-gray-50"
          />
        </div>
        <style>
          {`
            @media print {
              body * {
                visibility: hidden;
              }
              .invoice-content, .invoice-content * {
                visibility: visible;
              }
              .invoice-content {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
              }
            }
          `}
        </style>
        <Button
          type="primary"
          onClick={printInvoice}
          className="mt-6 block mx-auto"
        >
          Print Invoice
        </Button>
      </Content>
    </Layout>
  );
}
