// reducer.js
import {
    GET_DAYBOOK_REQUEST,
    GET_DAYBOOK_SUCCESS,
    GET_DAYBOOK_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    getDayBooks: {
        loading: false,
        error: null,
        response: null,
    }
};

const dayBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DAYBOOK_REQUEST:
        return {
            ...state,
            getDayBooks: {
                ...state.getDayBooks,
                loading: true,
                error: null,
                response: null
            }
        };
        case GET_DAYBOOK_SUCCESS:
            return {
                ...state,
                getDayBooks: {
                    ...state.getDayBooks,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_DAYBOOK_FAILURE:
            return {
                ...state,
                getDayBooks: {
                    ...state.getDayBooks,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default dayBookReducer;
