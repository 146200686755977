// reducer.js
import {
    SAVE_PaymentIn_REQUEST,
    SAVE_PaymentIn_SUCCESS,
    SAVE_PaymentIn_FAILURE,

    GET_PaymentIn_REQUEST,
    GET_PaymentIn_SUCCESS,
    GET_PaymentIn_FAILURE,

    DELETE_PAYMENTIN_REQUEST,
    DELETE_PAYMENTIN_SUCCESS,
    DELETE_PAYMENTIN_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    savePaymentIn: {
        loading: false,
        error: null,
        response: null,
    },

    getPaymentIn: {
        loading: false,
        error: null,
        response: null,
    },

    deletePaymentIn: {
        loading: false,
        error: null,
        response: null,
    }
};


const paymentInReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PaymentIn_REQUEST:
            return {
                ...state,
                savePaymentIn: {
                    ...state.savePaymentIn,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_PaymentIn_SUCCESS:
            return {
                ...state,
                savePaymentIn: {
                    ...state.savePaymentIn,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case SAVE_PaymentIn_FAILURE:
            return {
                ...state,
                savePaymentIn: {
                    ...state.savePaymentIn,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        case GET_PaymentIn_REQUEST:
            return {
                ...state,
                getPaymentIn: {
                    ...state.getPaymentIn,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_PaymentIn_SUCCESS:
            return {
                ...state,
                getPaymentIn: {
                    ...state.getPaymentIn,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_PaymentIn_FAILURE:
            return {
                ...state,
                getPaymentIn: {
                    ...state.getPaymentIn,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        // Handle delete actions
        case DELETE_PAYMENTIN_REQUEST:
            return {
                ...state,
                deletePaymentIn: {
                    ...state.deletePaymentIn,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case DELETE_PAYMENTIN_SUCCESS:
            return {
                ...state,
                deletePaymentIn: {
                    ...state.deletePaymentIn,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case DELETE_PAYMENTIN_FAILURE:
            return {
                ...state,
                deletePaymentIn: {
                    ...state.deletePaymentIn,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default paymentInReducer;
