import React, { useEffect, useState } from 'react';
import { Card, Select, DatePicker } from 'antd';
import DynamicTable from '../tables/DynamicTable';
import { useDispatch, useSelector } from 'react-redux';
import { getTdsPayable } from './action';
import AppListHeader from '../header/AppListHeader';
import * as XLSX from 'xlsx';
import { rangePresets } from '../../settings/codeSettings/additionalMethods';

const { RangePicker } = DatePicker;

function TdsPayable({ companyDetail }) {

  const dispatch = useDispatch()
  const TdsPayablesResponse = useSelector(state => state.taxDetails.getTdsPayableCharged.response)
  const partyResponse = useSelector(state => state.parties.getParties.response)

  const [filterQuery, setFilterQuery] = useState({ party__id: companyDetail?.id })

  useEffect(() => {
    getTdsPayableData()
  }, [dispatch, filterQuery])


  /**
   * To create for header and access key
   */
  const columns = [
    { Header: 'Date', accessor: 'date' },
    { Header: 'Tds Rate', accessor: 'tds_rate_percentage' },
    { Header: 'Tds Amount', accessor: 'tds_amount' },
    { Header: 'Particular', accessor: 'particular' },
  ];


  const getTdsPayableData = async () => {
    const partyID = companyDetail?.id
    const response = await dispatch(getTdsPayable(filterQuery))
  }

  const tableColumns = columns.map((TdsPayable) => ({
    title: TdsPayable.Header,
    dataIndex: TdsPayable.accessor,
    key: TdsPayable.accessor,
  }));

  const preparedTdsPayables = TdsPayablesResponse?.tds_payable?.map((TdsPayable) => ({
    date: TdsPayable.date,
    tds_rate_percentage: TdsPayable.tds_rate_percentage,
    tds_amount: TdsPayable.tds_amount,
    particular: TdsPayable.sale && 'Sale' || TdsPayable.purchase && 'Purchase' || TdsPayable.expense && 'Expense' || TdsPayable.payment_in && 'Payment In' || TdsPayable.payment_out && 'Payment Out'
  }));


  /**
   * current data export to excel sheet
   */
  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Assuming purchaseDatas is the list you want to export
    const wsData = preparedTdsPayables?.map((e, index) => [
      index + 1,
      e.date,
      e.tds_rate_percentage,
      e.tds_amount,
      e.particular,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      [
        'S.No.',
        'Date',
        'TDS Rate',
        'TDS amount',
        'Particular'
      ],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, `TdsPayable_${companyDetail?.company_name || ''}`);
    XLSX.writeFile(workbook, `TdsPayable_${companyDetail?.company_name || ''}.xlsx`);
  };
  /**
   * end of xeporting data
   */


  /**
   * filter for changes
   */
  const handleVoucherTypeChange = (type) => {
    setFilterQuery({
      ...filterQuery,
      voucher_type: type
    })
  }

  const handlePartyChange = (party) => {
    setFilterQuery({
      ...filterQuery,
      party: party
    })
  }

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      // console.log('From: ', dates[0], ', to: ', dates[1]);
      // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      setFilterQuery({
        ...filterQuery,
        date: dateStrings[0],
        date_lte: dateStrings[1]
      })
    } else {
      setFilterQuery({
        ...filterQuery,
        date: null,
        date_lte: null
      })
    }
  };
  /**
   * end of changing filteration
   */


  return (
    <div className="">

      {!companyDetail &&
        <AppListHeader
          headerContent='TdsPayable Report'
          excel={true}
          handleExcelExport={handleExportToExcel}
          print={true}
          download={false}
          cancel={true}
          printNavigation='/ListPrint'
          printData={{ tableColunm: tableColumns, tableData: preparedTdsPayables, name: ' Party TdsPayable  List' }}
        />
      }

      <div className="flex flex-wrap mb-4 shadow-md p-1">
        <Select
          placeholder="Select Party"
          className='w-[250px] m-1'
          onChange={handlePartyChange}
          allowClear
          showSearch
          optionFilterProp='children'
        >
          {partyResponse?.parties?.results?.map((data, id) => (
            <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
          ))}
        </Select>

        <Select
          placeholder="Select Voucer Type"
          className='w-[250px] m-1'
          onChange={handleVoucherTypeChange}
          allowClear
          showSearch
          optionFilterProp='children'
        >
          <Select.Option value='sale'>Sale</Select.Option>
          <Select.Option value='purchase'>Purchase</Select.Option>
        </Select>
        <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={onRangeChange} />
      </div>


      {companyDetail ?
        <Card title={`${companyDetail?.company_name}`} size="small" className="border-2 border-gray-300">
          <div className="flex justify-between">
            <div className="p-2 text-sm">
              To,<br />
              {companyDetail?.company_name}<br />
              {companyDetail?.address}, {companyDetail?.city}, {companyDetail?.state}, {companyDetail?.zip_code} <br />
              {companyDetail?.mobile_no} <br />
              {companyDetail?.gst_no}
            </div>
            <div className="border-2 border-gray-300 p-2 text-sm">
              <hr className="my-1 border-gray-400" />
              Total Receivable<br />
            </div>
          </div>
          <DynamicTable columns={tableColumns} data={preparedTdsPayables} tableColumns={tableColumns} />
        </Card>
        :
        <DynamicTable columns={tableColumns} data={preparedTdsPayables} tableColumns={tableColumns} />
      }
    </div>
  );
}

export default TdsPayable;
