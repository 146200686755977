import React, { useState } from 'react';
import { DownOutlined, EditOutlined, DeleteOutlined, UserOutlined, ReadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Tabs, Radio } from 'antd';
import Ledger from '../ledger/Ledger';
import Profile from '../parties/Profile';
import { useLocation } from 'react-router-dom';

export default function Parties() {

  const [loadings, setLoadings] = useState([]);
  const [size, setSize] = useState('large'); // State for tab size

  const location = useLocation()

  const { companyDetail } = location.state || {};

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 1000);
  };

  const menuProps = {
    items: [
      {
        key: '1',
        label: 'Option 1',
      },
      {
        key: '2',
        label: 'Option 2',
      },
      {
        key: '3',
        label: 'Option 3',
      },
    ],
  };

  const menu = (
    <Menu>
      {menuProps.items.map(item => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const tabItems = [
    {
      label: (
        <span>
          <UserOutlined />
          Profile
        </span>
      ),
      key: '1',
      children: <Profile companyDetail={companyDetail} />,
    },
    {
      label: (
        <span>
          <ReadOutlined />
          Ledger (Statement)
        </span>
      ),
      key: '2',
      children: <Ledger companyDetail={companyDetail} />,
    },
  ];

  return (
    <div className="p-4">
      {/* <div className="flex ml-[70%] space-x-2">
        <Dropdown overlay={menu}>
          <Button>
            Create New Invoice <DownOutlined />
          </Button>
        </Dropdown>
        <Button type="primary" icon={<EditOutlined />}>
        </Button>
        <Button type="danger" icon={<DeleteOutlined />}>
        </Button>
      </div> */}


      <Tabs defaultActiveKey="1" size={size} items={tabItems} />
    </div>
  );
}
