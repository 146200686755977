// actions.js
import axios from 'axios';
import {
  SAVE_CLIENT_REQUEST,
  SAVE_CLIENT_SUCCESS,
  SAVE_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE
} from './actionTypes';
import { saveClientAPI, updateClientAPI } from '../../api/Services';
import { message } from 'antd';


export const saveClientRequest = () => ({
  type: SAVE_CLIENT_REQUEST
});

export const saveClientSuccess = (response) => ({
  type: SAVE_CLIENT_SUCCESS,
  payload: response
});

export const saveClientFailure = error => ({
  type: SAVE_CLIENT_FAILURE,
  payload: error
});

// New action creators for updating client
export const updateClientRequest = () => ({
  type: UPDATE_CLIENT_REQUEST
});

export const updateClientSuccess = (response) => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: response
});

export const updateClientFailure = error => ({
  type: UPDATE_CLIENT_FAILURE,
  payload: error
});


/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To send the otp for varfication
 */
export const saveClient = (data) => async (dispatch) => {
  dispatch(saveClientRequest());
  try {
    const response = await saveClientAPI(data)

    if (response?.success) {
      dispatch(saveClientSuccess(response))
      message.success(response?.success)
    } 
    if (response?.error) {
      dispatch(saveClientFailure(response))
    }
    return response;

  } catch (error) {
    dispatch(saveClientFailure(error.message));
    throw error;
  }
};

/**
 * Action Method for updating client data
 */
export const updateClient = (clientId, data) => async (dispatch) => {
  dispatch(updateClientRequest());
  try {
    const response = await updateClientAPI(clientId, data);

    if (response?.success) {
      dispatch(updateClientSuccess(response));
      message.success(response?.success);
    } 
    if (response?.error) {
      dispatch(updateClientFailure(response));
    }
    return response;

  } catch (error) {
    dispatch(updateClientFailure(error.message));
    throw error;
  }
};