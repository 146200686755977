// actions.js
import axios from 'axios';
import {
  SAVE_SALE_REQUEST,
  SAVE_SALE_SUCCESS,
  SAVE_SALE_FAILURE,

  GET_SALES_REQUEST,
  GET_SALES_SUCCESS,
  GET_SALES_FAILURE,

  DELETE_SALE_FAILURE,
  DELETE_SALE_SUCCESS,
  DELETE_SALE_REQUEST


} from './actionTypes';
import { deleteSalesAPI, getSalesAPI, saveSaleAPI } from '../../../api/Services';
import { message } from 'antd';


export const saveSaleRequest = () => ({
  type: SAVE_SALE_REQUEST
});

export const saveSaleSuccess = (response) => ({
  type: SAVE_SALE_SUCCESS,
  payload: response
});

export const saveSaleFailure = error => ({
  type: SAVE_SALE_FAILURE,
  payload: error
});


/**
 * Get all sales
 */
export const getSalesRequest = () => ({
  type: GET_SALES_REQUEST
});

export const getSalesSuccess = (response) => ({
  type: GET_SALES_SUCCESS,
  payload: response
});

export const getSalesFailure = error => ({
  type: GET_SALES_FAILURE,
  payload: error
});


/**
 * Delete the invoices
 */
export const deleteSaleRequest = () => ({
  type: DELETE_SALE_REQUEST
});

export const deleteSaleSuccess = (response, id) => ({
  type: DELETE_SALE_SUCCESS,
  payload: response,
  meta: { id: id }
});

export const deleteSaleFailure = error => ({
  type: DELETE_SALE_FAILURE,
  payload: error
});



/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To save the sale detail
 */
export const saveSale = (data) => async (dispatch) => {
  dispatch(saveSaleRequest());
  try {
    const response = await saveSaleAPI(data)

    if (response?.success) {
      dispatch(saveSaleSuccess(response))
      message.success(response?.success)
    }
    else {
      dispatch(saveSaleFailure(response))
      if (response?.error?.items) {
        message.error(response?.error?.items["0"][0] || "Qty can't be more than stock")
      } else {
        message.error("Data not saved! Please Recheck Your data.")
      }
    }
    return response;

  } catch (error) {
    dispatch(saveSaleFailure(error.message));
    message.error("Something went wrong! Please check your data")
    throw error;
  }
};



/**
 * To get the all sales
 */
export const getSales = (data) => async (dispatch) => {
  dispatch(getSalesRequest());
  try {
    const response = await getSalesAPI(data)

    if (response) {
      dispatch(getSalesSuccess(response))
    }
    return response;

  } catch (error) {
    dispatch(getSalesFailure(error?.message));
    throw error;
  }
};


/**
 * To delete the sale detail
 */
export const deleteSale = (id) => async (dispatch) => {
  dispatch(deleteSaleRequest());
  try {
    const response = await deleteSalesAPI(id)
    if (response?.success) {
      dispatch(deleteSaleSuccess(response, id))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(deleteSaleFailure(response))
      message.error(response?.error)
    }
    return response;
  } catch (error) {
    dispatch(deleteSaleFailure(error.message));
    throw error;
  }
};