import React from 'react';
import logo from '../../../images/logo.png';
import { Button, Col, Table, Row, Divider } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useLocation } from 'react-router-dom';
import { loggedInUser } from '../../../settings/codeSettings/additionalMethods';
import secureLocalStorage from 'react-secure-storage';

export default function Invoice() {
    const location = useLocation();
    const record = location.state?.record || {};
    console.log(record)

    // Use record for dynamic data
    const {
        delivery_challan = {},
        billDetail = {},
        customerDetail = {}
    } = record;

    const basicAmount = billDetail.basicAmount || 1000;
    const taxAmount = (basicAmount * 18) / 100;
    const billAmount = basicAmount + taxAmount;

    const printInvoice = () => {

        window?.print();
    };

    const columns = [
        { title: 'ITEM', dataIndex: 'item_name', key: 'item_name' },
        { title: 'QTY', dataIndex: 'qty', key: 'qty' },
        { title: 'RATE', dataIndex: 'rate', key: 'rate' },
        { title: 'DISCOUNT', dataIndex: 'discount', key: 'discount' },
        { title: 'GST', dataIndex: 'gst_amount', key: 'gst_amount' },
        { title: 'AMOUNT', dataIndex: 'amount', key: 'amount' },
    ];

    const user = JSON.parse(secureLocalStorage.getItem("loggedInUser")) || {};

console.log(user,"usser")
    return (
        <div className="p-8 bg-white shadow-lg rounded-lg text-[9px] md:text-[15px]">
            <div className="invoice-content">
                <div className="overflow-auto w-[100%] m-auto px-2">
                  

                   
                            <div style={{ display: 'flex',justifyContent:'space-between' }}>
                                <div style={{ height:'20vh'}}>
                                     <img
                            src={user.company_logo || logo}
                            alt="Logo"
                            className="h-20"
                        />
                                    <p className='text-[10px] md:text-large font-bold' >Delivery Challan</p>

                                </div>
                                <div style={{ textAlign: 'right' }}>
                            <h1 className="invoice-company-name">{user?.company_name}</h1>
                            <p className="invoice-detail">
                                        {user?.address && user?.address + ','}
                                        {user?.city && user?.city + ','}
                                        {user?.state && user?.state + ','}
                                        {user?.zip_code && user?.zip_code}
                                    </p>
                                    <p>Mobile Number: {user?.mobile_number}</p>
                                    <p>Email: {user?.email}</p>
                                    <p className='text-[10px] md:text-large font-bold'>GST Number: {user?.gst}</p>
                                </div>
                            </div>
                        
                    <Divider className='m-0' />

                    

                        <div className='py-3 text-[9px] md:text-[15px]'>
                            <Row gutter={16} align="middle">
                                <Col span={13} className='text-start'>
                                    <strong className=' text-[9px] md:text-[15px]'>Quotation Number:</strong> <strong className=' text-[9px] md:text-[15px]'> {record?.delivery_challan_no}</strong>
                                </Col>
                                <Col span={11} className='text-start'>
                                    <strong className=' text-[9px] md:text-[15px]'>Date:</strong> <strong className=' text-[9px] md:text-[15px]'> {record?.date}</strong>
                                </Col>
                            </Row>
                        </div>

                        {/* <div className='flex'>
                            <div className='w-[35%] text-start'>Purchase Order Number</div>- {record?.delivery_challan_no}
                        </div>
                        <div className='flex'>
                            <div className='w-[35%] text-start'> Date</div>- {record.date}
                        </div> */}
               
                    <div className='flex sm:flex-row justify-between mt-3'>
                        <div className='w-[95%] sm:w-[45%]'>
                            <div className='bg-[lightgray] font-semibold px-2'>Billed From</div>
                            <div className='px-2'>Company Name - {loggedInUser?.company_name}</div>
                            <div className='px-2'>Address - {loggedInUser?.address}</div>
                            <div className='px-2'>City - {loggedInUser?.city}</div>
                            <div className='px-2'>State - {loggedInUser?.state}</div>
                            <div className='px-2'>Zip Code - {loggedInUser?.zip_code}</div>
                            <div className='px-2'>Phone - {loggedInUser?.mobile_number}</div>
                            <div className='px-2'>GST - {loggedInUser?.gst}</div>
                        </div>
                        <div className='w-[95%] sm:w-[45%]'>
                            <div className='bg-[lightgray] font-semibold px-2'>Billed To</div>
                            <div className='px-2'>Company Name - {record?.billed_to_company_name || ''}</div>
                            <div className='px-2'>Address - {record?.billed_to_address || ''}</div>
                            <div className='px-2'>City - {record?.city || ''}</div>
                            <div className='px-2'>State - {record?.state || ''}</div>
                            <div className='px-2'>Zip Code - {record?.zip_code || ''}</div>
                            <div className='px-2'>Phone - {record?.mobile_number || ''}</div>
                            <div className='px-2'>GST - {record?.gst || ''}</div>
                        </div>
                    </div>
                    <div className='overflow-auto'>

                   
                    <Table
                        columns={columns}
                        dataSource={record?.delivery_challan_items || []}
                        pagination={false}
                        className="rounded-lg border border-gray-300 mb-4 w-full text-[8px] sm:text-[10px] md:text-[12px]"
                        size="small"
                        rowClassName="hover:bg-gray-50"
                        cellFontSizeSM
                        style={{ fontSize: 'inherit' }}
                    />
                     </div>
                    <Row>

                        <Col span={12}></Col>
                        <Col span={12}>
                            <table className="table-auto w-full text-left text-[9px] md:text-[15px]">
                                <tbody>
                                    <tr>
                                        <td className='p-2 font-medium'>SUB TOTAL</td>
                                        <td className='p-2'>₹ {record?.sub_total}</td>
                                    </tr>
                                    <tr>
                                        <td className='p-2 font-medium'>GST Amount</td>
                                        <td className='p-2'>₹ {record?.gst_amount}</td>
                                    </tr>
                                    <tr>
                                        <td className='p-2 font-medium'>Shipping Charges</td>
                                        <td className='p-2'>₹ {record?.shipping_charge}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="border-t border-gray-300 my-2"></td>
                                    </tr>
                                    <tr>
                                        <th className='p-2 font-semibold'>TOTAL AMOUNT</th>
                                        <th className='p-2 font-semibold'>₹ {record?.total_amount}</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="border-t border-gray-300 my-2"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <div className='w-[100%] flex my-5'>
                    </div>
                </div>

                <style>
                    {`
                    @media print {
                        body {
                            margin: 0;
                            padding: 0;
                            width: 210mm; /* A4 width */
                            height: 297mm; /* A4 height */
                        }
                        .invoice-content {
                            width: 210mm;
                            height: auto; /* Set height as needed */
                            margin: 0 auto;
                            // padding: 20mm; 
                        }
                        body * {
                            visibility: hidden;
                        }
                        .invoice-content, .invoice-content * {
                            visibility: visible;
                        }
                        .print-button {
                            display: none !important; /* Hide the print button during print */
                        }
                    }
                    `}
                </style>
                <Button
                    type="primary"
                    onClick={printInvoice}
                    className="mt-6 block mx-auto"
                >
                    Print Invoice
                </Button>
            </div>
        </div>
    );
}
