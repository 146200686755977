import React, { useRef, useState } from 'react';
import { Button, Card } from 'antd';
import invoice1 from '../../../images/invoiceFolder/invoice1.webp';
import invoice2 from '../../../images/invoiceFolder/invoice2.webp';
import invoice3 from '../../../images/invoiceFolder/invoice3.webp';
import { useLocation } from 'react-router-dom';
import Invoice from './Invoice';
import InvoiceY from './InvoiceY';
import InvoiceW from './InvoiceW';

const PrintDashboard = () => {
  const [printFormat, setPrintFormat] = useState("format1");
  const scrollRef = useRef(null); // Ref for scrolling
  const location = useLocation();
  const invoiceData = location.state?.record;

  const data = [
    {
      id: 1,
      image: invoice1,
      title: 'Invoice 1',
      description: 'Invoice 1 description',
      format: "format1",
    },
    // {
    //   id: 2,
    //   image: invoice2,
    //   title: 'Invoice 2',
    //   description: 'Invoice 2 description',
    //   format: "format2",
    // },
    // {
    //   id: 3,
    //   image: invoice3,
    //   title: 'Invoice 3',
    //   description: 'Invoice 3 description',
    //   format: "format3",
    // },
  ];

  // Function to handle scrolling
  const handleScroll = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Update button handler to include scroll functionality
  const handlePrintPreview = (format) => {
    setPrintFormat(format);
    handleScroll(); // Trigger scroll after setting format
  };

  return (
    <div>
      <h1 className="text-xl font-semibold">Print Dashboard</h1>
      <div className='w-full h-[62vh] flex justify-between gap-4 border border-gray-300 rounded-md p-4'>
        {data.map((item) => (
          <Card key={item.id} className='w-[30%] h-[95%] p-0 m-0'>
            <img className='w-[90%] h-[45vh] m-0 p-0' src={item.image} alt={item.title} />
            <Button onClick={() => handlePrintPreview(item.format)}>Print Preview</Button>
          </Card>
        ))}
      </div>

      {/* Preview Section - Attach scrollRef here */}
      <div ref={scrollRef} className='w-full flex justify-center border border-gray-300 rounded-md p-4'>
        {printFormat === "format1" && <Invoice invoiceData={invoiceData} />}
        {/* {printFormat === "format2" && <InvoiceY invoiceData={invoiceData} />}
        {printFormat === "format3" && <InvoiceW invoiceData={invoiceData} />} */}
      </div>
    </div>
  );
};

export default PrintDashboard;
