import React from 'react';
import { Layout, Row, Col, Divider, Typography, Table, Button } from 'antd'; // Import Ant Design components
import logo from '../../../images/logo.png'; // Import logo image
import { useLocation } from 'react-router-dom'; // Import useLocation hook

const { Header, Content } = Layout;
const { Title } = Typography;

// Define columns for the invoice table
const columns = [
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'PuchaseOrder Challan Number', dataIndex: 'puchase_order_no', key: 'puchase_order_no' },

  { title: 'Party Name', dataIndex: 'billed_to', key: 'billed_to' },
  { title: 'Amount', dataIndex: 'total_amount', key: 'total_amount' },
];

// Function to handle print functionality
const printInvoice = () => {
  window.print();
};

export default function PurchaseOrderListPrint() {
  const location = useLocation();
  const purchaseOrderList = location.state?.puchaseorderDatas; // Corrected property name

  // // Check if data exists
  // if (!purchaseOrderList) {
  //   return <p>No data available</p>;
  // }

  return (
    <Layout className="p-6 bg-gray-100 min-h-screen">
      <Header className="bg-blue-500 py-1 text-center h-10 shadow-md">
        <Title level={2} className="text-blue-100">Invoice</Title>
      </Header>
      <Content className="p-8 bg-white shadow-lg rounded-lg">
        <div className="invoice-content">
          <Row>
            <Col span={4}>
              <img src={logo} style={{ height: '20vh' }} alt="Company Logo" />
            </Col>
            <Col span={18}>
              <div style={{ textAlign: 'center' }}>
                <h1 style={{ fontSize: 'xx-large', fontWeight: 'bold' }}>ALPHA AUTOMATION PVT. LTD.</h1>
                <p style={{ fontSize: 'large' }}>104, The Grand Apurva Nr Hotel Fortune Palace</p>
                <p>Mobile Number: 9807765678</p>
                <p>Email: user1234@gmail.com</p>
                <p style={{ fontSize: 'large', fontWeight: 'bold' }}>GST Number: 24AMUG34IJDYEH45</p>
              </div>
            </Col>
          </Row>
          <Divider />
          <Table
            columns={columns}
            dataSource={purchaseOrderList?.purchase_orders || []} // Corrected property name
            pagination={false}
            className="rounded-lg border border-gray-300 mb-6"
            size="small"
            rowClassName="hover:bg-gray-50"
          />
        </div>
        <style>
          {`
            @media print {
              body * {
                visibility: hidden;
              }
              .invoice-content, .invoice-content * {
                visibility: visible;
              }
              .invoice-content {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
              }
            }
          `}
        </style>
        <Button
          type="primary"
          onClick={printInvoice}
          className="mt-6 block mx-auto"
        >
          Print Invoice
        </Button>
      </Content>
    </Layout>
  );
}
