// Actions Types for clents

export const SAVE_PURCHASE_REQUEST = 'SAVE_PURCHASE_REQUEST';
export const SAVE_PURCHASE_SUCCESS = 'SAVE_PURCHASE_SUCCESS';
export const SAVE_PURCHASE_FAILURE = 'SAVE_PURCHASE_FAILURE';

export const GET_PURCHASES_REQUEST = 'GET_PURCHASES_REQUEST';
export const GET_PURCHASES_SUCCESS = 'GET_PURCHASES_SUCCESS';
export const GET_PURCHASES_FAILURE = 'GET_PURCHASES_FAILURE';


export const SAVE_PAYMENT_OUT_REQUEST = 'SAVE_PAYMENT_OUT_REQUEST';
export const SAVE_PAYMENT_OUT_SUCCESS = 'SAVE_PAYMENT_OUT_SUCCESS';
export const SAVE_PAYMENT_OUT_FAILURE = 'SAVE_PAYMENT_OUT_FAILURE';

export const GET_PAYMENT_OUTS_REQUEST = 'GET_PAYMENT_OUT_REQUEST';
export const GET_PAYMENT_OUTS_SUCCESS = 'GET_PAYMENT_OUT_SUCCESS';
export const GET_PAYMENT_OUTS_FAILURE = 'GET_PAYMENT_OUT_FAILURE';

export const DELETE_PAYMENT_OUTS_REQUEST = 'DELETE_PAYMENT_OUT_REQUEST';
export const DELETE_PAYMENT_OUTS_SUCCESS = 'DELETE_PAYMENT_OUT_SUCCESS';
export const DELETE_PAYMENT_OUTS_FAILURE = 'DELETE_PAYMENT_OUT_FAILURE';

export const GET_PENDING_PURCHASE_REQUEST = 'GET_PENDING_PURCHASE_REQUEST';
export const GET_PENDING_PURCHASE_SUCCESS = 'GET_PENDING_PURCHASE_SUCCESS';
export const GET_PENDING_PURCHASE_FAILURE = 'GET_PENDING_PURCHASE_FAILURE';
