import React, { useEffect, useState } from 'react'
import PaymentList from './PaymentList'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPaymentReports } from './actions';
import AppListHeader from '../../header/AppListHeader';
import * as XLSX from 'xlsx';
import { getParties } from '../../party/actions';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';
import { DatePicker, Select } from 'antd';

const { RangePicker } = DatePicker;

export default function PaymentReport() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const paymentReportResponse = useSelector(state => state.paymentReports.getPaymentReports.response);
    const partyResponse = useSelector(state => state.parties.getParties.response)

    const [filterQuery, setFilterQuery] = useState(null)                                                    // state for filter query

    useEffect(() => {
        /**
        * handle payment state
        */
        if (
            paymentReportResponse?.payment_count == paymentReportResponse?.paymentReports?.length &&
            paymentReportResponse?.paymentReports?.length > 0
        ) {

        }
        else {
            // getPayments()
        }
        /**
         * end of handling payment state
         */

        /**
         * handle party state
         */
        if (
            partyResponse?.party_count == partyResponse?.parties?.length &&
            partyResponse?.parties?.length > 0
        ) {

        }
        else {
            dispatch(getParties())
        }
        /**
         * end of handling party state
         */
    }, [dispatch, filterQuery])

    const getPayments = async () => {
        const response = await getPaymentReports(filterQuery)
    }

    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order == 'ascend' ?
                `${sorter?.field}`
                :
                sorter?.order == 'descend' ?
                    `-${sorter?.field}`
                    :
                    null,
        })
    }

    /**
     * EXPORT TO EXCEL
     */
    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Assuming quotationDatas is the list you want to export
        const wsData = paymentReportResponse?.paymentReports?.map((e) => [
            e.date,
            e.billed_to_company_name,
            e.payment_mode,
            e.amount
        ]) || [];

        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'Date',
                'Party Name',
                'Payment Mode',
                'Amount'
            ],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'PaymentReport');
        XLSX.writeFile(workbook, 'PaymentReport.xlsx');
    };


    /**
     * Changes the filteration of data
     */
    const handlePartyChange = (party) => {
        setFilterQuery({
            ...filterQuery,
            party: party
        })
    }

    const handleVoucherTypeChange = (type) => {
        setFilterQuery({
            ...filterQuery,
            voucher_type: type
        })
    }

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            setFilterQuery({
                ...filterQuery,
                date: dateStrings[0],
                date_lte: dateStrings[1]
            })
        } else {
            console.log('Clear');
        }
    };
    /**
     * end of changes the filteration of the data
     */
    const columns = [
        { title: 'Date', dataIndex: 'date' },
        { title: 'Party Name', dataIndex: 'party_company_name' },
        { title: 'Payment Mode', dataIndex: 'payment_mode' },
        { title: 'Amount', dataIndex: 'amount' },
    ];


    return (
        <div>

            <AppListHeader
                headerContent='Payment Report'
                excel={true}
                handleExcelExport={handleExportToExcel}
                print={true}
                download={false}
                cancel={true}
                printNavigation='/ListPrint'
                printData={{tableColunm:columns,tableData:paymentReportResponse?.paymentReports,name:' Payment List'}}
            />

            <div className="flex flex-wrap mb-4 shadow-md p-1">
                <Select
                    placeholder="Select Party"
                    className='w-[250px] m-1'
                    onChange={handlePartyChange}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                >
                    {partyResponse?.parties?.results?.map((data, id) => (
                        <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
                    ))}
                </Select>

                <Select
                    placeholder="Select Voucer Type"
                    className='w-[250px] m-1'
                    onChange={handleVoucherTypeChange}
                    allowClear
                >
                    <Select.Option value='payment in'>Payment In</Select.Option>
                    <Select.Option value='payment out'>Payment Out</Select.Option>
                </Select>
                <RangePicker className='w-[250px] m-1' presets={rangePresets} onChange={onRangeChange} />
            </div>

            <PaymentList
                paymentInDatas={paymentReportResponse?.paymentReports}
                handleTableChange={handleTableChange}
            />

        </div>
    )
}
