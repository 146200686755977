import React from 'react'
import EmailVarification from './EmailVarification'
import Registration from '../../client/Registration'
import Login from './Login'
import yashvitech from '../../../images/yashvitech.png'
import { FileProtectOutlined } from '@ant-design/icons';
// import authImage from '../../../images/auth-img.jpeg'
import authImage from '../../../images/auth-img1.jpeg'
import ResetPasswordLink from './ResetPasswordLink'
import ResetPassword from './ResetPassword'

export default function Auth({ name }) {
    return (
        <div className='h-[90vh] w-[100%] flex'>
            <div
                className='
                    bg-[white] rounded-lg m-auto w-fit
                    shadow-2xl sm:w-[70vw] w-[90vw]
                '
            >
                <div
                    className='
                    flex justify-around p-4 sm:min-h-[50vh] min-h-[40vh]
                '
                >
                    <div className='w-[45%] sm:block hidden'>
                        <img
                            className='w-[100%] h-[45vh]'
                            // src="https://th.bing.com/th/id/OIP.CvtOeGwWp7HZRuzoxEcXsgHaGu?w=500&h=454&rs=1&pid=ImgDetMain"
                            src={authImage}
                            alt='image'
                        />
                    </div>
                    <div className='sm:w-[45%] w-[100%] flex shadow-inner rounded-lg p-2'>
                        {name === "register" ?
                            <Registration />
                            :
                            name === "login" ?
                                <Login />
                                :
                                name === "reset password link" ?
                                    <ResetPasswordLink />
                                    :
                                    name === "reset password" ?
                                        <ResetPassword />
                                        :
                                        <EmailVarification />
                        }
                    </div>
                </div>
                <div
                    className='
                        flex justify-between px-5 w-[100%] bg-[#d9d9d9] rounded-b-lg'
                >
                    <div
                        className='text-[14px] my-auto'
                    >
                        <FileProtectOutlined className='text-[green] text-[18px]' /> 100 % Secure
                    </div>
                    <div className='text-[14px] flex items-center'>
    <a href="https://www.yashvitech.com" target="_blank" rel="noopener noreferrer">
        <img
            src={yashvitech}
            alt="yashvitech"
            className='w-[100px] h-[70px]'
        />
    </a>
    {/* <div className='text-[14px]'>
        Yashvitech Powered
    </div> */}
</div>

                        {/* <div
                        className='text-[14px]'
                        >
                        Yashvitech Powered
                        </div> */}
                  
                </div>
            </div>
        </div>
    )
}
