import React, { useEffect, useState } from 'react';
import { Card, Button, Select, Input, Table } from 'antd';
import { CheckOutlined, WarningOutlined, ShoppingCartOutlined, UnorderedListOutlined, DisconnectOutlined, DownloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSales } from './actions';
import SaleList from './SaleList';
import * as XLSX from 'xlsx';

const { Option } = Select;
const { Search } = Input;

export default function SalesDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = useSelector(state => state.sale.getSales.loading);
    const salesData = useSelector(state => state.sale.getSales.response);
    const saleDeleteResponse = useSelector(state => state.sale.deleteSale.response);

    const [saleSearchText, setSaleSearchText] = useState('');
    const [filterQuery, setFilterQuery] = useState({ payment_status: 'unpaid_or_partial_paid' });
    const [salesDetail, setSalesDetail] = useState({
        totalSales: 0,
        totalPaidAmount: 0,
        totalUnPaidAmount: 0,
    });

    useEffect(() => {
        getSalesData();
    }, [dispatch, saleSearchText, filterQuery, saleDeleteResponse]);

    const getSalesData = async () => {
        const response = await dispatch(getSales(saleSearchText ? { search: saleSearchText } : filterQuery));
        if (response) {
            setSalesDetail({
                totalSales: response.total_sales,
                totalPaidAmount: response.total_paid_amount,
                totalUnPaidAmount: response.total_unpaid_amount,
            });
        }
    };

    const onCreateSalesInvoice = () => {
        navigate('/sales-form');
    };

    const handlePrint = () => {
        const dt = salesData?.sales?.results;
        navigate('/sale-invoice-bill/print', { state: { salesData: dt } });
    };

    const handleSaleSearch = (value) => {
        setSaleSearchText(value);
        setFilterQuery({ ...filterQuery, search: value });
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order === 'ascend' ? `${sorter?.field}` : sorter?.order === 'descend' ? `-${sorter?.field}` : null,
        });
    };

    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const wsData = salesData?.sales?.results.map((e, index) => [
            index + 1,
            e.date,
            e.sale_inv_no,
            e.billed_to,
            e.total_amount,
            e.balance_amount,
            e.payment_status,
        ]) || [];

        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'Sr. No',
                'Date',
                'Sale Invoice Number',
                'Billed To',
                'Total Amount',
                'Balance Amount',
                'Payment Status',
            ],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales');
        XLSX.writeFile(workbook, 'sales.xlsx');
    };

    return (
        <div className="">
        <div className="flex justify-between items-center mb-4">
            <h1 className="text-xl font-semibold">Sales Invoices</h1>
            <div className="flex items-center space-x-2">
                <Button className="bg-primary text-primary-foreground" onClick={() => navigate('/reports/sales-report')}>Reports</Button>
                <Button className="bg-secondary text-secondary-foreground">Settings</Button>
            </div>
        </div>


            <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
                    <Card className="border border-blue-500 bg-blue-100">
                        <Card.Meta
                            title={<span className="text-muted-foreground"><UnorderedListOutlined className="mr-2" />Total Sales</span>}
                            description={<p className="text-2xl font-bold text-primary">₹ {salesDetail?.totalSales}</p>}
                        />
                    </Card>
                    <Card className="border border-green-500 bg-green-100">
                        <Card.Meta
                            title={<span className="text-muted-foreground"><CheckOutlined className="mr-2" />Paid</span>}
                            description={<p className="text-2xl font-bold text-primary">₹ {salesDetail?.totalPaidAmount}</p>}
                        />
                    </Card>
                    <Card className="border border-red-500 bg-red-100">
                        <Card.Meta
                            title={<span className="text-muted-foreground"><DisconnectOutlined className="mr-2" />Unpaid</span>}
                            description={<p className="text-2xl font-bold text-primary">₹ {salesDetail?.totalUnPaidAmount}</p>}
                        />
                    </Card>
                </div>

                <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                    <Search
                        placeholder="Search here"
                        allowClear
                        onSearch={handleSaleSearch}
                        className="w-full md:w-1/3"
                    />
                    <div className="flex space-x-2 mt-4 md:mt-0">
                        <Button className="bg-[#a854f9] text-white" onClick={handlePrint}>
                            <b>Print</b>
                        </Button>
                        <Button onClick={handleExportToExcel} className='bg-white-100 text-blue-500 border-blue-500'>
                            <b>Excel</b>
                            <DownloadOutlined />
                        </Button>
                        <Button className="bg-violet-600 text-white" onClick={onCreateSalesInvoice}>
                            <b>Create Sale Invoice</b>
                        </Button>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <SaleList
                        sale={salesData?.sales?.results}
                        handleTableChange={handleTableChange}
                    />
                </div>
            </div>
        </div>
    );
}
