// actions.js
import axios from 'axios';
import {
  SAVE_PARTY_REQUEST,
  SAVE_PARTY_SUCCESS,
  SAVE_PARTY_FAILURE,

  GET_PARTIES_REQUEST,
  GET_PARTIES_SUCCESS,
  GET_PARTIES_FAILURE,

  UPDATE_PARTY_REQUEST,
  UPDATE_PARTY_SUCCESS,
  UPDATE_PARTY_FAILURE

} from './actionTypes';
import { getPartiesAPI, savePartyAPI, updatePartyAPI } from '../../api/Services';
import { message } from 'antd';


export const savePartyRequest = () => ({
  type: SAVE_PARTY_REQUEST
});

export const savePartySuccess = (response) => ({
  type: SAVE_PARTY_SUCCESS,
  payload: response
});

export const savePartyFailure = error => ({
  type: SAVE_PARTY_FAILURE,
  payload: error
});


/**
 * Get all parties
 */
export const getPartiesRequest = () => ({
  type: GET_PARTIES_REQUEST
});

export const getPartiesSuccess = (response) => ({
  type: GET_PARTIES_SUCCESS,
  payload: response
});

export const getPartiesFailure = error => ({
  type: GET_PARTIES_FAILURE,
  payload: error
});


/**
 * UPDATE THE PARTY
 */
export const updatePartyRequest = () => ({
  type: UPDATE_PARTY_REQUEST
});

export const updatePartySuccess = (response) => ({
  type: UPDATE_PARTY_SUCCESS,
  payload: response
});

export const updatePartyFailure = error => ({
  type: UPDATE_PARTY_FAILURE,
  payload: error
});


/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To save the party detail
 */
export const saveParty = (data) => async (dispatch) => {
  dispatch(savePartyRequest());
  try {
    const response = await savePartyAPI(data)

    if (response?.success) {
      dispatch(savePartySuccess(response))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(savePartyFailure(response.error))
    }
    return response;

  } catch (error) {
    dispatch(savePartyFailure(error.message));
    throw error;
  }
};



/**
 * To get the all parties
 */
export const getParties = (data) => async (dispatch) => {
  dispatch(getPartiesRequest());
  try {
    const response = await getPartiesAPI(data)

    if (response) {
      dispatch(getPartiesSuccess(response))
    }
    return response;

  } catch (error) {
    dispatch(getPartiesFailure(error.message));
    throw error;
  }
};



/**
 * To update the party
 */
export const updateParty = (partyId, data) => async (dispatch) => {
  dispatch(updatePartyRequest());
  try {
    const response = await updatePartyAPI(partyId, data)
    if (response?.success) {
      dispatch(updatePartySuccess(response))
    }
    return response;

  } catch (error) {
    dispatch(updatePartyFailure(error.message));
    throw error;
  }
};