// Actions Types for purchase order

export const SAVE_PURCHASE_ORDER_REQUEST = 'SAVE_PURCHASE_ORDER_REQUEST';
export const SAVE_PURCHASE_ORDER_SUCCESS = 'SAVE_PURCHASE_ORDER_SUCCESS';
export const SAVE_PURCHASE_ORDER_FAILURE = 'SAVE_PURCHASE_ORDER_FAILURE';

export const GET_PURCHASE_ORDERS_REQUEST = 'GET_PURCHASE_ORDERS_REQUEST';
export const GET_PURCHASE_ORDERS_SUCCESS = 'GET_PURCHASE_ORDERS_SUCCESS';
export const GET_PURCHASE_ORDERS_FAILURE = 'GET_PURCHASE_ORDERS_FAILURE';

export const DELETE_PURCHASE_ORDERS_REQUEST = 'DELETE_PURCHASE_ORDERS_REQUEST';
export const DELETE_PURCHASE_ORDERS_SUCCESS = 'DELETE_PURCHASE_ORDERS_SUCCESS';
export const DELETE_PURCHASE_ORDERS_FAILURE = 'DELETE_PURCHASE_ORDERS_FAILURE';
