import React, { useEffect } from 'react';
import DynamicTable from '../../tables/DynamicTable';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenseCategories } from './actions';

export default function ExpenseCategoryList({ Newcategory }) {
  const dispatch = useDispatch();
  
  // Get expense categories response from Redux state
  const responseExpensesCategory = useSelector(state => state.expenseNames.getExpenseNames.response);
  
  useEffect(() => {
    // Dispatch the action to fetch expense categories
    dispatch(getExpenseCategories());
  }, [dispatch]);

  // Define table columns
  const columns = [
    { Header: 'Category Name', accessor: 'category_name' },
    // { Header: 'Created Date', accessor: 'created_at' },
    // { Header: 'Updated Date', accessor: 'updated_at' },
  ];

  // Transform columns for the DynamicTable component
  const tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
    // sorter: ['category_name', 'created_at'],  // Add sorters as needed
  }));

  // Ensure data is an array, fallback to empty array if undefined/null
  // const data = Array.isArray(responseExpensesCategory) ? responseExpensesCategory : [];

  return (
    <div>
      <DynamicTable
        columns={columns}       
        data={responseExpensesCategory?.expense_categories}            
        tableColumns={tableColumns} // Transformed columns
      />
    </div>
  );
}
