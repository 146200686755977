import React from 'react';
import { Card, Button, Divider } from 'antd';
import 'animate.css'; // Import Animate.css
import { CheckOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './Pricing.css'

const Pricing = () => {
    const navigate = useNavigate();

    const upcomingPlans = [
        {
            title: 'Premium Plan',
            releaseDate: 'Coming Soon',
            features: ['Feature 6', 'Feature 7', 'Feature 8', 'Feature 9'],
        },
    ];

    return (
        <div
    
            id='feature'
            className="flex w-full flex-col items-center justify-center min-h-screen font-Caladea mt-5 px-4 md:px-0" // Add responsive padding
        >
            <h1 className="text-3xl font-bold" data-aos="fade-up">Pricing</h1>
            <div className='border-solid border-2 border-red-500 w-[20%] m-auto my-5' />
        <div className="flex flex-col md:flex-row justify-center items-center w-full p-4">
            {/* First Section */}
            <div className="w-full md:w-1/2  ">
                <h1 className="text-center text-4xl font-bold my-10">Choose Your Plan</h1>

                <Card
                    className="text-center p-4 rounded-lg bg-transparent shadow-md border-orange-500 shadow-purple-500 m-2 md:w-[70%] w-[100%]  mx-auto" 
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                >
                    <h1 className="text-2xl font-semibold mb-5 text-orange-600">Enterprise Plan</h1>
                    <strong>Add features with customizable plans</strong>
                    <h2 className="text-3xl font-bold mb-5"><strong>₹208/month</strong></h2>
                    <p>Pay ₹2499/year. Billed annually</p>

                    <Button className="bg-orange-500 hover:bg-orange-700 text-white font-bold" onClick={() => navigate('/email-verify')}>
                        Start Free Trial
                    </Button>
                    <br />
                    <Button className="w-[70%] h-[40px] mt-5 bg-purple-600 text-white hover:bg-purple-700 rounded-lg animate__animated animate__zoomIn font-bold" onClick={() => navigate('/email-verify')}>
                        Get Started
                    </Button>
                    <Divider />
                    <div className="text-left text-black">
                        {[
                            "Generate e-Invoicing",
                            "Automate Recurring Billing",
                            "Bulk Download & Bulk Print Invoices",
                            "Personalized Invoice Designs",
                            "Access via Web Browser",
                            "Create, print (for A4) & scan barcodes",
                            "Monitor User Activities"
                        ].map((feature, idx) => (
                            <p key={idx} className="text-base my-2">
                                <strong><CheckOutlined className='text-red-600' /> {feature}</strong>
                            </p>
                        ))}
                    </div>
                </Card>
            </div>

            {/* Second Section */}
            <div className="w-full md:w-1/2 h-[100%] ">
                <h2 className="text-center text-3xl font-bold my-10 text-purple-600">Upcoming Plans</h2>

                {upcomingPlans.map((plan, index) => (
                    <Card
                    key={index}
                    className="text-center p-4 rounded-lg bg-transparent shadow-md animated-dotted-border m-2 md:w-[70%] w-[100%] h-[83%] mx-auto"
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                >
                    <h3 className="text-2xl font-semibold mb-5 ">{plan.title}</h3>
                    <h2 className="text-xl text-gray-600 mb-5">
                        <strong className="text-red-600 animate__animated animate__flash">{plan.releaseDate}</strong>
                    </h2>
                
                    <Button className="w-[70%] h-[40px] mt-5 bg-purple-600 text-white hover:bg-purple-700 rounded-lg animate__animated animate__zoomIn font-bold">
                        Notify Me
                    </Button>
                    <Divider />
                    <div className='text-left'>
                        {[
                            "Payment Gateway",
                            "Different Payment Methods",
                            "Payment Reminder",
                            "Customizable Themes",
                            "Print Barcode (Label printer)",
                            "Low Stock Alert",
                            "WhatsApp Integration",
                            "Chatbot"
                        ].map((feature, idx) => (
                            <p key={idx} className="text-base my-2">
                                <strong><SafetyCertificateOutlined className='text-red-600' /> {feature}</strong>
                            </p>
                        ))}
                    </div>
                </Card>
                
                ))}
            </div>
        </div>
        </div>
    );
};

export default Pricing;
