import React, { useEffect } from 'react'
import CategoryForm from './CategoryForm'
import CategoryList from './CategoryList'
import { useState } from 'react'
import { Button, Card } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories } from './actions';

function CategoryContained() {

    const [Newcategory, setNewCategory] = useState(false)

    const handleCategoryOpen = () => {
        setNewCategory(true)
    }

    return (

        <div className='flex flex-col'>
            <div className=''>
                {Newcategory ?
                    <CategoryForm setNewCategory={setNewCategory} />
                    :
                    <Button
                        className='bg-[#a854f9] float-right mr-5 my-2 text-white'
                        onClick={handleCategoryOpen}>
                        <b>Create Category</b>
                    </Button>
                }
            </div>
            {/* <br />  <br /> */}
            <CategoryList Newcategory={Newcategory} />

        </div>

    )
}

export default CategoryContained