// actions.js
import { getDayBooksAPI } from '../../../api/Services';
import {
  GET_DAYBOOK_REQUEST,
  GET_DAYBOOK_SUCCESS,
  GET_DAYBOOK_FAILURE
} from './actionTypes';

/**
 * Get all daybook data
 */
export const getDayBooksRequest = () => ({
  type: GET_DAYBOOK_REQUEST
});

export const getDayBooksSuccess = (response) => ({
  type: GET_DAYBOOK_SUCCESS,
  payload: response
});

export const getDayBooksFailure = error => ({
  type: GET_DAYBOOK_FAILURE,
  payload: error
});


/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To get the all daybook data
 */
export const getDayBook = (data) => async (dispatch) => {
  dispatch(getDayBooksRequest());
  try {
    const response = await getDayBooksAPI(data)

    if (response) {
      dispatch(getDayBooksSuccess(response))
    }
    return response;

  } catch (error) {
    dispatch(getDayBooksFailure(error.message));
    throw error;
  }
};