// Actions Types for clents

export const SAVE_PURCHASE_REQUEST = 'SAVE_PURCHASE_REQUEST';
export const SAVE_PURCHASE_SUCCESS = 'SAVE_PURCHASE_SUCCESS';
export const SAVE_PURCHASE_FAILURE = 'SAVE_PURCHASE_FAILURE';

export const GET_PURCHASES_REQUEST = 'GET_PURCHASES_REQUEST';
export const GET_PURCHASES_SUCCESS = 'GET_PURCHASES_SUCCESS';
export const GET_PURCHASES_FAILURE = 'GET_PURCHASES_FAILURE';

export const DELETE_PURCHASE_FAILURE = 'DELETE_PURCHASE_FAILURE';
export const DELETE_PURCHASE_SUCCESS = 'DELETE_PURCHASE_SUCCESS';
export const DELETE_PURCHASE_REQUEST = 'DELETE_PURCHASE_REQUEST';