// reducer.js
import {
    SAVE_PURCHASE_ORDER_REQUEST,
    SAVE_PURCHASE_ORDER_SUCCESS,
    SAVE_PURCHASE_ORDER_FAILURE,

    GET_PURCHASE_ORDERS_REQUEST,
    GET_PURCHASE_ORDERS_SUCCESS,
    GET_PURCHASE_ORDERS_FAILURE,

    DELETE_PURCHASE_ORDERS_REQUEST,
    DELETE_PURCHASE_ORDERS_SUCCESS,
    DELETE_PURCHASE_ORDERS_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,
    
    savePurchaseOrder: {
        loading: false,
        error: null,
        response: null,
    },

    getPurchaseOrders: {
        loading: false,
        error: null,
        response: null,
    },

    deletePurchaseOrder: {
        loading: false,
        error: null,
        response: null,
    }
};

const purchaseOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PURCHASE_ORDER_REQUEST:
            return {
                ...state,
                savePurchaseOrder: {
                    ...state.savePurchaseOrder,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                savePurchaseOrder: {
                    ...state.savePurchaseOrder,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case SAVE_PURCHASE_ORDER_FAILURE:
            return {
                ...state,
                savePurchaseOrder: {
                    ...state.savePurchaseOrder,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        case GET_PURCHASE_ORDERS_REQUEST:
            return {
                ...state,
                getPurchaseOrders: {
                    ...state.getPurchaseOrders,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_PURCHASE_ORDERS_SUCCESS:
            return {
                ...state,
                getPurchaseOrders: {
                    ...state.getPurchaseOrders,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_PURCHASE_ORDERS_FAILURE:
            return {
                ...state,
                getPurchaseOrders: {
                    ...state.getPurchaseOrders,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        // Handle delete actions
        case DELETE_PURCHASE_ORDERS_REQUEST:
            return {
                ...state,
                deletePurchaseOrder: {
                    ...state.deletePurchaseOrder,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case DELETE_PURCHASE_ORDERS_SUCCESS:
            return {
                ...state,
                deletePurchaseOrder: {
                    ...state.deletePurchaseOrder,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case DELETE_PURCHASE_ORDERS_FAILURE:
            return {
                ...state,
                deletePurchaseOrder: {
                    ...state.deletePurchaseOrder,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default purchaseOrderReducer;
