import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, InputNumber, Row, Col, Card, Divider, Select, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getParties } from '../../party/actions';
import { getItems } from '../../product/item/actions';
import { saveQuotation } from './actions';
import { Link, useNavigate } from 'react-router-dom';


export default function QuotationForm() {

  const dispatch = useDispatch()

  const partyResponse = useSelector(state => state.parties.getParties.response)
  const loading = useSelector(state => state.parties.getParties.loading)
  const itemResponse = useSelector(state => state.items.getItems.response)

  const navigate = useNavigate()

  useEffect(() => {
    /**
     * handle party state
     */
    if (
      partyResponse?.party_count == partyResponse?.parties?.results?.length &&
      partyResponse?.parties?.results?.length > 0
    ) {
    }
    else {
      dispatch(getParties())
    }
    /**
     * end of handling party state
     */

    /**
     * handle item state
     */
    if (
      itemResponse?.items_count == itemResponse?.items?.results?.length &&
      itemResponse?.items?.results?.length > 0
    ) {
    }
    else {
      dispatch(getItems())
    }
    /**
     * end of handling item state
     */
  }, [dispatch])

  const datas = [
    {
      id: 1,
      item_name: "jeans",
      price: 500,
      gst: 12,
      hsn: "assaa"
    },
    {
      id: 2,
      item_name: "shirt",
      price: 1000,
      gst: 18,
      hsn: "lkkl"
    },
    {
      id: 3,
      item_name: "frok",
      price: 1500,
      gst: 5,
      hsn: "wqwqqw"
    },
    {
      id: 4,
      item_name: "Cap",
      price: 200,
      gst: 20,
      hsn: "popop"
    },
    {
      id: 5,
      item_name: "nepkin",
      price: 150,
      gst: 18,
      hsn: "trt"
    },
  ];

  const [form] = Form.useForm();
  const [items, setItems] = useState([{ amount: 0 }]);
  const [freightAmount, setFreightAmount] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const today = new Date().toISOString().split('T')[0];

  const onFinish = async (values) => {
    const formattedValues = {
      date: values.date,
      quotation_no: values.quotationNo,
      purchase_order_no: values.orderNo,
      billed_to: values.billed_to,
      sub_total: parseFloat(values.subtotal),
      gst_amount: parseFloat(values.gst),
      shipping_charge: parseFloat(freightAmount),
      total_amount: parseFloat(values.amount),
      description: values.description,
      quotation_items: values.items.map(item => ({
        item: item.id,
        qty: parseFloat(item.qty),
        rate: parseFloat(item.price),
        discount: parseFloat(item.discount),
        total: parseFloat(item.amount),
        gst_amount: parseFloat(item.gst),
        amount: parseFloat(item.amount),
      }))
    };

    try {
      const response = await dispatch(saveQuotation(formattedValues));
      if (response.success) {
        navigate(-1);
      } else {
        message.error(response.message || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      message.error(error.message || 'Something went wrong. Please try again.');
    }
  };

  const onFinishFailed = () => {
    console.log("failed");
  }
  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    if (!newItems[index]) {
      newItems[index] = {}; // Initialize if it doesn't exist
    }

    newItems[index][field] = value;


    if (field === "item") {
      const itemOject = itemResponse?.items?.results
      console.log(itemOject)
      const itemObj = itemOject.find(dt => dt?.id === value);
      console.log(itemObj, 'id')
  const current_qty1 = Math.ceil( itemObj.current_qty); 

      if (itemObj) {
        newItems[index].price = itemObj.price;
        newItems[index].gst = itemObj.gst_percentage;
        newItems[index].qty =current_qty1;

        newItems[index].id = itemObj.id;
        newItems[index].hsn = itemObj.hsn_code;
        console.log(newItems[index].hsn, 'hsn')
      }
    }

    newItems[index].amount = calculateAmount(newItems[index]);
    setItems(newItems);
    form.setFieldsValue({ items: newItems });
    updateTotalValues(newItems, freightAmount);
  };

  const calculateAmount = (item) => {
    const price = parseFloat(item.price || 0);
    const qty = parseFloat(item.qty || 0);
    const discount = parseFloat(item.discount || 0);
    const gst = parseFloat(item.gst || 0);
    const amount = (price * qty);
    const gstAmount = (amount * gst) / 100;
    return Math.round((amount + gstAmount) - discount.toFixed(2));
  };

  const updateTotalValues = (updatedItems, freight) => {
    let subtotal = updatedItems.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
    const gstTotal = updatedItems.reduce((sum, item) => sum + ((parseFloat(item.price || 0) * parseFloat(item.qty || 0) * (parseFloat(item.gst || 0) / 100))), 0);
    subtotal = subtotal - gstTotal;
    const totalAmount = subtotal + gstTotal + parseFloat(freight || 0);
    form.setFieldsValue({
      subtotal: subtotal.toFixed(2),
      gst: gstTotal.toFixed(2),
      amount: totalAmount.toFixed(2),
    });

    updateBalanceAmount(totalAmount, amountPaid);
  };

  const updateBalanceAmount = (totalAmount, amountPaid) => {
    const balance = totalAmount - amountPaid;
    setBalanceAmount(balance.toFixed(2));
    form.setFieldsValue({
      balance_amount: balance.toFixed(2),
    });
  };

  const validateNumber = (maxLength) => (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const regex = new RegExp(`^\\d{1,${maxLength}}$`);
    if (!regex.test(value)) {
      return Promise.reject(`Please enter a valid number with up to ${maxLength} digits.`);
    }
    return Promise.resolve();
  };

  useEffect(() => {
    updateTotalValues(items, freightAmount);
  }, [items, freightAmount]);

  useEffect(() => {
    form.setFieldsValue({ items });
  }, [items, form]);


  useEffect(() => {
    const totalAmount = parseFloat(form.getFieldValue('amount') || 0);
    updateBalanceAmount(totalAmount, amountPaid);
  }, [amountPaid]);


  return (
    <div>
      <Form
        form={form}
        initialValues={{ items: [{ amount: 0, discount: '00' }] }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="flex justify-between sticky top-[65px] mb-4 z-10 bg-white">
          <h2 className="text-2xl font-semibold">Create Quotation Form</h2>
          <div className="flex space-x-2">
            <Button type="primary" htmlType="submit" className="bg-purple-500 border-none shadow-inner">
              <b>Save</b>
            </Button>
            <Button
              htmlType="button"
              className="shadow-inner"
              onClick={() => navigate(-1)}
            >
              <b>Cancel</b>
            </Button>
          </div>
        </div>
        <Card title="Party Name" size="small" className="w-full shadow-custom-light shadow-custom-dark"
          style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }} >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-1 ">

            {/* <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Billed To"
                  name="billed_to"
                  rules={[{ required: true, message: 'Please select billing name!' }]}
                >
                  <Select>
                    {response?.map((data, id) => (
                      <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

            </Row> */}

            <Form.Item
              label="Billed To"
              name="billed_to"
              rules={[{ required: true, message: 'Please select billing name!' }]}
            >
              <Select
                placeholder='Select party'
                showSearch
                optionFilterProp='children'
                dropdownRender={menu => (
                  <>
                    <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
                      <Link
                        className='flex ml-auto text-blue-500'
                        to='/party-form'
                      >
                        New Party
                      </Link>
                    </div>
                    <Divider style={{ margin: '0px 0' }} />
                    {menu}
                  </>
                )}
              >
                {partyResponse?.parties?.results?.map((data, id) => (
                  <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
                ))}
              </Select>
            </Form.Item>


           
              <Form.Item
                label="Quotation Date"
                name="date"
                rules={[{ required: true, message: 'Please select the Purchase Invoice Date!' }]}
              >
                <Input type='date' />
              </Form.Item>
            

            <Form.Item
              label="Purchase Order No"
              name="orderNo"
              rules={[{ required: true, message: 'Please input the Purchase Order Number!' }]}
            >
              <Input maxLength={20} placeholder="Purchase Order No" />
            </Form.Item>
          </div>
        </Card>

        <Form.List name="items">
  {(fields, { add, remove }) => (
    <div>
      <div className="overflow-x-auto">
        <table className="min-w-full mb-6 border-collapse mt-4 shadow-custom-light shadow-custom-dark"
          style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }} >
          <thead>
            <tr>
              <th className="p-2 border border-gray-300">NO</th>
              <th className="p-2 border border-gray-300">ITEMS/ SERVICES</th>
              <th className="p-2 border border-gray-300">HSN/ SAC</th>
              <th className="p-2 border border-gray-300">QTY</th>
              <th className="p-2 border border-gray-300">PRICE/ITEM (₹)</th>
              <th className="p-2 border border-gray-300">DISCOUNT</th>
              <th className="p-2 border border-gray-300">GST</th>
              <th className="p-2 border border-gray-300">AMOUNT (₹)</th>
              <th className="p-2 border border-gray-300">Actions</th>
            </tr>
          </thead>
          <tbody>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
              <tr key={key}>
                <td className="p-2 border border-gray-300">{index + 1}</td>
                <td className="p-2 border border-gray-300">
                  <Form.Item
                    {...restField}
                    name={[name, 'item']}
                    fieldKey={[fieldKey, 'item']}
                    rules={[{ required: true, message: 'Missing item name' }]}
                    className="mb-0 w-[200px]"
                  >
                    <Select
                      placeholder='Select item'
                      onChange={(value) => handleItemChange(index, 'item', value)}
                      showSearch
                      optionFilterProp='children'
                    >
                      {itemResponse?.items?.results?.map((data, id) => (
                        <Select.Option key={id} value={data?.id}>
                          {data?.item_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </td>
                <td className="p-2 border border-gray-300">
                  <Form.Item
                    {...restField}
                    name={[name, 'hsn']}
                    fieldKey={[fieldKey, 'hsn']}
                    rules={[{ required: true, message: 'Missing HSN/SAC' }]}
                    className="mb-0"
                  >
                    <Input readOnly />
                  </Form.Item>
                </td>
                <td className="p-2 border border-gray-300">
                  <Form.Item
                    {...restField}
                    name={[name, 'qty']}
                    fieldKey={[fieldKey, 'qty']}
                    rules={[
                      { required: true, message: 'Missing quantity' },
                      { validator: validateNumber(5) }
                    ]}
                    className="mb-0"
                  >
                    <Input
                      onChange={(e) => handleItemChange(index, 'qty', e.target.value)}
                      defaultValue={0}
                    />
                  </Form.Item>
                </td>
                <td className="p-2 border border-gray-300">
                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    fieldKey={[fieldKey, 'price']}
                    rules={[{ required: true, message: 'Missing price' }]}
                    className="mb-0"
                  >
                    <Input
                      onChange={(e) => {
                        const value = e.target.value;
                        const validValue = value.replace(/[^0-9.]/g, '');
                        if ((validValue.match(/\./g) || []).length <= 1) {
                          handleItemChange(index, 'price', validValue);
                        }
                      }}
                      maxLength={10}
                    />
                  </Form.Item>
                </td>
                <td className="p-2 border border-gray-300">
                  <Form.Item
                    {...restField}
                    name={[name, 'discount']}
                    fieldKey={[fieldKey, 'discount']}
                    rules={[{ required: true, message: 'Missing discount' }]}
                    className="mb-0"
                  >
                    <Input
                      onChange={(e) => {
                        const value = e.target.value;
                        const validValue = value.replace(/[^0-9.]/g, '');
                        if ((validValue.match(/\./g) || []).length <= 1) {
                          handleItemChange(index, 'discount', validValue);
                        }
                      }}
                      maxLength={10}
                    />
                  </Form.Item>
                </td>
                <td className="p-2 border border-gray-300">
                  <Form.Item
                    {...restField}
                    name={[name, 'gst']}
                    fieldKey={[fieldKey, 'gst']}
                    rules={[{ required: true, message: 'Missing GST' }]}
                    className="mb-0"
                  >
                    <InputNumber min={0} readOnly value={items[index]?.amount} />
                  </Form.Item>
                </td>
                <td className="p-2 border border-gray-300">
                  <Form.Item
                    {...restField}
                    name={[name, 'amount']}
                    fieldKey={[fieldKey, 'amount']}
                    rules={[{ required: true, message: 'Missing amount' }]}
                    className="mb-0"
                  >
                    <InputNumber min={0} readOnly value={items[index]?.amount} />
                  </Form.Item>
                </td>
                <td className="p-2 border border-gray-300">
                  <DeleteOutlined
                    onClick={() => {
                      const newItems = [...items];
                      newItems.splice(index, 1);
                      setItems(newItems);
                      updateTotalValues(newItems, freightAmount);
                    }}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="9" className="p-2 border border-gray-300">
                <Button type="dashed"
                  onClick={() => {
                    add();
                    setItems([...items, { amount: 0 }]);
                  }} block>
                  Add Item
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )}
</Form.List>

        <div className="mt-4 flex w-full md:flex-row flex-col-reverse  justify-between">
          <div
            className='md:w-[45%] w-[100%]'
          >
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true, message: 'Enter the description' }
              ]}
            >
              <Input.TextArea
                placeholder="Enter Description"
                className="w-full h-10"
                autoSize={{ minRows: 5, maxRows: 8 }}
              />
            </Form.Item>
          </div>
          <Card
            // title="Amount"
            className="text-left md:w-[45%] w-[100%] shadow-lg p-0 bg-[rgba(0,0,0,0.02)]"
          >
            <Form.Item
              label="Shipping Charge (₹)"
              name="shipping_charge"
              className='m-0 p-0'

              rules={[
                {  message: 'Missing quantity' },
                { validator: validateNumber(5) }
              ]}
            >
              <Input


                type="text"
                onChange={(value) => {
                  setFreightAmount(value.target.value);
                  updateTotalValues(items, value.target.value);
                }}
                maxLength={7}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: 'requered' }]}
              name="subtotal"
              className='m-0 p-0'
            >
              <div className='flex justify-between font-bold'>
                <div>Sub Total</div>
                <div>{form.getFieldValue('subtotal') ? form.getFieldValue('subtotal') : '0.00'}</div>
              </div>
            </Form.Item>

            <Form.Item
              name="gst"
              className='m-0 p-0'
            >
              <div className='flex justify-between'>
                <div>GST Amount</div>
                <div>{form.getFieldValue('gst') ? form.getFieldValue('gst') : '0.00'}</div>
              </div>
              {/* <InputNumber placeholder="GST Amount" className="w-full" readOnly /> */}
            </Form.Item>
            
            <Divider />
            <Form.Item
              name="amount"
              className='m-0 p-0'
            >
              <div className='flex justify-between font-bold'>
                <div>Amount</div>
                <div>{form.getFieldValue('amount') ? form.getFieldValue('amount') : '0.00'}</div>
              </div>
              {/* <InputNumber placeholder="Total Amount" className="w-full" readOnly /> */}
            </Form.Item>
            <Form.Item
              name="amountPaid"
              className='m-0 p-0'
            >
            </Form.Item>
          </Card>
        </div>

        {/* <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item> */}
      </Form>
    </div>
  );
}