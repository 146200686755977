import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { useDispatch, useSelector } from 'react-redux';
import { saveParty, updateParty } from './actions';
import { useLocation, useNavigate } from 'react-router-dom';

const { Option } = Select;

export default function PartyForm() {

  const [initialValues, setInitialValues] = useState({ party_type: 'To Pay', opening_balance: 0 })

  const [form] = Form.useForm()
  const location = useLocation()
  // const { partyDetail } = location.state

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error, response } = useSelector(state => state.parties.saveParty)


  useEffect(() => {
    if (error) {
      const fieldErrors = Object.keys(error).map((field) => ({
        name: field,
        errors: error[field],
      }));
      form.setFields(fieldErrors);
      message.error("There were errors while saving. Please check your inputs."); // Display a generic error message
    }
  }, [error, form]);


  useEffect(() => {
    if (location.state?.partyDetail) {
      setInitialValues(location.state?.partyDetail)
      form.setFieldsValue(location.state?.partyDetail);
    }
  }, [dispatch, location.state, form])

  const onFinish = async (values) => {
    values["country"] = "India";
    if (!location.state?.partyDetail) {
      values["current_balance"] = values['opening_balance'];
      const response = await dispatch(saveParty(values));
      if (response?.success) {
        navigate('/customer-view');
      } else {
        message.error("Failed to save party. Please try again."); // Display error if save fails
      }
    } else {
      const response = await dispatch(updateParty(location.state?.partyDetail?.id, values));
      if (response?.success) {
        navigate('/customer-view');
      } else {
        message.error("Failed to update party. Please try again."); // Display error if update fails
      }
    }
  };

  return (
    <div className="">
      <Form
        layout="vertical"
        name="partyForm"
        initialValues={initialValues}
        onFinish={onFinish}
        form={form}
      >
        <div className="flex justify-between mb-4">
          <h2 className="text-2xl font-semibold">New Party</h2>
          <div className="flex space-x-2">
            <Button type="primary" htmlType="submit" className="bg-purple-500 border-none shadow-inner">
              <b>Save</b>
            </Button>
            <Button
              htmlType="button"
              className="shadow-inner"
              onClick={() => navigate(-1)}
            >
              <b>Cancel</b>
            </Button>
          </div>
        </div>
        <div
          className="flex flex-wrap justify-between"
        >
          <Card
            title="Personal"
            size="small"
            className="w-full md:w-[49%] mb-4"
            style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
              <Form.Item
                name="company_name"
                className="col-span-1"
                label="Company Name"
                rules={[{ required: true, message: 'Please enter company name' }]}
              >
                <Input placeholder="Company Name" className="shadow-inner" />
              </Form.Item>

              <Form.Item
                name="party_full_name"
                className="col-span-1"
                label="Full Name"
                rules={[{ required: true, message: 'Please enter party name' }]}
              >
                <Input placeholder="Full Name" className="shadow-inner" />
              </Form.Item>

              <Form.Item
                name="party_type"
                label="Party Type"
                className="col-span-1"
                rules={[
                  {
                    required: true,
                    message: "Please Select Party Type"
                  }
                ]}
              >
                <Select placeholder="Select Customer Type" className="shadow-inner">
                  <Option value="To Pay">To Pay</Option>
                  <Option value="To Collect">To Collect</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="mobile_no"
                className="col-span-1"
                label="Mobile Number"
                rules={[{ required: true,max:13,  message: 'Please valid enter mobile number' }]}
              >
                <Input type="number" placeholder="Mobile Number" maxLength={13} className="shadow-inner" />
              </Form.Item>

              <Form.Item
                name="email"
                className="col-span-2"
                label="Email"
                rules={[{ required: true, message: 'Please enter email' }]}
              >
                <Input prefix={<MailOutlined />} placeholder="Email" className="shadow-inner" />
              </Form.Item>

            </div>
          </Card>

          <Card
            title="Address"
            size="small"
            className="w-full md:w-[49%] mb-4"
            style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
              <Form.Item
                name="address"
                rules={[{ required: true, message: 'Please enter the address' }]}
                label="Address"
                className="col-span-2"
              >
                <Input placeholder="Enter address" className="shadow-inner" />
              </Form.Item>

              <Form.Item
                name="zip_code"
                rules={[{ required: true,max:6, message: 'Please enter valid the zip code' }]}
                label="Zip Code"
              >
                <Input type="number" placeholder="Enter Zip code"  className="shadow-inner" />
              </Form.Item>

              <Form.Item
                name="state"
                rules={[{ required: true, message: 'Please enter the state' }]}
                label="State"
              >
                <Input placeholder="Enter state" className="shadow-inner" />
              </Form.Item>

              <Form.Item
                name="city"
                rules={[{ required: true, message: 'Please enter the city' }]}
                label="City"
              >
                <Input placeholder="Enter city" className="shadow-inner" />
              </Form.Item>
            </div>
          </Card>

          <Card
            title="Account"
            size="small"
            className="w-full mb-4"
            style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <Form.Item
                name="gst_no"
                className="col-span-1"
                label="GST No"
              >
                <Input placeholder="GST Number"
                 maxLength={15} 
                 className="shadow-inner" />
              </Form.Item>

              <Form.Item
                name="pan_no"
                className="col-span-1"
                label="Pan No"
              >
                <Input placeholder="Pan Number" maxLength={10} className="shadow-inner" />
              </Form.Item>

              <Form.Item
                name="opening_balance"
                className="col-span-1"
                label="Opening Balance"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Opening Balance"
                  }
                ]}
              >
                <Input type="number" placeholder="Opening Number" className="shadow-inner" />
              </Form.Item>
            </div>
          </Card>
        </div>
      </Form>
    </div>
  );
}
