import React, { useEffect, useState } from 'react'
import DymanicTable from '../../tables/DynamicTable'
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from './actions';
import { handleformatDate } from '../../../App';


export default function CategoryList({ Newcategory }) {

  const dispatch = useDispatch()

  const response = useSelector(state => state.categories.getCategories.response)

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  const columns = [
    { Header: 'Category Name', accessor: 'category_name' },
    // { Header: 'Created Date', accessor: 'created_at' },
    // { Header: 'Updated Date', accessor: 'updated_at' },
  ];
  const tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
  }));

  // const categoryList = response?.map(category => ({
  //   category_name: category.category,
  //   created_at: handleformatDate(category.created_at),
  //   updated_at: handleformatDate(category.updated_at)
  // }))

  return (
    <div>
      <DymanicTable columns={columns} data={response} tableColumns={tableColumns} />
    </div>
  )
}