import {
  SAVE_PaymentIn_REQUEST,
  SAVE_PaymentIn_SUCCESS,
  SAVE_PaymentIn_FAILURE,

  GET_PaymentIn_REQUEST,
  GET_PaymentIn_SUCCESS,
  GET_PaymentIn_FAILURE,

  GET_PENDING_SALES_REQUEST,
  GET_PENDING_SALES_SUCCESS,
  GET_PENDING_SALES_FAILURE,

  DELETE_PAYMENTIN_FAILURE,
  DELETE_PAYMENTIN_SUCCESS,
  DELETE_PAYMENTIN_REQUEST
} from './actionTypes';


import { message } from 'antd';
import { getPendingSalesApi, savePaymentInAPI, getPaymentInAPI, deletePaymentInAPI } from '../../../api/Services';

// Action creators for saving payment in
export const savePaymentInRequest = () => ({ type: SAVE_PaymentIn_REQUEST });

export const savePaymentInSuccess = (response) => ({
  type: SAVE_PaymentIn_SUCCESS,
  payload: response
});

export const savePaymentInFailure = error => ({
  type: SAVE_PaymentIn_FAILURE,
  payload: error
});

// Action creators for getting payment in
export const getPaymentInRequest = () => ({ type: GET_PaymentIn_REQUEST });

export const getPaymentInSuccess = (response) => ({
  type: GET_PaymentIn_SUCCESS,
  payload: response
});

export const getPaymentInFailure = error => ({
  type: GET_PaymentIn_FAILURE,
  payload: error
});

// Action creators for getting pending sales
export const getPendingSalesRequest = () => ({ type: GET_PENDING_SALES_REQUEST });

export const getPendingSalesSuccess = (response) => ({
  type: GET_PENDING_SALES_SUCCESS,
  payload: response
});

export const getPendingSalesFailure = error => ({
  type: GET_PENDING_SALES_FAILURE,
  payload: error
});


/**
 * Delete the invoices
 */
export const deletePaymentInRequest = () => ({
  type: DELETE_PAYMENTIN_REQUEST
});

export const deletePaymentInSuccess = (response, id) => ({
  type: DELETE_PAYMENTIN_SUCCESS,
  payload: response,
  meta: { id: id }
});

export const deletePaymentInFailure = error => ({
  type: DELETE_PAYMENTIN_FAILURE,
  payload: error
});


// Thunk action creator for saving payment
export const savePaymentIn = (data) => async (dispatch) => {
  dispatch(savePaymentInRequest());
  try {
    const response = await savePaymentInAPI(data);
    if (response?.success) {
      dispatch(savePaymentInSuccess(response));
      message.success(response?.success);
    } else if (response?.error) {
      alert("eeee")
      dispatch(savePaymentInFailure(response.error));
      message.error(response.error);
    }
    return response;
  } catch (error) {
    dispatch(savePaymentInFailure(error.message));
    message.error('An error occurred while saving payment.');
    throw error;
  }
};

// Thunk action creator for getting pending sales
export const getPendingSales = (data) => async (dispatch) => {
  dispatch(getPendingSalesRequest());
  try {
    const response = await getPendingSalesApi(data);

    if (response) {
      dispatch(getPendingSalesSuccess(response));
    } else if (response?.error) {
      dispatch(getPendingSalesFailure(response.error));
      message.error(response.error);
    }

    return response;
  } catch (error) {
    dispatch(getPendingSalesFailure(error.message));
    message.error('An error occurred while fetching pending sales.');
    throw error;
  }
};

// Thunk action creator for getting payment in
export const getPaymentIn = (data) => async (dispatch) => {
  dispatch(getPaymentInRequest());
  try {
    const response = await getPaymentInAPI(data);

    if (response) {
      dispatch(getPaymentInSuccess(response));
    }

    return response;
  } catch (error) {
    dispatch(getPaymentInFailure(error.message));
    message.error('An error occurred while fetching payment data.');
    throw error;
  }
};


/**
 * To delete the PaymentIndetail
 */
export const deletePaymentIn = (id) => async (dispatch) => {
  dispatch(deletePaymentInRequest());
  try {
    const response = await deletePaymentInAPI(id)
    if (response?.success) {
      dispatch(deletePaymentInSuccess(response, id))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(deletePaymentInFailure(response))
      message.error(response?.error)
    }
    return response;
  } catch (error) {
    dispatch(deletePaymentInFailure(error.message));
    throw error;
  }
};
