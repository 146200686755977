// actions.js
import axios from 'axios';
import {
  SAVE_DELIVERY_CHALLAN_REQUEST,
  SAVE_DELIVERY_CHALLAN_SUCCESS,
  SAVE_DELIVERY_CHALLAN_FAILURE,

  GET_DELIVERY_CHALLANS_REQUEST,
  GET_DELIVERY_CHALLANS_SUCCESS,
  GET_DELIVERY_CHALLANS_FAILURE,

  DELETE_DELIVERY_CHALLAN_FAILURE,
  DELETE_DELIVERY_CHALLAN_SUCCESS,
  DELETE_DELIVERY_CHALLAN_REQUEST
} from './actionTypes';
import { message } from 'antd';
import { getDeliveryChallansAPI, saveDeliveryChallanAPI,deleteDeliveryChallanAPI } from '../../../api/Services';


export const saveDeliveryChallanRequest = () => ({
  type: SAVE_DELIVERY_CHALLAN_REQUEST
});

export const saveDeliveryChallanSuccess = (response) => ({
  type: SAVE_DELIVERY_CHALLAN_SUCCESS,
  payload: response
});

export const saveDeliveryChallanFailure = error => ({
  type: SAVE_DELIVERY_CHALLAN_FAILURE,
  payload: error
});


/**
 * Get all delivery challan
 */
export const getDeliveryChallansRequest = () => ({
  type: GET_DELIVERY_CHALLANS_REQUEST
});

export const getDeliveryChallansSuccess = (response) => ({
  type: GET_DELIVERY_CHALLANS_SUCCESS,
  payload: response
});

export const getDeliveryChallansFailure = error => ({
  type: GET_DELIVERY_CHALLANS_FAILURE,
  payload: error
});

/**
 * Delete the invoices
 */
export const deleteDelieveryChallanRequest = () => ({
  type: DELETE_DELIVERY_CHALLAN_REQUEST
});

export const deleteDelieveryChallanSuccess = (response, id) => ({
  type: DELETE_DELIVERY_CHALLAN_SUCCESS,
  payload: response,
  meta: { id: id }
});

export const deleteDelieveryChallanFailure = error => ({
  type: DELETE_DELIVERY_CHALLAN_FAILURE,
  payload: error
});
/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To save the delivery challans detail
 */
export const saveDeliveryChallan = (data) => async (dispatch) => {
  dispatch(saveDeliveryChallanRequest());
  try {
    const response = await saveDeliveryChallanAPI(data)

    if (response?.success) {
      dispatch(saveDeliveryChallanSuccess(response))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(saveDeliveryChallanFailure(response))
      message.error(response?.error)
    }
    return response;

  } catch (error) {
    dispatch(saveDeliveryChallanFailure(error.message));
    throw error;
  }
};



/**
 * To get the all quotations
 */
export const getDeliveryChallans = (data) => async (dispatch) => {
  dispatch(getDeliveryChallansRequest());
  try {
    const response = await getDeliveryChallansAPI(data)

    if (response) {
      dispatch(getDeliveryChallansSuccess(response))
    }
    return response;

  } catch (error) {
    dispatch(getDeliveryChallansFailure(error.message));
    throw error;
  }
};

/**
 * To delete the DelieveryChallandetail
 */
export const deleteDelieveryChallan= (id) => async (dispatch) => {
  dispatch(deleteDelieveryChallanRequest());
  try {
    const response = await deleteDeliveryChallanAPI(id)
    if (response?.success) {
      dispatch(deleteDelieveryChallanSuccess(response, id))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(deleteDelieveryChallanFailure(response))
      message.error(response?.error)
    }
    return response;
  } catch (error) {
    dispatch(deleteDelieveryChallanFailure(error.message));
    throw error;
  }
};