// reducer.js
import {
    SAVE_CATEGORY_REQUEST,
    SAVE_CATEGORY_SUCCESS,
    SAVE_CATEGORY_FAILURE,

    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,
    
    saveCategory: {
        loading: false,
        error: null,
        response: null,
    },

    getCategories: {
        loading: false,
        error: null,
        response: null,
        // list: []
    }
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_CATEGORY_REQUEST:
            return {
                ...state,
                saveCategory: {
                    ...state.saveCategory,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_CATEGORY_SUCCESS:
            return {
                ...state,
                saveCategory: {
                    ...state.saveCategory,
                    loading: false,
                    error: null,
                    response: action.payload
                },
                getCategories: {
                    ...state.getCategories,
                    loading: false,
                    error: null,
                    response: [...state.getCategories.response, action.payload?.data]
                }
            };
        case SAVE_CATEGORY_FAILURE:
            return {
                ...state,
                saveCategory: {
                    ...state.saveCategory,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        
        case GET_CATEGORIES_REQUEST:
        return {
            ...state,
            getCategories: {
                ...state.getCategories,
                loading: true,
                error: null,
                response: null
            }
        };
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                getCategories: {
                    ...state.getCategories,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_CATEGORIES_FAILURE:
            return {
                ...state,
                getCategories: {
                    ...state.getCategories,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default categoryReducer;
