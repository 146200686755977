import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PrinterOutlined, DownloadOutlined, FileExcelOutlined, CloseOutlined } from '@ant-design/icons';

export default function AppListHeader({
    headerContent,
    creationLable,
    handleCreateNew,
    excel,
    handleExcelExport,
    print,
    download,
    cancel,
    printNavigation,
    printData
}) {
    const navigate = useNavigate();

    return (
        <div>
            <div className="flex flex-wrap justify-between items-center mb-4 bg-[#f5f5ef] p-4 border-b-2">
                <h2 className="text-xl sm:text-2xl font-semibold w-full sm:w-auto">{headerContent}</h2>
                <div className="flex flex-wrap space-x-2 mt-2 sm:mt-0">
                    {handleCreateNew && (
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                            onClick={handleCreateNew}
                        >
                            <b>{creationLable}</b>
                        </Button>
                    )}
                    {excel && (
                        <Button
                            htmlType="button"
                            className="shadow-inner bg-green-500 text-white"
                            onClick={handleExcelExport}
                        >
                            <b><FileExcelOutlined /></b>
                        </Button>
                    )}
                    {print && (
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                            onClick={() => navigate(printNavigation, { state: printData })}
                        >
                            <b><PrinterOutlined /></b>
                        </Button>
                    )}
                    {download && (
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                        >
                            <b><DownloadOutlined /></b>
                        </Button>
                    )}
                    {cancel && (
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                            onClick={() => navigate(-1)}
                        >
                            <b><CloseOutlined /></b>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
