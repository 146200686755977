import React from 'react'
import { Button, Form, Input, message } from 'antd'
import { useDispatch } from 'react-redux';
import { sendResetPasswordLink } from './actions';
import { Link } from 'react-router-dom';

export default function ResetPasswordLink() {

    const [form] = Form.useForm()

    const dispatch = useDispatch();

    const handleSendResetLink = async (values) => {

        const response = await dispatch(sendResetPasswordLink(values))

        if (response?.success) {
            message.success("Password reset link has been sent to your email.")
            form.resetFields()
        } else if (response?.error) {
            message.error(response?.error)
        } else {
            message.error("Something Went Wrong.")
        }
    }

    return (
        <div className='w-[100%]'>
            <div
                className='
                text-[18px] font-bold text-[#000080]
                '
            >
                Reset Your Password
            </div>
            <div
                className='
                text-[14px]
                '
            >
                Enter your email to receive a password reset link
            </div>
            <br />

            <Form
                form={form}
                onFinish={handleSendResetLink}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please Enter Email"
                        },
                        {
                            type: 'email',
                            message: "Please Enter Valid Email"
                        }
                    ]}
                >
                    <Input
                        placeholder='Enter Email ID'
                        maxLength={50}
                    />
                </Form.Item>
                <div
                    className='
                    flex justify-between
                    '
                >
                    <Button
                        className='
                    rounded-2xl shadow-lg bg-[#000080] text-[white] px-10 h-[35px]
                    '
                        htmlType='submit'
                    >
                        <b>Send Reset Link</b>
                    </Button>

                    <Link to="/login" className='text-[blue]'>Back to Login</Link>
                </div>
            </Form>
        </div>
    )
}
