// actions.js
import axios from 'axios';
import {
  SAVE_PURCHASE_ORDER_REQUEST,
  SAVE_PURCHASE_ORDER_SUCCESS,
  SAVE_PURCHASE_ORDER_FAILURE,

  GET_PURCHASE_ORDERS_REQUEST,
  GET_PURCHASE_ORDERS_SUCCESS,
  GET_PURCHASE_ORDERS_FAILURE,

  DELETE_PURCHASE_ORDERS_REQUEST,
  DELETE_PURCHASE_ORDERS_SUCCESS,
  DELETE_PURCHASE_ORDERS_FAILURE

} from './actionTypes';
import { message } from 'antd';
import { getPurchaseOrderAPI, savePurchaseOrderAPI ,deletePurchaseOrderAPI} from '../../../api/Services';
import { json } from 'react-router-dom';


export const savePurchaseOrderRequest = () => ({
  type: SAVE_PURCHASE_ORDER_REQUEST
});

export const savePurchaseOrderSuccess = (response) => ({
  type: SAVE_PURCHASE_ORDER_SUCCESS,
  payload: response
});

export const savePurchaseOrderFailure = error => ({
  type: SAVE_PURCHASE_ORDER_FAILURE,
  payload: error
});


/**
 * Get all purchased order data
 */
export const getPurchaseOrdersRequest = () => ({
  type: GET_PURCHASE_ORDERS_REQUEST
});

export const getPurchaseOrdersSuccess = (response) => ({
  type: GET_PURCHASE_ORDERS_SUCCESS,
  payload: response
});

export const getPurchaseOrdersFailure = error => ({
  type: GET_PURCHASE_ORDERS_FAILURE,
  payload: error
});

/**
 * Delete the invoices
 */
export const deletePurchaseOrderRequest = () => ({
  type: DELETE_PURCHASE_ORDERS_REQUEST
});

export const deletePurchaseOrderSuccess = (response, id) => ({
  type: DELETE_PURCHASE_ORDERS_SUCCESS,
  payload: response,
  meta: { id: id }
});

export const deletePurchaseOrderFailure = error => ({
  type: DELETE_PURCHASE_ORDERS_FAILURE,
  payload: error
});

/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To save the purchase order detail
 */
export const savePurchaseOrder = (data) => async (dispatch) => {
  dispatch(savePurchaseOrderRequest());
  try {
    const response = await savePurchaseOrderAPI(data)

    if (response?.success) {
      dispatch(savePurchaseOrderSuccess(response))
      message.success(response?.success)
      return response;
    }
    
    if (response) {
      console.log(response, "action error")
      dispatch(savePurchaseOrderFailure(response))
      message.error(response?.error)
      return response;
    }
    

  } catch (error) {
    dispatch(savePurchaseOrderFailure(error.message));
    throw error;
  }
};


/**
 * To get the all purchased data
 */
export const getPurchaseOrders = () => async (dispatch) => {
  dispatch(getPurchaseOrdersRequest());
  try {
    const response = await getPurchaseOrderAPI()
    if (response?.purchase_orders) {
      dispatch(getPurchaseOrdersSuccess(response))
    }
    return response;

  } catch (error) {
    dispatch(getPurchaseOrdersFailure(error.message));
    throw error;
  }
};

/**
 * To delete the PurchaseOrderdetail
 */
export const deletePurchaseOrder= (id) => async (dispatch) => {
  dispatch(deletePurchaseOrderRequest());
  try {
    const response = await deletePurchaseOrderAPI(id)
    if (response?.success) {
      dispatch(deletePurchaseOrderSuccess(response, id))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(deletePurchaseOrderFailure(response))
      message.error(response?.error)
    }
    return response;
  } catch (error) {
    dispatch(deletePurchaseOrderFailure(error.message));
    throw error;
  }
};