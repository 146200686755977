// Actions Types for clents

export const SAVE_DELIVERY_CHALLAN_REQUEST = 'SAVE_DELIVERY_CHALLAN_REQUEST';
export const SAVE_DELIVERY_CHALLAN_SUCCESS = 'SAVE_DELIVERY_CHALLAN_SUCCESS';
export const SAVE_DELIVERY_CHALLAN_FAILURE = 'SAVE_DELIVERY_CHALLAN_FAILURE';

export const GET_DELIVERY_CHALLANS_REQUEST = 'GET_DELIVERY_CHALLANS_REQUEST';
export const GET_DELIVERY_CHALLANS_SUCCESS = 'GET_DELIVERY_CHALLANS_SUCCESS';
export const GET_DELIVERY_CHALLANS_FAILURE = 'GET_DELIVERY_CHALLANS_FAILURE';

export const DELETE_DELIVERY_CHALLAN_FAILURE = 'DELETE_DELIVERY_CHALLAN_FAILURE';
export const DELETE_DELIVERY_CHALLAN_SUCCESS = 'DELETE_DELIVERY_CHALLAN_SUCCESS';
export const DELETE_DELIVERY_CHALLAN_REQUEST = 'DELETE_DELIVERY_CHALLAN_REQUEST';


