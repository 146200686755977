import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FcExpand, FcNext } from 'react-icons/fc'; 
import './Sidebar.css';
import { FaHome, FaUser, FaBox, FaFileInvoice, FaMoneyBillWave, FaChartBar } from 'react-icons/fa';
import { MdOutlineReceiptLong } from 'react-icons/md'; 
import Logo from '../../images/BillZap_logo.png';
import secureLocalStorage from 'react-secure-storage';

const Sidebar = ({ isOpen, isItemsOpen, isPurchaseOpen, issaleOpen, toggleItems, togglePurchase, toggleSale, onCloseSidebar }) => {

  const navigate = useNavigate()

  const handleItemClick = () => {
    if (isOpen) {
      onCloseSidebar(); // Close the sidebar upon clicking any link
    }
  };

  const handleLogout = () => {
    secureLocalStorage.removeItem('access');
    secureLocalStorage.removeItem('tenent');
    navigate("/login");
  }

  return (
    <div className={isOpen ? 'sidebar open' : 'sidebar'}>
      {/* <h2 className="invoice-heading">
        <MdOutlineReceiptLong className="icon" /> 
        Invoice
      </h2>  */}
      <img src={Logo} alt='billzap' className='w-[50%] m-auto rounded flex'/>
      <ul>
        <li>
          <NavLink to="/dashboard" activeClassName="active" className="options" onClick={handleItemClick}>
            <FaHome className="icon" /> <span className="text">Dashboard</span> 
          </NavLink>
        </li>

        <li>
          <NavLink to="/customer-view" activeClassName="active" className="options" onClick={handleItemClick}> 
            <FaUser className="icon" /> <span className="text">Parties</span>
          </NavLink>
        </li>

        <li>
          <NavLink to="/product/item" activeClassName="active" className="subitem" onClick={handleItemClick}> 
            <FaUser className="icon" /> <span className="text">Item</span>
          </NavLink>
        </li>

        
        {/* <li>
          <div className="options" onClick={toggleItems}>
            <FaBox className="icon" /> <span className="text">Items</span> 
            <span className="icons">{isItemsOpen ? <FcExpand /> : <FcNext />}</span>
          </div>
          <ul className={isItemsOpen ? 'nested open' : 'nested'}>
           
            <li>
              <NavLink to="/product/item" activeClassName="active" className="subitem" onClick={handleItemClick}>Item</NavLink>
            </li>
          </ul>
        </li> */}

        <li>
          <div className="options" onClick={togglePurchase}>
            <FaFileInvoice className="icon" /> <span className="text">Purchase</span> 
            <span className="icons">{isPurchaseOpen ? <FcExpand /> : <FcNext />}</span>
          </div>
          <ul className={isPurchaseOpen ? 'nested open' : 'nested'}>
          <li>
              <NavLink to="/purchase/purchase-invoice" activeClassName="active" className="subitem" onClick={handleItemClick}>Purchase Invoices</NavLink>
            </li>
            <li>
              <NavLink to="/purchase/purchaseout" activeClassName="active" className="subitem" onClick={handleItemClick}>Payment Out</NavLink>
            </li>
         
            <li>
              <NavLink to="/purchase/purchase-order" activeClassName="active" className="subitem" onClick={handleItemClick}>Purchase orders </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <div className="options" onClick={toggleSale}>
            <FaFileInvoice className="icon" /> <span className="text">Sale</span> 
            <span className="icons">{issaleOpen ? <FcExpand /> : <FcNext />}</span>
          </div>
          <ul className={issaleOpen ? 'nested open' : 'nested'}>
            <li>
              <NavLink to="/sales/sale-dashboard" activeClassName="active" className="subitem" onClick={handleItemClick}>Sale Invoices</NavLink>
            </li>
            <li>
              <NavLink to="/sales/paymentin" activeClassName="active" className="subitem" onClick={handleItemClick}>Payment In</NavLink>
            </li>
            <li>
              <NavLink to="/sales/quotation-dashboard" activeClassName="active" className="subitem" onClick={handleItemClick}>Quotations</NavLink>
            </li>
            <li>
              <NavLink to="/sales/deliverychalan" activeClassName="active" className="subitem" onClick={handleItemClick}>Delivery Challan</NavLink>
            </li>
          </ul>
        </li>
        {/* <li>
          <NavLink to="/invoices" activeClassName="active" className="options" onClick={handleItemClick}>
            <FaFileInvoice className="icon" /> <span className="text">Invoices</span> 
          </NavLink>
        </li> */}
        <li>
          <NavLink to="/expenses" activeClassName="active" className="options" onClick={handleItemClick}>
            <FaMoneyBillWave className="icon" /> <span className="text">Expenses</span> 
          </NavLink>
        </li>
        <li>
          <NavLink to="/reports" activeClassName="active" className="options" onClick={handleItemClick}>
            <FaChartBar className="icon" /> <span className="text">Reports</span> 
          </NavLink>
        </li>
        <li>
          <div className="options" onClick={handleLogout}>
            <FaUser className="icon" /> <span className="text">Logout</span>
          </div>
        </li>
        {/* Add more links as needed */}
      </ul>
    </div>
  );
};

export default Sidebar;