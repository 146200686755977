import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, InputNumber, Row, Col, Card, Divider, Select, Checkbox, Modal, Radio, Cascader, message } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, FileProtectOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getParties } from '../../party/actions';
import { getItems } from '../../product/item/actions'
import { savePurchase } from './actions';
import { Link, useNavigate } from 'react-router-dom';
import { loggedInUser } from '../../../settings/codeSettings/additionalMethods';
import TcsRateForm from '../../taxModule/TcsRateForm';
import TdsRateForm from '../../taxModule/TdsRateForm';
import { getTcsDetails } from '../../taxModule/action';
import { getTdsDetails } from '../../taxModule/action';


export default function PurchaseForm() {

    const dispatch = useDispatch()
    const tcsRateTaxResponse = useSelector(state => state.taxDetails.getTcs.response)
    const tdsRateTaxResponse = useSelector(state => state.taxDetails.getTds.response)

    const partyResponse = useSelector(state => state.parties.getParties.response)
    const itemResponse = useSelector(state => state.items.getItems.response)

    const { error, response, loading } = useSelector(state => state.purchase.savePurchase);

    const [items, setItems] = useState([{ amount: 0 }]);
    // const [freightAmount, setFreightAmount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [roundOff, setRoundOff] = useState(0);
    const [balanceAmount, setBalanceAmount] = useState(0);
    const [billedToParty, setBilledToParty] = useState(null);
    const [taxDetail, setTaxDetail] = useState(null)

    const [applyTds, setApplyTds] = useState(false)
    const [isAddTds, setIsAddTds] = useState(false)
    const [applyTcs, setApplyTcs] = useState(false)
    const [isAddTcs, setIsAddTcs] = useState(false)
    const [tcsId, setTcsId] = useState(null)
    const [tdsId, setTdsId] = useState(null)
    const [tdsOn, setTdsOn] = useState('total amount')
    const [tcsOn, setTcsOn] = useState('total amount')

    const [tcsAmount, setTcsAmount] = useState(0)
    const [tdsAmount, setTdsAmount] = useState(0)


    const navigate = useNavigate()
    const [form] = Form.useForm();


    // useEffect(() => {
    //     if (error) {
    //         const fieldErrors = Object.keys(error).map((field) => ({
    //             name: field,
    //             errors: error[field],
    //         }));
    //         form.setFields(fieldErrors);
    //         // message.error('There were errors in the form. Please check and try again.');
    //     }
    // }, [error]);

    useEffect(() => {
        /**
         * handle party state
         */
        if (
            partyResponse?.party_count == partyResponse?.parties?.results?.length &&
            partyResponse?.parties?.results?.length > 0
        ) {

        }
        else {
            dispatch(getParties())
        }
        /**
         * end of handling party state
         */

        /**
       * handle item state
       */
        if (
            itemResponse?.items_count == itemResponse?.items?.results?.length &&
            itemResponse?.items?.results?.length > 0
        ) {

        }
        else {
            dispatch(getItems())
        }
        /**
         * end of handling item state
         */


        /**
       * handle TCS Tax Rate state
       */
        if (!tcsRateTaxResponse) {
            dispatch(getTcsDetails())
        }
        /**
         * end of handling item state
         */


        /**
       * handle TDS Tax Rate state
       */
        if (!tdsRateTaxResponse) {
            dispatch(getTdsDetails())
        }
        /**
         * end of handling item state
         */

    }, [dispatch])


    const [charges, setCharges] = useState([]);


    const handleAddCharge = () => {
        setCharges([
            ...charges,
            { description: "", amount: 0, tax: "No Tax Applicable" },
        ]);
        updateTotalValues(items); // Call after updating charges
    };

    const handleDescriptionChange = (index, value) => {
        const updatedCharges = [...charges];
        updatedCharges[index].description = value;
        setCharges(updatedCharges);
        updateTotalValues(items); // Call after updating charges
    };

    const handleAmountChanges = (index, value) => {
        const updatedCharges = [...charges];
        updatedCharges[index].amount = value;
        setCharges(updatedCharges);
        updateTotalValues(items); // Call after updating charges
    };

    const handleDeleteCharge = (index, amount) => {
        const updatedCharges = [...charges];
        updatedCharges.splice(index, 1); // Remove the charge
        setCharges(updatedCharges);      // Update the state
        updateTotalValues(items);
        form.setFieldsValue({ 'total_amount': parseFloat(form.getFieldValue('total_amount')) - parseFloat(amount).toFixed(2) }) // Recalculate totals after charge is deleted
        setBalanceAmount(parseFloat(balanceAmount) - parseFloat(amount).toFixed(2))
    };



    const initialValues = {
        purchase_items: [{ amount: 0, discount: '00' }], // Your existing initial values for items
        // Initial value for discount
        //shipping_charges: 0, // Initial value for shipping charges
        paid_amount: amountPaid, // Initial value for amount paid
        items: items,
        purchase_inv_no: '',
        date: '',
        original_inv_no: '',
        order_no: '',
        dispatch_through: '',
        vehicle_no: '',
        sub_total: 0,
        gst_amount: 0,
        total_amount: 0,
        description: '' // Add initial value for description
    };

    const onFinish = async (values) => {
        window.scroll(0, 0)

        const formattedValues = {
            purchase_inv_no: values.purchase_inv_no,
            original_inv_no: values.original_inv_no,
            billed_to: values.billed_to,
            shipped_to: values.shipped_to,
            order_no: values.order_no,
            dispatch_through: values.dispatch_through,
            vehicle_no: values.vehicle_no,
            date: values.date,
            sub_total: parseFloat(values.sub_total),
            gst_amount: parseFloat(values.gst_amount),
            cgst: parseFloat(taxDetail?.cgst) || 0,
            sgst: parseFloat(taxDetail?.sgst) || 0,
            igst: parseFloat(taxDetail?.igst) || 0,
            // shipping_charge: parseFloat(values.shipping_charges),
            // total_amount: parseFloat(values.amount),
            total_amount: parseFloat(values.total_amount),
            advance_amount: 0,
            paid_amount: amountPaid,
            round_off: roundOff,
            balance_amount: parseFloat(balanceAmount),
            payment_status: balanceAmount == 0 ? "PAID" : amountPaid == 0 ? 'UNPAID' : 'PARTIAL PAID',
            description: values.description,
            eway_bill_no: values.eway_bill_no,
            purchase_items: values.items.map(item => ({
                item: item.id,
                qty: parseFloat(item.qty),
                rate: parseFloat(item.price) || 0,
                discount: parseFloat(item.discount) || 0,
                total: parseFloat(item.amount),
                gst_amount: parseFloat(((item.qty * item.price) * item.gst) / 100).toFixed(2),
                amount: parseFloat(item.amount),
            })),

            tcs_applicable: applyTcs,
            tds_applicable: applyTds,
            purchase_additional_charges: charges.map(charge => ({
                charge_name: charge.description,
                amount: parseFloat(charge.amount),
            })),

        };

        if (applyTcs) {
            formattedValues['purchase_tcs'] =
                [{
                    tcs_rate: tcsId,
                    tcs_amount: tcsAmount,
                }]
        }
        if (applyTds) {
            formattedValues['purchase_tds'] =
                [{
                    tds_rate: tdsId,
                    tds_amount: tdsAmount,
                }]
        }

        const response = await dispatch(savePurchase(formattedValues));

        if (response?.success) {
            navigate(-1);
        } else if (response?.error) {
            const fieldErrors = Object.keys(response?.error)?.map((field) => ({
                name: field,
                errors: response?.error[field],
            }));
            form.setFields(fieldErrors);
        }

        // try {
        //     const response = await dispatch(savePurchase(formattedValues));

        //     if (response?.success) {
        //         navigate(-1);
        //     } else {
        //         // Assuming response.error is an object with error messages
        //         const errorMessages = response.error?.message || 'An error occurred. Please try again.';
        //         message.error(errorMessages);
        //     }
        // } catch (err) {
        //     // Handle any unexpected errors
        //     message.error('An unexpected error occurred. Please try again.');
        //     console.error(err);
        // }
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error('Please fix the highlighted errors before submitting.');
    };

    const handleAmountChange = (index, value) => {
        const newItems = [...items];
        newItems[index].amount = value;

        // Reverse calculate price, qty, discount, and gst based on the entered amount
        let amount = parseFloat(value || 0);
        const gst = parseFloat(newItems[index].gst || 0);
        const discount = parseFloat(newItems[index].discount || 0);
        const qty = parseFloat(newItems[index].qty || 0);

        // Ensure qty is not zero to avoid division by zero
        if (qty > 0) {
            amount = amount + discount
            const amountWithoutGst = amount / (1 + gst / 100);
            const price = (amountWithoutGst) / qty;

            newItems[index].price = price.toFixed(2);
        } else {
            message.error('Quantity must be greater than zero');
        }

        setItems(newItems);
        form.setFieldsValue({ items: newItems });
        updateTotalValues(newItems);
    };

    const handleItemChange = (index, field, value) => {

        const newItems = [...items];
        newItems[index][field] = value;

        if (field == "item") {
            const itemObj = itemResponse?.items?.results?.find(dt => dt?.id == value);
            if (itemObj) {
                newItems[index].price = itemObj?.current_rate;
                newItems[index].gst = itemObj?.gst_percentage;
                newItems[index].id = itemObj?.id;
                newItems[index].hsn = itemObj?.hsn_code;
            } else {
                message.error('Item not found');
            }
        }
        if (field === "amount") {
            if (!newItems[index].qty || newItems[index].qty < 0) {
                message.error('Please Enter the Quantity')
            }
            else {
                handleAmountChange(index, value);
            }
        } else {
            newItems[index].amount = calculateAmount(newItems[index]);
        }

        // newItems[index].amount = calculateAmount(newItems[index]); // Update amount when fields change
        setItems(newItems);
        form.setFieldsValue({ items: newItems }); // Update form state
        updateTotalValues(newItems); // Update totals when an item changes
    };

    const calculateAmount = (item) => {
        const price = parseFloat(item.price || 0);
        const qty = parseFloat(item.qty || 0);
        const discount = parseFloat(item.discount || 0);
        const gst = parseFloat(item.gst || 0);
        const total = (price * qty);
        const gstAmount = (total * gst) / 100;
        return Math.round((total + gstAmount - discount).toFixed(2));
    };

    const updateTotalValues = (updatedItems) => {
        var cgst = null
        var sgst = null
        var igst = null
        let subtotal = updatedItems.reduce((sum, item) => sum + parseFloat((item.qty * item.price) || 0), 0);
        const totalCharges = charges.reduce(
            (sum, charge) => sum + parseFloat(charge.amount || 0),
            0
        );
        const gstTotal = updatedItems.reduce((sum, item) => sum + ((parseFloat(item.price || 0) * parseFloat(item.qty || 0)) * (parseFloat(item.gst || 0) / 100)), 0);
        if (!billedToParty?.gst_no || !loggedInUser?.gst) {
            cgst = parseFloat(gstTotal / 2).toFixed(2)
            sgst = parseFloat(gstTotal / 2).toFixed(2)
            setTaxDetail({ ...taxDetail, cgst, sgst, igst: null })
        } else {
            if (billedToParty?.gst_no?.substring(0, 2) != loggedInUser?.gst?.substring(0, 2)) {
                igst = gstTotal.toFixed(2)
                setTaxDetail({ ...taxDetail, igst, cgst: null, sgst: null })
            } else {
                cgst = parseFloat(gstTotal / 2).toFixed(2)
                sgst = parseFloat(gstTotal / 2).toFixed(2)
                setTaxDetail({ ...taxDetail, cgst, sgst, igst: null })
            }
        }
        const totalDiscount = updatedItems.reduce((sum, item) => sum + parseFloat((item.discount) || 0), 0);
        // let subtotal = updatedItems.reduce((sum, item) => sum + ((parseFloat(item.price || 0) * parseFloat(item.qty || 0)), 0))
        // subtotal = subtotal - gstTotal
        // handleCalculateTcsAmount(tcsId, tcsOn)
        const totalAmount = parseFloat(subtotal) + gstTotal - totalDiscount + handleCalculateTcsAmount(tcsId, tcsOn) + handleCalculateTdsAmount(tdsId, tdsOn) + totalCharges;
        form.setFieldsValue({
            sub_total: subtotal.toFixed(2),
            gst_amount: gstTotal.toFixed(2),
            cgst: cgst,
            sgst: sgst,
            igst: igst,
            total_amount: parseFloat(totalAmount).toFixed(2),
        });

        updateBalanceAmount(totalAmount, amountPaid, roundOff);

    };

    const updateBalanceAmount = (totalAmount, amountPaid, roundOff) => {
        const balance = parseFloat(totalAmount).toFixed(2) - parseFloat(amountPaid) + (parseFloat(roundOff) || 0);
        setBalanceAmount(balance.toFixed(2));
        form.setFieldsValue({
            balance_amount: balance.toFixed(2),
        });
    };

    const validateNumber = (maxLength) => (_, value) => {
        if (!value) {
            return Promise.resolve();
        }
        const regex = new RegExp(`^\\d{1,${maxLength}}$`);
        if (!regex.test(value)) {
            return Promise.reject(`Please enter a valid number with up to ${maxLength} digits.`);
        }
        return Promise.resolve();
    };

    useEffect(() => {
        form.setFieldsValue({ items });
        if (tcsId) {
            handleCalculateTcsAmount(tcsId, tcsOn)
        }
        if (tdsId) {
            handleCalculateTdsAmount(tdsId, tdsOn)
        }
    }, [items, form, tcsId, tcsOn, tdsId, tdsOn]);

    useEffect(() => {
        updateTotalValues(items);
    }, [items, billedToParty, tcsId, tcsOn, tdsId, tdsOn]);


    useEffect(() => {
        const totalAmount = parseFloat(form.getFieldValue('total_amount') || 0);
        updateBalanceAmount(totalAmount, amountPaid, roundOff);
    }, [amountPaid, roundOff]);

    const handlePartyChange = (event) => {
        const partyObj = partyResponse?.parties?.results?.find(party => party.id === event)
        setBilledToParty(partyObj)
        var cgst = null
        var sgst = null
        var igst = null
        const gstTotal = form.getFieldValue('gst_amount')
        if (!billedToParty?.gst_no || !loggedInUser?.gst) {
            cgst = parseFloat(gstTotal / 2)
            sgst = parseFloat(gstTotal / 2)
            setTaxDetail({ ...taxDetail, cgst, sgst, igst: null })
        } else {
            if (billedToParty?.gst_no?.substring(0, 2) != loggedInUser?.gst?.substring(0, 2)) {
                igst = gstTotal
                setTaxDetail({ ...taxDetail, igst, cgst: null, sgst: null })
            } else {
                cgst = parseFloat(gstTotal / 2)
                sgst = parseFloat(gstTotal / 2)
                setTaxDetail({ ...taxDetail, cgst, sgst, igst: null })
            }
        }
        form.setFieldsValue({ cgst: cgst, sgst: sgst, igst: igst })
    }

    const handleCalculateTcsAmount = (rate, onTcs) => {
        let calculateTcsAmount = 0;
        const tcsRateObj = tcsRateTaxResponse?.find(taxRate => taxRate.id == rate)
        if (tcsRateObj) {
            if (onTcs === 'total amount') {
                calculateTcsAmount = ((parseFloat(form.getFieldValue('sub_total')) + parseFloat(form.getFieldValue('gst_amount'))) * tcsRateObj.tax_rate) / 100;
            } else if (onTcs === 'taxable amount') {
                calculateTcsAmount = (form.getFieldValue('sub_total') * tcsRateObj.tax_rate) / 100;
            }
            calculateTcsAmount = parseFloat(calculateTcsAmount.toFixed(2))
            setTcsAmount(calculateTcsAmount)
        }
        return calculateTcsAmount
    }

    const handleCalculateTdsAmount = (rate, onTds) => {
        let calculateTdsAmount = 0;
        const tdsRateObj = tdsRateTaxResponse?.find(taxRate => taxRate.id == rate)
        if (tdsRateObj) {
            if (onTds === 'total amount') {
                calculateTdsAmount = ((parseFloat(form.getFieldValue('sub_total')) + parseFloat(form.getFieldValue('gst_amount'))) * tdsRateObj.tax_rate) / 100;
            } else if (onTds === 'taxable amount') {
                calculateTdsAmount = (form.getFieldValue('sub_total') * tdsRateObj.tax_rate) / 100;
            }
            calculateTdsAmount = parseFloat(calculateTdsAmount.toFixed(2))
            setTdsAmount(calculateTdsAmount)
        }
        return calculateTdsAmount
    }


    const handleTcsTotalAmountChange = (e) => {
        setTcsOn(e.target.value)
        handleCalculateTcsAmount(tcsId, e.target.value)
    };

    const handleTdsTotalAmountChange = (e) => {
        setTdsOn(e.target.value)
        handleCalculateTdsAmount(tdsId, e.target.value)
    };

    const handleTcsChecked = (checked) => {
        if (checked == false) {
            setTcsAmount(0)
            setTcsId(null)
        }
        setApplyTcs(checked)
    }

    const handleTdsChecked = (checked) => {
        if (checked == false) {
            setTdsAmount(0)
            setTdsId(null)
        }
        setApplyTds(checked)
    }

    return (
        <div>
            {isAddTcs &&
                <Modal
                    title="Add Tcs Rate"
                    open={isAddTcs}
                    onOk={() => setIsAddTcs(!isAddTcs)}
                    onCancel={() => setIsAddTcs(!isAddTcs)}
                    footer={null}
                >
                    <TcsRateForm setTcsId={setTcsId} setIsAddTcs={setIsAddTcs} />
                </Modal>
            }
            {isAddTds &&
                <Modal
                    title="Add Tds Rate"
                    open={isAddTds}
                    onOk={() => setIsAddTds(!isAddTds)}
                    onCancel={() => setIsAddTds(!isAddTds)}
                    footer={null}
                >
                    <TdsRateForm setTdsId={setTdsId} setIsAddTds={setIsAddTds} />
                </Modal>
            }
            <Form
                form={form}
                name="purchaseInvoice"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="mx-auto bg-white rounded-md "
                initialValues={initialValues}

            >
                <div className="flex justify-between sticky top-[65px] mb-4 z-10 bg-white ">
                    <h2 className="text-2xl font-semibold py-0">Create Purchase Invoice</h2>
                    <div className="flex space-x-2">
                        <Button type="primary" htmlType="submit" className="bg-purple-500 border-none shadow-inner">
                            <b>Save</b>
                        </Button>
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                            onClick={() => navigate(-1)}
                        >
                            <b>Cancel</b>
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row  gap-4 ">
                    <Card title="Billing Details" size="small" className="w-full md:w-1/2 mb-4 shadow-md">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <Form.Item
                                label="Billed To"
                                name="billed_to"
                                rules={[{ required: true, message: 'Please select billing name!' }]}
                                className='m-0 p-0'
                            >
                                <Select
                                    placeholder='Select Party'
                                    onChange={handlePartyChange}
                                    showSearch
                                    optionFilterProp='children'
                                    dropdownRender={menu => (
                                        <>
                                            <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
                                                <Link
                                                    className='flex ml-auto text-blue-500'
                                                    to='/party-form'
                                                >
                                                    New Party
                                                </Link>
                                            </div>
                                            <Divider style={{ margin: '0px 0' }} />
                                            {menu}
                                        </>
                                    )}
                                >
                                    {partyResponse?.parties?.results?.map((data, id) => (
                                        <Select.Option key={id} value={data.id}>{data.company_name}, {data.party_type}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Shipped To"
                                name="shipped_to"
                                rules={[{ required: true, message: 'Please select shipping name!' }]}
                                className='m-0 p-0'
                            >
                                <Select
                                    placeholder='Select Party'
                                    showSearch
                                    optionFilterProp='children'
                                    dropdownRender={menu => (
                                        <>
                                            <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
                                                <Link
                                                    className='flex ml-auto text-blue-500'
                                                    to='/party-form'
                                                >
                                                    New Party
                                                </Link>
                                            </div>
                                            <Divider style={{ margin: '0px 0' }} />
                                            {menu}
                                        </>
                                    )}
                                >
                                    {partyResponse?.parties?.results?.map((data, id) => (
                                        <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Dispatch Through"
                                name="dispatch_through"
                                className='m-0 p-0'
                            >
                                <Input
                                    maxLength={50}
                                    placeholder='Enter dispatch through / transporter name'
                                />
                            </Form.Item>

                            <Form.Item
                                label="Vehicle NO."
                                name="vehicle_no"
                                className='m-0 p-0'
                            >
                                <Input
                                    maxLength={15}
                                    placeholder='Enter vehicle no.'
                                />
                            </Form.Item>

                        </div>
                    </Card>

                    <Card title="Invoice Details" size="small" className="w-full md:w-1/2 mb-4 shadow-md">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <Form.Item
                                label="Purchase Inv Date"
                                name="date"
                                rules={[{ required: true, message: 'Please select the Purchase Invoice Date!' }]}
                                className='m-0 p-0'
                            >
                                <Input type='date' />
                            </Form.Item>
                            <Form.Item
                                label="Order No"
                                name="order_no"
                                rules={[{ required: true, message: 'Please input the Order Number!' }]}
                                className='m-0 p-0'
                            >
                                <Input maxLength={20} placeholder="Order No" />
                            </Form.Item>

                            <Form.Item
                                label="Original Inv No"
                                name="original_inv_no"
                                rules={[{ required: true, message: 'Please input the Original Invoice Number!' }]}
                                className='m-0 p-0'
                            >
                                <Input maxLength={15} placeholder="Original Inv No" />
                            </Form.Item>
                            <Form.Item
                                label="Purchase Inv No"
                                name="purchase_inv_no"
                                rules={[{ required: true, message: 'Please input the Purchase Invoice Number!' }]}
                                className='m-0 p-0'
                            >
                                <Input maxLength={10} placeholder="Purchase Inv No" />
                            </Form.Item>
                        </div>
                    </Card>
                </div>


                <Form.List name="items">
                    {(fields, { add, remove }) => (
                        <div className='shadow-md'>
                            <div className="overflow-x-auto mb-6">
                                <table className="min-w-full border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="p-2 border border-gray-300 text-xs">NO</th>
                                            <th className="p-2 border border-gray-300 text-xs">ITEMS/ SERVICES</th>
                                            <th className="p-2 border border-gray-300 text-xs">HSN/ SAC</th>
                                            <th className="p-2 border border-gray-300 text-xs">QTY</th>
                                            <th className="p-2 border border-gray-300 text-xs">PRICE/ITEM (₹)</th>
                                            <th className="p-2 border border-gray-300 text-xs">DISCOUNT</th>
                                            <th className="p-2 border border-gray-300 text-xs">GST</th>
                                            <th className="p-2 border border-gray-300 text-xs">AMOUNT (₹)</th>
                                            <th className="p-2 border border-gray-300 text-xs">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                            <tr key={key}>
                                                <td className="p-2 border border-gray-300 text-xs">{index + 1}</td>
                                                <td className="p-2 border border-gray-300 text-xs">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'item']}
                                                        fieldKey={[fieldKey, 'item']}
                                                        rules={[{ required: true, message: 'Missing item name' }]}
                                                        className="mb-0 w-[200px]"
                                                    >
                                                        <Select
                                                            onChange={(value) => handleItemChange(index, 'item', value)}
                                                            placeholder='Select Item'
                                                            showSearch
                                                            optionFilterProp='children'
                                                        >
                                                            {itemResponse?.items?.results?.map((data, i) => (
                                                                <Select.Option
                                                                    key={i}
                                                                    value={data?.id}
                                                                >
                                                                    {data?.item_name}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </td>
                                                <td className="p-2 border border-gray-300 text-xs">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'hsn']}
                                                        fieldKey={[fieldKey, 'hsn']}
                                                        rules={[{ required: true, message: 'Missing HSN code' }]}
                                                        className="mb-0 w-[200px]"
                                                    >
                                                        <Input
                                                            onChange={(e) => handleItemChange(index, 'hsn', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </td>
                                                <td className="p-2 border border-gray-300 text-xs">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'qty']}
                                                        fieldKey={[fieldKey, 'qty']}
                                                        rules={[{ required: true, message: 'Missing quantity' }, { validator: validateNumber(3) }]}
                                                        className="mb-0 w-[120px]"

                                                    >
                                                        <Input
                                                            onChange={(e) => handleItemChange(index, 'qty', e.target.value)}
                                                            maxLength={3}
                                                        />
                                                    </Form.Item>
                                                </td>
                                                <td className="p-2 border border-gray-300 text-xs">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'price']}
                                                        fieldKey={[fieldKey, 'price']}
                                                        className="mb-0 w-[130px]"
                                                    >
                                                        <Input
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                const validValue = value.replace(/[^0-9.]/g, '');
                                                                if ((validValue.match(/\./g) || []).length <= 1) {
                                                                    handleItemChange(index, 'price', validValue);
                                                                }
                                                            }}
                                                            maxLength={10}
                                                        />
                                                    </Form.Item>
                                                </td>
                                                <td className="p-2 border border-gray-300 text-xs">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'discount']}
                                                        fieldKey={[fieldKey, 'discount']}
                                                        rules={[{ validator: validateNumber(6) }]}
                                                        className="mb-0 w-[100px]"
                                                    >
                                                        <Input
                                                            onChange={(e) => handleItemChange(index, 'discount', e.target.value)}
                                                            maxLength={6}
                                                        />
                                                    </Form.Item>
                                                </td>
                                                <td className="p-2 border border-gray-300 text-xs">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'gst']}
                                                        fieldKey={[fieldKey, 'gst']}
                                                        rules={[{ required: true, message: 'Missing GST' }]}
                                                        className="mb-0 w-[100px]"
                                                    >
                                                        <Input
                                                            onChange={(e) => handleItemChange(index, 'gst', e.target.value)} readOnly
                                                        />
                                                    </Form.Item>
                                                </td>
                                                <td className="p-2 border border-gray-300 text-xs">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'amount']}
                                                        fieldKey={[fieldKey, 'amount']}
                                                        className="mb-0 w-[100px]"
                                                    >
                                                        <Input
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                const validValue = value.replace(/[^0-9.]/g, '');
                                                                if ((validValue.match(/\./g) || []).length <= 1) {
                                                                    handleItemChange(index, 'amount', validValue);
                                                                }
                                                            }}
                                                            maxLength={10}
                                                        />
                                                    </Form.Item>
                                                </td>
                                                <td className="p-2 border border-gray-300 text-xs">
                                                    <Button
                                                        type="danger"
                                                        onClick={() => {
                                                            const newItems = [...items];
                                                            newItems.splice(index, 1);
                                                            setItems(newItems);
                                                            form.setFieldsValue({ items: newItems });
                                                            updateTotalValues(newItems);
                                                        }}
                                                    >
                                                        <DeleteOutlined className='text-red-600' />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan="9" className="p-2 border border-gray-300 text-xs">
                                                <Button type="dashed"
                                                    onClick={() => {
                                                        add();
                                                        setItems([...items, { amount: 0 }]); // Add new item to state
                                                    }} block>
                                                    Add Item
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </Form.List>

                <div className="mt-4 flex w-full md:flex-row flex-col-reverse  justify-between">
                    <div
                        className='md:w-[45%] w-[100%]'
                    >
                        <Form.Item
                            label="E-Way Bill No."
                            name="eway_bill_no"
                            className='m-0 p-0'
                        >
                            <Input
                                maxLength={100}
                                placeholder='Enter E-Way Bill No'
                            />
                        </Form.Item>

                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                { required: true, message: 'Enter the description' }
                            ]}
                        >
                            <Input.TextArea
                                autoSize={{ minRows: 5, maxRows: 8 }}
                                placeholder="Enter Description"
                                className="w-full shadow-md"
                            />
                        </Form.Item>
                    </div>
                    <Card
                        className="text-left md:w-[45%] w-[100%] shadow-lg p-0 bg-[rgba(0,0,0,0.02)]"
                    >
                        <Form.Item
                            style={{ margin: 0 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'requered'
                                }
                            ]}
                            name="sub_total"
                        >
                            <div className='flex justify-between font-bold'>
                                <div>Sub Total</div>
                                <div>{form.getFieldValue('sub_total') ? form.getFieldValue('sub_total') : '0.00'}</div>
                            </div>
                        </Form.Item>

                        <Form.Item
                            name="charges"
                        >
                            <div>
                                {/* Show 'Add Charge' button if no charges exist */}
                                {charges.length === 0 && (
                                    <div style={{ display: "flex", width: '100%' }}>
                                        <div className='w-1/2'>
                                            <button onClick={handleAddCharge} style={{ color: "blue" }}>
                                                + Add Charge
                                            </button>
                                        </div>
                                        <div className='w-1/2 text-end'>
                                            <span style={{ marginLeft: "10px" }}>₹ 0.00</span>
                                        </div>
                                    </div>
                                )}

                                {/* Render each charge */}
                                {charges.map((charge, index) => (
                                    <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: '10px' }}>
                                        <Input
                                            className='w-1/2'
                                            type="text"
                                            placeholder="Enter charge (ex. Transport Charge)"
                                            value={charge.description}
                                            onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                        />
                                        <div className='text-end w-28 '>₹</div>
                                        {/* <InputNumber
                                            className='w-28 m-2 text-end'
                                            value={charge.amount}
                                            onChange={(value) => handleAmountChanges(index, value)}
                                            min={0}  // Optional: Restrict to non-negative numbers
                                        /> */}
                                        <input
                                            type="number"
                                            value={charge?.amount}
                                            onChange={(e) => handleAmountChanges(index, e.target.value)}
                                            onInput={(event) => {
                                                // Prevent input of negative numbers or non-integer values
                                                event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                            }}
                                            maxLength={7}
                                            className='p-[3px] rounded-lg border w-20 text-right w-28 m-2 text-end'
                                        />
                                        {/* Delete button */}
                                        <Button
                                            onClick={() => handleDeleteCharge(index, charge.amount)}
                                            style={{ marginLeft: "5px", border: "none", background: "none" }}
                                        >
                                            <DeleteOutlined className='text-red-600' />
                                        </Button>
                                    </div>
                                ))}

                                {/* Show 'Add Another Charge' button if there are already charges */}
                                {charges.length > 0 && (
                                    <Button onClick={handleAddCharge} style={{ color: "blue" }}>
                                        + Add Another Charge
                                    </Button>
                                )}
                            </div>
                        </Form.Item>

                        {/* <Form.Item
                            name="shipping_charges"
                            rules={[
                                {
                                    required: true,
                                    message: 'Shipping Charges is required'
                                },
                                {
                                    validator: (_, value) =>
                                        value > 1000000
                                            ? Promise.reject('Shipping charge should not be greater than 1000000')
                                            : Promise.resolve(),
                                },
                            ]}
                            initialValue={0}
                            style={{ margin: 0 }}
                        >
                            <div className='flex justify-between'>
                                <div>Shipping Charges</div>
                                <input
                                    defaultValue={0}
                                    type="number"
                                    onChange={(value) => {
                                        updateTotalValues(items, value.target.value);
                                    }}
                                    onInput={(event) => {
                                        // Prevent input of negative numbers or non-integer values
                                        event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                    }}
                                    maxLength={7}
                                    className='p-[3px] rounded-lg border w-20 text-right'
                                    step="any"
                                />
                            </div>
                        </Form.Item> */}

                        {taxDetail?.cgst &&
                            <Form.Item
                                name="cgst"
                                style={{ margin: 0 }}
                            >
                                <div className='flex justify-between'>
                                    <div>CGST</div>
                                    <div>{taxDetail?.cgst ? taxDetail?.cgst : '0.00'}</div>
                                </div>
                            </Form.Item>
                        }
                        {taxDetail?.sgst &&
                            <Form.Item
                                name="sgst"
                                style={{ margin: 0 }}
                            >
                                <div className='flex justify-between'>
                                    <div>SGST</div>
                                    <div>{taxDetail?.sgst ? taxDetail?.sgst : '0.00'}</div>
                                </div>
                            </Form.Item>
                        }
                        {taxDetail?.igst &&
                            <Form.Item
                                name="igst"
                                style={{ margin: 0 }}
                            >
                                <div className='flex justify-between'>
                                    <div>IGST</div>
                                    <div>{taxDetail?.igst ? taxDetail?.igst : '0.00'}</div>
                                </div>
                            </Form.Item>
                        }
                        <Form.Item
                            name="gst_amount"
                            style={{ margin: 0 }}
                        >
                            <div className='flex justify-between'>
                                <div>GST Amount</div>
                                <div>{form.getFieldValue('gst_amount') ? form.getFieldValue('gst_amount') : '0.00'}</div>
                            </div>


                        </Form.Item>
                        <div className='flex flex-col gap-4'>
                            {/* TDS Section */}
                            <div className='flex justify-between items-center' >
                                <Checkbox onChange={e => handleTdsChecked(e.target.checked)}>Apply TDS</Checkbox>
                                {applyTds && (
                                    <div className='flex items-center gap-2'>
                                        {/* {tdsAmount} */}
                                        <Form.Item
                                            name="tds"
                                            className='m-0 w-[290px]'
                                            valuePropName={tdsId}
                                        >
                                            <Select
                                                onChange={setTdsId}
                                                value={tdsId}
                                                className='w-full h-[30px]'
                                                showSearch
                                                optionFilterProp='children'
                                                dropdownRender={menu => (
                                                    <>
                                                        <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
                                                            <Link
                                                                onClick={() => setIsAddTds(!isAddTds)}
                                                                className='flex ml-auto text-blue-500'
                                                            >
                                                                Add New TDS Rate
                                                            </Link>
                                                        </div>
                                                        <Divider style={{ margin: '0px' }} />
                                                        {menu}
                                                    </>
                                                )}
                                                placeholder="Select TDS"
                                            >
                                                {tdsRateTaxResponse?.map((tax, i) => (
                                                    <Select.Option key={i} value={tax.id}>
                                                        {tax.tax_name} ({tax.section_name} - {tax.tax_rate}%)
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                )}
                            </div>
                            {applyTds && (
                                <div className='flex justify-end'>
                                    <Radio.Group
                                        onChange={handleTdsTotalAmountChange}
                                        value={tdsOn}
                                        className='my-2'
                                    >
                                        <Radio value='total amount'>Total Amount</Radio>
                                        <Radio value='taxable amount'>Taxable Amount</Radio>
                                    </Radio.Group>
                                </div>
                            )}

                            {/* TCS Section */}
                            <div className='flex justify-between items-center'>
                                <Checkbox onChange={e => handleTcsChecked(e.target.checked)}>Apply TCS</Checkbox>
                                {applyTcs && (
                                    <div className='flex items-center gap-2'>
                                        {/* {tcsAmount} */}
                                        <Form.Item
                                            name="tcs"
                                            className='m-0 w-[290px]'
                                            valuePropName={tcsId}
                                        >
                                            <Select
                                                allowClear
                                                value={tcsId}
                                                onChange={setTcsId}
                                                className='h-[30px]'
                                                showSearch
                                                optionFilterProp='children'
                                                dropdownRender={menu => (
                                                    <>
                                                        <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
                                                            <Link
                                                                onClick={() => setIsAddTcs(!isAddTcs)}
                                                                className='flex ml-auto text-blue-500'
                                                            >
                                                                Add New TCS Rate
                                                            </Link>
                                                        </div>
                                                        <Divider style={{ margin: '0px' }} />
                                                        {menu}
                                                    </>
                                                )}
                                                placeholder="Select TCS"
                                            >
                                                {tcsRateTaxResponse?.map((tax, i) => (
                                                    <Select.Option key={i} value={tax.id}>
                                                        {tax.tax_rate}% {tax.tax_name} ({tax.section_name})
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                )}
                            </div>
                            {applyTcs && (
                                <div className='flex justify-end'>
                                    <Radio.Group
                                        onChange={handleTcsTotalAmountChange}
                                        value={tcsOn}
                                        className='my-2'
                                    >
                                        <Radio value='total amount'>Total Amount</Radio>
                                        <Radio value='taxable amount'>Taxable Amount</Radio>
                                    </Radio.Group>
                                </div>
                            )}
                        </div>

                        <Divider className='m-0' />
                        <Form.Item
                            name="total_amount"
                            style={{ margin: 0 }}
                        >
                            <div className='flex justify-between font-bold'>
                                <div>Amount</div>
                                <div>{form.getFieldValue('total_amount') ? form.getFieldValue('total_amount') : '0.00'}</div>
                            </div>
                        </Form.Item>
                        <Form.Item
                            name="amountPaid"
                            style={{ margin: 0 }}
                            initialValue={0}
                            rules={[
                                { required: true, message: 'Please input the amount!' },
                                {
                                    validator: async (_, value) => {
                                        const totalAmount = form.getFieldValue('total_amount'); // Get the total amount from the form
                                        if (value && parseFloat(value) > totalAmount) {
                                            return Promise.reject('Paid amount cannot be greater than the total amount');
                                        }
                                        return Promise.resolve();
                                    },
                                },

                                {
                                    validator: (_, value) => {
                                        if (value && !/^\d+(\.\d{1,2})?$/.test(value)) { // Regex for valid decimal number with up to 2 decimal places
                                            return Promise.reject('Please enter a valid decimal number with up to 2 decimal places');
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <div className='flex justify-between font-bold'>
                                <div>Amount Paid</div>
                                <div>
                                    <input
                                        type="text" // Use text to handle custom validation
                                        defaultValue={0}
                                        onChange={(e) => {
                                            const value = e.target.value;

                                            // Regex validation for decimal with up to 2 places
                                            if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
                                                setAmountPaid(value); // Update amountPaid state only if valid
                                            }
                                        }}
                                        className='p-[3px] rounded-lg border w-20 text-right'
                                    />
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item
                            name="round_off"
                            rules={[
                                {
                                    required: true,
                                    message: 'Round Off is required'
                                },
                                {
                                    validator: (_, value) =>
                                        value > 1000
                                            ? Promise.reject('Round off should not be greater than 1000')
                                            : Promise.resolve(),
                                },
                            ]}
                            initialValue={0}
                            style={{ margin: 0 }}
                        >
                            <div className='flex justify-between'>
                                <div>Round Off</div>
                                <input
                                    defaultValue={0}
                                    type="number"
                                    onChange={(e) => {
                                        // setFreightAmount(value.target.value);
                                        // updateTotalValues(items, value.target.value);
                                        setRoundOff(e.target.value)
                                    }}
                                    onInput={(event) => {
                                        // Allow only numbers and a single decimal point
                                        // event.target.value = event.target.value.replace(/[^0-9.]/g, '');

                                        // Ensure only one decimal point is allowed
                                        if ((event.target.value.match(/\./g) || []).length > 1) {
                                            event.target.value = event.target.value.slice(0, -1);
                                        }
                                    }}
                                    maxLength={7}
                                    className='p-[3px] rounded-lg border w-20 text-right'
                                    step="any"
                                />
                            </div>
                        </Form.Item>
                        <Divider className='m-0' />
                        <Form.Item
                            name="balance_amount"
                            style={{ margin: 0 }}
                        >
                            <div className='flex justify-between font-bold'>
                                <div>Balance Amount</div>
                                <div>
                                    {balanceAmount}
                                </div>
                            </div>
                        </Form.Item>
                    </Card>
                </div>
            </Form>
        </div>
    );
}
