import React, { useState, useEffect } from 'react';
import { Input, Button, Modal, DatePicker } from 'antd';
import ExpenseList from './ExpensesList';
import * as XLSX from 'xlsx';
import ExpenseCategoryContained from '../categary/ExpenseCategoryContained';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenses } from './actions';
import AppListHeader from '../../header/AppListHeader';
import { rangePresets } from '../../../settings/codeSettings/additionalMethods';

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function ExpenseDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const expenseDatas = useSelector((state) => state.expenses.getExpenses?.response);
  const [expenseSearchText, setExpenseSearchText] = useState('');
  const [filterQuery, setFilterQuery] = useState({});

  const fetchExpenses = async () => {
    try {
      if (expenseSearchText) {
        await dispatch(getExpenses({ search: expenseSearchText }));
      } else {
        await dispatch(getExpenses(filterQuery));
      }
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, [expenseSearchText, filterQuery]);

  const handleTableChange = (pagination, filters, sorter) => {
    setFilterQuery((prevFilterQuery) => ({
      ...prevFilterQuery,
      ordering: sorter?.order === 'ascend'
        ? sorter?.field
        : sorter?.order === 'descend'
        ? `-${sorter?.field}`
        : null,
    }));
  };

  const handleSearch = (value) => {
    setExpenseSearchText(value);
  };

  const onRangeChange = (dates, dateStrings) => {
    setFilterQuery({
      ...filterQuery,
      start_date: dates ? dateStrings[0] : null,
      end_date: dates ? dateStrings[1] : null,
    });
  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const wsData = expenseDatas?.expenses?.map((e, index) => [
      index + 1,
      e.date,
      e.exp_no,
      e.payment_mode,
      e.amount,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Date', 'Expense Number', 'Payment Mode', 'Amount'],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Expenses');
    XLSX.writeFile(workbook, 'Expenses.xlsx');
  };

  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);

  const onCreateExpenseForm = () => {
    navigate('/expense/expense-form');
  };

  /**
     * To create for header and access key
     */
  const columns = [
    { Header: 'Date', accessor: 'date' },
    { Header: 'Expense No', accessor: 'exp_no' },
    { Header: 'Payment Mode', accessor: 'payment_mode' },
    { Header: 'Amount', accessor: 'total_amount' },
    { Header: 'Description', accessor: 'description' },
];

  var tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
    // sorter: ["total_amount", 'balance_amount', 'date'].includes(column.accessor),
    ellipsis: true,
}));

  return (
    <div className="p-4 md:p-6">
      <AppListHeader
        headerContent="Expense List"
        excel={true}
        print={true}
        handleExcelExport={handleExportToExcel}
        printNavigation='/ListPrint'
        printData={{ tableColunm: tableColumns, tableData: expenseDatas?.expenses, name: 'Expense List' }}
        download={false}
        cancel={true}
      />

      <div className="flex flex-col md:flex-row items-center md:space-x-4 mb-4">
        <Search
          placeholder="Search Expenses"
          allowClear
          onSearch={handleSearch}
          className="flex-grow mb-2 md:mb-0"
        />

        <RangePicker
          presets={rangePresets}
          onChange={onRangeChange}
          className="mb-2 md:mb-0"
        />

        <div className="flex space-x-2">
          <Button className="bg-[#a854f9] text-white" onClick={() => setIsCategoryModalVisible(true)}>
            <b>Create Ex.Category</b>
          </Button>
          <Button className="ml-2 bg-violet-600 text-white" onClick={onCreateExpenseForm}>
            <b>Create Expense</b>
          </Button>
        </div>
      </div>

      {/* Expense list table */}
      <ExpenseList expenseDatas={expenseDatas?.expenses} handleTableChange={handleTableChange} />
      {/* Category Modal */}
      <Modal
        title="Create Expense Category"
        visible={isCategoryModalVisible}
        onCancel={() => setIsCategoryModalVisible(false)}
        footer={null}
      >
        <ExpenseCategoryContained />
      </Modal>
    </div>
  );
}