import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Select, message, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getPendingPurchase, savePaymentOut } from './actions';
import { getParties } from '../../party/actions';
// import {savePaymentOut} from '../../../api/Services'
import PaymentOutTable from './PaymentOutTable'
import { Link, useNavigate } from 'react-router-dom';


const CreatePaymentOut = () => {
    const [form] = Form.useForm();
    const [loadings, setLoadings] = useState([]);
    const [invoiceSettled, setInvoiceSettled] = useState(0);
    const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
    const [pendingPurchases, setPendingPurchases] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [totalRemainingAmount, setTotalRemainingAmount] = useState(0);

    const partyList = useSelector(state => state.parties.getParties.response);
    const partyResponse = useSelector(state => state.parties.getParties.response);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        /**
        * handle party state
        */
        // if (
        //     partyResponse?.party_count == partyResponse?.parties?.results?.length &&
        //     partyResponse?.parties?.results?.length > 0
        // ) {
        // }
        // else {
        //     dispatch(getParties())
        // }
        dispatch(getParties())
        /**
         * end of handling party state
         */
    }, [dispatch]);

    const handleInvoiceSettledChange = (e) => {
        const value = parseFloat(e.target.value).toFixed(2) || 0;
        setInvoiceSettled(value);
        setPaymentAmount(value);
        // setTotalRemainingAmount(value);
    };


    const validateInteger = (_, value) => {
        // if (!value) {
        //     return Promise.resolve();
        // }
        // if (!Number.isInteger(Number(value))) {
        //     return Promise.reject(new Error('Please enter a valid integer'));
        // }
        // return Promise.resolve();
        if (!value) {
            return Promise.resolve();
        }
        
        const numberValue = Number(value);
        
        if (isNaN(numberValue)) {
            return Promise.reject(new Error('Please enter a valid number'));
        }
        
        // Optionally, check for specific types
        if (Number.isInteger(numberValue)) {
            return Promise.resolve('Valid integer');
        } else {
            return Promise.resolve('Valid float');
        }
    };
    const onFinish = async (values) => {
        const filteredInvoices = invoices
            .filter(invoice => invoice.checked)
            .map(invoice => ({
                purchase: invoice.id,
                invoice_amount: invoice.total_amount,
                settled_amount: invoice.settled_amount,
            }));

        values['payment_outs'] = filteredInvoices;

        try {
            const response = await dispatch(savePaymentOut(values));
            if (response?.success) {
                navigate(-1);
            } else {
                message.error(response?.message || 'Error saving payment');
            }
        } catch (error) {
            message.error(error.message || 'Error saving payment');
        }
    };

    const handleGetPurchases = async (id) => {
        let data = {
            billed_to__id: id,
            payment_status: "unpaid_or_partial_paid"
        };
        const response = await dispatch(getPendingPurchase(data));

        if (response?.purchases) {
            setPendingPurchases(response.purchases);
            const transformedInvoices = response?.purchases?.results?.map((invoice) => ({
                id: invoice.id,
                date: invoice.date,
                invoice_no: invoice.purchase_inv_no,
                total_amount: invoice.total_amount,
                balance_amount: invoice.balance_amount,
                settled_amount: 0,
                checked: false
            }));

            setInvoices(transformedInvoices)

        }
    };

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    };

    return (
        <div className="m-2">

            {/* {partyLoading ? 'loading......' : (partyList && Array.isArray(partyList.parties)) ? ( */}
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={{ invoice_settled: invoiceSettled }}
                {...formItemLayout}
            >

                <div className="flex justify-between sticky top-[65px] mb-4 z-10 bg-white">
                    <h2 className="text-2xl font-semibold">New Payment Out</h2>
                    <div className="flex space-x-2">
                        <Button onClick={() => navigate(-1)} type="primary" htmlType="submit" className="bg-purple-500 border-none shadow-inner">
                            <b >Save</b>
                        </Button>
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                            onClick={() => navigate(-1)}
                        >
                            <b>Cancel</b>
                        </Button>
                    </div>
                </div>

                <div className="flex justify-evenly gap-2 mt-2  ">
                <Card title="Party Name" size="small" className="w-full sm:w-[100%]" 

                        style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}>
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
                            <Form.Item
                                name="party"
                                label="Party Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Party Name'
                                    }
                                ]}
                                className='m-0 p-0'
                            >
                                <Select
                                    onChange={handleGetPurchases}
                                    className="shadow-inner"
                                    placeholder='Select Party'
                                    showSearch
                                    optionFilterProp='children'
                                    dropdownRender={menu => (
                                        <>
                                            <div style={{ display: 'flex', padding: '5px', gap: '5px' }}>
                                                <Link
                                                    className='flex ml-auto text-blue-500'
                                                    to='/party-form'
                                                >
                                                    New Party
                                                </Link>
                                            </div>
                                            <Divider style={{ margin: '0px 0' }} />
                                            {menu}
                                        </>
                                    )}
                                >
                                    {partyList?.parties?.results?.map((party, id) => (
                                        <Select.Option
                                            key={id}
                                            value={party.id}>
                                            {party.company_name}, {party.current_balance}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="amount"
                                label="Amount"
                                rules={[
                                    { required: true, message: 'Please enter an invoice settled amount' },
                                    { validator: validateInteger },
                                ]}
                                className='m-0 p-0'
                            >
                                <Input
                                    placeholder='Enter Payment Amount'
                                    className="shadow-inner"
                                    value={invoiceSettled}
                                    onChange={handleInvoiceSettledChange}
                                    maxLength={10}
                                    addonAfter={<span>Remaining: ₹{totalRemainingAmount}</span>}
                                />
                            </Form.Item>
                        </div>
                    </Card>

                    <Card title="Payment Details"  size="small" className="w-full sm:w-[100%]"
                        style={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}>
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-1">
                            <Form.Item
                                name="date"
                                label="Payment Date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Date'
                                    }
                                ]}
                                className='m-0 p-0'
                            >
                                <Input className="h-8 shadow-inner" type="date" />
                            </Form.Item>

                            <Form.Item
                                name="payment_mode"
                                label="Payment Mode"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Payment Mode'
                                    }
                                ]}
                                className='m-0 p-0'
                            >
                                <Select placeholder="Select payment mode" className="shadow-inner" >
                                    <Select.Option value="BANK">BANK</Select.Option>
                                    <Select.Option value="CASH">CASH</Select.Option>
                                    <Select.Option value="CARD">CARD</Select.Option>
                                    <Select.Option value="UPI">UPI</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="remark"
                                label="Description"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please Enter Description'
                                    }
                                ]}
                                className='m-0 p-0'
                            >
                                <Input.TextArea className="h-8 shadow-inner" type='text' />
                            </Form.Item>
                        </div>
                    </Card>
                </div>

            </Form>
            
            {/* ) : 'error'} */}
            {invoices.length > 0 && (
                <PaymentOutTable
                    invoices={invoices}
                    setInvoices={setInvoices}
                    paymentAmount={paymentAmount}
                    setPaymentAmount={setPaymentAmount}
                    totalRemainingAmount={totalRemainingAmount}
                    setTotalRemainingAmount={setTotalRemainingAmount}
                />
            )}
        </div>
    );
}

export default CreatePaymentOut;
