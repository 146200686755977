import React from 'react';
import Contact from './Contact';
import ContactMap from './ContactMap';

const MainContent = () => {
  return (
    <div>
        <Contact></Contact>
        <ContactMap></ContactMap>
    </div>
  );
};

export default MainContent;