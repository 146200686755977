import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Select, message } from 'antd';
import * as XLSX from 'xlsx';
import { getSales } from '../../../sale/saleInvoice/actions';
import { getPurchases } from '../../../purchase/purchaseInvoice/actions';
import AppListHeader from '../../../header/AppListHeader';
import GstList from './GstR3List'; 
import { rangePresets } from '../../../../settings/codeSettings/additionalMethods';
import { getParties } from '../../../party/actions';
import { getLedgers } from '../../../party/ledger/actions';

const { RangePicker } = DatePicker;

export default function GstR3Report() {
  const dispatch = useDispatch();

  const gstR1ReportResponse = useSelector(state => state.sale.getSales.response); // Sales data
  const gstR2ReportResponse = useSelector(state => state.purchase.getPurchases.response); // Purchase data

  const ledgersResponse = useSelector(state => state.ledgers.getLedgers.response)
  const partyResponse = useSelector((state) => state.parties.getParties.response);

  const [filterQuery, setFilterQuery] = useState({voucher:'sale_and_purchase'});
  const [combinedData, setCombinedData] = useState([]);
  const [salesDetail, setSalesDetail] = useState({});
  const [purchaseDetail, setPurchaseDetail] = useState({});
  const [searchText, setSearchText] = useState('');

  // Fetch both sales and purchase data on load and when search text or filter query changes
  useEffect(() => {
    getLedgerData()


    getSalesData();
    getPurchaseData();
    /**
     * handle party state
     */
    if (
      partyResponse?.party_count == partyResponse?.parties?.results?.length &&
      partyResponse?.parties?.results?.length > 0
    ) {

    }
    else {
      dispatch(getParties())
    }
    /**
     * end of handling party state
     */
  }, [dispatch, searchText, filterQuery]);

  const getLedgerData = async () => {
    // const partyID = companyDetail?.id
    const response = await dispatch(getLedgers(filterQuery))
  }

  const getSalesData = async () => {
    const response = await dispatch(getSales(filterQuery));
    if (response) {
      setSalesDetail({
        totalSales: response.total_sales,
        totalPaidAmount: response.total_paid_amount,
        totalUnPaidAmount: response.total_unpaid_amount,
      });
    }
  };

  const getPurchaseData = async () => {
    const response = await dispatch(getPurchases(filterQuery));
    if (response) {
      setPurchaseDetail({
        totalPurchases: response.total_purchases,
        totalPaidAmount: response.total_paid_amount,
        totalUnPaidAmount: response.total_unpaid_amount,
      });
    }
  };

  // Combine sales and purchase data for rendering in a unified table
  useEffect(() => {
    if (gstR1ReportResponse?.sales?.results && gstR2ReportResponse?.purchases?.results) {
      const combined = [
        ...gstR1ReportResponse?.sales?.results?.map((sale) => ({ ...sale, type: 'Sale' })),
        ...gstR2ReportResponse?.purchases?.results?.map((purchase) => ({ ...purchase, type: 'Purchase' })),
      ];
      setCombinedData(combined);
    }
  }, [gstR1ReportResponse, gstR2ReportResponse]);

  // Export combined data to Excel
  const handleExportToExcel = useCallback(() => {
    if (!combinedData?.length) {
      message.warning('No data available to export');
      return;
    }

    const workbook = XLSX.utils.book_new();
    const wsData = combinedData.map((item) => [
      item.type === 'Sale' ? item.sale_inv_no : item.purchase_inv_no,
      item.date,
      item.partyName,
      item.taxableValue,
      item.cgst,
      item.sgst,
      item.igst,
      item.gstTax,
      item.type,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Invoice Number', 'Date', 'Party Name', 'Taxable Value', 'CGST', 'SGST', 'IGST', 'Total Tax', 'Type'],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'GstR3Report');
    XLSX.writeFile(workbook, 'GstR3Report.xlsx');
  }, [combinedData]);

  // Handle Party selection
  const handlePartyChange = (partyId) => {
    setFilterQuery((prevQuery) => ({ ...prevQuery, party: partyId || undefined }));
  };

  // Handle GST Type selection
  const handleGstTypeChange = (gstType) => {
    setFilterQuery((prevQuery) => ({ ...prevQuery, gst_type: gstType || undefined }));
  };

  // Handle Date Range selection
  const handleRangeChange = (dates, dateStrings) => {
    setFilterQuery((prevQuery) => ({
      ...prevQuery,
      date: dates ? dateStrings[0] : undefined,
      date_lte: dates ? dateStrings[1] : undefined,
    }));
  };

  // Handle table changes (pagination, sorting, filtering)
  const handleTableChange = async (pagination, filters, sorter) => {
    setFilterQuery({
      ...filterQuery,
      ordering: sorter?.order === 'ascend' ? sorter?.field : sorter?.order === 'descend' ? `-${sorter?.field}` : null,
    });
  };

  return (
    <div>
      <AppListHeader
        headerContent="GST R3 Report"
        excel
        handleExcelExport={handleExportToExcel}
        print
        cancel
      />

      <div className="flex flex-wrap mb-4 shadow-md p-1">
        {/* Party Selector */}
        <Select
          placeholder="Select Party"
          className="w-[250px] m-1"
          onChange={handlePartyChange}
          allowClear
          value={filterQuery.party || undefined}
        >
          {partyResponse?.parties?.results?.map((party) => (
            <Select.Option key={party.id} value={party.id}>
              {party.company_name}
            </Select.Option>
          ))}
        </Select>

        {/* GST Type Selector */}
        <Select
          placeholder="Select GST Type"
          className="w-[250px] m-1"
          onChange={handleGstTypeChange}
          allowClear
          value={filterQuery.gst_type || undefined}
        >
          <Select.Option value="cgst">CGST</Select.Option>
          <Select.Option value="sgst">SGST</Select.Option>
          <Select.Option value="igst">IGST</Select.Option>
        </Select>

        {/* Date Range Picker */}
        <RangePicker
          className="w-[250px] m-1"
          presets={rangePresets}
          onChange={handleRangeChange}
        />
      </div>

      {console.log(combinedData)}

      {/* GST List Table (combining sales and purchase data) */}
      <GstList
        gstR3Report={ledgersResponse?.ledgers}
        handleTableChange={handleTableChange} // Passing handleTableChange function
      />
    </div>
  );
}
