import React, { useEffect } from 'react'
import { Button, Form, Input, message } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { saveClient } from './actions'

export default function Registration() {

    const [form] = Form.useForm()

    const location = useLocation()

    const navigate = useNavigate()

    const dispatch = useDispatch();

    const clientLoading = useSelector(state => state.clients.loading);
    const clientError = useSelector(state => state.clients.error);
    const clientResponse = useSelector(state => state.clients.response);

    useEffect(() => {
        if (!location.state) {
            navigate('/')
        } else {
            form.setFieldsValue({ email: location?.state?.email })
        }
    }, [])

    const handleRegister = async (values) => {
        // const response = await saveClientData(values)

        const response = await dispatch(saveClient(values))

        if (response?.success) {
            navigate('/login')
        } else if (response?.error) {
            if (response?.error){
                message.error(response?.error)
            }
        } else {
            message.error("Something Went Wrong")
        }

    }

    const validateConfirmPassword = (_, value) => {
        const { getFieldValue } = form;
        if (value && value !== getFieldValue('password')) {
            return Promise.reject('The two passwords that you entered do not match!');
        }
        return Promise.resolve();
    }

    return (
        <div className='w-[100%]'>

            <div
                className='
                text-[18px] font-bold text-[#000080]
                '
            >
                Continue Registration
            </div>
            <br />

            <Form
                form={form}
                onFinish={handleRegister}
            >
                <Form.Item
                    name="company_name"
                    rules={
                        [
                            {
                                required: true,
                                message: "Please Enter Company Name"
                            }
                        ]
                    }
                >
                    <Input
                        placeholder='Enter Company Name'
                        maxLength={50}
                    />
                </Form.Item>
                <Form.Item
                    name="owner_name"
                    rules={
                        [
                            {
                                required: true,
                                message: "Please Enter Your Name"
                            }
                        ]
                    }
                >
                    <Input
                        placeholder='Enter Your Name'
                        maxLength={50}
                    />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={
                        [
                            {
                                required: true,
                                message: "Please Enter Email"
                            },
                            {
                                type: 'email',
                                message: "Please Enter Valid Email"
                            }
                        ]
                    }
                >
                    <Input
                        placeholder='Enter Email ID'
                        maxLength={50}
                        disabled={true}
                    />
                </Form.Item>
                <Form.Item
                    name="mobile_number"
                    rules={
                        [
                            {
                                required: true,
                                message: "Please Enter Mobile Number"
                            },
                            // {
                            //     type: "number",
                            //     message: "Please Enter Valid Mobile Number Format"
                            // }
                        ]
                    }
                >
                    <Input
                        type='number'
                        placeholder='Enter Mobile Number'
                        maxLength={15}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={
                        [
                            {
                                required: true,
                                message: "Please Enter Password"
                            }
                        ]
                    }
                >
                    <Input.Password
                        type='number'
                        placeholder='Enter Password'
                        maxLength={50}
                    />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: "Please Confirm Your Password"
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match!');
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        placeholder='Enter Confirm Password'
                        maxLength={15}
                    />
                </Form.Item>
                <Button
                    className='
                    rounded-2xl shadow-lg bg-[#000080] text-[white] px-10 h-[35px]
                    '
                    htmlType='submit'
                    disabled={clientLoading}
                >
                    <b>{clientLoading ? "Loading..." : "Submit"}</b>
                </Button>
            </Form>
        </div>
    )
}

