import React from 'react';
import PurchaseDashboardTable from './PurchaseDashboardTable';
import { useDispatch } from 'react-redux';
import { deletePurchase } from './actions';
import { useNavigate } from 'react-router-dom';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';

export default function PurchaseList({ purchase, handleTableChange }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Column definitions
  const columns = [
    { Header: 'Date', accessor: 'date' },
    { Header: 'Purchase Invoice Number', accessor: 'purchase_inv_no' },
    { Header: 'Party Name', accessor: 'billed_to_company_name' },
    { Header: 'Amount', accessor: 'total_amount' },
    { Header: 'Balance Amount', accessor: 'balance_amount' },
    { Header: 'Status', accessor: 'payment_status' },
  ];

  // Navigation handler
  const handleView = (record) => {
    navigate("/invoice2/print-dashboard", { state: { record } });
  };

  // Delete handler
  const handleDelete = async (data) => {
    if (data?.id) {
      await dispatch(deletePurchase(data?.id));
    } else {
      message.error("Please Select Data");
    }
  };

  // Transform columns for Ant Design Table
  const tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
    sorter: ["total_amount", 'purchase_inv_no', 'balance_amount', 'date'].includes(column.accessor),
    // ellipsis: true,
  }));

  // Add action column
  tableColumns.push({
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <span className="flex space-x-2">
        <Tooltip title="View" color="black">
          <button className="p-1 mx-0.5 bg-black rounded" onClick={() => handleView(record)}>
            {/* <EyeInvisibleOutlined className="text-white p-0 m-0" /> */}
            <EyeOutlined className="text-white p-0 m-0"/>
          </button>
        </Tooltip>
        
        <Popconfirm
          placement="topRight"
          title='object delete'
          description='Are you sure want to delete'
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDelete(record)}
        >
          <Button disabled={record?.payment_status === 'paid' ? true : false} className="p-1 mx-0.5 bg-red-500 rounded">
            <DeleteOutlined className="text-black p-0 m-0" />
          </Button>
        </Popconfirm>
      </span>
    ),
  });

  return (
    <div className="overflow-x-auto mt-4">
      <PurchaseDashboardTable
        columns={columns}
        data={purchase}
        tableColumns={tableColumns}
        handleTableChange={handleTableChange}
      />
    </div>
  );
}
