import React from "react";

const baseURL = "https://billzap.in/"
// const baseURL = ""

const config = {
    frontEndUrl: "https://billzap.in/",
    apiBaseURL : `${baseURL}/api`,
    staticBaseURL : `${baseURL}`,
    apiTimeout: 500000
}
export default config;