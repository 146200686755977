import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './UnAuthHeader.css';
import logo from '../../images/BillZap_logo.png';
import { Link, useNavigate } from 'react-router-dom';

function UnAuthHeader() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuOpen(prev => !prev);
    };

    const handleScroll = () => {
        setIsScrolled(window.scrollY > 50);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const closeMenu = () => {
        setMenuOpen(false); 
    };

    // Check for lastSection on mount
    useEffect(() => {
        const lastSection = localStorage.getItem('lastSection');
        if (lastSection) {
            window.scrollTo({
                top: document.getElementById(lastSection).offsetTop,
                behavior: 'smooth',
            });
            localStorage.removeItem('lastSection'); 
        }
    }, []);

    // Helper function to handle click
    const handleClick = (type, target) => {
        closeMenu();
        if (type === 'navigate') {
            navigate(target);
        } else if (type === 'scroll') {
            const targetElement = document.getElementById(target);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            } else {
                console.warn(`Element with ID '${target}' not found.`);
            }
        }
    };
    

    return (
        <div className={`UnAuthHeaderContainer ${isScrolled ? 'scrolled' : ''}`}>
            <div className='logoImageContainer'>
                <img
                    className="logoImage w-full h-full object-cover mt-4 sm:ml-6"
                    style={{ borderRadius: '40%' }}
                    src={logo}
                    alt="Logo"
                />
            </div>

            <div className='menu-icon' onClick={toggleMenu}>
                {isMenuOpen ? (
                    <div className='cross'>
                        <div className='bar active'></div>
                        <div className='bar active'></div>
                    </div>
                ) : (
                    <div className='hamburger'>
                        <div className='bar'></div>
                        <div className='bar'></div>
                        <div className='bar'></div>
                    </div>
                )}
            </div>

            <ul className={`HeaderList ${isMenuOpen ? 'open' : ''}`}>
                <li onClick={() => handleClick('navigate', '/')}><span className='Span'>H</span>ome</li>
                <li onClick={() => handleClick('navigate', '/about')}><span className='Span'>A</span>bout</li>
                <li onClick={() => handleClick('navigate', '/feature')}><span className='Span'>F</span>eatures</li>
                <li onClick={() => handleClick('navigate', '/Princing')}><span className='Span'>P</span>ricing</li>

                <li onClick={() => handleClick('navigate', '/Contact')}><span className='Span'>C</span>ontact</li>
                <li onClick={() => handleClick('navigate', '/login' ,{ scrollTo: '' })}><span className='Span'>L</span>ogin</li>
                {/* <li onClick={() => handleClick('navigate', '/login', { scrollTo: 'contact' })}>
    <span className='Span'>L</span>ogin
</li> */}

                <li onClick={() => handleClick('navigate', '/email-verify')}><span className='Span'>R</span>egistration</li>
            </ul>
        </div>
    );
}

export default UnAuthHeader;
