import React, { useEffect } from 'react'
import About from './About'
import Contact from './Contact'
import ContactMap from './ContactMap'
import Feature from './Feature'
import Hero from './Hero'
import AOS from 'aos'
import Aos from 'aos'
import 'aos/dist/aos.css'
import 'animate.css/animate.min.css'
import Pricing from './Pricing'

export default function LandingPage() {

  useEffect(() => {
    Aos.init({
      duration: 500,
      easing: 'ease-in-out',
    })
  })

  return (
    <div className='drop-shadow-lg'>

      <Hero />

      <About />

      <Feature />
      <Pricing />
      <Contact />

      <ContactMap />

      <div className='mb-20'></div>

    </div>
  )
}
