// reducer.js
import {
    SAVE_PURCHASE_REQUEST,
    SAVE_PURCHASE_SUCCESS,
    SAVE_PURCHASE_FAILURE,

    GET_PURCHASES_REQUEST,
    GET_PURCHASES_SUCCESS,
    GET_PURCHASES_FAILURE,

    DELETE_PURCHASE_FAILURE,
    DELETE_PURCHASE_SUCCESS,
    DELETE_PURCHASE_REQUEST

} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    savePurchase: {
        loading: false,
        error: null,
        response: null,
    },

    getPurchases: {
        loading: false,
        error: null,
        response: null,
    },

    deletePurchase: {
        loading: false,
        error: null,
        response: null,
    }

};

const purchaseReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PURCHASE_REQUEST:
            return {
                ...state,
                savePurchase: {
                    ...state.savePurchase,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_PURCHASE_SUCCESS:
            return {
                ...state,
                savePurchase: {
                    ...state.savePurchase,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case SAVE_PURCHASE_FAILURE:
            return {
                ...state,
                savePurchase: {
                    ...state.savePurchase,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };


        // get the purchases 
        case GET_PURCHASES_REQUEST:
            return {
                ...state,
                getPurchases: {
                    ...state.getPurchases,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_PURCHASES_SUCCESS:
            return {
                ...state,
                getPurchases: {
                    ...state.getPurchases,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_PURCHASES_FAILURE:
            return {
                ...state,
                getPurchases: {
                    ...state.getPurchases,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };


        // delete the purchase
        case DELETE_PURCHASE_REQUEST:
            return {
                ...state,
                deletePurchase: {
                    ...state.deletePurchase,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case DELETE_PURCHASE_SUCCESS:
            return {
                ...state,
                deletePurchase: {
                    ...state.deletePurchase,
                    loading: false,
                    error: null,
                    response: action.payload
                },
            };
        case DELETE_PURCHASE_FAILURE:
            return {
                ...state,
                deletePurchase: {
                    ...state.deletePurchase,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default purchaseReducer;
