import React, { useEffect, useState } from 'react'
import PurchaseList from '../../purchase/purchaseInvoice/PurchaseList';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPurchases } from '../../purchase/purchaseInvoice/actions';
import AppListHeader from '../../header/AppListHeader';
import { Input } from 'antd';
import { handleSearchText } from '../../../settings/codeSettings/additionalMethods';
import * as XLSX from 'xlsx';

const { Search } = Input;

export default function PurchaseReport() {

    const dispatch = useDispatch();
    const purchaseDatas = useSelector(state => state.purchase.getPurchases.response);
    const deletePurchaseResponse = useSelector(state => state.purchase.deletePurchase.response);

    const [purchaseSearchText, setPurchaseSearchText] = useState('')   // state for set the searched value

    const [filterQuery, setFilterQuery] = useState(null)                                                           // state for filter query
    const [purchasesDetail, setPurchasesDetail] = useState({
        totalPurchase: 0,
        totalPaidAmount: 0,
        totalUnPaidAmount: 0
    });

    const navigate = useNavigate();

    useEffect(() => {
        getPurchasesData();
    }, [dispatch, purchaseSearchText, filterQuery, deletePurchaseResponse]);

    const getPurchasesData = async () => {

        if (purchaseSearchText) {
            await dispatch(getPurchases({ search: purchaseSearchText }));
        }

        else if (filterQuery) {
            await dispatch(getPurchases(filterQuery));
        }

        else {
            const response = await dispatch(getPurchases());

            // if (response) {
            //     setPurchasesDetail({
            //         ...purchasesDetail,
            //         totalPurchase: response.total_purchases,
            //         totalPaidAmount: response.total_paid_amount,
            //         totalUnPaidAmount: response.total_unpaid_amount,
            //     });
            // }
        }
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order == 'ascend' ?
                `${sorter?.field}`
                :
                sorter?.order == 'descend' ?
                    `-${sorter?.field}`
                    :
                    null,
        })
    }
    const columns = [
        { title: 'Date', dataIndex: 'date' },
        { title: 'Purchase Invoice Number', dataIndex: 'purchase_inv_no' },
        { title: 'Party Name', dataIndex: 'billed_to_company_name' },
        // { title: 'Due In', dataIndex: 'dueIn' },
        { title: 'Amount', dataIndex: 'total_amount' },
        { title: 'Balance Amount', dataIndex: 'balance_amount' },
        { title: 'Status', dataIndex: 'payment_status' },
        // { title: 'Current Balance', dataIndex: 'current_balance' },
    ];


    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Assuming purchaseDatas is the list you want to export
        const wsData = purchaseDatas?.purchases?.results.map((e, index) => [
            index + 1,
            e.date, // Replace with correct date format if necessary
            e.purchase_inv_no,
            e.billed_to,
            e.total_amount,
            e.balance_amount,
            e.payment_status,
        ]);

        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'S.No.',
                'Date',
                'Purchase Invoice Number',
                'Party Name',
                'Amount',
                'Balance Amount',
                'Status',

            ],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Purchases');
        XLSX.writeFile(workbook, 'Purchases.xlsx');
    };

    return (
        <div>

            <AppListHeader
                headerContent='Purchase Report'
                excel={true}
                print={true}
                download={false}
                cancel={true}
                handleExcelExport={handleExportToExcel}
                printNavigation='/ListPrint'
                printData={{ tableColunm: columns, tableData: purchaseDatas?.purchases?.results, name: 'Purchase Report List' }}
            />

            <div
                className="
                flex flex-wrap mb-4 shadow-md p-1
                "
            >
                <Search
                    placeholder="Search here"
                    allowClear
                    onSearch={(value) => handleSearchText(value, setPurchaseSearchText)}
                    className='w-[250px] m-1'
                />
            </div>

            <PurchaseList
                purchase={purchaseDatas?.purchases?.results}
                handleTableChange={handleTableChange}
            />
        </div>
    )
}
