import {
    SAVE_EXPENSE_REQUEST, // Updated action types
    SAVE_EXPENSE_SUCCESS,
    SAVE_EXPENSE_FAILURE,

    GET_EXPENSES_REQUEST,
    GET_EXPENSES_SUCCESS,
    GET_EXPENSES_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,
    
    saveExpenses: { // Updated state variable
        loading: false,
        error: null,
        response: null,
    },

    getExpenses: { // Updated state variable
        loading: false,
        error: null,
        response: null,
    }
};

const expenseReducer = (state = initialState, action) => { // Updated reducer function name
    switch (action.type) {
        case SAVE_EXPENSE_REQUEST: // Updated action type
            return {
                ...state,
                saveExpenses: {
                    ...state.saveExpenses,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_EXPENSE_SUCCESS: // Updated action type
            return {
                ...state,
                saveExpenses: {
                    ...state.saveExpenses,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case SAVE_EXPENSE_FAILURE: // Updated action type
            return {
                ...state,
                saveExpenses: {
                    ...state.saveExpenses,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        
        case GET_EXPENSES_REQUEST: // Updated action type
        return {
            ...state,
            getExpenses: {
                ...state.getExpenses,
                loading: true,
                error: null,
                response: null
            }
        };
        case GET_EXPENSES_SUCCESS: // Updated action type
            return {
                ...state,
                getExpenses: {
                    ...state.getExpenses,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_EXPENSES_FAILURE: // Updated action type
            return {
                ...state,
                getExpenses: {
                    ...state.getExpenses,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default expenseReducer; // Updated export name
