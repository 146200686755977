
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';


export default function PaymentList({ paymentInDatas, handleTableChange }) {

    /**
     * To create for header and access key
     */
    const columns = [
        { Header: 'Date', accessor: 'date' },
        { Header: 'Party Name', accessor: 'party_company_name' },
        { Header: 'Payment Mode', accessor: 'payment_mode' },
        { Header: 'Amount', accessor: 'amount' },
    ];

    const navigate = useNavigate();

    // Function to handle navigation
    const handleView = (record) => {
    };

    // Convert columns to the format expected by Ant Design Table
    const tableColumns = columns.map((column) => ({
        title: column.Header,
        dataIndex: column.accessor,
        key: column.accessor,
        sorter: ['date', 'payemnt_mode', 'amount'].includes(column.accessor),
        ellipsis: true,
    }));

    // Add an action column
    tableColumns.push({
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <span className="flex space-x-2">
                <Tooltip title="View" color="black">
                    <button className="p-1 mx-0.5 bg-black rounded" onClick={() => handleView(record)}>
                        <EyeOutlined className="text-white p-0 m-0" />
                    </button>
                </Tooltip>
                <Tooltip title="Edit" color="blue">
                    <button className="p-1 mx-0.5 bg-blue-500 rounded">
                        <EditOutlined className="text-white p-0 m-0" />
                    </button>
                </Tooltip>
                {/* <Tooltip title="Delete" color="red">
          <button className="p-1 mx-0.5 bg-red-500 rounded">
            <DeleteOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip> */}
            </span>
        ),
    });

    return (
        <div className="overflow-scroll scrollbar-thin scrollbar-thumb-red-500 scrollbar-track-orange-200 mt-4">
            <Table
                size='small'
                // indentSize={100}
                columns={tableColumns}
                dataSource={paymentInDatas}
                style={{ fontSize: 'xx-large' }}
                bordered
                pagination={false}
                onChange={handleTableChange}
                rowKey={(record, index) => index} // Assuming data array is not uniquely keyed
            // onRow={(record) => ({
            //   onClick: () => handleView(record),
            // })}
            />
        </div>
    )
}