import React, { useEffect } from 'react';
import { Card, Table } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardData } from './actions';

const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector(state => state.dashboard.getDashboardData.response);

  useEffect(() => {
    if (!dashboardData) {
      dispatch(getDashboardData());
    }
  }, [dispatch, dashboardData]);

  const columns = [
    { Header: 'Party Name', accessor: 'company_name' },
    { Header: 'Voucher', accessor: 'voucher' },
    { Header: 'Amount', accessor: 'amount' }
  ];
  
  const StockColumn = [
    { Header: 'Product', accessor: 'item_name' },
    { Header: 'Quantity', accessor: 'quantity' },
  ];

  const stockTableColumns = StockColumn.map(column => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor
  }));

  const stockrecentActions = dashboardData?.top_five_low_stock_items?.map(data => ({
    item_name: data?.item_name ? data?.item_name : '-', 
    quantity: data?.current_qty, 
  }));

  const tableColumns = columns.map(column => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor
  }));

  const recentActions = dashboardData?.ledgers?.map(data => ({
    company_name: data?.company_name || '-',
    amount: data?.amount,
    voucher: data?.sale ? 'Sale' : data?.purchase ? 'Purchase' : data?.payment_in ? 'Payment In' : data?.expense ? 'Expense' : 'Payment Out'
  }));

  return (
    <div className="bg-lavender min-h-screen">
<div className="flex flex-wrap justify-between rounded-xl gap-4 border border-white w-full h-1/6 bg-white p-2">
  <Card size='small' className="w-[45%] sm:w-1/3 md:w-1/5 lg:w-1/6 p-1 bg-lavender shadow-md border-[orange] shadow-[orange]">
    <p className='text-[17px] text-[orange]'><b>{dashboardData?.total_sale}</b></p>
    <p className='text-[15px]'>Total Sales</p>
  </Card>
  <Card size='small' className="w-[45%] sm:w-1/3 md:w-1/5 lg:w-1/6 p-1 bg-lavender shadow-md border border-[#fa638a] shadow-[#fa638a]">
    <p className='text-[17px] text-[#fa638a]'><b>{dashboardData?.total_purchase}</b></p>
    <p className='text-[15px]'>Total Purchase</p>
  </Card>
  <Card size='small' className="w-[45%] sm:w-1/3 md:w-1/5 lg:w-1/6 p-1 bg-lavender shadow-md border border-[red] shadow-[red]">
    <p className='text-[17px] text-[red]'><b>{dashboardData?.total_payment_out}</b></p>
    <p className='text-[15px]'>Payment Sent</p>
  </Card>
  <Card size='small' className="w-[45%] sm:w-1/3 md:w-1/5 lg:w-1/6 p-1 bg-lavender shadow-md border border-[green] shadow-[green]">
    <p className='text-[17px] text-[green]'><b>{dashboardData?.total_payment_in}</b></p>
    <p className='text-[15px]'>Payment Received</p>
  </Card>
  <Card size='small' className="w-[45%] sm:w-1/3 md:w-1/5 lg:w-1/6 p-1 bg-lavender shadow-md border border-[blue] shadow-[blue]">
    <p className='text-[17px] text-[blue]'><b>{dashboardData?.total_cash_and_bank_balance}</b></p>
    <p className='text-[15px]'>Bank+Cash</p>
  </Card>
</div>





      <div className="flex flex-col md:flex-row justify-between gap-4 p-4">
        <div className="flex-1 bg-white">
          <h3 className='mb-2'><b>Sales and Purchase</b></h3>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              width={1000}
              height={200}
              data={dashboardData?.monthly_data || []}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="sale" fill="#8884d8" name="Sale" />
              <Bar dataKey="purchase" fill="#82ca9d" name="Purchase" />
              <Bar dataKey="expense" fill="#ffc658" name="Expense" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4">
        <Card className="flex-1 bg-lavender">
          <strong><p>Recent Invoice</p></strong>
          <Table size='small' columns={tableColumns} dataSource={recentActions || []} pagination={false} />
        </Card>
        <Card className="w-full md:w-1/3 p-4 bg-white">
         <strong> <h3>Top 5 Lowest Stock</h3></strong>
          <Table
            size='small'
            columns={stockTableColumns}
            dataSource={stockrecentActions || []} 
            pagination={false}
          />
        </Card>
      </div>

      <div className="p-4 flex flex-col md:flex-row gap-4">
        <div className="flex-1 bg-white">
          <h3 className='mb-2'><b>Payment Sent and Received</b></h3>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              width={1000}
              height={200}
              data={dashboardData?.monthly_payment_data || []}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="payment_sent" fill="#8884d8" name="Payment Sent" />
              <Bar dataKey="payment_received" fill="#82ca9d" name="Payment Received" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
