import React, { useEffect, useState } from 'react';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Dropdown, Button, Menu, Modal, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
// import ItemList from './ItemList';
import { useDispatch, useSelector } from 'react-redux';
// import { getItems } from './actions';
import ItemList from '../../product/item/ItemList';
import { getItems } from '../../product/item/actions';
import AppListHeader from '../../header/AppListHeader';
import { handleSearchText } from '../../../settings/codeSettings/additionalMethods';

const { Search } = Input;

export default function ItemWiseReport() {

    const dispatch = useDispatch();
    const itemsResponse = useSelector(state => state.items.getItems.response);

    const [itemSearchText, setItemSearchText] = useState('')
    const [filterQuery, setFilterQuery] = useState(null)   // state for filter query
    const [paymentOutDetail, setPaymentOutDetail] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        if (
            itemsResponse?.item_count == itemsResponse?.items?.results?.length &&
            itemsResponse?.items?.results?.length > 0
        ) {

        }
        else {
            getItemsData()
        }
    }, [dispatch, itemSearchText, filterQuery]);


    /**
     * api call for get the items data
     */
    const getItemsData = async () => {

        if (itemSearchText) {
            await dispatch(getItems({ search: itemSearchText }));
        }

        else if (filterQuery) {
            await dispatch(getItems(filterQuery));
        }

        else {
            const response = await dispatch(getItems());
            if (response) {
                setPaymentOutDetail({
                    ...paymentOutDetail,
                    partiesCount: response.party_count,
                    toCollect: response.total_to_collect_balance,
                    toPay: response.total_to_pay_balance,
                });
            }
        }
    };

    /**
     * data filteration on table
     */
    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order == 'ascend' ?
                `${sorter?.field}`
                :
                sorter?.order == 'descend' ?
                    `-${sorter?.field}`
                    :
                    null,
        })
    }


    /**
     * navigate item report
     */
    const handleNavigateItemReport = (item) => {
        navigate('/item-wise-report/invoices', { state: { item: item } });
    };


    /**
     * define columns to show the data on table
     */
    const columns = [
        { Header: 'Item Name', accessor: 'item_name' },
        { Header: 'Model No.', accessor: 'model_no' },
        { Header: 'Category', accessor: 'category_name' },
        // { Header: 'Brand', accessor: 'brand' },
        // { Header: 'GST', accessor: 'gst_percentage' },
        { Header: 'Qty', accessor: 'current_qty' },
        { Header: 'Unit', accessor: 'unit_name' },
        // { Header: 'Rate', accessor: 'current_rate' },
    ];

    const tableColumns = columns.map((column) => ({
        title: column.Header,
        dataIndex: column.accessor,
        key: column.accessor,
        sorter: ['item_name', 'brand', 'model_no'].includes(column.accessor)
    }));

    tableColumns.push({
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <span className="flex space-x-2">
                <Tooltip title="View" color="black">
                    <button className="mx-0.5 rounded" onClick={() => handleNavigateItemReport(record)}>
                        <Button>Detail</Button>
                    </button>
                </Tooltip>
            </span>
        ),
    });
    /**
     * End of define and modify the columns
     */


    return (
        <div>
            <AppListHeader
                headerContent='Item Report'
                excel={false}
                print={false}
                download={false}
                cancel={true}
            />
            {/* <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 justify-between">
                <h2 className="text-2xl font-semibold">Item Report</h2>
                <Search
                    placeholder="Search by item name, brand, model no and hsn code"
                    allowClear
                    onSearch={(e) => { handleSearchText(e, setItemSearchText); }}
                    className="w-full md:w-1/2 lg:w-1/3 mr-0"
                />
            </div> */}
            <Search
                placeholder="Search by item name, brand, model no and hsn code"
                allowClear
                onSearch={(e) => { handleSearchText(e, setItemSearchText); }}
                className="w-full md:w-1/2 lg:w-1/3 mr-0"
            />
            <ItemList
                columns={columns}
                tableColumns={tableColumns}
                items={itemsResponse?.items?.results}
                handleTableChange={handleTableChange}
            />
        </div>
    );
}
