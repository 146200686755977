import React, { useEffect,useState } from 'react'
import UnitForm from './UnitForm'
import UnitList from './UnitList'
import { Button } from 'antd'
// import { useDispatch, useSelector } from 'react-redux'
// import { getCategories } from './actions';


function UnitContained() {

    const [NewUnit, setNewUnit] = useState(false)

    const handleUnitOpen = () => {
        setNewUnit(true)
    }
    return (

        <div className='flex flex-col'>
            <div className=''>
                {NewUnit ?
                    <UnitForm setNewUnit={setNewUnit}/>
                    :
                    <Button
                        className='float-right mr-5 my-2 text-white bg-[#a854f9]'
                        onClick={handleUnitOpen}>
                        <b>Create Unit</b>
                    </Button>
                }
            </div>
            <UnitList />

        </div>

    )
}

export default UnitContained