// reducer.js
import {
    GET_ITEM_WISE_REPORT_REQUEST,
    GET_ITEM_WISE_REPORT_SUCCESS,
    GET_ITEM_WISE_REPORT_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    getItemWiseReport: {
        loading: false,
        error: null,
        response: null,
    }
};

const itemWiseReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ITEM_WISE_REPORT_REQUEST:
        return {
            ...state,
            getItemWiseReport: {
                ...state.getItemWiseReport,
                loading: true,
                error: null,
                response: null
            }
        };
        case GET_ITEM_WISE_REPORT_SUCCESS:
            return {
                ...state,
                getItemWiseReport: {
                    ...state.getItemWiseReport,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_ITEM_WISE_REPORT_FAILURE:
            return {
                ...state,
                getItemWiseReport: {
                    ...state.getItemWiseReport,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default itemWiseReportReducer;
