import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeliveryChallanList from '../../sale/deliveryChallan/DeliveryChalanList';
import { getDeliveryChallans } from '../../sale/deliveryChallan/actions';
import { Input } from 'antd';
import { handleSearchText } from '../../../settings/codeSettings/additionalMethods';
import * as XLSX from 'xlsx';
import AppListHeader from '../../header/AppListHeader';

const { Search } = Input;

export default function DeliveryChallanReport() {

    const dispatch = useDispatch();
    const DeliveryChalanDatas = useSelector(state => state.deliveryChallans.getDeliveryChallans.response)

    const [searchText, setSearchText] = useState('')
    const [filterQuery, setFilterQuery] = useState(null)

    useEffect(() => {
        getDeliveryChallanData()
    }, [dispatch, searchText])

    const getDeliveryChallanData = async () => {
        if (searchText) {
            await dispatch(getDeliveryChallans({ search: searchText }));
        }
        else if (filterQuery) {
            await dispatch(getDeliveryChallans(filterQuery));
        }
        else {
            const response = await dispatch(getDeliveryChallans());
        }
    };

    const navigate = useNavigate();

    const handlePrint = () => {
        console.log(DeliveryChalanDatas)
        navigate('/delivery-challan-bill/', { state: { DeliveryChalanDatas } });
    };

    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Assuming DeliveryChalanDatas is the list you want to export
        const wsData = DeliveryChalanDatas?.delivery_challans?.results?.map((e) => [
            e.date,
            e.delivery_challan_no,
            e.billed_to_company_name,
            e.total_amount,
        ]) || [];

        const worksheet = XLSX.utils.aoa_to_sheet([
            ['Date', 'Delivery Challan Number', 'Party Name', 'Total Amount'],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Delivery Challan');
        XLSX.writeFile(workbook, 'deliveryChallanList.xlsx');
    };


    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order == 'ascend' ?
                `${sorter?.field}`
                :
                sorter?.order == 'descend' ?
                    `-${sorter?.field}`
                    :
                    null,
        })
    }

    const columns = [
        { title: 'Date', dataIndex: 'date' },
        { title: 'Delivery Challan Number', dataIndex: 'delivery_challan_no' },
        
        { title: 'Party Name', dataIndex: 'billed_to_company_name' },
        { title: 'Total Amount', dataIndex: 'total_amount' },
        // { title: 'Items', dataIndex: 'purchase_order_items' },
    
       
      ];

    return (
        <div>

            <AppListHeader
                headerContent='Delivery Challan Report'
                excel={true}
                handleExcelExport={handleExportToExcel}
                print={true}
                download={false}
                cancel={true}
                printNavigation='/ListPrint'
                printData={{tableColunm:columns,tableData:DeliveryChalanDatas?.delivery_challans?.results,name:'Delivery Challan List'}}
            />

            <div
                className="
                flex flex-wrap mb-4 shadow-md p-1
                "
            >
                <Search
                    placeholder="Search Delivery Challan"
                    allowClear
                    onSearch={(value) => handleSearchText(value, setSearchText)}
                    className='w-[250px]'
                />
            </div>

            <DeliveryChallanList DeliveryChalanDatas={DeliveryChalanDatas?.delivery_challans?.results} />
        </div>
    )
}