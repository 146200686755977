// actions.js
import {
  GET_ITEM_WISE_REPORT_REQUEST,
  GET_ITEM_WISE_REPORT_SUCCESS,
  GET_ITEM_WISE_REPORT_FAILURE
} from './actionTypes';
import { message } from 'antd';
import { getItemsWiseReportAPI, getLedgersAPI } from '../../../api/Services';


/**
 * Get all ITEM WISE REPORT
 */
export const getItemWiseReportRequest = () => ({
  type: GET_ITEM_WISE_REPORT_REQUEST
});

export const getItemWiseReportSuccess = (response) => ({
  type: GET_ITEM_WISE_REPORT_SUCCESS,
  payload: response
});

export const getItemWiseReportFailure = error => ({
  type: GET_ITEM_WISE_REPORT_FAILURE,
  payload: error
});


/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To get the all Item wise report
 */
export const getItemWiseReport = (data) => async (dispatch) => {
  dispatch(getItemWiseReportRequest());
  try {
    const response = await getItemsWiseReportAPI(data)

    if (response) {
      dispatch(getItemWiseReportSuccess(response))
    }
    return response;

  } catch (error) {
    dispatch(getItemWiseReportFailure(error.message));
    throw error;
  }
};