import React from 'react';
import { Layout, Typography, Table, Button, Card, Alert, Row, Col, Divider } from 'antd';
import { useLocation } from 'react-router-dom';
import logo from '../../../images/logo.png'
import { loggedInUser } from '../../../settings/codeSettings/additionalMethods';
const { Header, Content } = Layout;
const { Title, Paragraph } = Typography;

const ProfessionalInvoice = () => {
  const location = useLocation();
  const invoiceData = location.state?.record;
  if (!invoiceData) {
    return (
      <Layout className="p-6 bg-gray-100 min-h-screen">
        <Content className="p-8 bg-white shadow-lg rounded-lg">
          <Alert message="Error" description="Invoice data is not available?." type="error" showIcon />
        </Content>
      </Layout>
    );
  }

  const columns = [
    { title: 'ITEM', dataIndex: 'item_name', key: 'item_name' },
    { title: 'QTY', dataIndex: 'qty', key: 'qty' },
    { title: 'RATE', dataIndex: 'rate', key: 'rate' },
    { title: 'DISCOUNT', dataIndex: 'discount', key: 'discount' },
    { title: 'GST', dataIndex: 'gst_amount', key: 'gst_amount' },
    { title: 'AMOUNT', dataIndex: 'amount', key: 'amount' },
  ];

  const footer = () => (
    <div className="flex justify-between">
      <div className="w-[70%]">
        <Paragraph strong>SUB TOTAL</Paragraph>
      </div>
      <div className="w-[10%]">
      </div>
      <div className="w-[19%]">
        <Paragraph strong>₹ {invoiceData?.total_amount}</Paragraph>
      </div>
    </div>
  );

  const printInvoice = () => {
    window?.print();
  };

  return (
    <Layout className="p-6 bg-gray-100 min-h-screen">
      <Header className="bg-blue-500 py-1 text-center h-10 shadow-md">
        <Title level={2} className="text-blue-100">Invoice</Title>
      </Header>
      <Content className="p-8 bg-white shadow-lg rounded-lg">
        <div className="invoice-content">
          <Row className='justify-center'>
            {/* <Col span={4} ><img src={logo} style={{ height: '20vh' }} /></Col> */}
            <Col>
              <div style={{ textAlign: 'center' }}>
                <h1 style={{ fontSize: 'xx-large ', fontWeight: 'bold' }}>{loggedInUser?.company_name}</h1>
                <p style={{ fontSize: 'large ' }}>
                  {loggedInUser?.address && loggedInUser?.address + ','}
                  {loggedInUser?.city && loggedInUser?.city + ','}
                  {loggedInUser?.state && loggedInUser?.state + ','}
                  {loggedInUser?.zip_code && loggedInUser?.zip_code}
                  </p>
                <p>Mobile Number: {loggedInUser?.mobile_number}</p>
                <p>Email: {loggedInUser?.email}</p>
                <p style={{ fontSize: 'medium', fontWeight: 'bold' }}>GST Number: {loggedInUser?.gst}</p>
                <p style={{ fontSize: 'large', fontWeight: 'bold' }}>Purchase Invoice</p>
              </div>
            </Col>
          </Row>
          <Divider className='m-2' />
          <Row>
            <Col span={11}>
              <table className="table-auto w-full text-left ">
                <tr><th className='border p-1'>Invoice No:</th> <td className='border p-1'>{invoiceData?.purchase_inv_no}</td></tr>
                <tr><th className='border p-1 '>Original Invoice No:</th> <td className='border p-1' >{invoiceData?.original_inv_no}</td></tr>
                <tr><th className='border p-1'>Dispatch Through:</th> <td className='border p-1' >{invoiceData?.dispatch_through}</td></tr>


              </table>
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <table className="table-auto w-full text-left">
                <tr><th className=' border p-1'>Date:</th> <td className='border p-1' >{invoiceData?.date}</td></tr>
                <tr><th className=' border p-1'>Order No:</th> <td className='border p-1' >{invoiceData?.order_no}</td></tr>
                <tr><th className=' border p-1'>Vehicle No:</th> <td className='border p-1' >{invoiceData?.vehicle_no}</td></tr>

              </table>
            </Col>
          </Row>
          <Divider className='m-3' />

          <div className=" mb-6">
            <Row>

              <Col span={11}>
                <Card title="Billed From:" size="small" className="w-full  ">
                  <p
                    className='text-[16px] font-semibold mb-1'
                  >
                    {invoiceData?.billed_to_company_name}
                  </p>
                  {invoiceData?.billed_to_gst_no &&
                    <p className='mb-1'>Gst No. - {invoiceData?.billed_to_gst_no}</p>
                  }
                  <p className='mb-1'>Address - {invoiceData?.billed_to_address}</p>
                  <p className='mb-1'>City - {invoiceData?.billed_to_city}</p>
                  <p className='mb-1'>State - {invoiceData?.billed_to_state}</p>
                  <p className='mb-1'>Zip code - {invoiceData?.billed_to_zip_code}</p>
                </Card>
              </Col>

              <Col span={2}></Col>
              <Col span={11}>
                <Card title="Shipped To:" size="small" className="w-full  ">
                  <p
                    className='text-[16px] font-semibold mb-1'
                  >
                    {invoiceData?.shipped_to_company_name}
                  </p>
                  {invoiceData?.shipped_to_gst_no &&
                    <p className='mb-1'>Gst No. - {invoiceData?.shipped_to_gst_no}</p>
                  }
                  <p className='mb-1'>Address - {invoiceData?.shipped_to_address}</p>
                  <p className='mb-1'>City - {invoiceData?.shipped_to_city}</p>
                  <p className='mb-1'>State - {invoiceData?.shipped_to_state}</p>
                  <p className='mb-1'>Zip code - {invoiceData?.shipped_to_zip_code}</p>
                </Card>
              </Col>

            </Row>

          </div>

          <Table
            columns={columns}
            dataSource={invoiceData?.purchase_items || []}
            pagination={false}
            className="rounded-lg border border-gray-300 mb-6"
            footer={footer}
            size="small"
            rowClassName="hover:bg-gray-50"
          />

          <div className="flex justify-between items-start p-4 border-t border-gray-300 mt-6">
            <div className="w-1/3 space-y-2">
              <Paragraph strong className="text-gray-700">BANK DETAILS</Paragraph>
              <table className="table-auto w-full text-left">
                <tbody>
                  <tr>
                    <td className='p-2 font-medium'>Name</td>
                    <td className='p-2'>Mr. Naru Praveen</td>
                  </tr>
                  <tr>
                    <td className='p-2 font-medium'>IFSC Code</td>
                    <td className='p-2'>SBIN00000873</td>
                  </tr>
                  <tr>
                    <td className='p-2 font-medium'>Account Number</td>
                    <td className='p-2'>200333113873</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="w-1/2 space-y-2">
              <table className="table-auto w-full text-left">
                <tbody>
                  <tr>
                    <td className='p-2 font-medium'>TAXIBLE AMOUNT</td>
                    <td className='p-2'>₹ {invoiceData?.sub_total}</td>
                  </tr>
                  <tr>
                    <td className='p-2 font-medium'>GST Amount</td>
                    <td className='p-2'>₹ {invoiceData?.gst_amount}</td>
                  </tr>
                  <tr>
                    <td className='p-2 font-medium'>Shipping Charges</td>
                    <td className='p-2'>₹ {invoiceData?.shipping_charge}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="border-t border-gray-300 my-2"></td>
                  </tr>
                  <tr>
                    <th className='p-2 font-semibold'>TOTAL AMOUNT</th>
                    <th className='p-2 font-semibold'>₹ {invoiceData?.total_amount}</th>
                  </tr>
                  <tr>
                    <td colSpan="2" className="border-t border-gray-300 my-2"></td>
                  </tr>
                  <tr>
                    <td className='p-2 font-medium'>Received Amount</td>
                    <td className='p-2'>₹ {invoiceData?.paid_amount}</td>
                  </tr>
                  <tr>
                    <th className='p-2 font-semibold'>Balance</th>
                    <th className='p-2 font-semibold'>₹ {invoiceData?.balance_amount}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* </Content> */}
      </Content>


      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            .invoice-content, .invoice-content * {
              visibility: visible;
            }
            .invoice-content {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
            }
          }
        `}
      </style>
      <Button
        type="primary"
        onClick={printInvoice}
        className="mt-6 block mx-auto"
      >
        Print Invoice
      </Button>
    </Layout>
  );
};

export default ProfessionalInvoice;
