// actions.js
import axios from 'axios';
import {
  SAVE_PAYMENT_OUT_REQUEST,
  SAVE_PAYMENT_OUT_SUCCESS,
  SAVE_PAYMENT_OUT_FAILURE,

  GET_PAYMENT_OUTS_REQUEST,
  GET_PAYMENT_OUTS_SUCCESS,
  GET_PAYMENT_OUTS_FAILURE,

 DELETE_PAYMENT_OUTS_REQUEST,
 DELETE_PAYMENT_OUTS_SUCCESS,
 DELETE_PAYMENT_OUTS_FAILURE,

  GET_PENDING_PURCHASE_REQUEST,
  GET_PENDING_PURCHASE_SUCCESS,
  GET_PENDING_PURCHASE_FAILURE,

  SAVE_PURCHASE_REQUEST,
  SAVE_PURCHASE_SUCCESS,
  SAVE_PURCHASE_FAILURE,
  
} from './actionTypes';

import { message } from 'antd';
import { getPartiesAPI, getPendingPurchaseApi, savePaymentOutAPI, savePurchaseAPI,getPaymentOutAPI ,deletePaymentOutAPI} from '../../../api/Services';

// Save Payment Out Actions
export const savePaymentOutRequest = () => ({
  type: SAVE_PAYMENT_OUT_REQUEST,
});

export const savePaymentOutSuccess = (response) => ({
  type: SAVE_PAYMENT_OUT_SUCCESS,
  payload: response,
});

export const savePaymentOutFailure = (error) => ({
  type: SAVE_PAYMENT_OUT_FAILURE,
  payload: error,
});

// Get Payment Out Actions
export const getPaymentOutRequest = () => ({
  type: GET_PAYMENT_OUTS_REQUEST,
});

export const getPaymentOutSuccess = (response) => ({
  type: GET_PAYMENT_OUTS_SUCCESS,
  payload: response,
});

export const getPaymentOutFailure = (error) => ({
  type: GET_PAYMENT_OUTS_FAILURE,
  payload: error,
});

// Get Pending Purchase Actions
export const getPendingPurchaseRequest = () => ({
  type: GET_PENDING_PURCHASE_REQUEST,
});

export const getPendingPurchaseSuccess = (response) => ({
  type: GET_PENDING_PURCHASE_SUCCESS,
  payload: response,
});

export const getPendingPurchaseFailure = (error) => ({
  type: GET_PENDING_PURCHASE_FAILURE,
  payload: error,
});

/**
 * Delete the invoices
 */
export const deletePaymentOutRequest = () => ({
  type: DELETE_PAYMENT_OUTS_REQUEST
});

export const deletePaymentOutSuccess = (response, id) => ({
  type: DELETE_PAYMENT_OUTS_SUCCESS,
  payload: response,
  meta: { id: id }
});

export const deletePaymentOutFailure = error => ({
  type: DELETE_PAYMENT_OUTS_FAILURE,
  payload: error
});


// Save Payment Out Thunk
export const savePaymentOut = (data) => async (dispatch) => {
  dispatch(savePaymentOutRequest());
  try {
    const response = await savePaymentOutAPI(data);
    if (response?.success) {
      dispatch(savePaymentOutSuccess(response));
      message.success(response?.success);
    } else if (response?.error) {
      dispatch(savePaymentOutFailure(response));
      message.error(response?.error);
    } else {
      dispatch(savePaymentOutFailure(response));
    }
    return response;
  } catch (error) {
    dispatch(savePaymentOutFailure(error.message));
    throw error;
  }
};

// Get Pending Purchase Thunk
export const getPendingPurchase = (data) => async (dispatch) => {
  dispatch(getPendingPurchaseRequest());
  try {
    const response = await getPendingPurchaseApi(data);
    if (response) {
      dispatch(getPendingPurchaseSuccess(response));
    }
    if (response?.error) {
      dispatch(getPendingPurchaseFailure(response));
      message.error(response?.error);
    }
    return response;
  } catch (error) {
    dispatch(getPendingPurchaseFailure(error.message));
    throw error;
  }
};

// Save Purchase Actions
export const savePurchaseRequest = () => ({
  type: SAVE_PURCHASE_REQUEST,
});

export const savePurchaseSuccess = (response) => ({
  type: SAVE_PURCHASE_SUCCESS,
  payload: response,
});

export const savePurchaseFailure = (error) => ({
  type: SAVE_PURCHASE_FAILURE,
  payload: error,
});

// Save Purchase Thunk
export const savePurchase = (data) => async (dispatch) => {
  dispatch(savePurchaseRequest());
  try {
    const response = await savePurchaseAPI(data);
    if (response?.success) {
      dispatch(savePurchaseSuccess(response));
      message.success(response?.success);
    } else if (response?.error) {
      dispatch(savePurchaseFailure(response));
      message.error(response?.error);
    }
    return response;
  } catch (error) {
    dispatch(savePurchaseFailure(error.message));
    throw error;
  }
};

// Get Payment Out Thunk
export const getPaymentOut = (data) => async (dispatch) => {
  dispatch(getPaymentOutRequest());
  try {
    const response = await getPaymentOutAPI(data); 
    if (response) {
      dispatch(getPaymentOutSuccess(response));
    }
    if (response?.error) {
      dispatch(getPaymentOutFailure(response));
      message.error(response?.error);
    }
    return response;
  } catch (error) {
    dispatch(getPaymentOutFailure(error.message));
    throw error;
  }
};

/**
 * To delete the PaymentOutdetail
 */
export const deletePaymentOut= (id) => async (dispatch) => {
  dispatch(deletePaymentOutRequest());
  try {
    const response = await deletePaymentOutAPI(id)
    if (response?.success) {
      dispatch(deletePaymentOutSuccess(response, id))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(deletePaymentOutFailure(response))
      message.error(response?.error)
    }
    return response;
  } catch (error) {
    dispatch(deletePaymentOutFailure(error.message));
    throw error;
  }
};