import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const DynamicTable = ({ columns, data, tableColumns, handleTableChange }) => {
  return (
    <div className="overflow-x-auto mt-4">
      <Table
        size='small'
        columns={tableColumns}
        dataSource={data}
        bordered
        pagination={false}
        onChange={handleTableChange}
        rowKey={(record, index) => index} // Assuming data array is not uniquely keyed
      />
    </div>
  );
};

DynamicTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleTableChange: PropTypes.func.isRequired,
};

export default DynamicTable;
