// Actions Types for clents

export const SAVE_PARTY_REQUEST = 'SAVE_PARTY_REQUEST';
export const SAVE_PARTY_SUCCESS = 'SAVE_PARTY_SUCCESS';
export const SAVE_PARTY_FAILURE = 'SAVE_PARTY_FAILURE';

export const GET_PARTIES_REQUEST = 'GET_PARTIES_REQUEST';
export const GET_PARTIES_SUCCESS = 'GET_PARTIES_SUCCESS';
export const GET_PARTIES_FAILURE = 'GET_PARTIES_FAILURE';

export const UPDATE_PARTY_REQUEST = 'UPDATE_PARTY_REQUEST';
export const UPDATE_PARTY_SUCCESS = 'UPDATE_PARTY_SUCCESS';
export const UPDATE_PARTY_FAILURE = 'UPDATE_PARTY_FAILURE';