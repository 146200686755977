import React, { useEffect } from 'react';
import invoicingi from '../../images/landingPage/AutomatedInvoice.png';
import invoicingj from '../../images/landingPage/Inventory_Management.png';
import invoicingk from '../../images/landingPage/Payment _Management.webp';
import invoicinpk from '../../images/landingPage/client_image.png';
import invoicinck from '../../images/landingPage/mobile_Accessibility.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Feature() {
    // useEffect(() => {
    //     AOS.init({
    //         duration: 1000,
    //         easing: 'ease-in-out',
    //     });
    // }, []);

    return (
        <div
            id='feature'
            className="flex flex-col items-center justify-center min-h-screen font-Caladea mt-5 px-4 md:px-0" // Add responsive padding
        >
            <h1 className="text-3xl font-bold" data-aos="fade-up">Features</h1>

            <div className='border-solid border-2 border-red-500 w-[20%] m-auto my-5' />

            <main className="flex flex-wrap justify-around w-full px-0 md:px-10"> {/* Set padding to 0 on small screens */}
                <div className="product flex w-full flex-col-reverse items-center md:flex-row mb-8"> {/* Add margin bottom for spacing */}
                    <div className='flex flex-wrap justify-around w-full mt-8'>
                        <h2 className="text-2xl font-bold mb-2 w-full" data-aos="fade-up">Automated Invoice Generation and Delivery</h2>
                        <p className="text-lg text-justify mb-2" data-aos="fade-up">
                            Allow users to quickly create professional-looking invoices with customizable templates. Include features for adding items, discounts, taxes, and shipping costs.
                            Support recurring billing cycles (weekly, monthly, annually) to automate invoices for subscriptions or regular services.
                            Offer various delivery methods: email, PDF download, print, and potential integration with payment gateways for online payments.
                        </p>
                    </div>
                    <div className="flex flex-wrap justify-around w-full mt-8">
                        <img src={invoicingi} className='w-85 h-92' data-aos="fade-up" alt="Automated Invoice" />
                    </div>
                </div>
                <div className="product flex w-full flex-col items-center md:flex-row mb-8">
                    <div className="flex flex-wrap justify-around w-full mt-8">
                        <img src={invoicingj} className='w-72 h-72' data-aos="fade-up" alt="Inventory Management" />
                    </div>
                    <div className='flex flex-wrap justify-around w-full mt-8'>
                        <h2 className="text-2xl font-bold mb-2 w-full" data-aos="fade-up">Inventory Management</h2>
                        <p className="text-lg text-justify mb-2" data-aos="fade-up">
                            Manage inventory levels for products or services. Track stock quantities, reorder points, and potential cost variations.
                            Display accurate stock availability on invoices and provide notifications when items are running low.
                            (Optional) For more complex inventories, allow users to track specific lots or batches for expiry dates, serial numbers, etc.
                        </p>
                    </div>
                </div>
                <div className="product flex w-full flex-col-reverse items-center md:flex-row mb-8">
                    <div className='flex flex-wrap justify-around w-full mt-8'>
                        <h2 className="text-2xl font-bold mb-2 w-full" data-aos="fade-up">Payment Processing and Reporting</h2>
                        <p className="text-lg text-justify mb-2" data-aos="fade-up">
                            Connect with popular payment processors (Stripe, PayPal, etc.) to enable online payments.
                            Send automated reminders to clients when invoices are due and overdue, helping to reduce late payments.
                            Generate comprehensive reports on revenue, expenses, outstanding invoices, payment history, and other crucial financial data.
                        </p>
                    </div>
                    <div className="flex flex-wrap justify-around w-full mt-8">
                        <img src={invoicingk} className='w-72 h-72' data-aos="fade-up" alt="Payment Processing" />
                    </div>
                </div>
                <div className="product flex w-full flex-col items-center md:flex-row mb-8">
                    <div className="flex flex-wrap justify-around w-full mt-8">
                        <img src={invoicinpk} className='w-80 h-72' data-aos="fade-up" alt="Client Management" />
                    </div>
                    <div className='flex flex-wrap justify-around w-full mt-8'>
                        <h2 className="text-2xl font-bold mb-2 w-full" data-aos="fade-up">Client Management</h2>
                        <p className="text-lg text-justify mb-2" data-aos="fade-up">
                            Store customer information (contact details, billing addresses, payment history) in a centralized and easily accessible location.
                            Send personalized email templates for invoices, reminders, and other communications.
                            Allow users to group clients for targeted promotions or special offers.
                        </p>
                    </div>
                </div>
                <div className="product flex w-full flex-col-reverse items-center md:flex-row mb-8">
                    <div className='flex flex-wrap justify-around items-center w-full mt-8'>
                        <h2 className="text-2xl font-bold mb-2 w-full" data-aos="fade-up">Mobile Accessibility</h2>
                        <p className="text-lg text-justify mb-2" data-aos="fade-up">
                            Ensure the billing app is accessible on all devices (desktops, tablets, smartphones)
                            with a responsive design that adapts to screen sizes. Consider developing a dedicated
                            mobile app for on-the-go invoice creation, client management, and payment tracking.
                        </p>
                    </div>
                    <div className="flex flex-wrap justify-around w-full mt-8">
                        <img src={invoicinck} className='w-80 h-72' data-aos="fade-up" alt="Mobile Accessibility" />
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Feature;
