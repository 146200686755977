import React, { useEffect } from 'react'
import PurchaseOrdertable from './PurchaseOrderTable';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchaseOrders,deletePurchaseOrder } from './actions';
import { useNavigate } from 'react-router-dom';
import { Button, Popconfirm, Tooltip, message } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';


export default function PurchaseOrderList({ purchaseOrderDatas }) {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  /**
   * To create for header and access key
   */
  const columns = [
    { Header: 'Date', accessor: 'date' },
    { Header: 'P O Number', accessor: 'purchase_order_no' },
    { Header: 'Party Name', accessor: 'billed_to_company_name' },
    { Header: 'Total Amount', accessor: 'total_amount' },
    // { Header: 'Items', accessor: 'purchase_order_items' },
  ];

  // Function to handle navigation
  const handleView = (record) => {
    navigate("/purchase-order-invoice-bill", { state: { record } });
  };

  // Convert columns to the format expected by Ant Design Table
  const tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
  }));

  /**
 * to delete the 
 */
  const handleDelete = async (data) => {
    if (data?.id) {
      const response = await dispatch(deletePurchaseOrder(data?.id))
    } else {
      message.error("Please Select Data")
    }
  }

  // Add an action column
  tableColumns.push({
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <span className="flex space-x-2">
        <Tooltip title="View" color="black">
          <button className="p-1 mx-0.5 bg-black rounded" onClick={() => handleView(record)}>
            <EyeOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip>
        <Tooltip title="Edit" color="blue">
          <button className="p-1 mx-0.5 bg-blue-500 rounded">
            <EditOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip>
        {/* <Tooltip title="Delete" color="red">
          <button className="p-1 mx-0.5 bg-red-500 rounded">
            <DeleteOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip> */}
         <Popconfirm
          placement="topRight"
          title='object delete'
          description='Are you sure want to delete'
          okText="Yes"
          cancelText="No"
          onConfirm={() => handleDelete(record)}
        >
          <Button disabled={record?.payment_status === 'paid' ? true : false} className="p-1 mx-0.5 bg-red-500 rounded">
            <DeleteOutlined className="text-black p-0 m-0" />
          </Button>
        </Popconfirm>
      </span>
    ),
  });


  return (
    <div>
      <PurchaseOrdertable columns={columns} data={purchaseOrderDatas} tableColumns={tableColumns} />
    </div>
  )
}
