// actions.js
import axios from 'axios';
import {
  SAVE_CATEGORY_REQUEST,
  SAVE_CATEGORY_SUCCESS,
  SAVE_CATEGORY_FAILURE,

  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE
} from './actionTypes';
import { message } from 'antd';
import { getCategoriesAPI, saveCategoryAPI } from '../../../api/Services';


export const saveCategoryRequest = () => ({
  type: SAVE_CATEGORY_REQUEST
});

export const saveCategorySuccess = (response) => ({
  type: SAVE_CATEGORY_SUCCESS,
  payload: response
});

export const saveCategoryFailure = error => ({
  type: SAVE_CATEGORY_FAILURE,
  payload: error
});


/**
 * Get all categories data
 */
export const getCategoriesRequest = () => ({
  type: GET_CATEGORIES_REQUEST
});

export const getCategoriesSuccess = (response) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: response
});

export const getCategoriesFailure = error => ({
  type: GET_CATEGORIES_FAILURE,
  payload: error
});


/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To save the category detail
 */
export const saveCategory = (data) => async (dispatch) => {
  dispatch(saveCategoryRequest());
  try {
    const response = await saveCategoryAPI(data)

    if (response?.success) {
      dispatch(saveCategorySuccess(response))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(saveCategoryFailure(response))
      message.error(response?.error)
    }
    return response;

  } catch (error) {
    dispatch(saveCategoryFailure(error?.message));
    throw error;
  }
};



/**
 * To get the all categories data
 */
export const getCategories = () => async (dispatch) => {
  dispatch(getCategoriesRequest());
  try {
    const response = await getCategoriesAPI()

    if (response) {
      dispatch(getCategoriesSuccess(response))
    }
    return response;

  } catch (error) {
    dispatch(getCategoriesFailure(error?.message));
    throw error;
  }
};