import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DatePicker, Select, message } from 'antd';
import * as XLSX from 'xlsx';
import { getPurchases } from '../../../purchase/purchaseInvoice/actions'; // Assuming a similar action for fetching GST R2 data
import AppListHeader from '../../../header/AppListHeader';
import GstR2List from './GstR2List';
import { rangePresets } from '../../../../settings/codeSettings/additionalMethods';
import { getParties } from '../../../party/actions';

const { RangePicker } = DatePicker;

export default function GstR2Report() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gstR2ReportResponse = useSelector(state => state.purchase.getPurchases.response); // Use the appropriate selector for GST R2 data
  console.log(gstR2ReportResponse, 'bbbb')
  const partyResponse = useSelector((state) => state.parties.getParties.response);

  const [filterQuery, setFilterQuery] = useState({});
  const [purchaseDetail, setPurchaseDetail] = useState({});
  const [purchaseSearchText, setPurchaseSearchText] = useState('');

  // Fetch the GST R2 data on load and when search text or filter query changes
  useEffect(() => {
    getPurchaseData();
    /**
         * handle party state
         */
    if (
      partyResponse?.party_count == partyResponse?.parties?.results?.length &&
      partyResponse?.parties?.results?.length > 0
    ) {

    }
    else {
      dispatch(getParties())
    }
    /**
     * end of handling party state
     */
  }, [dispatch, purchaseSearchText, filterQuery]);

  const getPurchaseData = async () => {
    let response;

    if (purchaseSearchText || filterQuery) {
      response = await dispatch(getPurchases(filterQuery));
    } else {
      response = await dispatch(getPurchases());
    }

    if (response) {
      setPurchaseDetail({
        totalPurchases: response.total_purchases,
        totalPaidAmount: response.total_paid_amount,
        totalUnPaidAmount: response.total_unpaid_amount,
      });
    }
  };

  // Export data to Excel
  const handleExportToExcel = useCallback(() => {
    if (!gstR2ReportResponse?.purchases?.results?.length) {
      message.warning('No data available to export');
      return;
    }

    const workbook = XLSX.utils.book_new();
    const wsData = gstR2ReportResponse?.purchases?.results?.map((item) => [
      item.purchase_inv_no,
      item.date,
      item.partyName,
      item.taxableValue,
      item.cgst,
      item.sgst,
      item.igst,
      item.gstTax,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Invoice Number', 'Date', 'Supplier GSTIN', 'Taxable Value', 'CGST', 'SGST', 'IGST', 'Total Tax'],
      ...wsData,
    ]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'GstR2Report');
    XLSX.writeFile(workbook, 'GstR2Report.xlsx');
  }, [gstR2ReportResponse]);

  // Handle Party selection
  const handlePartyChange = (partyId) => {
    setFilterQuery((prevQuery) => ({ ...prevQuery, billed_to__id: partyId || undefined }));
  };

  // Handle GST Type selection
  const handleGstTypeChange = (gstType) => {
    setFilterQuery((prevQuery) => ({ ...prevQuery, gst_type: gstType || undefined }));
  };

  // Handle Date Range selection
  const handleRangeChange = (dates, dateStrings) => {
    setFilterQuery((prevQuery) => ({
      ...prevQuery,
      start_date: dates ? dateStrings[0] : undefined,
      end_date: dates ? dateStrings[1] : undefined,
    }));
  };

  // Handle table changes (pagination, sorting, filtering)
  const handleTableChange = (pagination, filters, sorter) => {
    console.log('Table changed:', pagination, filters, sorter);
  };

  return (
    <div>
      <AppListHeader
        headerContent="GST R2 Report"
        excel
        handleExcelExport={handleExportToExcel}
        print
        cancel
      />

      <div className="flex flex-wrap mb-4 shadow-md p-1">
        {/* Party Selector */}
        <Select
          placeholder="Select Supplier"
          className="w-[250px] m-1"
          onChange={handlePartyChange}
          allowClear
          value={filterQuery.billed_to__id || undefined}
        >
          {partyResponse?.parties?.results?.map((party) => (
            <Select.Option key={party.id} value={party.id}>
              {party.company_name}
            </Select.Option>
          ))}
        </Select>

        {/* GST Type Selector */}
        <Select
          placeholder="Select GST Type"
          className="w-[250px] m-1"
          onChange={handleGstTypeChange}
          allowClear
          value={filterQuery.gst_type || undefined}
        >
          <Select.Option value="cgst">CGST</Select.Option>
          <Select.Option value="sgst">SGST</Select.Option>
          <Select.Option value="igst">IGST</Select.Option>
        </Select>

        {/* Date Range Picker */}
        <RangePicker
          className="w-[250px] m-1"
          presets={rangePresets}
          onChange={handleRangeChange}
        />
      </div>

      {/* GST R2 List Table */}
      <GstR2List
        gstR2Data={gstR2ReportResponse?.purchases?.results}
        handleTableChange={handleTableChange} // Passing handleTableChange function
      />
    </div>
  );
}
