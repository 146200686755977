import React, { useState, useEffect } from 'react';
import './Header.css';
import { Dropdown, Menu, Drawer } from 'antd';
import { FaUserCircle } from 'react-icons/fa';
import UserProfile  from './UserProfile';
import secureLocalStorage from 'react-secure-storage';
const items = [
  {

    key: '1',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        1st menu item
      </a>
    ),
  },
  {
    key: '2',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        2nd menu item
      </a>
    ),
  },
  {
    key: '3',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
        3rd menu item
      </a>
    ),
  },
];

const Header = ({ toggleSidebar }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [companyName, setCompanyName] = useState('Company Name');
  const [companyLogo, setCompanyLogo] = useState(null);

  const handleMenuClick = () => {

    setDropdownVisible(!dropdownVisible);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    const user = JSON.parse(secureLocalStorage.getItem('loggedInUser'));
    setCompanyName(user.company_name.toUpperCase());
    if (user.company_logo) {
      setCompanyLogo((user.company_logo).split('?X-Amz-Algorithm=')[0]);
    }
  }, [secureLocalStorage.getItem('loggedInUser')]);


  return (
    <>
      <div className="header">
        <h1 className="header-title">{companyName}</h1>
        <div className="sidebar-toggle" onClick={toggleSidebar}>
          ☰
        </div>
        <div className="user-icon" onClick={showDrawer}>
          {companyLogo ? (
            <img 
              src={companyLogo} 
              alt="User Profile" 
              style={{ width: '30px', height: '30px', borderRadius: '50%' }}

            />
          ) : (
            <FaUserCircle />
          )}
        </div>
      </div>
      <Drawer
        title="User Menu"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={drawerVisible}
        getContainer={false}
        className="custom-drawer"
        style={{ height: 'calc(100vh - 67px)' }} // Adjust height to fit within viewport minus header
      >
        <div className="drawer-content">

          <UserProfile onClose={onClose} />
        </div>
      </Drawer>
    </>
  );
};

export default Header;
