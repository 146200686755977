import React from 'react';
import { Button, Card, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';


function Profile({companyDetail}) {
  const navigate=useNavigate();
  const location = useLocation();

  const handleEditPage=()=>{
    navigate("/party-form", { state: { partyDetail: companyDetail } });
  }

  return (
    <div className="p-4">
      <div className="flex space-x-4 text-xs mt-4">
        <Card title="Personal Details" size="small" className="w-1/2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <Form.Item name="party_name" label={<span className="font-bold">Party Name</span>}>
              <p className="p-1">{companyDetail?.company_name || 'N/A'}</p>
            </Form.Item>
            <Form.Item name="party_name" label={<span className="font-bold">Full Name</span>}>
              <p className="p-1">{companyDetail?.party_full_name || 'N/A'}</p>
            </Form.Item>
            <Form.Item name="party_type" label={<span className="font-bold">Party Type</span>}>
              <p className="p-1">{companyDetail?.party_type ? "To Pay" : "To Collect"}</p>
            </Form.Item>
            <Form.Item name="Mobile_Number" label={<span className="font-bold">Mobile Number</span>}>
              <p className="p-1">{companyDetail?.mobile_no || 'N/A'}</p>
            </Form.Item>
            <Form.Item name="Mobile_Number" label={<span className="font-bold"> Email</span>}>
              <p className="p-1">{companyDetail?.email || 'N/A'}</p>
            </Form.Item>
            {/* <Form.Item name="Party_Category" label={<span className="font-bold">Party Category</span>}>
      <p className="p-1">{companyDetail?.party_category || 'N/A'}</p>
    </Form.Item> */}
            {/* <Form.Item name="opening_balance" label={<span className="font-bold">Opening Balance</span>}>
      <p className="p-1">{companyDetail?.opening_balance || 'N/A'}</p>
    </Form.Item> */}
          </div>
        </Card>

        <Card title="Address Details" size="small" className="w-1/2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">

          <Form.Item name="state" label={<span className="font-bold">Address</span>}>
              <p className="p-1">{companyDetail?.address || 'N/A'}</p>
            </Form.Item>
            <Form.Item name="state" label={<span className="font-bold">Zip Code </span>}>
              <p className="p-1">{companyDetail?.zip_code || 'N/A'}</p>
            </Form.Item>
            <Form.Item name="state" label={<span className="font-bold">State</span>}>
              <p className="p-1">{companyDetail?.state || 'N/A'}</p>
            </Form.Item>
            <Form.Item name="city" label={<span className="font-bold">City</span>}>
              <p className="p-1">{companyDetail?.city || 'N/A'}</p>
            </Form.Item>
            {/* <Form.Item name="PAN_Number" label={<span className="font-bold">PAN Number</span>}>
      <p className="p-1">{companyDetail?.pan_number || 'N/A'}</p>
    </Form.Item> */}
          </div>
        </Card>
      </div>

      <div className="flex space-x-4 text-xs mt-4">
        <Card title="Account Details" size="small" className="w-1/2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <Form.Item name="gst_no" label={<span className="font-bold">GST No</span>}>
              <p className="p-1">{companyDetail?.gst_no || 'N/A'}</p>
            </Form.Item>
            <Form.Item name="opening_balance" label={<span className="font-bold">Opening Balance</span>}>
              <p className="p-1">{companyDetail?.opening_balance || 'N/A'}</p>
            </Form.Item>
            <Form.Item name="current_balance" label={<span className="font-bold">Current Balance</span>}>
              <p className="p-1">{companyDetail?.current_balance ? `₹ ${companyDetail.current_balance}` : 'N/A'}</p>
            </Form.Item>
          </div>
        </Card>

        <div className="mt-2 w-1/2 border border-gray-300 bg-yellow-100 p-2 flex items-center">
          <div className="flex-1">
            <p className="font-semibold">Custom Fields</p>
            <p>Now you can add custom item fields like Colour, Size, ID Number, etc.</p>
          </div>
          <Button className="ml-2"><PlusOutlined onClick={() => handleEditPage()} /></Button>
        </div>
      </div>
    </div>
  );
}

export default Profile;
