// Actions Types for clents

export const  SAVE_TCS_REQUEST = ' SAVE_TCS_REQUEST';
export const  SAVE_TCS_SUCCESS = ' SAVE_TCS_SUCCESS';
export const  SAVE_TCS_FAILURE = ' SAVE_TCS_FAILURE';

export const  GET_TCS_REQUEST = ' GET_TCS_REQUEST';
export const  GET_TCS_SUCCESS = ' GET_TCS_SUCCESS';
export const  GET_TCS_FAILURE = ' GET_TCS_FAILURE';


export const  SAVE_TDS_REQUEST = ' SAVE_TDS_REQUEST';
export const  SAVE_TDS_SUCCESS = ' SAVE_TDS_SUCCESS';
export const  SAVE_TDS_FAILURE = ' SAVE_TDS_FAILURE';

export const  GET_TDS_REQUEST = ' GET_TDS_REQUEST';
export const  GET_TDS_SUCCESS = ' GET_TDS_SUCCESS';
export const  GET_TDS_FAILURE = ' GET_TDS_FAILURE';


export const GET_TDS_RECEIVABLE_CHARGED_REQUEST = 'GET_TDS_RECEIVABLE_CHARGED_REQUEST';
export const GET_TDS_RECEIVABLE_CHARGED_SUCCESS = 'GET_TDS_RECEIVABLE_CHARGED_SUCCESS';
export const GET_TDS_RECEIVABLE_CHARGED_FAILURE = 'GET_TDS_RECEIVABLE_CHARGED_FAILURE';

export const  GET_TCS_RECEIVABLE_CHARGED_REQUEST = ' GET_TCS_RECEIVABLE_CHARGED_REQUEST';
export const  GET_TCS_RECEIVABLE_CHARGED_SUCCESS = ' GET_TCS_RECEIVABLE_CHARGED_SUCCESS';
export const  GET_TCS_RECEIVABLE_CHARGED_FAILURE = ' GET_TCS_RECEIVABLE_CHARGED_FAILURE';

export const GET_TCS_PAYABLE_CHARGED_REQUEST = 'GET_TCS_PAYABLE_CHARGED_REQUEST';
export const GET_TCS_PAYABLE_CHARGED_SUCCESS = 'GET_TCS_PAYABLE_CHARGED_SUCCESS';
export const GET_TCS_PAYABLE_CHARGED_FAILURE = 'GET_TCS_PAYABLE_CHARGED_FAILURE';

export const  GET_TDS_PAYABLE_CHARGED_REQUEST = ' GET_TDS_PAYABLE_CHARGED_REQUEST';
export const  GET_TDS_PAYABLE_CHARGED_SUCCESS = ' GET_TDS_PAYABLE_CHARGED_SUCCESS';
export const  GET_TDS_PAYABLE_CHARGED_FAILURE = ' GET_TDS_PAYABLE_CHARGED_FAILURE';