// reducer.js
import { getParties } from './actions';
import {
    SAVE_PARTY_REQUEST,
    SAVE_PARTY_SUCCESS,
    SAVE_PARTY_FAILURE,

    GET_PARTIES_REQUEST,
    GET_PARTIES_SUCCESS,
    GET_PARTIES_FAILURE,

    UPDATE_PARTY_REQUEST,
    UPDATE_PARTY_SUCCESS,
    UPDATE_PARTY_FAILURE

} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    saveParty: {
        loading: false,
        error: null,
        response: null,
    },

    getParties: {
        loading: false,
        error: null,
        response: null,
    },

    updateParty: {
        loading: false,
        error: null,
        response: null,
    }
};

const partyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PARTY_REQUEST:
            return {
                ...state,
                saveParty: {
                    ...state.saveParty,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_PARTY_SUCCESS:
            return {
                ...state,
                saveParty: {
                    ...state.saveParty,
                    loading: false,
                    error: null,
                    response: action.payload
                },
                getParties: {
                    ...state.getParties,
                    response: {
                        ...action.payload?.party_count,
                        party_count: action.payload?.party_count + 1
                    }
                }
            };
        case SAVE_PARTY_FAILURE:
            return {
                ...state,
                saveParty: {
                    ...state.saveParty,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };


        case GET_PARTIES_REQUEST:
            return {
                ...state,
                getParties: {
                    ...state.getParties,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_PARTIES_SUCCESS:
            return {
                ...state,
                getParties: {
                    ...state.getParties,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_PARTIES_FAILURE:
            return {
                ...state,
                getParties: {
                    ...state.getParties,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };


        // UPDATE THE PARTIES
        case UPDATE_PARTY_REQUEST:
            return {
                ...state,
                updateParty: {
                    ...state.updateParty,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case UPDATE_PARTY_SUCCESS:
            return {
                ...state,
                updateParty: {
                    ...state.updateParty,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case UPDATE_PARTY_FAILURE:
            return {
                ...state,
                updateParty: {
                    ...state.updateParty,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default partyReducer;
