// reducer.js
import {
   GET_DASHBOARD_REQUEST,
   GET_DASHBOARD_SUCCESS,
   GET_DASHBOARD_FAILURE
} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    getDashboardData: {
        loading: false,
        error: null,
        response: null,
    }
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_REQUEST:
        return {
            ...state,
            getDashboardData: {
                ...state.getDashboardData,
                loading: true,
                error: null,
                response: null
            }
        };
        case GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                getDashboardData: {
                    ...state.getDashboardData,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_DASHBOARD_FAILURE:
            return {
                ...state,
                getDashboardData: {
                    ...state.getDashboardData,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };
        default:
            return state;
    }
};

export default dashboardReducer;
