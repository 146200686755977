import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SaleList from '../../sale/saleInvoice/SaleList';
import AppListHeader from '../../header/AppListHeader';
import { getSales } from '../../sale/saleInvoice/actions';
import { handleSearchText } from '../../../settings/codeSettings/additionalMethods';
import { Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

const { Search } = Input;

export default function SaleReport() {
    const navigate = useNavigate()

    const columns = [
        { title: 'Date', dataIndex: 'date' },
        { title: 'Party Name', dataIndex: 'billed_to_company_name' },
        { title: 'sale Invoice Number', dataIndex: 'sale_inv_no' },
        { title: 'original Invoice Number', dataIndex: 'original_inv_no' },
        { title: 'Amount', dataIndex: 'total_amount' },
        { title: 'Balance Amount', dataIndex: 'balance_amount' },
        { title: 'Status', dataIndex: 'payment_status' },
    ];


    const dispatch = useDispatch();
    const salesData = useSelector(state => state.sale.getSales.response);
    const saleDeleteResponse = useSelector(state => state.sale.deleteSale.response);

    const [filterQuery, setFilterQuery] = useState(null)
    const [saleSearchText, setSaleSearchText] = useState('')

    useEffect(() => {
        getSalesData();
    }, [dispatch, saleSearchText, filterQuery, saleDeleteResponse]);

    const getSalesData = async () => {
        if (saleSearchText) {
            await dispatch(getSales({ search: saleSearchText }));
        }
        else if (filterQuery) {
            await dispatch(getSales(filterQuery));
        }
        else {
            const response = await dispatch(getSales());
        }
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order == 'ascend' ?
                `${sorter?.field}`
                :
                sorter?.order == 'descend' ?
                    `-${sorter?.field}`
                    :
                    null,
        })
    }
    // const handlePrint = () => {
    //     // console.log(partyDatas);
    //     navigate('/ListPrint', { state: { tableData :salesData?.sales?.results, tableColunm: columns,name:'Party List' } });
    // };


    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        const wsData = salesData?.sales?.results.map((e, index) => [
            index + 1,
            e.date, // Replace with correct date format if necessary
            e.sale_inv_no,
            e.billed_to_company_name,
            e.total_amount,
            e.balance_amount,
            e.payment_status,
        ]) || [];

        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'Sr. No',
                'Date',
                'Sale Invoice Number',
                'Billed To',
                'Total Amount',
                'Balance Amount',
                'Payment Status',
            ],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales');
        XLSX.writeFile(workbook, 'sales.xlsx');
    };

    return (
        <div>

            <AppListHeader
                headerContent='Sales Report'
                excel={true}
                print={true}
                download={false}
                cancel={true}
                handleExcelExport={handleExportToExcel}
                printNavigation='/ListPrint'
                printData={{ tableColunm: columns, tableData: salesData?.sales?.results, name: 'Sale List' }}
            />

            <div
                className="
                flex flex-wrap mb-4 shadow-md p-1
                "
            >
                <Search
                    placeholder="Search here"
                    allowClear
                    onSearch={(value) => handleSearchText(value, setSaleSearchText)}
                    className='w-[250px] m-1'
                />
            </div>

            <SaleList
                sale={salesData?.sales?.results}
                handleTableChange={handleTableChange}
            />
        </div>
    )
}
