// actions.js
import axios from 'axios';
import {
  SAVE_QUOTATION_REQUEST,
  SAVE_QUOTATION_SUCCESS,
  SAVE_QUOTATION_FAILURE,

  GET_QUOTATIONS_REQUEST,
  GET_QUOTATIONS_SUCCESS,
  GET_QUOTATIONS_FAILURE,

   DELETE_QUOTATIONS_REQUEST,
  DELETE_QUOTATIONS_SUCCESS,
  DELETE_QUOTATIONS_FAILURE,


} from './actionTypes';
import { message } from 'antd';
import { getQuotationsAPI, saveQuotationAPI,deleteQuotationAPI } from '../../../api/Services';


export const saveQuotationRequest = () => ({
  type: SAVE_QUOTATION_REQUEST
});

export const saveQuotationSuccess = (response) => ({
  type: SAVE_QUOTATION_SUCCESS,
  payload: response
});

export const saveQuotationFailure = error => ({
  type: SAVE_QUOTATION_FAILURE,
  payload: error
});


/**
 * Get all quotations
 */
export const getQuotationsRequest = () => ({
  type: GET_QUOTATIONS_REQUEST
});

export const getQuotationsSuccess = (response) => ({
  type: GET_QUOTATIONS_SUCCESS,
  payload: response
});

export const getQuotationsFailure = error => ({
  type: GET_QUOTATIONS_FAILURE,
  payload: error
});

/**
 * Delete the invoices
 */
export const deleteQuotationRequest = () => ({
  type: DELETE_QUOTATIONS_REQUEST
});

export const deleteQuotationSuccess = (response, id) => ({
  type: DELETE_QUOTATIONS_SUCCESS,
  payload: response,
  meta: { id: id }
});

export const deleteQuotationFailure = error => ({
  type: DELETE_QUOTATIONS_FAILURE,
  payload: error
});

/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To save the quotations detail
 */
export const saveQuotation = (data) => async (dispatch) => {
  dispatch(saveQuotationRequest());
  try {
    const response = await saveQuotationAPI(data)

    if (response?.success) {
      dispatch(saveQuotationSuccess(response))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(saveQuotationFailure(response))
      message.error(response?.error)
    }
    return response;

  } catch (error) {
    dispatch(saveQuotationFailure(error.message));
    throw error;
  }
};



/**
 * To get the all quotations
 */
export const getQuotations = (data) => async (dispatch) => {
  dispatch(getQuotationsRequest());
  try {
    const response = await getQuotationsAPI(data)

    if (response) {
      dispatch(getQuotationsSuccess(response))
    }
    return response;

  } catch (error) {
    dispatch(getQuotationsFailure(error.message));
    throw error;
  }
};
/**
 * To delete the Quotationdetail
 */
export const deleteQuotation= (id) => async (dispatch) => {
  dispatch(deleteQuotationRequest());
  try {
    const response = await deleteQuotationAPI(id)
    if (response?.success) {
      dispatch(deleteQuotationSuccess(response, id))
      message.success(response?.success)
    }
    if (response?.error) {
      dispatch(deleteQuotationFailure(response))
      message.error(response?.error)
    }
    return response;
  } catch (error) {
    dispatch(deleteQuotationFailure(error.message));
    throw error;
  }
};