import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../images/invoiceFolder/Screenshot (5).png';
import qrCode from '../../../images/invoiceFolder/qr.png';
import secureLocalStorage from 'react-secure-storage';

const Invoice = ({ invoiceData }) => {
    const [user, setUser] = useState({});
    const printRef = useRef();

    const handlePrint = () => {
        const originalContent = document.body.innerHTML;
        const printContent = printRef.current.innerHTML;

        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
    };

    const numberToWords = (num) => {
        const a = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
            'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        const convert = (n) => {
            if (n < 20) return a[n];
            if (n < 100) return b[Math.floor(n / 10)] + (n % 10 ? '-' + a[n % 10] : '');
            if (n < 1000) return a[Math.floor(n / 100)] + ' Hundred' + (n % 100 ? ' ' + convert(n % 100) : '');
            if (n < 100000) return convert(Math.floor(n / 1000)) + ' Thousand' + (n % 1000 ? ' ' + convert(n % 1000) : '');
            if (n < 10000000) return convert(Math.floor(n / 100000)) + ' Lakh' + (n % 100000 ? ' ' + convert(n % 100000) : '');
            return convert(Math.floor(n / 10000000)) + ' Crore' + (n % 10000000 ? ' ' + convert(n % 10000000) : '');
        };

        return convert(num) + ' Only';
    };

    useEffect(() => {
        const userData = JSON.parse(secureLocalStorage.getItem("loggedInUser"));
        if (userData) {
            setUser({
                ...userData,
                company_logo: userData.company_logo.split('?X-Amz-Algorithm=')[0]
            });
        }
    }, []);

    return (
        <div ref={printRef} style={styles.invoiceContainer}>
            <h2 style={styles.heading}>Tax Invoice</h2>
            <div style={styles.headerContainer}>
                <div style={styles.leftHeader}>
                    <div style={styles.logodiv}>
                        <img src={user.company_logo || logo} alt="Company Logo" style={styles.logo} />
                        <h2 style={styles.heading}>{user.company_name || "Default Company Name"}</h2>
                    </div>
                    <p>GSTIN: {user.gst || 'N/A'}</p>
                    <p>Billed To Address: {user.address || 'Default Company Address'}</p>
                    <p>Mobile: {user.mobile_number || 'N/A'}</p>
                </div>
                <div style={styles.rightHeader}>
                    <p><strong>Invoice #: </strong>{invoiceData.sale_inv_no || invoiceData.purchase_inv_no}</p>
                    <p><strong>Invoice Date: </strong>{invoiceData.date}</p>
                    <p><strong>Place of Supply: </strong>{invoiceData.billed_to_address}</p>
                    <p><strong>Due Date: </strong>{invoiceData.date}</p>
                </div>
            </div>

            <div style={styles.detailsContainer}>
                <div style={styles.customerDetails}>
                    <h3>Customer Details</h3>
                    <p><strong>Name: </strong>{invoiceData.billed_to_party_full_name}</p>
                    <p><strong>Billing Address: </strong>{invoiceData.billed_to_address}</p>
                    <p><strong>Phone: </strong>{invoiceData.billed_to_mobile_no}</p>
                </div>
                <div style={styles.shippingDetails}>
                    <h3>Shipping Address</h3>
                    <p>{invoiceData.shipped_to_address}</p>
                </div>
            </div>

            <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={styles.th}>#</th>
                        <th style={styles.th}>Item</th>
                        <th style={styles.th}>HSN/SAC</th>
                        <th style={styles.th}>Qty</th>
                        <th style={styles.th}>Unit</th>
                        <th style={styles.th}>Rate</th>
                        <th style={styles.th}>GST</th>
                        <th style={styles.th}>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {invoiceData.sale_items && invoiceData.sale_items?.map((item, index) => (
                        <tr key={index}>
                            <td style={styles.td}>{index + 1}</td>
                            <td style={styles.td}>{item.item_name}</td>
                            <td style={styles.td}>{item.item_hsn_code}</td>
                            <td style={styles.td}>{item.qty}</td>
                            <td style={styles.td}>{item.item_unit}</td>
                            <td style={styles.td}>{parseFloat(item.rate).toLocaleString('en-IN', { minimumFractionDigits: 2 })}</td>
                            <td style={styles.td}>{item.gst_amount}</td>
                            <td style={styles.td}>{parseFloat(item.amount).toLocaleString('en-IN', { minimumFractionDigits: 2 })}</td>
                        </tr>
                    ))}
                    {invoiceData.purchase_items && invoiceData.purchase_items?.map((item, index) => (
                        <tr key={index}>
                            <td style={styles.td}>{index + 1}</td>
                            <td style={styles.td}>{item.item_name}</td>
                            <td style={styles.td}>{item.item_hsn_code}</td>
                            <td style={styles.td}>{item.qty}</td>
                            <td style={styles.td}>{item.item_unit}</td>
                            <td style={styles.td}>{parseFloat(item.rate).toLocaleString('en-IN', { minimumFractionDigits: 2 })}</td>
                            <td style={styles.td}>{item.gst_amount}</td>
                            <td style={styles.td}>{parseFloat(item.amount).toLocaleString('en-IN', { minimumFractionDigits: 2 })}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div style={styles.totalSection}>
                <p><strong>Taxable Amount: </strong>₹{invoiceData.sub_total}</p>
                <h3>Total: ₹{invoiceData.total_amount}</h3>
                <p>Amount Chargeable (in words): <strong>{numberToWords(invoiceData.total_amount)}</strong></p>
            </div>

            <div style={styles.bankDetails}>
                <h3>Bank Details:</h3>
                <p>Bank: <strong>{user.bank_name}</strong></p>
                <p>Account #: <strong>{user.acc_no}</strong></p>
                <p>IFSC: <strong>{user.ifsc_code}</strong></p>
                {/* <div style={styles.paydiv}>
                    <p>Pay using UPI:</p>
                    <img src={qrCode} alt="QR Code" style={styles.qrCode} />
                </div> */}
            </div>

            <div style={styles.footer}>
                <div style={styles.notes}>
                    <h3>Notes:</h3>
                    <p>Thank you for the Business</p>
                </div>
                <div style={styles.terms}>
                    <h3>Terms and Conditions:</h3>
                    <ul>
                        <li>Goods once sold cannot be taken back or exchanged.</li>
                        <li>We are not the manufacturers; the company will stand for warranty as per their terms and conditions.</li>
                        <li>Interest @24% p.a. will be charged for uncleared bills beyond 15 days.</li>
                        <li>Subject to local Jurisdiction.</li>
                    </ul>
                </div>
            </div>

            <button onClick={handlePrint} className="print-btn">
                Print Invoice
            </button>
        </div>
    );
};

// Styles for the invoice component
const styles = {
    invoiceContainer: {
        width: '80%',
        margin: '0 auto',
        fontFamily: 'Arial, sans-serif',
    },
    headerContainer: {
        display: 'flex',
    },
    leftHeader: {
        width: '50%',
        border: '1px solid black',
        padding: '10px',
    },
    rightHeader: {
        width: '50%',
        paddingTop: '80px',
        paddingLeft: '21px',
        border: '1px solid black',
    },
    detailsContainer: {
        display: 'flex',
    },
    customerDetails: {
        width: '50%',
        border: '1px solid black',
        padding: '10px',
    },
    shippingDetails: {
        width: '50%',
        border: '1px solid black',
        padding: '10px',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    th: {
        border: '1px solid black',
        padding: '8px',
    },
    td: {
        border: '1px solid black',
        padding: '8px',
    },
    totalSection: {
        border: '1px solid black',
        padding: '10px',
        textAlign: 'right',
    },
    bankDetails: {
        border: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
    },
    qrCode: {
        width: '200px',
        height: '100px',
    },
    footer: {
        display: 'flex',
        borderTop: '1px solid black',
        border: '1px solid black',
    },
    notes: {
        width: '40%',
        border: '1px solid',
        paddingLeft: '2%',
    },
    terms: {
        width: '50%',
        paddingLeft: '2%',
    },
    logo: {
        height: '10vh',
    },
    heading: {
        marginTop: '34px',
        marginLeft: '5%',
        textAlign: 'center',
    },
    logodiv: {
        display: 'flex',
    },
    paydiv: {
        textAlign: 'center',
        marginTop: '10px',
    },
    printButton: {
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
    },
};

export default Invoice;
