import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import AuthenticatesRoutes from './AuthenticatesRoutes';
import UnAuthenticatedRotes from './UnAuthenticatedRoutes';
import secureLocalStorage from 'react-secure-storage';


function AuthRoutes() {

    const isAuthenticated = secureLocalStorage.getItem('access') &&
        secureLocalStorage.getItem('refresh_token') &&
        secureLocalStorage.getItem('tenant') &&
        secureLocalStorage.getItem('loggedInUser')

    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            secureLocalStorage.removeItem('access');
            secureLocalStorage.removeItem('loggedInUser');
            secureLocalStorage.removeItem('tenant');
            secureLocalStorage.removeItem('refresh_token');
            // navigate('/login');
            // window.location.href = '/login';
        }
    }, [isAuthenticated, navigate]);

    return (
        <div>
            {isAuthenticated ?
                <AuthenticatesRoutes />
                :
                <UnAuthenticatedRotes />
            }
        </div>
    );
}

export default AuthRoutes;