import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import Auth from './component/globalFiles/authentication/Auth';
import Header from './component/globalFiles/Header';
import LandingPage from './component/globalFiles/LandingPage';
import { useEffect } from 'react';
import AuthRoutes from './routes/AuthRoutes';

function handleformatDate(date) {
  // Ensure input is a Date object
  if (!(date instanceof Date)) {
    throw new Error("Input must be a Date object");
  }
  
  // Extract day, month, and year
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  
  // Format date as dd-mm-yyyy
  return `${day}-${month}-${year}`;
}

// Example usage
const date = new Date();

function App() {

  return (
    <div className="App">
      <Router>
        <AuthRoutes />
      </ Router>
    </div>
  );
}

export default App;
export {handleformatDate}