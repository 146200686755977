// actions.js
import {
  GET_LEDGERS_REQUEST,
  GET_LEDGERS_SUCCESS,
  GET_LEDGERS_FAILURE
} from './actionTypes';
import { message } from 'antd';
import { getLedgersAPI } from '../../../api/Services';


/**
 * Get all parties
 */
export const getLedgersRequest = () => ({
  type: GET_LEDGERS_REQUEST
});

export const getLedgersSuccess = (response) => ({
  type: GET_LEDGERS_SUCCESS,
  payload: response
});

export const getLedgersFailure = error => ({
  type: GET_LEDGERS_FAILURE,
  payload: error
});


/**
 * Action Method for api call and send data to the backend side
 */

/**
 * To get the all Ledgers
 */
export const getLedgers = (data) => async (dispatch) => {
  dispatch(getLedgersRequest());
  try {
    const response = await getLedgersAPI(data)

    if (response) {
      dispatch(getLedgersSuccess(response))
    }
    return response;

  } catch (error) {
    dispatch(getLedgersFailure(error.message));
    throw error;
  }
};