import React, { useState, useEffect } from 'react';
import { Divider, Input, Checkbox, message } from 'antd';

const { Search } = Input;

const PaymentInTable = ({ invoices, setInvoices, paymentAmount, setPaymentAmount, totalRemainingAmount, setTotalRemainingAmount }) => {
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        let amountToBeSettled = parseFloat(paymentAmount).toFixed(2);

        const updatedInvoices = invoices.map((invoice) => {
            if (amountToBeSettled >= invoice.balance_amount) {
                amountToBeSettled -= parseFloat(invoice.balance_amount);
                return { ...invoice, settled_amount: parseFloat(invoice.balance_amount).toFixed(2), checked: true };
            } else if (amountToBeSettled > 0) {
                const settled = amountToBeSettled;
                amountToBeSettled = parseFloat(0).toFixed(2);
                return { ...invoice, settled_amount: parseFloat(settled).toFixed(2), checked: true };
            } else {
                return { ...invoice, settled_amount: parseFloat(0).toFixed(2), checked: false };
            }
        });

        setTotalRemainingAmount(parseFloat(amountToBeSettled).toFixed(2));
        setInvoices(updatedInvoices);
    }, [paymentAmount]);

    const handleCheckboxChange = (index) => {
        let updatedInvoices = [...invoices];
        let newRemainingAmount = parseFloat(totalRemainingAmount);

        if (newRemainingAmount <= 0 && !updatedInvoices[index].checked) {
            message.error("You can't check more than settled");
            return;
        }

        updatedInvoices[index].checked = !updatedInvoices[index].checked;

        if (!updatedInvoices[index].checked) {
            newRemainingAmount += parseFloat(updatedInvoices[index].settled_amount);
            updatedInvoices[index].settled_amount = parseFloat(0).toFixed(2);
        } else {
            // if (newRemainingAmount >= updatedInvoices[index].balance_amount) {
            //     newRemainingAmount -= updatedInvoices[index].balance_amount;
            //     updatedInvoices[index].settled_amount = parseFloat(updatedInvoices[index].balance_amount).toFixed(2);
            //     newRemainingAmount -= parseFloat(updatedInvoices[index].balance_amount);
            //     updatedInvoices[index].settled_amount = parseFloat(updatedInvoices[index].balance_amount).toFixed(2);
            // } else {
            //     updatedInvoices[index].settled_amount = parseFloat(newRemainingAmount).toFixed(2);
            //     newRemainingAmount = parseFloat(0);
            // }
            if (newRemainingAmount >= updatedInvoices[index].balance_amount) {
                newRemainingAmount -= parseFloat(updatedInvoices[index].balance_amount);
                updatedInvoices[index].settled_amount = parseFloat(updatedInvoices[index].balance_amount).toFixed(2);
            } else {
                updatedInvoices[index].settled_amount = parseFloat(newRemainingAmount).toFixed(2);
                newRemainingAmount = parseFloat(0);
            }
        }

        setTotalRemainingAmount(parseFloat(newRemainingAmount).toFixed(2));
        setInvoices(updatedInvoices);
    };

    return (
        <div>
            <Divider />
            <div className="flex justify-between items-center">
                <h4>Settle invoices with this payment</h4>
                <Search
                    placeholder="Search Invoice Number"
                    onSearch={(value) => setSearchText(value)}
                    className="w-52"
                />
            </div>
            <div className="mt-4 overflow-x-auto">
                <table className="table-auto text-center text-sm border-collapse border border-gray-200 w-full mt-8">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="border border-gray-300 px-4 py-2"></th>
                            <th className="border border-gray-300 px-4 py-2">Date</th>
                            <th className="border border-gray-300 px-4 py-2">Invoice Number</th>
                            <th className="border border-gray-300 px-4 py-2">Total Amount</th>
                            <th className="border border-gray-300 px-4 py-2">Amount Settled</th>
                            <th className="border border-gray-300 px-4 py-2">Remaining Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((item, index) => (
                            <tr key={item.key}>
                                <td className="border border-gray-300 px-4 py-2">
                                    <Checkbox
                                        checked={item.checked}
                                        onChange={() => handleCheckboxChange(index)}
                                    />
                                </td>
                                <td className="border border-gray-300 px-4 py-2">{item.date}</td>
                                <td className="border border-gray-300 px-4 py-2">{item.invoice_no}</td>
                                <td className="border border-gray-300 px-4 py-2">{item.total_amount} ({item.balance_amount})</td>
                                <td className="border border-gray-300 px-4 py-2">{item.settled_amount}</td>
                                <td className="border border-gray-300 px-4 py-2">{parseFloat(item.balance_amount).toFixed(2) - parseFloat(item.settled_amount).toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PaymentInTable;
