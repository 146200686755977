// reducer.js
import {
    SAVE_SALE_REQUEST,
    SAVE_SALE_SUCCESS,
    SAVE_SALE_FAILURE,

    GET_SALES_REQUEST,
    GET_SALES_SUCCESS,
    GET_SALES_FAILURE,

    DELETE_SALE_FAILURE,
    DELETE_SALE_SUCCESS,
    DELETE_SALE_REQUEST

} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    saveSale: {
        loading: false,
        error: null,
        response: null,
    },

    getSales: {
        loading: false,
        error: null,
        response: null,
    },

    deleteSale: {
        loading: false,
        error: null,
        response: null,
    }
};

const saleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_SALE_REQUEST:
            return {
                ...state,
                saveSale: {
                    ...state.saveSale,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_SALE_SUCCESS:
            return {
                ...state,
                saveSale: {
                    ...state.saveSale,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case SAVE_SALE_FAILURE:
            return {
                ...state,
                saveSale: {
                    ...state.saveSale,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };


        case GET_SALES_REQUEST:
            return {
                ...state,
                getSales: {
                    ...state.getSales,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_SALES_SUCCESS:
            return {
                ...state,
                getSales: {
                    ...state.getSales,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_SALES_FAILURE:
            return {
                ...state,
                getSales: {
                    ...state.getSales,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        // delete the sale
        case DELETE_SALE_REQUEST:
            return {
                ...state,
                deleteSale: {
                    ...state.deleteSale,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case DELETE_SALE_SUCCESS:
            return {
                ...state,
                deleteSale: {
                    ...state.deleteSale,
                    loading: false,
                    error: null,
                    response: action.payload
                },
            };
        case DELETE_SALE_FAILURE:
            return {
                ...state,
                deleteSale: {
                    ...state.deleteSale,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default saleReducer;