import React from 'react';
import { Form, Input, Button, Card, Alert } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { useDispatch, useSelector } from 'react-redux';
import { saveUnit } from './actions';

export default function UnitForm({ setNewUnit }) {
  const dispatch = useDispatch();

  const loading = useSelector(state => state?.units?.saveUnit?.loading);
  const error = useSelector(state => state?.units?.saveUnit?.error);

  const onFinish = async (values) => {
    const response = await dispatch(saveUnit(values));

    if (response?.success) {
      setNewUnit(false);
    }
  };

  return (
    <div>
      <div className="p-1 max-w-lg m-auto">
        <Form
          layout="vertical"
          name="UnitForm"
          onFinish={onFinish}
        >
          <Card title="Add Unit Name" size="small">
            <div className="flex items-end gap-3">
              <Form.Item
                name="name"
                className="flex-grow m-0"
                label="Unit Name"
                rules={[{ required: true, message: 'Please enter unit name' }]}
              >
                <Input placeholder="name" />
              </Form.Item>
              <Form.Item className="m-0">
                <Button className='bg-[#a854f9]' disabled={loading} type="primary" htmlType="submit">
                  <b>{loading ? "....." : ""}Save</b>
                </Button>
              </Form.Item>
            </div>
            {error && (
              <Alert message={error} type="error" showIcon style={{ marginTop: '10px' }} />
            )}
          </Card>
          {/* <div className='p-4'>

          </div> */}
        </Form>
      </div>
    </div>
  );
}
