import {
    SAVE_TCS_REQUEST,
    SAVE_TCS_SUCCESS,
    SAVE_TCS_FAILURE,
    GET_TCS_REQUEST,
    GET_TCS_SUCCESS,
    GET_TCS_FAILURE,
    SAVE_TDS_REQUEST,
    SAVE_TDS_SUCCESS,
    SAVE_TDS_FAILURE,
    GET_TDS_REQUEST,
    GET_TDS_SUCCESS,
    GET_TDS_FAILURE,
    GET_TDS_RECEIVABLE_CHARGED_REQUEST,
    GET_TDS_RECEIVABLE_CHARGED_SUCCESS,
    GET_TDS_RECEIVABLE_CHARGED_FAILURE,
    GET_TCS_RECEIVABLE_CHARGED_REQUEST,
    GET_TCS_RECEIVABLE_CHARGED_SUCCESS,
    GET_TCS_RECEIVABLE_CHARGED_FAILURE,
    GET_TCS_PAYABLE_CHARGED_REQUEST,
    GET_TCS_PAYABLE_CHARGED_SUCCESS,
    GET_TCS_PAYABLE_CHARGED_FAILURE,
    GET_TDS_PAYABLE_CHARGED_REQUEST,
    GET_TDS_PAYABLE_CHARGED_SUCCESS,
    GET_TDS_PAYABLE_CHARGED_FAILURE
} from './actionTypes';

const initialState = {
    saveTcs: {
        loading: false,
        error: null,
        response: null,
    },
    getTcs: {
        loading: false,
        error: null,
        response: null,
    },
    saveTds: {
        loading: false,
        error: null,
        response: null,
    },
    getTds: {
        loading: false,
        error: null,
        response: null,
    },
    getTdsReceivableCharged: {
        loading: false,
        error: null,
        response: null,
    },
    getTcsReceivableCharged: {
        loading: false,
        error: null,
        response: null,
    },
    getTcsPayableCharged: {
        loading: false,
        error: null,
        response: null,
    },
    getTdsPayableCharged: {
        loading: false,
        error: null,
        response: null,
    }
};

const tcsAndTdsReducer = (state = initialState, action) => {
    switch (action.type) {
        // TCS Actions
        case SAVE_TCS_REQUEST:
            return {
                ...state,
                saveTcs: {
                    ...state.saveTcs,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_TCS_SUCCESS:
            return {
                ...state,
                saveTcs: {
                    ...state.saveTcs,
                    loading: false,
                    error: null,
                    response: action.payload
                },
                getTcs: {
                    ...state.getTcs,
                    loading: false,
                    error: null,
                    response: [...state.getTcs.response, action.payload?.data]
                }
            };
        case SAVE_TCS_FAILURE:
            return {
                ...state,
                saveTcs: {
                    ...state.saveTcs,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        case GET_TCS_REQUEST:
            return {
                ...state,
                getTcs: {
                    ...state.getTcs,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_TCS_SUCCESS:
            return {
                ...state,
                getTcs: {
                    ...state.getTcs,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_TCS_FAILURE:
            return {
                ...state,
                getTcs: {
                    ...state.getTcs,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        // TDS Actions
        case SAVE_TDS_REQUEST:
            return {
                ...state,
                saveTds: {
                    ...state.saveTds,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_TDS_SUCCESS:
            return {
                ...state,
                saveTds: {
                    ...state.saveTds,
                    loading: false,
                    error: null,
                    response: action.payload
                },
                getTds: {
                    ...state.getTds,
                    loading: false,
                    error: null,
                    response: [...state.getTds.response, action.payload?.data]
                }
            };
        case SAVE_TDS_FAILURE:
            return {
                ...state,
                saveTds: {
                    ...state.saveTds,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        case GET_TDS_REQUEST:
            return {
                ...state,
                getTds: {
                    ...state.getTds,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_TDS_SUCCESS:
            return {
                ...state,
                getTds: {
                    ...state.getTds,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_TDS_FAILURE:
            return {
                ...state,
                getTds: {
                    ...state.getTds,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        // TDS Receivable Charged Actions
        case GET_TDS_RECEIVABLE_CHARGED_REQUEST:
            return {
                ...state,
                getTdsReceivableCharged: {
                    ...state.getTdsReceivableCharged,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_TDS_RECEIVABLE_CHARGED_SUCCESS:
            return {
                ...state,
                getTdsReceivableCharged: {
                    ...state.getTdsReceivableCharged,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_TDS_RECEIVABLE_CHARGED_FAILURE:
            return {
                ...state,
                getTdsReceivableCharged: {
                    ...state.getTdsReceivableCharged,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        // TCS Receivable Charged Actions
        case GET_TCS_RECEIVABLE_CHARGED_REQUEST:
            return {
                ...state,
                getTcsReceivableCharged: {
                    ...state.getTcsReceivableCharged,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_TCS_RECEIVABLE_CHARGED_SUCCESS:
            return {
                ...state,
                getTcsReceivableCharged: {
                    ...state.getTcsReceivableCharged,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_TCS_RECEIVABLE_CHARGED_FAILURE:
            return {
                ...state,
                getTcsReceivableCharged: {
                    ...state.getTcsReceivableCharged,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        // TCS Payable Charged Actions
        case GET_TCS_PAYABLE_CHARGED_REQUEST:
            return {
                ...state,
                getTcsPayableCharged: {
                    ...state.getTcsPayableCharged,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_TCS_PAYABLE_CHARGED_SUCCESS:
            return {
                ...state,
                getTcsPayableCharged: {
                    ...state.getTcsPayableCharged,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_TCS_PAYABLE_CHARGED_FAILURE:
            return {
                ...state,
                getTcsPayableCharged: {
                    ...state.getTcsPayableCharged,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        // TDS Payable Charged Actions
        case GET_TDS_PAYABLE_CHARGED_REQUEST:
            return {
                ...state,
                getTdsPayableCharged: {
                    ...state.getTdsPayableCharged,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_TDS_PAYABLE_CHARGED_SUCCESS:
            return {
                ...state,
                getTdsPayableCharged: {
                    ...state.getTdsPayableCharged,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_TDS_PAYABLE_CHARGED_FAILURE:
            return {
                ...state,
                getTdsPayableCharged: {
                    ...state.getTdsPayableCharged,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default tcsAndTdsReducer;
