import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPurchaseOrders } from '../../purchase/purchaseOrder/actions';
import PurchaseOrderList from '../../purchase/purchaseOrder/PurchaseOrderList';
import AppListHeader from '../../header/AppListHeader';
import { Input } from 'antd';
import { handleSearchText } from '../../../settings/codeSettings/additionalMethods';

const { Search } = Input;

export default function PurchaseOrderReport() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const purchaseOrderDatas = useSelector(state => state.purchaseOrders.getPurchaseOrders.response);

    const [searchText, setSearchText] = useState('')   // state for set the searched value
    const [filterQuery, setFilterQuery] = useState(null)                                                           // state for filter query


    useEffect(() => {
        getPurchaseOrdersData();
    }, [dispatch, searchText, filterQuery]);

    const getPurchaseOrdersData = async () => {
        if (searchText) {
            await dispatch(getPurchaseOrders({ search: searchText }));
        }
        else if (filterQuery) {
            await dispatch(getPurchaseOrders(filterQuery));
        }
        else {
            const response = await dispatch(getPurchaseOrders());
        }
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        setFilterQuery({
            ...filterQuery,
            ordering: sorter?.order == 'ascend' ?
                `${sorter?.field}`
                :
                sorter?.order == 'descend' ?
                    `-${sorter?.field}`
                    :
                    null,
        })
    }

    const handlePrint = () => {
        navigate('/purchase-invoice-bill/print', { state: { purchaseOrderDatas } });
    };

    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Assuming purchaseDatas is the list you want to export
        const wsData = purchaseOrderDatas?.purchase_orders?.results?.map((e, index) => [
            index + 1,
            e.date, // Replace with correct date format if necessary
            e.purchase_order_no,
            e.billed_to_company_name,
            e.total_amount,
        ]);

        const worksheet = XLSX.utils.aoa_to_sheet([
            [
                'S.No.',
                'Date',
                'PO Number',
                'Company Name',
                'Amount',
            ],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Purchase Orders');
        XLSX.writeFile(workbook, 'PurchaseOrders.xlsx');
    };

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            setFilterQuery({
                ...filterQuery,
                date: dateStrings[0],
                date_lt: dateStrings[1]
            })
        } else {
            console.log('Clear');
        }
    };

    const columns = [
        { title: 'Date', dataIndex: 'date' },
        { title: 'P O Number', dataIndex: 'purchase_order_no' },
        { title: 'Party Name', dataIndex: 'billed_to_company_name' },
        { title: 'Total Amount', dataIndex: 'total_amount' },
        // { title: 'Items', dataIndex: 'purchase_order_items' },
      ];

    return (
        <div>

            <AppListHeader
                headerContent='Purchase Order Report'
                excel={true}
                handleExcelExport={handleExportToExcel}
                print={true}
                download={false}
                cancel={true}
                printNavigation='/ListPrint'
                printData={{tableColunm:columns,tableData:purchaseOrderDatas?.purchase_orders?.results ,name:'Purchase Order List'}}
            />

            <div
                className="
                flex flex-wrap mb-4 shadow-md p-1
                "
            >
                <Search
                    placeholder="Search Delivery Challan"
                    allowClear
                    onSearch={(value) => handleSearchText(value, setSearchText)}
                    className='w-[250px]'
                />
            </div>

            <PurchaseOrderList purchaseOrderDatas={purchaseOrderDatas?.purchase_orders?.results} />
        </div>
    )
}
