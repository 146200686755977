// Actions Types for clents

export const SAVE_SALE_REQUEST = 'SAVE_SALE_REQUEST';
export const SAVE_SALE_SUCCESS = 'SAVE_SALE_SUCCESS';
export const SAVE_SALE_FAILURE = 'SAVE_SALE_FAILURE';

export const GET_SALES_REQUEST = 'GET_SALES_REQUEST';
export const GET_SALES_SUCCESS = 'GET_SALES_SUCCESS';
export const GET_SALES_FAILURE = 'GET_SALES_FAILURE';

export const DELETE_SALE_FAILURE = 'DELETE_SALE_FAILURE';
export const DELETE_SALE_SUCCESS = 'DELETE_SALE_SUCCESS';
export const DELETE_SALE_REQUEST = 'DELETE_SALE_REQUEST';
