import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getQuotations } from '../../sale/quotation/actions';
import QuotationList from '../../sale/quotation/QuotationList';
import AppListHeader from '../../header/AppListHeader';
import { Input } from 'antd';
import { handleSearchText } from '../../../settings/codeSettings/additionalMethods';
import * as XLSX from 'xlsx';

const { Search } = Input;

export default function QuotationReport() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const quotationDatas = useSelector(state => state.quotations.getQuotations.response);

    const [searchText, setSearchText] = useState('')   // state for set the searched value
    const [filterQuery, setFilterQuery] = useState(null)

    useEffect(() => {
        getQuotationsData()
    }, [dispatch, searchText, filterQuery]);


    const getQuotationsData = async () => {
        if (searchText) {
            await dispatch(getQuotations({ search: searchText }));
        }
        else if (filterQuery) {
            await dispatch(getQuotations(filterQuery));
        }
        else {
            const response = await dispatch(getQuotations());
        }
    };

    const handlePrint = () => {
        navigate('/quotation-bill/print', { state: { quotationDatas } });
    };

    const handleExportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Assuming quotationDatas is the list you want to export
        const wsData = quotationDatas?.quotations?.results?.map((e) => [
            e.date,
            e.quotation_no,
            e.billed_to,
            e.total_amount,
        ]) || [];

        const worksheet = XLSX.utils.aoa_to_sheet([
            ['Date', 'Quotation Number', 'Party Name', 'Total Amount'],
            ...wsData,
        ]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
        XLSX.writeFile(workbook, 'Quotations.xlsx');
    };

    const navigateToQuotationForm = () => {
        navigate('/sales/quotation-form');
    };

    const columns = [
        { title: 'Date', dataindex: 'date' },
        { title: 'Quotation Number', dataindex: 'quotation_no' },
        { title: 'Party Name', dataindex: 'billed_to_company_name' },
        { title: 'Total Amount', dataindex: 'total_amount' },
    ];

    return (
        <div>
            <AppListHeader
                headerContent='Quotation Report'
                excel={true}
                handleExcelExport={handleExportToExcel}
                print={true}
                download={false}
                cancel={true}
                printNavigation='/ListPrint'
                printData={{ tableColunm: columns, tableData:quotationDatas?.quotations?.results, name: 'Quotation List' }}
            />

            <div
                className="
                flex flex-wrap mb-4 shadow-md p-1
                "
            >
                <Search
                    placeholder="Search Quotation"
                    allowClear
                    onSearch={(value) => handleSearchText(value, setSearchText)}
                    className='w-[250px]'
                />
            </div>
            <QuotationList quotationDatas={quotationDatas?.quotations?.results} />
        </div>
    )
}
