import { Table, Tooltip } from 'antd';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function InvoiceList({tableColumns, data, handleTableChange }) {

    return (
        <div>
            <Table
                size='small'
                columns={tableColumns}
                // expandedRowClassName={() => 'bg-[#f5f5f0]'}
                dataSource={data}
                bordered
                pagination={false}
                onChange={handleTableChange}
                rowKey={(record, index) => index} // Assuming data array is not uniquely keyed
                // expandable={{
                //     expandedRowRender: (record) => (
                //         <>
                //             {customTable(record?.sale_items || record?.purchase_items)}
                //         </>
                //     ),
                //     rowExpandable: (record) => record.name !== 'Not Expandable',
                // }}
            />
        </div>
    )
}
