import React, { useState, useEffect } from 'react';
import { Form, message, Input, Button, DatePicker, InputNumber, Row, Col, Card, Divider, Select } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getParties } from '../../party/actions';
import { savePurchaseOrder } from './actions';
import { getItems } from '../../product/item/actions';
import { Link, useNavigate } from 'react-router-dom';



export default function PurchaseOrderForm() {

    const dispatch = useDispatch()

    const partyResponse = useSelector(state => state.parties.getParties.response)
    const loading = useSelector(state => state.parties.getParties.loading)
    const itemResponse = useSelector(state => state.items.getItems.response)
    const itemLoading = useSelector(state => state.items.getItems.loading)

    const savePurchaseOrderError = useSelector(state => state.purchaseOrders.savePurchaseOrder.error)

    const [form] = Form.useForm();
    const [items, setItems] = useState([{ amount: 0 }]);
    const [freightAmount, setFreightAmount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [balanceAmount, setBalanceAmount] = useState(0);
    const [error, setError] = useState(null); // Local state to manage error

    const navigate = useNavigate()

    useEffect(() => {
        /**
        * handle party state
        */
        if (
            partyResponse?.party_count == partyResponse?.parties?.results?.length &&
            partyResponse?.parties?.results?.length > 0
        ) {

        }
        else {
            dispatch(getParties())
        }
        /**
         * end of handling party state
         */

        /**
     * handle item state
     */
        if (
            itemResponse?.items_count == itemResponse?.items?.results?.length &&
            itemResponse?.items?.results?.length > 0
        ) {
        }
        else {
            dispatch(getItems())
        }
        /**
         * end of handling item state
         */
    }, [dispatch, partyResponse, itemResponse]);

    useEffect(() => {
        // Handle error messages in form fields and display a generic error message
        if (error) {
            setFormErrors(form, error);
            message.error("There were errors while saving. Please check your inputs.");
        }
    }, [error, form]);

    const setFormErrors = (form, error) => {
        const fieldErrors = Object.keys(error).map((field) => ({
            name: field,
            errors: error[field],
        }));
        form.setFields(fieldErrors);
    };

    const initialValues = {
        purchase_order_items: [{ amount: 0, discount: '00' }], // Your existing initial values for items
        // Initial value for discount
        // items: items,
        date: '',
        sub_total: 0,
        gst_amount: 0,
        total_amount: 0,
        description: '' // Add initial value for description
    };

    const onFinish = async (values) => {
        const formattedValues = {
            billed_to: values.billed_to,
            date: values.date,
            sub_total: parseFloat(values.sub_total),
            gst_amount: parseFloat(values.gst_amount),
            total_amount: parseFloat(values.total_amount),
            description: values.description,
            purchase_order_items: items.map(item => ({
                item: item.id,
                qty: parseFloat(item.qty),
                rate: parseFloat(item.rate),
                discount: parseFloat(item.discount),
                total: parseFloat(item.amount),
                gst_amount: parseFloat(((item.qty * item.rate) * item.gst) / 100).toFixed(2),
                amount: parseFloat(item.amount),
            })),
        };

        const response = await dispatch(savePurchaseOrder(formattedValues));

        if (response?.success) {
            navigate(-1);
        } else {
            setError(savePurchaseOrderError); // Set error to local state to trigger useEffect
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleItemChange = (index, field, value) => {

        const newItems = [...items];
        newItems[index][field] = value;

        if (field == "item") {
            const itemObj = itemResponse?.items?.results?.find(dt => dt?.id == value)
            newItems[index].rate = itemObj?.current_rate
            newItems[index].gst = itemObj?.gst_percentage
            newItems[index].id = itemObj?.id
            newItems[index].hsn = itemObj?.hsn_code
        }

        newItems[index].amount = calculateAmount(newItems[index]); // Update amount when fields change
        setItems(newItems);
        form.setFieldsValue({ purchase_order_items: newItems }); // Update form state
        updateTotalValues(newItems, freightAmount); // Update totals when an item changes
    };

    const calculateAmount = (item) => {
        const rate = parseFloat(item.rate || 0);
        const qty = parseFloat(item.qty || 0);
        const discount = parseFloat(item.discount || 0);
        const gst = parseFloat(item.gst || 0);
        const total = (rate * qty);
        const gstAmount = (total * gst) / 100;
        return Math.round((total + gstAmount - discount).toFixed(2));
    };

    const updateTotalValues = (updatedItems, freight) => {
        let subtotal = updatedItems.reduce((sum, item) => sum + parseFloat((item.qty * item.rate) || 0), 0);
        const gstTotal = updatedItems.reduce((sum, item) => sum + ((parseFloat(item.rate || 0) * parseFloat(item.qty || 0)) * (parseFloat(item.gst || 0) / 100)), 0);
        const totalDiscount = updatedItems.reduce((sum, item) => sum + parseFloat((item.discount) || 0), 0);
        const totalAmount = parseFloat(subtotal) + gstTotal - totalDiscount + parseFloat(freight || 0);

        form.setFieldsValue({
            sub_total: subtotal.toFixed(2),
            gst_amount: gstTotal.toFixed(2),
            total_amount: totalAmount.toFixed(2),
        });

        updateBalanceAmount(totalAmount, amountPaid);

    };

    const updateBalanceAmount = (totalAmount, amountPaid) => {
        const balance = totalAmount - amountPaid;
        setBalanceAmount(balance.toFixed(2));
        // form.setFieldsValue({
        //     balance_amount: balance.toFixed(2),
        // });
    };
    const validateNumber = (maxLength) => (_, value) => {
        if (!value) {
            return Promise.resolve();
        }
        const regex = new RegExp(`^\\d{1,${maxLength}}$`);
        if (!regex.test(value)) {
            return Promise.reject(`Please enter a valid number with up to ${maxLength} digits.`);
        }
        return Promise.resolve();
    };

    useEffect(() => {
        updateTotalValues(items, freightAmount);
    }, [items, freightAmount]);

    useEffect(() => {
        form.setFieldsValue({ items });
    }, [items, form]);

    useEffect(() => {
        const totalAmount = parseFloat(form.getFieldValue('total_amount') || 0);
        updateBalanceAmount(totalAmount, amountPaid);
    }, [amountPaid]);

    return (
        <div className="px-4 md:px-8">
            <Form
                form={form}
                name="purchase_order_form"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="mx-auto bg-white rounded-md shadow-md"
                initialValues={initialValues}
            >
                <div className="flex flex-col md:flex-row justify-between sticky top-[65px] mb-4 z-10 bg-white">
                    <h2 className="text-2xl font-semibold mb-2 md:mb-0">Create Purchase Order</h2>
                    <div className="flex space-x-2">
                        <Button type="primary" htmlType="submit" className="bg-purple-500 border-none shadow-inner">
                            <b>Save</b>
                        </Button>
                        <Button
                            htmlType="button"
                            className="shadow-inner"
                            onClick={() => navigate(-1)}
                        >
                            <b>Cancel</b>
                        </Button>
                    </div>
                </div>
    
                <Card title="Billing Details" size="small" className="w-full mb-4 shadow-md">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Form.Item
                            label="Party"
                            name="billed_to"
                            rules={[{ required: true, message: 'Please select party!' }]}
                        >
                            <Select
                                placeholder='Select party'
                                showSearch
                                optionFilterProp='children'
                                dropdownRender={menu => (
                                    <>
                                        <div className="flex p-2">
                                            <Link className='text-blue-500' to='/party-form'>New Party</Link>
                                        </div>
                                        <Divider style={{ margin: '0px 0' }} />
                                        {menu}
                                    </>
                                )}
                            >
                                {partyResponse?.parties?.results?.map((data, id) => (
                                    <Select.Option key={id} value={data.id}>{data.company_name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
    
                        <Form.Item
                            label="Purchase Inv Date"
                            name="date"
                        >
                            <Input type='date' />
                        </Form.Item>
                    </div>
                </Card>
                <Form.List name="items">
    {(fields, { add, remove }) => (
        <div>
            <div className="overflow-x-auto mb-6 shadow-md">
                <table className="min-w-full">
                    <thead>
                        <tr>
                            <th className="p-2 border border-gray-300">NO</th>
                            <th className="p-2 border border-gray-300">ITEMS/ SERVICES</th>
                            <th className="p-2 border border-gray-300">HSN/ SAC</th>
                            <th className="p-2 border border-gray-300">QTY</th>
                            <th className="p-2 border border-gray-300">PRICE/ITEM (₹)</th>
                            <th className="p-2 border border-gray-300">DISCOUNT</th>
                            <th className="p-2 border border-gray-300">GST</th>
                            <th className="p-2 border border-gray-300">AMOUNT (₹)</th>
                            <th className="p-2 border border-gray-300">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                            <tr key={key}>
                                <td className="p-2 border border-gray-300">{index + 1}</td>
                                <td className="p-2 border border-gray-300">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'item']}
                                        fieldKey={[fieldKey, 'item']}
                                        rules={[{ required: true, message: 'Missing item name' }]}
                                        className="mb-0 w-full"
                                    >
                                        <Select
                                            placeholder='Select Item'
                                            showSearch
                                            optionFilterProp='children'
                                            onChange={(value) => handleItemChange(index, 'item', value)}
                                        >
                                            {itemResponse?.items?.results?.map((data) => (
                                                <Select.Option key={data?.id} value={data?.id}>
                                                    {data?.item_name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </td>
                                <td className="p-2 border border-gray-300">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'hsn']}
                                        fieldKey={[fieldKey, 'hsn']}
                                        rules={[{ required: true, message: 'Missing HSN code' }]}
                                        className="mb-0"
                                    >
                                        <Input onChange={(e) => handleItemChange(index, 'hsn', e.target.value)} />
                                    </Form.Item>
                                </td>
                                <td className="p-2 border border-gray-300">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'qty']}
                                        fieldKey={[fieldKey, 'qty']}
                                        rules={[
                                            { required: true, message: 'Missing quantity' },
                                            { validator: validateNumber(3) }
                                        ]}
                                        className="mb-0"
                                    >
                                        <Input onChange={(e) => handleItemChange(index, 'qty', e.target.value)} defaultValue={0} />
                                    </Form.Item>
                                </td>
                                <td className="p-2 border border-gray-300">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'rate']}
                                        fieldKey={[fieldKey, 'rate']}
                                        className="mb-0"
                                    >
                                        <Input
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^0-9.]/g, '');
                                                if ((value.match(/\./g) || []).length <= 1) {
                                                    handleItemChange(index, 'rate', value);
                                                }
                                            }}
                                            maxLength={10}
                                        />
                                    </Form.Item>
                                </td>
                                <td className="p-2 border border-gray-300">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'discount']}
                                        fieldKey={[fieldKey, 'discount']}
                                        rules={[{ validator: validateNumber(6) }]}
                                        className="mb-0"
                                    >
                                        <Input
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^0-9.]/g, '');
                                                if ((value.match(/\./g) || []).length <= 1) {
                                                    handleItemChange(index, 'discount', value);
                                                }
                                            }}
                                            maxLength={10}
                                        />
                                    </Form.Item>
                                </td>
                                <td className="p-2 border border-gray-300">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'gst']}
                                        fieldKey={[fieldKey, 'gst']}
                                        rules={[{ required: true, message: 'Missing GST' }]}
                                        className="mb-0"
                                    >
                                        <Input onChange={(e) => handleItemChange(index, 'gst', e.target.value)} readOnly />
                                    </Form.Item>
                                </td>
                                <td className="p-2 border border-gray-300">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'amount']}
                                        fieldKey={[fieldKey, 'amount']}
                                        className="mb-0"
                                    >
                                        <Input readOnly onChange={(e) => handleItemChange(index, 'amount', e.target.value)} />
                                    </Form.Item>
                                </td>
                                <td className="p-2 border border-gray-300">
                                    <Button
                                        type="danger"
                                        onClick={() => {
                                            const newItems = [...items];
                                            newItems.splice(index, 1);
                                            setItems(newItems);
                                            form.setFieldsValue({ items: newItems });
                                            updateTotalValues(newItems, freightAmount);
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="9" className="p-2 border border-gray-300">
                                <Button type="dashed" onClick={() => {
                                    add();
                                    setItems([...items, { amount: 0 }]);
                                }} block>
                                    Add Item
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )}
</Form.List>

    
                <div className="mt-4 flex flex-col md:flex-row justify-between">
                    <div className='md:w-[45%] w-full'>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Enter the description' }]}
                        >
                            <Input.TextArea
                                placeholder="Enter Description"
                                className="w-full h-10"
                                autoSize={{ minRows: 5, maxRows: 8 }}
                            />
                        </Form.Item>
                    </div>
                    <Card className="text-left md:w-[45%] w-full shadow-lg p-4 bg-[rgba(0,0,0,0.02)]">
                        <Form.Item
                            rules={[{ required: true, message: 'Required' }]}
                            name="sub_total"
                            className='m-0 p-0'
                        >
                            <div className='flex justify-between font-bold'>
                                <div>Sub Total</div>
                                <div>{form.getFieldValue('sub_total') ? form.getFieldValue('sub_total') : '0.00'}</div>
                            </div>
                        </Form.Item>
                        <Form.Item
                            name="gst_amount"
                            className='p-0 m-0'
                        >
                            <div className='flex justify-between'>
                                <div>GST Amount</div>
                                <div>{form.getFieldValue('gst_amount') ? form.getFieldValue('gst_amount') : '0.00'}</div>
                            </div>
                        </Form.Item>
                        <Divider />
                        <Form.Item
                            name="total_amount"
                            className='m-0 p-0'
                        >
                            <div className='flex justify-between font-bold'>
                                <div>Amount</div>
                                <div>{form.getFieldValue('total_amount') ? form.getFieldValue('total_amount') : '0.00'}</div>
                            </div>
                        </Form.Item>
                    </Card>
                </div>
            </Form>
        </div>
    );
}    
