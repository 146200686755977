// reducer.js
import {
    SAVE_ITEM_REQUEST,
    SAVE_ITEM_SUCCESS,
    SAVE_ITEM_FAILURE,

    GET_ITEMS_REQUEST,
    GET_ITEMS_SUCCESS,
    GET_ITEMS_FAILURE,

    UPDATE_ITEM_REQUEST,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_FAILURE

} from './actionTypes';

const initialState = {
    loading: false,
    error: null,
    response: null,

    saveItem: {
        loading: false,
        error: null,
        response: null,
    },
    getItems: {
        loading: false,
        error: null,
        response: null,
    },
    updateItem: {
        loading: false,
        error: null,
        response: null,
    }

};

const itemReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_ITEM_REQUEST:
            return {
                ...state,
                saveItem: {
                    ...state.saveItem,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case SAVE_ITEM_SUCCESS:
            return {
                ...state,
                saveItem: {
                    ...state.saveItem,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case SAVE_ITEM_FAILURE:
            return {
                ...state,
                saveItem: {
                    ...state.saveItem,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };


        case GET_ITEMS_REQUEST:
            return {
                ...state,
                getItems: {
                    ...state.getItems,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case GET_ITEMS_SUCCESS:
            return {
                ...state,
                getItems: {
                    ...state.getItems,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case GET_ITEMS_FAILURE:
            return {
                ...state,
                getItems: {
                    ...state.getItems,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        // update the items
        case UPDATE_ITEM_REQUEST:
            return {
                ...state,
                updateItem: {
                    ...state.updateItem,
                    loading: true,
                    error: null,
                    response: null
                }
            };
        case UPDATE_ITEM_SUCCESS:
            return {
                ...state,
                updateItem: {
                    ...state.updateItem,
                    loading: false,
                    error: null,
                    response: action.payload
                }
            };
        case UPDATE_ITEM_FAILURE:
            return {
                ...state,
                updateItem: {
                    ...state.updateItem,
                    loading: false,
                    error: action.payload,
                    response: null
                }
            };

        default:
            return state;
    }
};

export default itemReducer;
