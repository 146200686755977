import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { customTable } from '../../../settings/codeSettings/additionalMethods';

const PurchaseDashboardTable = ({ columns, data, tableColumns, handleTableChange }) => {

  /**
   * Data to show inside the table (sale items)
   */
  const childTableColumns = [
    { title: 'Item Name', dataIndex: 'item_name', width: 150 },
    { title: 'Hsn No', dataIndex: 'item_hsn_code', width: 100 },
    { title: 'Qty', dataIndex: 'qty', width: 100 },
    { title: 'Rate', dataIndex: 'rate', width: 100 },
    { title: 'Total', dataIndex: 'total', width: 100 },
    { title: 'Gst Amount', dataIndex: 'gst_amount', width: 100 },
    { title: 'Amount', dataIndex: 'amount', width: 100 },
  ];

  return (
    <div className="mt-4 overflow-x-auto">
      <Table
        size="small"
        columns={tableColumns}
        dataSource={data}
        bordered
        pagination={false}
        onChange={handleTableChange}
        rowKey={(record) => record.id || record.key || Math.random()} // Use a unique key if available
        onRow={(record) => ({
          style: { backgroundColor: record?.payment_status === 'paid' ? '#e6ffe6' : '' },
        })}
        expandable={{
          expandedRowRender: (record) => (
            <>{customTable(childTableColumns, record?.purchase_items)}</>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        expandedRowClassName={() => 'bg-[#f5f5f0]'}
        scroll={{ x: 'max-content' }} // Enable horizontal scroll
        // components={{
        //   body: {
        //     cell: (props) => (
        //       <td className="text-xs sm:text-sm">{props.children}</td>
        //     ),
        //   },
        // }}
      />
    </div>
  );
};

PurchaseDashboardTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableColumns: PropTypes.array.isRequired, // Add prop types for tableColumns
  handleTableChange: PropTypes.func.isRequired, // Add prop types for handleTableChange
};

export default PurchaseDashboardTable;
