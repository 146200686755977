import React, { useEffect } from 'react'
import DynamicTable from '../tables/DynamicTable';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Pagination } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';


export default function PartyList({ paginationDetail, handlePageChange, onShowSizeChange, parties, handleTableChange }) {

  /**
   * To create for header and access key
   */
  const columns = [
    { Header: 'Party Name', accessor: 'company_name' },
    { Header: 'Mobile No', accessor: 'mobile_no' },
    { Header: 'Party Type', accessor: 'party_type' },
    { Header: 'State', accessor: 'state' },
    { Header: 'City', accessor: 'city' },
    { Header: 'GST No', accessor: 'gst_no' },
    { Header: 'Opening Balance', accessor: 'opening_balance' },
    { Header: 'Current Balance', accessor: 'current_balance' },
  ];

  const navigate = useNavigate();

  // Function to handle navigation
  const handleView = (record) => {
    navigate("/party-Parties", { state: { companyDetail: record } });
  };

  // Function to handle navigation
  const handleUpdateView = (record) => {
    navigate("/party-form", { state: { partyDetail: record } });
  };

  // Convert columns to the format expected by Ant Design Table
  const tableColumns = columns.map((column) => ({
    title: column.Header,
    dataIndex: column.accessor,
    key: column.accessor,
    // sorter: column.accessor == 'company_name' ? true : false,
    sorter: ['company_name', 'party_type', 'opening_balance', 'current_balance', 'state', 'gst_no'].includes(column.accessor),
    // ellipsis: true,
    // filters: column.accessor === 'company_name' ? companyNameFilters : null
  }));

  // Add an action column
  tableColumns.push({
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <span className="flex space-x-2">
        <Tooltip title="View" color="black">
          <button className="p-1 mx-0.5 bg-black rounded" onClick={() => handleView(record)}>
            <EyeOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip>
        <Tooltip title="Edit" color="blue">
          <button className="p-1 mx-0.5 bg-blue-500 rounded" onClick={() => handleUpdateView(record)}>
            <EditOutlined className="text-white p-0 m-0" />
          </button>
        </Tooltip>
      </span>
    ),
  });

  return (
    <div>
      <DynamicTable
        columns={columns}
        // pageSize={pageSize}
        data={parties}
        tableColumns={tableColumns}
        handleTableChange={handleTableChange}
      />
      {/* <Pagination
          defaultCurrent={paginationDetail?.currentPage}
          current={paginationDetail?.currentPage}
          total={paginationDetail?.partyCount}
          defaultPageSize={paginationDetail?.pageSize}
          pageSize={paginationDetail?.pageSize}
          onChange={handlePageChange}
          // simple
          // showSizeChanger
          onShowSizeChange={onShowSizeChange}
          // pageSizeOptions={[5, 10, 20, 50, 100]}
          className='mt-1 w-[350px] mx-auto flex justify-center'
          size='small'
        /> */}
    </div>
  )
}
