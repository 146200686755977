import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Table } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { customTable } from '../../../settings/codeSettings/additionalMethods';

const ExpensesTable = ({ columns, data, tableColumns, handleTableChange }) => {
  
  const childTableColumns = [
    { title: 'Category Name', dataIndex: 'category_name' },
    { title: 'Amount', dataIndex: 'amount' },
    { title: 'Description', dataIndex: 'description' },
  ];

  return (
    <div className="overflow-scroll scrollbar-thin scrollbar-thumb-red-500 scrollbar-track-orange-200 mt-4">
      <Table
      size='small'
      // indentSize={100}
        columns={tableColumns}
        dataSource={data}
        style={{fontSize:'xx-large' }} 
        bordered
        pagination={false}
        onChange={handleTableChange}
        rowKey={(record, index) => index} 
        onRow={(record) => ({
          // onClick: () => handleView(record),
          style: {backgroundColor: record?.payment_status === 'paid' ? '#e6ffe6' : ''}
        })}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {customTable(childTableColumns, record?.expense_items)}
            </>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        expandedRowClassName={() => 'bg-[#f5f5f0]'}
      />
    </div>
  );
  
};

export default ExpensesTable;

