import React from 'react'
import './AuthenticatedRoutes.css'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'
import Dashboard from '../component/dashboard/Dashboard'
import Sidebar from '../component/sidebar/Sidebar'
import CategoryList from '../component/product/category/CategoryList'
import PartyDashboard from '../component/party/PartyDashboard'
import PartyForm from '../component/party/PartyForm'
import PurchaseForm from '../component/purchase/purchaseInvoice/PurchaseForm'
import ItemForm from '../component/product/item/ItemForm'
import Purchaseout from '../component/purchase/paymentOut/PaymentOutDashboard'
import DeliveryChalan from '../component/sale/deliveryChallan/DeliveryChalanDashboard'
import ItemDashboard from '../component/product/item/ItemDashboard'
import SalesForm from '../component/sale/saleInvoice/SalesForm'

import CreatePurchaseorder from '../component/purchase/purchaseOrder/CreatePurchaseOrder'
import PurchaseOrder from '../component/purchase/purchaseOrder/PurchaseOrderDashboard'

// import QuotationForm from '../component/sale/quotation/Quotationform'
import QuotationForm from '../component/sale/quotation/Quotationform'
import DeliveryChallanForm from '../component/sale/deliveryChallan/Delivaryform'
import CreatePaymentIn from '../component/sale/paymentIn/CreatePaymentIn'
import PaymentInForm from '../component/sale/paymentIn/PaymentInForm'
import UpdateClientForm from '../component/client/UpdateClientForm'

// import PurchaseDashboard from '../component/purchase/Purchase/PurchaseDashboard'

// import PurchaseInvoice from '../component/purchase/Purchase/PurchaseInvoice'
import PurchaseDashboard from '../component/purchase/purchaseInvoice/PurchaseDashboard'
import SalesDashboard from '../component/sale/saleInvoice/SalesDashboard'
// import QuotationDashboard from '../component/sale/quotation/QuatationDashboard'
import QuotationDashboard from '../component/sale/quotation/QuatationDashboard'
import PaymentIn from '../component/sale/paymentIn/PaymentInDashboard'
import CreatePaymentOut from '../component/purchase/paymentOut/CreatePaymentOut'

import SaleInvoiceBill from '../component/sale/SaleInvoiceBill'
import PurchaseInvoiceBill from '../component/purchase/purchaseInvoice/PurchaseInvoiceBill'
import Header from '../component/globalFiles/Header'

import PurchaseorderinvoiceBill from '../component/purchase/purchaseOrder/PurchaseOrderInvoiceBill'
import DelieveryChallanInvoiceBill from '../component/sale/deliveryChallan/DelieveryChallanInvoiceBill'
import QuotationInvoiceBill from '../component/sale/quotation/QuotationInvoiceBill'


// import SaleInvoicePrint from '../component/sale/saleInvoice/SaleInvoicePrint'
// import Profile from '../component/party/Profile'
// import Ledger from '../component/party/Ledger'
// import Parties from '../component/party/Parties'
// import Profile from '../component/party/Profile'
import Parties from '../component/party/parties/Parties'
import EditPartyForm from '../component/client/UpdateClientForm'
import SaleInvoiceListPrint from '../component/sale/saleInvoice/SaleInvoiceListPrint'
import DeliverychallanListprint from '../component/sale/deliveryChallan/DeliveryChallanListPrint'
import PurchaseInvoiceListPrint from '../component/purchase/purchaseInvoice/PurchaseInvoiceListPrint'
import PurchaseOrderListPrint from '../component/purchase/purchaseOrder/PurchaseOrderListPrint'
import QuotationListPrint from '../component/sale/quotation/QuotationListPrint'
import PaymentOutListPrint from '../component/purchase/paymentOut/PaymentOutListPrint'
import PaymentInListPrint from '../component/sale/paymentIn/PaymentInListPrint'

// import './AuthRoutes';



import { useState } from 'react'
import Report from '../component/report/Report'
import Ledger from '../component/party/ledger/Ledger'
import DayBook from '../component/report/dayBook/DayBook'
import ItemWiseReport from '../component/report/itemWiseReport/ItemWiseReport'
import ItemInvoiceReport from '../component/report/itemWiseReport/ItemInvoiceReport'
import SaleReport from '../component/report/voucherReport/SaleReport'
import PurchaseReport from '../component/report/voucherReport/PurchaseReport'
import DeliveryChallanReport from '../component/report/voucherReport/DeliveryChallanReport'
import PurchaseOrderReport from '../component/report/voucherReport/PurchaseOrderReport'
import QuotationReport from '../component/report/voucherReport/QuotationReport'
import PaymentReport from '../component/report/payment/PaymentReport'

import TcsPayable from '../component/taxModule/TcsPayable'
import TcsReceivable from '../component/taxModule/TcsReceivable'
import TdsPayable from '../component/taxModule/TdsPayable'
import TdsReceivable from '../component/taxModule/TdsReceivale'

import ListPrint from '../component/printingFolder/ListPrint'
import Invoice from '../component/printingFolder/format/Invoice'
import InvoiceY from '../component/printingFolder/format/InvoiceY'
import GstR1Report from '../component/report/gstTax/GstR1/GstR1Report'
import GstR2Report from '../component/report/gstTax/GstR2/GstR2Report'
import GstR3Report from '../component/report/gstTax/GstR3/GstR3Report'
import ExpenseDashboard from '../component/expenses/expenses/ExpensesDashboard'
import CreateExpense from '../component/expenses/expenses/Expensesform'
import ExpenseNameForm from '../component/expenses/categary/ExpenseNameForm'
import ExpensesView from '../component/expenses/expenses/ExpensesView'
import ExpeneseInvoiceBill from '../component/expenses/expenses/ExpensesInvoiceBill'

import PrintDashboard from '../component/printingFolder/format/printdashboard'

export default function AuthenticatesRoutes() {


  const [isOpen, setIsOpen] = useState(false);
  const [isItemsOpen, setIsItemsOpen] = useState(window.location.pathname.startsWith('/items'));
  const [isPurchaseOpen, setIsPurchaseOpen] = useState(window.location.pathname.startsWith('/purchase'));
  const [issaleOpen, setIssaleOpen] = useState(window.location.pathname.startsWith('/sale'));

  const toggleItems = () => {
    setIsItemsOpen(!isItemsOpen);
  };

  const togglePurchase = () => {
    setIsPurchaseOpen(!isPurchaseOpen);
  };

  const toggleSale = () => {
    setIssaleOpen(!issaleOpen);
  };

  const onCloseSidebar = () => {
    setIsOpen(false);
  };
  return (
    <div className='sidebar-container'>
      <Sidebar
        isOpen={isOpen}
        isItemsOpen={isItemsOpen}
        isPurchaseOpen={isPurchaseOpen}
        issaleOpen={issaleOpen}
        toggleItems={toggleItems}
        togglePurchase={togglePurchase}
        toggleSale={toggleSale}
        onCloseSidebar={onCloseSidebar}
      />

      <div className="content">
        <Header toggleSidebar={() => setIsOpen(!isOpen)} />
        {/* <div> */}
        {/* <Header toggleSidebar=[()=>setIsOpend(isOpen)]/> */}
        {/* <div className="content bg-[#e6e6ff] h-[100vh] overflow-y-auto"> */}
        <div className='rounded-lg bg-white p-1'>
          <Routes>

            <Route path="/sale-invoice-bill/print" element={<SaleInvoiceListPrint />} />


            <Route path="/sale-invoice-bill" element={<SaleInvoiceBill />} />
            <Route path="/delivery-challan-bill/print" element={<DeliverychallanListprint />} />

            <Route path="/purchase-invoice-bill/print" element={<PurchaseInvoiceListPrint />} />

            <Route path="/purchase-order-bill/print" element={<PurchaseOrderListPrint />} />

            <Route path="/quotation-bill/print" element={<QuotationListPrint />} />

            <Route path="/payment-out-bill/print" element={<PaymentOutListPrint />} />

            <Route path="/payment-in-bill/print" element={<PaymentInListPrint />} />






            <Route path="/purchase-invoice-bill" element={<PurchaseInvoiceBill />} />


            <Route path="/quotation-invoice-bill" element={<QuotationInvoiceBill />} />
            <Route path="/delivery-challan-invoice-bill" element={<DelieveryChallanInvoiceBill />} />
            <Route path="/purchase-order-invoice-bill" element={<PurchaseorderinvoiceBill />} />




            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/update-client" element={<UpdateClientForm />} />
            <Route path="/customer-view" element={<PartyDashboard />} />
            <Route path="/party-form" element={<PartyForm />} />
            <Route path="/party-Parties" element={<Parties />} />


            {/* <Route path="/party-profile" element={<Profile />} />
                        <Route path="/party-Ledger" element={<Ledger />} />
                        <Route path="/party-Parties" element={<Parties />} /> */}


            <Route path="/product/category" element={<CategoryList />} />
            <Route path="/product/item" element={<ItemDashboard />} />
            <Route path="/product/form" element={<ItemForm />} />

            {/* <Route path="/purchase" element={<PurchaseDashboard />} /> */}

            {/* <Route path="purchase/purchase-invoice" element={<PurchaseInvoice />} /> */}
            <Route path="purchase/purchase-invoice" element={<PurchaseDashboard />} />


            <Route path="/purchase/purchase-form" element={<PurchaseForm />} />
            {/* <Route path="/purchase/purchase-order-form" element={<PurchaseOrderForm />} /> */}

            <Route path="/purchase/purchase-order-form" element={<CreatePurchaseorder />} />
            <Route path="/purchase/purchase-order" element={<PurchaseOrder />} />


            <Route path="/purchase/purchaseout" element={<Purchaseout />} />
            <Route path="/purchase/create-payment-out" element={<CreatePaymentOut />} />

            {/* <Route path="/sales/saleinvoice" element={<SalesInvoice />} /> */}
            <Route path="/sales/sale-dashboard" element={<SalesDashboard />} />

            {/* <Route path="/sales/paymentin" element={<PaymentInForm />} /> */}

            <Route path="/sales/paymentin" element={<PaymentIn />} />
            <Route path="/sales/CreatePaymentIn" element={<CreatePaymentIn />} />



            {/* <Route path="/sales/payment-in-form" element={<TableSelection />} /> */}
            {/* <Route path="/sales/payment-in-form" element={<PaymentInForm />} /> */}
            {/* <Route path="/sales/quotation" element={<Quotation />} /> */}
            <Route path="/sales/quotation-form" element={<QuotationForm />} />
            <Route path="/sales/quotation-dashboard" element={<QuotationDashboard />} />
            <Route path="/sales-form" element={<SalesForm />} />
            <Route path="/sales/deliverychalan" element={<DeliveryChalan />} />
            <Route path="/sales/delivery-challan-form" element={<DeliveryChallanForm />} />

            <Route path="/reports" element={<Report />} />
            <Route path="/reports/ledger" element={<Ledger />} />
            <Route path="/reports/daybook" element={<DayBook />} />
            <Route path="/item-wise-report" element={<ItemWiseReport />} />
            <Route path="/item-wise-report/invoices" element={<ItemInvoiceReport />} />
            <Route path="/reports/sales-report" element={<SaleReport />} />
            <Route path="/reports/purchases-report" element={<PurchaseReport />} />
            <Route path="/reports/delivery-challan-report" element={<DeliveryChallanReport />} />
            <Route path="/reports/purchase-order-report" element={<PurchaseOrderReport />} />
            <Route path="/reports/quotation-report" element={<QuotationReport />} />
            <Route path="/reports/payments" element={<PaymentReport />} />

            <Route path="/TcsReceivable" element={<TcsReceivable />} />
            <Route path="/TcsPayable" element={<TcsPayable />} />
            <Route path="/TdsReceivable" element={<TdsReceivable />} />
            <Route path="/TdsPayable" element={<TdsPayable />} />

            <Route path="/ListPrint" element={<ListPrint />} />
            <Route path="/invoice/print" element={<Invoice />} />
            <Route path="/invoice2/print" element={<InvoiceY />} />
            <Route path="/invoice2/print-dashboard" element={<PrintDashboard />} />


            {/* GST Report */}
            <Route path='/reports/gst-gstr1' element={<GstR1Report />} />
            <Route path='/reports/gst-gstr2' element={<GstR2Report />} />
            <Route path='/reports/gst-gstr3' element={<GstR3Report />} />
            <Route path='/expenses' element={<ExpenseDashboard />} />
            {/* <Route path='/expenses-form' element={<CreateExpense />} /> */}
            <Route path='/create-category' element={<ExpenseNameForm />} />

            <Route path='/expenses-view' element={<ExpensesView />} />

            {/* <Route path='/expenses-invoice-bill' element={<ExpeneseInvoiceBill />} /> */}


            <Route path="expense/expense-form" element={<CreateExpense />} />


            <Route path="*" element={<div className='text-[25px]'> not found</div>} />
          </Routes>
        </div>
        {/* </div> */}
      </div>
    </div>
  )
}
