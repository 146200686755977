// Action Types for saving an ExpenseCategory
export const SAVE_EXPENSE_CATEGORY_REQUEST = 'SAVE_EXPENSE_CATEGORY_REQUEST';
export const SAVE_EXPENSE_CATEGORY_SUCCESS = 'SAVE_EXPENSE_CATEGORY_SUCCESS';
export const SAVE_EXPENSE_CATEGORY_FAILURE = 'SAVE_EXPENSE_CATEGORY_FAILURE';

// Action Types for getting all ExpenseCategories
export const GET_EXPENSE_CATEGORIES_REQUEST = 'GET_EXPENSE_CATEGORIES_REQUEST';
export const GET_EXPENSE_CATEGORIES_SUCCESS = 'GET_EXPENSE_CATEGORIES_SUCCESS';
export const GET_EXPENSE_CATEGORIES_FAILURE = 'GET_EXPENSE_CATEGORIES_FAILURE';


